import React from 'react';

export const HighlightText = ({ text, highlight }) => {
  if (!highlight) {
    return <span>{text}</span>;
  }

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const escapedHighlight = escapeRegExp(highlight);
  const regex = new RegExp(`(${escapedHighlight})`, 'gi');
  const parts = text?.split(regex);

  return (
    <span>
      {parts?.map((part, index) =>
        part?.toLowerCase() === highlight?.toLowerCase() ? (
          <mark key={index}>{part}</mark>
        ) : (
          part
        )
      )}
    </span>
  );
};
