import React from 'react';
import {FieldContainer} from "./FieldContainer";
import FileInput from "../../apps/fzform/FileInput";

const acceptedFileTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/x-mspublisher',
  'application/pdf',
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
  'zip',
  'application/x-rar-compressed',
  'image/bmp',
  'audio/mpeg',
  'video/mp4',
  'audio/x-ms-wma',
  'video/x-msvideo',
  'video/x-matroska',
  'video/x-ms-wmv',
  'video/x-sgi-movie',
  'video/x-flv',
]

export function FilesField({
 name,
 value,
 label,
 onChange,
 onChangeFile,
 column,
 paramName,
 row,
 required,
 hideLabel,
 errorMessages,
 server,
 onremovefile,
 instantUpload,
 beforeRemoveFile,
 isConfirmDelete,
 allowDownload = false,
 disabled
}) {
  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages}}>
      <FileInput
        name={paramName}
        files={value(name)}
        acceptedFileTypes={acceptedFileTypes}
        setFiles={onChange}
        server={server}
        onremovefile={onremovefile}
        instantUpload={instantUpload}
        allowDownload={allowDownload}
        beforeRemoveFile={beforeRemoveFile}
        onChangeFile={onChangeFile}
        isConfirmDelete={isConfirmDelete}
        disabled={disabled}
        />
    </FieldContainer>
  )
}
