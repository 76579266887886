import React from 'react';

export function PaginationInfo({count, limit, offset}) {
  const currentPage = limit ? (offset / limit) + 1 : 0;
  const totalPages = count && limit ? Math.ceil(count / limit) : 1;

  return (
    <div>
      Страница: {currentPage} из {totalPages}<br/>
      Количество элементов: {count ?? 0}
    </div>
  );
};
