import React, {useEffect, useState} from 'react';
import {Button, Col, Row, HelpBlock} from 'react-bootstrap';
import uniFetch from "../../utils/uniFetch";
import {SelectField} from "../../components/Fields/SelectField";
import {filteredFields, formatDateToSelectOptions, getSelectOptionFromSlug} from "../../utils/common";
import {SvgIcon, svgType} from "../../components/Icons";
import {InputField} from "../../components/Fields/InputField";
import {ConfirmModal, LoaderModal, ResponseModal} from "../../components/Modal";
import {CheckBoxField} from "../../components/Fields/CheckBoxField";
import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../constants";
import {useDebounce} from 'use-debounce';
import {PaginationInfo} from "../../components/PaginationInfo";
import {ResponseAlert} from "../../components/ResponseAlert";

const fullColumnSize = 12;
const twoColumnSize = 12 / 2;

function FieldModal({isOpen, onClose, children, title}) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content content container" onClick={(e) => e.stopPropagation()}>
        <div
          className="block-label d-flex justify-content-between align-items-center modal-label">
          <label>
            {title}
          </label>
          <Button
              variant="secondary"
              type="button"
              className="btn btn-sm btn-outline-light"
              onClick={onClose}
              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
            </Button>
        </div>
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  );
}

const mapGender = [
  {slug: 10, name: "Мужской"},
  {slug: 20, name: "Женский"},
];

const labels = {
  last_name: 'Фамилия',
  last_name_dp: 'Фамилия (ДП)',
  first_name: 'Имя',
  first_name_dp: 'Имя (ДП)',
  middle_name: 'Отчество',
  middle_name_dp: 'Отчество (ДП)',
  position: 'Должность',
  position_dp: 'Должность (ДП)',
  gender: 'Пол',
  destination_type: 'Адресат',
  code: 'Код',
  approved: 'Актуализирован',
  is_head: 'Глава организации',
  is_public: 'Публичный',
  is_specialist: 'Специалист',
  phone_number: 'Номер телефона',
  email: "Электронная почта",
  id: 'Индекс',
  auth_user: 'Пользователь',
  can_all: "Администратор",
  can_register: "Регистрировать",
  can_moderate: "Модерировать",
  can_report: "Формировать отчеты",
  can_answer: "Формировать ответ",
  show_deleted: "Отображать удаленные",
  cabinet: "Кабинет руководителя"
};

function FZOrganizationUnitModal(props) {
  const isCreate = props.isCreate;
  const canEditValue = props.canEditValue;
  const [availableUsers, setAvailableUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createUserFields, setCreateUserFields] = useState({
    username: '',
    password: '',
    password2: '',
  });
  const [errorsUserFields, setErrorsUserFields] = useState({});
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [createdUnitId, setCreatedUnitId] = useState(null);
  const [disabledInputs, setDisabledInputs] = useState(false)

  const handeCheckBox = (e) => {
    const {name, checked} = e.target;
    props.setFields(prevFields => ({
      ...prevFields,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (props.isOpen) {
      uniFetch(`/fz59/auth-users/?limit=200&organisation_id=${props.organizationId}`)
        .then(data => {
          setAvailableUsers(data.results || data);
        })
        .catch(error => {
          console.error("Error fetching available users:", error);
        });
    }
  }, [props.isOpen, props.organizationId]);

  const handleCreateUserFieldsChange = (e) => {
    const {name, value} = e.target;
    setCreateUserFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleCreateUser = (unitId) => {
    uniFetch('/fz59/create-auth-user/', 'POST', {
      username: createUserFields.username,
      password: createUserFields.password,
      password2: createUserFields.password2,
      unit_id: unitId,
    }, false, true, true).then(data => {
      setAvailableUsers(prevUsers => [...prevUsers, data]);
      setShowCreateUserForm(false)
      setCreateUserFields({
        username: '',
        password: '',
        password2: '',
      })
      setErrorsUserFields({})
      props.setIsModalCreate(false);
      props.onClose();
    }).catch(error => {
      console.error("Error creating user:", error);
      setErrorsUserFields(error);
      setDisabledInputs(true)
    });
  };

  const handleSave = () => {
    const copiedFieldsUnit = {...props.fields};
    if (copiedFieldsUnit.destination_type === null) {
      copiedFieldsUnit.destination_type = '';
    }
    props.removeKeys(copiedFieldsUnit);
    if (isCreate) {
      copiedFieldsUnit['org'] = props.organizationId
    }
    const requestMethod = isCreate ? 'POST' : 'PATCH';
    const requestUrl = isCreate ? '/edw/api/data-marts/fzorganisationunit-dm/entities/' : `/edw/api/entities/${props.fields.id}/`;
    setIsLoading(true);
    uniFetch(requestUrl, requestMethod, copiedFieldsUnit, false, true, true)
      .then((data) => {
        if (isCreate) {
          data.short_fio = data.entity_name;
          const resp = {
            id: data.id,
            extra: data,
          };
          props.setDataList(prevDataList => [...prevDataList, resp]);
          props.setFields({});
          // props.setIsModalCreate(false);
          props.setErrorsFields({});
        } else {
          data.short_fio = data.entity_name;
          const resp = {
            id: data.id,
            extra: data,
          };
          props.setDataList(prevDataList =>
            prevDataList.map(item => (item.id === resp.id ? resp : item))
          );
          props.setErrorsFields({});
        }

        setCreatedUnitId(data.id);

        if (showCreateUserForm) {
          handleCreateUser(data.id);
        } else {
          props.onClose()
          setErrorsUserFields({})
        }

      })
      .catch(data => {
        props.setErrorsFields({errors: data.errors});
      }).finally(() => setIsLoading(false));
  };

  const handleRetryCreateUser = () => {
    if (createdUnitId) {
      handleCreateUser(createdUnitId);
    }
  };

  const handleCancelButton = () => {
    props.setIsModal(false);
    props.setErrorsFields({});
    props.setFields({});
    setErrorsUserFields({})
    setDisabledInputs(false)
    setShowCreateUserForm(false)
    setCreateUserFields({
      username: '',
      password: '',
      password2: '',
    })
  };

  return (
    <FieldModal isOpen={props.isOpen} onClose={props.onClose}
                title={isCreate ? 'Добавить должностное лицо' : 'Редактировать должностное лицо'}>
      {isLoading ? <LoaderModal/> : null}
      <Row className="mb-3">
        <Col sm={twoColumnSize}>
          <InputField
            name="last_name"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={twoColumnSize}>
          <InputField
            name="last_name_dp"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={twoColumnSize}>
          <InputField
            name="first_name"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={twoColumnSize}>
          <InputField
            name="first_name_dp"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={twoColumnSize}>
          <InputField
            name="middle_name"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={false}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={twoColumnSize}>
          <InputField
            name="middle_name_dp"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={false}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={twoColumnSize}>
          <InputField
            name="email"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={false}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>

        <Col sm={twoColumnSize}>
          <InputField
            name="phone_number"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={false}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={twoColumnSize}>
          <InputField
            name="code"
            value={name => props.fields?.[name] ?? ""}
            label={name => "Код специалиста"}
            required={false}
            column={2}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={twoColumnSize}>
          <SelectField
            name={'gender'}
            value={name => getSelectOptionFromSlug(formatDateToSelectOptions(mapGender), props.fields[name])}
            label={name => labels[name]}
            required={true}
            column={2}
            options={name => formatDateToSelectOptions(mapGender)}
            onChange={(name, value) => props.handleChangeSelect(name, value)}
            errorMessages={name => props.errorsFields.errors?.gender}
            placeholder="Выберите..."
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={fullColumnSize}>
          <InputField
            name="position"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={1}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={fullColumnSize}>
          <InputField
            name="position_dp"
            value={name => props.fields?.[name] ?? ""}
            label={name => labels[name]}
            required={true}
            column={1}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={props.handleFields}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
      </Row>
      <SelectField
        name={'destination_type'}
        value={name => getSelectOptionFromSlug(formatDateToSelectOptions(props.destinationType?.children), props.fields[name])}
        label={name => labels[name]}
        required={false}
        column={1}
        options={name => formatDateToSelectOptions(props.destinationType?.children)}
        onChange={(name, value) => props.handleChangeSelect(name, value)}
        errorMessages={name => props.errorsFields[name]}
        placeholder="Выберите..."
        disabled={!canEditValue || disabledInputs}
      />
      <Row className={'pt-4 pb-2'}>
        <Col sm={3}>
          <CheckBoxField
            name="is_head"
            value={name => props.fields?.[name] ?? false}
            label={name => labels[name]}
            required={false}
            column={4}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={handeCheckBox}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={3}>
          <CheckBoxField
            name="is_public"
            value={name => props.fields?.[name] ?? false}
            label={name => labels[name]}
            required={false}
            column={4}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={handeCheckBox}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={3}>
          <CheckBoxField
            name="is_specialist"
            value={name => props.fields?.[name] ?? false}
            label={name => labels[name]}
            required={false}
            column={4}
            errorMessages={name => props.errorsFields.errors?.[name]}
            onChange={handeCheckBox}
            disabled={!canEditValue || disabledInputs}
          />
        </Col>
        <Col sm={3}>
            <CheckBoxField
                name="approved"
                value={name => props.fields?.[name] ?? false}
                label={name =><>
                    <span className={props.fields?.[name] ? 'me-2 text-success': 'me-2 text-danger'}>
                      <SvgIcon type={svgType.approved} size={{width: 16, height: 16}}/>
                    </span>
                    <span className={props.fields?.[name] ? 'text-success': 'text-danger'}>{labels[name]}</span>
                </>}
            required={false}
            column={4}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue|| disabledInputs}
            />
        </Col>
      </Row>
      {canEditValue &&
        <>
          <label className="fw-bold my-4">
            Учётные данные для входа в систему
          </label>
          <div className={"mb-2"}>
            <CheckBoxField
              name="create_user"
              value={showCreateUserForm}
              label={name => "Создать пользователя"}
              required={false}
              column={1}
              errorMessages={null}
              onChange={(e) => {
                setShowCreateUserForm(e.target.checked)
                if (!e.target.checked && disabledInputs) {
                  setDisabledInputs(false)
                  setErrorsUserFields({})
                }
              }}
              disabled={!canEditValue}
            />
          </div>
          {showCreateUserForm && (
            <>
              <InputField
                name="username"
                value={name => createUserFields.username}
                label={name => "Логин"}
                required={true}
                onChange={handleCreateUserFieldsChange}
                column={1}
                errorMessages={name => errorsUserFields?.errors?.username ? [errorsUserFields?.errors?.username] : null}
              />
              <Row className={"mt-3"}>
                <Col sm={6}>
                  <InputField
                    name="password"
                    value={name => createUserFields.password}
                    label={name => "Пароль"}
                    type="password"
                    required={true}
                    onChange={handleCreateUserFieldsChange}
                    column={2}
                    errorMessages={name => errorsUserFields?.errors?.password ? [errorsUserFields?.errors?.password] : null}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    name="password2"
                    value={name => createUserFields.password2}
                    label={name => "Повторите пароль"}
                    type="password"
                    required={true}
                    onChange={handleCreateUserFieldsChange}
                    column={2}
                    errorMessages={name => errorsUserFields?.errors?.password2 ? [errorsUserFields?.errors?.password2] : null}
                  />
                </Col>
              </Row>
            </>
          )}
          {!showCreateUserForm && (
            <>
              <SelectField
                name={'auth_user'}
                value={name => getSelectOptionFromSlug(formatDateToSelectOptions(availableUsers.map(user => ({
                  slug: user.id,
                  name: user.username
                }))), props.fields[name])}
                label={name => labels[name]}
                required={false}
                column={1}
                options={() => formatDateToSelectOptions(availableUsers.map(user => ({
                  slug: user.id,
                  name: user.username
                })))}
                onChange={(name, value) => props.handleChangeSelect(name, value)}
                errorMessages={name => null}
                placeholder="Выберите пользователя..."
              />
            </>
          )}
          <Row className={'mt-3'}>
            <label className="fw-bold my-4">
              Права должностного лица
            </label>
            <Col sm={4}>
              <CheckBoxField
                name="can_all"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>
            <Col sm={4}>
              <CheckBoxField
                name="can_register"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>
            <Col sm={4}>
              <CheckBoxField
                name="can_moderate"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Col sm={4}>
              <CheckBoxField
                name="can_report"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>
            <Col sm={4}>
              <CheckBoxField
                name="can_answer"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>
            <Col sm={4}>
              <CheckBoxField
                name="cabinet"
                value={name => props.fields?.[name] ?? false}
                label={name => labels[name]}
                required={false}
                column={3}
                errorMessages={name => props.errorsFields.errors?.[name]}
                onChange={handeCheckBox}
                disabled={!canEditValue || disabledInputs}
              />
            </Col>

          </Row>
        </>}
      <Row>
        {errorsUserFields.errors && (
            <>
              <Col sm={12} className={'pt-5 pb-4 justify-content-center text-center'}>
                <HelpBlock className={"alert alert-danger d-flex justify-content-center"}>Учётный пользователь не был привязан, исправьте ошибки и
                  попробуйте снова.</HelpBlock>
              </Col>
            </>
        )}
        <div className="d-flex justify-content-end">
          {canEditValue ? (
              <>
                <Button bsStyle="danger" className={'mx-2 my-3'} onClick={handleCancelButton}>Отменить</Button>
                {errorsUserFields.errors && showCreateUserForm ? (
                    <Button bsStyle="warning" className={'mx-2 my-3'} onClick={handleRetryCreateUser}>Создать и привязать пользователя</Button>
                ) : <><Button bsStyle="success" className={'my-3'} onClick={handleSave}>Сохранить</Button></> }
              </>) : <Button bsStyle="primary" className={'mx-2 my-3'} onClick={() => {
            props.setIsModal(false);
          }}>Закрыть</Button>
          }
        </div>
      </Row>
    </FieldModal>
  );
}


function FZOrganizationUnits(props) {
  const [dataList, setDataList] = useState([]);
  const [destinationType, setDestinationType] = useState({});
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [forDel, setForDel] = useState(null);
  const [errorsFields, setErrorsFields] = useState({});
  const [fields, setFields] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [unit_title, setUnitTitle] = useState('');
  const [show_deleted, setShowDeleted] = useState(false);
  const [responseStatus, setResponseStatus] = useState(0)
  const limit = QUERY_LIMIT; // Количество элементов на странице
  const ordering = 'fzorganisationunit__last_name';
  const organizationId = props.organizationId;
  const orgunit = props?.orgunit
  const orgParent = props?.orgParent

  const [search_title] = useDebounce(unit_title, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, search_title, show_deleted]);

  const is_same_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === Number(organizationId));
  }

  const canEdit = () => {
    if (orgunit?.permissions?.can_root) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org()) return true;
    if (orgParent && orgunit?.permissions?.can_all && orgunit?.all_orgs?.some(obj => obj === orgParent)) return true;

    return false
  };

  const canShowAll = () =>{
    if (orgunit?.permissions?.can_root) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org()) return true;
    if (orgParent && orgunit?.permissions?.can_all && orgunit?.all_orgs?.some(obj => obj === orgParent)) return true;

    return false;
  }

  const fetchData = (page) => {
    setIsLoading(true);
    const offset = (page - 1) * limit;
    let url = `/edw/api/data-marts/fzorganisationunit-dm/entities/?org=${organizationId}&limit=${limit}&offset=${offset}&ordering=${ordering}`
    if (unit_title.length > 0){
      url += `&full_name=${unit_title}`
    }
    if(show_deleted){
      url += `&active=null`
    }
    //`/edw/api/data-marts/fzorganisationunit-dm/entities/?ordering=created_at&limit=${limit}&offset=${offset}&org=${organizationId}`
    uniFetch(url, 'GET', '', false, true, true)
      .then(data => {
        setDataList(data.results.objects);
        setDestinationType(data.results.meta.extra.model_definition.destination_type);
        setTotalItems(data.count);
      })
      .catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorsFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorsFields({error: 'Ошибка сервера'});
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeKeys = (item) => {
    const d = ['short_fio', 'model_definition', 'destination'];
    d.forEach(i => {
      delete item[i];
    });
  };

  const getValidationState = (f) => {
    if (Object.keys(errorsFields?.errors ?? []).length > 0) {
      return errorsFields.errors[f] ? 'error' : 'success';
    }
    return null;
  };

  const handleModelClose = (create) => {
    if (create) {
      setIsModalCreate(false);
      setFields({});
    } else {
      setIsModalEdit(false);
      setFields({});
    }
  };

  const handleConfirmDelete = () => {
    const data = {
      user_id: forDel,
      active: false
    }

    uniFetch('/edw/api/entities/' + forDel + '/', 'PATCH', data, false, true, true)
    .then((data) => {
      setDataList(dataList.filter(item => item.id !== forDel));
    }).catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorsFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorsFields({error: 'Ошибка сервера'});
      }
    });
    setForDel(null);
  };

  const handleCancelDelete = (e) => {
    setForDel(null);
  };

  const handleFields = (e, create) => {
    const {name, value} = e.target;
    setFields(prevState => {
      return {...prevState, [name]: value};
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrorsFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChange = (item) => {
    setIsLoadingDetail(true);
    setIsModalEdit(true);
    uniFetch(`/edw/api/entities/${item.id}`)
      .then(data => {
        setFields(filteredFields(data, labels));
      }).catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorsFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorsFields({error: 'Ошибка сервера'});
      }
    }).finally(() => setIsLoadingDetail(false))

  };

  const handleDelete = (id) => {
    setForDel(id);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / limit)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const canEditValue = canEdit();
  const handleCloseResponseModal = () => {
    setResponseStatus(0)
  }
  if (!Object.keys(destinationType).length && [403, 404, 500].includes(responseStatus)) return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <div className="text-center">
        <h2 className={'text-danger'}>{errorsFields?.detail ?? errorsFields?.error}</h2>
      </div>
    </div>
  )
  return (
    <div className="form-container">
      {isLoadingDetail ? <LoaderModal/> : null}
      <ResponseModal onClose={handleCloseResponseModal} status={(!Object.keys(destinationType).length && responseStatus!==403) ? responseStatus : null}>
          <ResponseAlert status={responseStatus} result={errorsFields} labels={labels}/>
        </ResponseModal>
      <div className="blocks-container">
        {
          canEditValue &&
          <div className="w-100">
            <div className={'d-flex justify-content-end'}>
              <button type="button" className="btn btn-success mb-3"
                      onClick={() => setIsModalCreate(true)}>
                + Добавить должностное лицо
              </button>
            </div>
          </div>
        }
        <div className="block-container full-width mb-5 w-100">
          <label className="block-label d-flex justify-content-between align-items-center">Должностные лица</label>
          <InputField
            name={'full_name'}
            onChange={e => {
               setUnitTitle(e.target.value)
            }}
            isClearable={true}
            label={() => 'ФИО'}
            value={() => unit_title}
             placeholder={"Введите ФИО..."}
          />
          <div className="card margin my-3 height-50em">
            <div className={'card-header py-3'}>
              <Row className={'fw-bold'}>
                <Col sm={2}>Код</Col>
                <Col sm={2}>ФИО</Col>
                <Col sm={3}>Должность</Col>
                <Col sm={3}>Адресат</Col>
              </Row>
            </div>
            <div className="card-body select-area">
              {!isLoading && dataList?.map(item =>
                <Row key={item.id}
                     className={'py-2 can-select'}
                >
                  <Col sm={2}>{item.extra?.code}
                  {item.extra.approved ? <span className={'ms-2 text-success'}><SvgIcon type={svgType.approved} size={{width: 16, height: 16}}/></span> : null}
                  </Col>
                  <Col sm={2}>{item.extra?.short_fio}</Col>
                  <Col sm={3}>{item.extra?.position}</Col>
                  <Col sm={3}>{item.extra?.destination || '-'}</Col>
                  {item.extra.active ?
                  <Col sm={2} className={'actions d-flex align-items-start'}>
                    <button type="button" className="btn btn-outline-success"
                            onClick={() => handleChange(item)}>
                      <SvgIcon type={!canEditValue ? svgType.eye : svgType.edit} size={{width: 16, height: 16}}/>
                    </button>
                    {canEditValue ?
                      <button type="button" disabled={!canEditValue} className="btn btn-outline-danger ml-2"
                              onClick={() => handleDelete(item.id)}>
                        <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                      </button> : null}
                  </Col>:
                  <Col sm={2}>
                    <span className={'text-muted'}>удален</span>
                  </Col>
                  }
                </Row>
              )}
              {isLoading ?
                <div className="text-center">
                  <div className="spinner-border text-primary m-5" role="status"
                       style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                  </div>
                </div>
                : null}
            </div>
            <div className={'card-footer'}>
              <Row>
                <Col sm={4}>
                  <PaginationInfo
                    count={totalItems}
                    limit={limit}
                    offset={(currentPage - 1) * limit}
                  />
                </Col>
                <Col sm={4}>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={handlePrevPage}
                      bsStyle="success"
                      className={'mx-2'}
                      disabled={currentPage === 1}>{'<'}
                    </Button>
                    <Button
                      onClick={handleNextPage}
                      bsStyle="success"
                      className={'mx-2'}
                      disabled={currentPage === Math.ceil(totalItems / limit)}>{'>'}
                    </Button>
                  </div>
                </Col>
                {canShowAll() ?
                    <Col sm={4}>
                      <CheckBoxField
                          name="show_deleted"
                          value={show_deleted}
                          label={name => labels[name]}
                          required={false}
                          column={4}
                          onChange={(e)=> setShowDeleted(!show_deleted)}
                        />
                    </Col>: null}
              </Row>
            </div>
          </div>
          <FZOrganizationUnitModal
            isCreate={true}
            isOpen={isModalCreate}
            setIsModal={setIsModalCreate}
            onClose={() => handleModelClose(true)}
            fields={fields}
            setFields={setFields}
            handleFields={(e) => handleFields(e, true)}
            errorsFields={errorsFields}
            setErrorsFields={setErrorsFields}
            getValidationState={getValidationState}
            handleChangeSelect={handleChangeSelect}
            destinationType={destinationType}
            organizationId={organizationId}
            setDataList={setDataList}
            removeKeys={removeKeys}
            setIsModalCreate={setIsModalCreate}
            canEditValue={canEditValue}
          />
          <FZOrganizationUnitModal
            isCreate={false}
            isOpen={isModalEdit}
            setIsModal={setIsModalEdit}
            onClose={() => handleModelClose(false)}
            fields={fields}
            setFields={setFields}
            handleFields={(e) => handleFields(e, false)}
            errorsFields={errorsFields}
            setErrorsFields={setErrorsFields}
            getValidationState={getValidationState}
            handleChangeSelect={handleChangeSelect}
            destinationType={destinationType}
            organizationId={organizationId}
            setDataList={setDataList}
            removeKeys={removeKeys}
            setIsModalCreate={setIsModalCreate}
            canEditValue={canEditValue}
          />
          {forDel && (
            <ConfirmModal
              label={'Удаление должностного лица'}
              text={'Вы действительно собираетесь удалить должностное лицо?'}
              handleConfirm={handleConfirmDelete}
              handleCancel={handleCancelDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FZOrganizationUnits;
