import React, {useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import {BlockContainer} from "../../components/Fields/BlockContainer";
import {HTMLConfirmModal} from "../../components/Modal";


const formatDate = (date_str) =>{
      let res = '';
      if(date_str){
        let dd = new Date(date_str),
            day = dd.getDate() > 9 ? dd.getDate().toString(10) : '0' + dd.getDate().toString(10),
            mn = dd.getMonth() + 1
            mn =  mn > 9 ? mn.toString(10) : '0' + mn.toString(10);
          res = '' + day + '.' + mn + '.' + dd.getFullYear().toString(10);
      }

      return res
}

function ReadField({name, title, value}) {
  const realValue = typeof value === 'function' ? value(name) : value;
  const realTitle = typeof title === 'function' ? title(name) : title;

  return (
      <div className="row py-2">
          <label className="col-md-4 col-12 fw-bold text-end">{realTitle}</label>
          <div className="col-md-8 col-12"><span dangerouslySetInnerHTML={{__html: realValue}}></span>
          </div>
      </div>
    )
}

export function SSTUTab({messageId, fields, labels, orgunit, responsible, respGroups, apiCommand, canEdit = true}) {
    const questionStates = {
        NotReceived: 'Не поступило',
        NotRegistered: 'Не зарегистрировано',
        InWork: 'Находится на рассмотрении',
        InWorkExtended: 'Рассмотрение продлено',
        Explained: 'Рассмотрено. Разъяснено',
        Supported: 'Рассмотрено. Поддержано',
        NotSupported: 'Рассмотрено. Не поддержано',
        Transferred: 'Направлено по компетенции',
        Answered: 'Дан ответ автору',
        LeftWithoutAnswer: 'Оставлено без ответа автору',
    }

    const messageReg = (fields.income_number || fields.reg_number) ? true : false;
    const main_aplicant = fields['applicants'].length > 0 ? fields['applicants'][0] : null;
    const problems = fields?.problems || [];
    const [selectedProblems, setSelectedProblems] = useState(new Map());
    const [showSendConfirm, setShowSendConfirm] = useState(false);

    const getApplicantAddress = ()=> {
        if (main_aplicant){
            let res = []
            if (main_aplicant.postal_code) res.push(main_aplicant.postal_code);
            if (main_aplicant.postal_country) res.push(main_aplicant.postal_country);
            if (main_aplicant.postal_region) res.push(main_aplicant.postal_region);
            if (main_aplicant.postal_district) res.push(main_aplicant.postal_district);
            if (main_aplicant.postal_city) res.push(main_aplicant.postal_city);
            if (main_aplicant.postal_street) res.push(main_aplicant.postal_street);
            if (main_aplicant.postal_house) res.push('д. ' + main_aplicant.postal_house);
            if (main_aplicant.postal_corpus) res.push('к. ' + main_aplicant.postal_corpus);
            if (main_aplicant.postal_home) res.push('кв. ' + main_aplicant.postal_home);
            return res.join(', ')
        }
        return ''
    }

    const getApplicantFio = () => {
        if (main_aplicant){
            let res = []
            if (main_aplicant.last_name) res.push(main_aplicant.last_name);
            if (main_aplicant.first_name) res.push(main_aplicant.first_name);
            if (main_aplicant.middle_name) res.push(main_aplicant.middle_name);
            return res.join(' ')
        }
        return ''
    }

    const getRespByOrg = () => {
        let orgs = [];
        responsible.forEach((item)=>{
            if (item?.unit.org){
                let current_og = orgs.find((element) => element.id === item.unit.org.id)
                if (current_og===undefined){
                    orgs.push(Object.assign({message: item.message, is_reg: item.unit.org.id===fields.reg_organisation}, {responsibles: [item]}, item.unit.org))
                }else{
                    current_og.responsibles.push(item)
                }
            }
        })
        //todo: sort by is_reg
        return orgs
    }
    const resp_orgs = getRespByOrg()
    const getSSTUProblems = () =>{
        let res = [];
        problems.forEach((item, ind)=>{
            if (item?.problem?.code){
                let resp_org = resp_orgs.find((element) => (element.sstu_number && item.sstu_org_number===element.sstu_number));
                res.push(Object.assign({'resp_org': resp_org}, item));
            }
        })
        return res;
    }

    const sstu_problems = getSSTUProblems();
    const handleSetProblem = (person, action, problem) => {
        let selected = !problem.selected;
        let new_selected = new Map(selectedProblems);
        if(!selected){
            new_selected.delete(problem.id)
        }else{
            let val = {
                state:action.state,
                sstu_problem_code: problem?.problem?.code,
                sstu_problem_title: problem?.problem?.title,
                sstu_responible: person.id,
                sstu_responible_title: person?.unit?.short_fio,
                sstu_org_number: person?.unit?.org?.sstu_number,
                sstu_org_title: person?.unit?.org?.title,
                sstu_org_id: person?.unit?.org?.id,
                person: person, //todo: del
                action_date: action.action_date,
                action_number: action.action_number,
                action_answer: action?.answer?.id
            }
            new_selected.set(problem.id, val)
        }
        setSelectedProblems(new_selected)
    }

    const hadleShowConfirm = () =>{
        setShowSendConfirm(true)
    }

    const sendToSSTU = () => {
        let data_for_send = {};
        selectedProblems.forEach((value, key, map) => {
            data_for_send[key] = value;
        })
        apiCommand("command_send_sstu", data_for_send);
        setShowSendConfirm(false);
    }

    const renderSendConfirm = () =>{
      if (!showSendConfirm) return null

        let valid = true;
        let err_message = '';

        if (selectedProblems.size === 0){
            valid = false;
            err_message = 'Нет изменений для выгрузки'
        }

        let send_info = []
        selectedProblems.forEach((value, key, map) =>{
            send_info.push(Object.assign({key: key}, value))
        })

      return (
          <HTMLConfirmModal label={'Выгрузить данные обращения в ССТУ.РФ?'}
                          handleConfirm={()=> sendToSSTU()}
                          handleCancel={()=> setShowSendConfirm(false)}
                          validateConfirm={valid}
                          >
              {valid ?
                  <>
                      <h4>Вы действительно хотите отправить данные в ССТУ.РФ?</h4>
                      {send_info.map((item, key)=> {
                          return(
                              <div key={key} className={'row py-2'}>
                                  <div className={'col-4 fw-bold'}>{item?.sstu_problem_code}</div>
                                  <div className={'col-8'}>{item?.sstu_problem_title}</div>
                                  <div className={'col-4'}>{item?.state ? questionStates[item?.state]: null}</div>
                                  <div className={'col-8'}>{item?.sstu_responible_title} ({item?.sstu_org_title})</div>
                              </div>
                          )
                      })}
                  </>
                  :
                  <h4 className={'text-danger'}>{err_message}</h4>
              }
        </HTMLConfirmModal>

      )
    }

    const getProblemItemForPerson = (item, state, person, p_answ=null) =>{

        const selectedProblem = selectedProblems.get(item.id);
        let selected = false;
        let disabled = (canEdit && messageReg) ? false : true;
        if (selectedProblem!==undefined){
            disabled = true
            if (selectedProblem?.state===state){
                if(selectedProblem?.sstu_responible===person.id){
                    const is_answer = (state==="Answered" || state==="Supported" || state==="NotSupported" || state==="Explained")
                    if(p_answ?.answer&&selectedProblem?.action_answer){
                        selected = selectedProblem?.action_answer === p_answ.answer.id
                    }else{
                        selected = true
                    }
                    disabled = canEdit ? false : true
                }
            }
        }
        return Object.assign({'selected': selected, 'disabled': disabled}, item);


        return item
    }

    const getTransferForPerson = (person, person_group) => {
        //let rightNow = new Date();
        let action_date = undefined;//rightNow.toISOString();
        let person_org = person?.unit?.org;

        if (person_org){
            let ch_message = fields?.children.find((element) => element?.reg_organisation?.id === person_org.id);
            if (ch_message){
                return [ch_message.income_at, ch_message.income_number]
            }
        }

        if(person_group && person_group.resolutions){
            let resolution = person_group.resolutions.findLast(((el) => el.send && (!el.responsible || el.responsible===person.id)));
            if (resolution) return [resolution.send_date, fields.reg_number];
        }

        return [action_date, fields.reg_number];
    }

    const getActionsForPerson = (person, group) =>{
        let res = []
        //NotRegistered
        let not_registered = []
        //InWork
        let in_work = []
        //InWorkExtended: 'Рассмотрение продлено',
        let in_work_ext = []
        // Transferred: 'Направлено по компетенции'
        let transferred = []
        // LeftWithoutAnswer: 'Оставлено без ответа автору'
        let with_out_ans = []

        sstu_problems.forEach((el, ind)=>{
            let item = Object.assign({}, el)

            const message_owner_id = fields.reg_organisation;
            const get_item_owner_id = () => {
                //item?.resp_org
                if (item.sstu_status && item.sstu_org_number){
                    return item?.resp_org?.id
                } else if (item.message===messageId){
                    return message_owner_id
                } else if (fields?.parent?.id===item.message){
                    return fields?.parent?.reg_organisation?.id
                }
                return null
            }

            const item_owner_id = get_item_owner_id()
            if(message_owner_id===item_owner_id){
                //проблема данной организации
                if (item.sstu_status&&item.sstu_org_number){
                    //назначен исполнитель
                    if((item.sstu_responible===person.id)||(person?.unit?.org?.id===message_owner_id)){
                        //назначено текущему исполнителю или организации
                        if(item.sstu_status==="Transferred"){
                             if(fields.reg_number){
                                 in_work.push(getProblemItemForPerson(item, 'InWork', person));
                             }else if(fields.income_number){
                                 not_registered.push(getProblemItemForPerson(item, 'NotRegistered', person));
                             }
                        } else if((item.sstu_status==="NotRegistered") && (fields.reg_number)){
                            if(item.sstu_recive_status!=="success"){
                                not_registered.push(getProblemItemForPerson(item, 'NotRegistered', person));
                            }
                            in_work.push(getProblemItemForPerson(item, 'InWork', person));
                        }else if(item.sstu_status==="InWork"){
                            if(item.sstu_recive_status!=="success"){
                                in_work.push(getProblemItemForPerson(item, 'InWork', person));
                            }
                            in_work_ext.push(getProblemItemForPerson(item, 'InWorkExtended', person));
                            with_out_ans.push(getProblemItemForPerson(item, 'LeftWithoutAnswer', person));
                        }else if(item.sstu_status==="InWorkExtended"){
                            if(item.sstu_recive_status!=="success"){
                                in_work_ext.push(getProblemItemForPerson(item, 'InWorkExtended', person));
                            }
                            with_out_ans.push(getProblemItemForPerson(item, 'LeftWithoutAnswer', person));
                        }
                    }else if ((fields.reg_number)&&((item.sstu_status==="Transferred")||(item.sstu_status==="InWork")||(item.sstu_status==="InWorkExtended"))){
                        // перенаправить свой вопрос в другую организацию
                        if(person?.unit?.org?.sstu_number===item.sstu_org_number){
                            // если подчиненная орг и номер ссту одинаковый
                            in_work.push(getProblemItemForPerson(item, 'InWork', person));
                            in_work_ext.push(getProblemItemForPerson(item, 'InWorkExtended', person));
                            with_out_ans.push(getProblemItemForPerson(item, 'LeftWithoutAnswer', person));
                        }else{
                            transferred.push(getProblemItemForPerson(item, 'Transferred', person));
                        }
                    }
                }else{
                    //нет исполнителя
                    if (person?.unit?.org.id===message_owner_id){
                        // ответственный сотрудник регистрирующей организации
                        if(fields.reg_number){
                            in_work.push(getProblemItemForPerson(item, 'InWork', person));
                         }else if(fields.income_number){
                            not_registered.push(getProblemItemForPerson(item, 'NotRegistered', person));
                         }else if((item.sstu_status==="Transferred")&&(item.sstu_recive_status!=="success")){
                            transferred.push(getProblemItemForPerson(item, 'Transferred', person));
                        }
                    }else if(fields.reg_number){
                        transferred.push(getProblemItemForPerson(item, 'Transferred', person));
                    }
                }

            }
            /*
            if((person.message===messageId)||(person?.unit?.org?.id===fields.reg_organisation)){
                if (item.sstu_status&&item.sstu_org_number){
                    //(person?.unit?.org?.id===fields.reg_organisation)
                    if((item.sstu_responible===person.id)&&(person?.unit?.org?.id===fields.reg_organisation)){
                        //назначено исполнителю
                        if(item.sstu_status==="Transferred"){
                             if(fields.reg_number){
                                 in_work.push(getProblemItemForPerson(item, 'InWork', person));
                             }else if(fields.income_number){
                                 not_registered.push(getProblemItemForPerson(item, 'NotRegistered', person));
                             }
                        } else if(item.sstu_status==="NotRegistered"){
                            //check reg number
                            in_work.push(getProblemItemForPerson(item, 'InWork', person));
                        }else if(item.sstu_status==="InWork"){
                            in_work_ext.push(getProblemItemForPerson(item, 'InWorkExtended', person));
                            with_out_ans.push(getProblemItemForPerson(item, 'LeftWithoutAnswer', person));
                        }else if(item.sstu_status==="InWorkExtended"){
                            with_out_ans.push(getProblemItemForPerson(item, 'LeftWithoutAnswer', person));
                        }
                    }else if ((item.sstu_responible!==person.id)&&((item.sstu_status==="Transferred")|| (item.sstu_status==="InWork"))){
                        transferred.push(getProblemItemForPerson(item, 'Transferred', person));
                    }
                }else{
                    //не назначен исполнитель
                    if(item.message===messageId){
                        //проблема данной организации
                        if(item.message===person.message){
                            //локальный ответственный
                            if (person?.unit?.org.id===fields.reg_organisation){
                                // ответственный сотрудник регистрирующей организации
                                if(fields.reg_number){
                                    in_work.push(getProblemItemForPerson(item, 'InWork', person));
                                 }else if(fields.income_number){
                                    not_registered.push(getProblemItemForPerson(item, 'NotRegistered', person));
                                 }
                            }else if(fields.reg_number){
                                transferred.push(getProblemItemForPerson(item, 'Transferred', person));
                            }
                        }
                    }
                }
            }
            */
        })
        if (not_registered.length > 0) {
            let action_date = fields?.income_at
            let action_number = fields?.income_number
            res.push({
                state: "NotRegistered",
                title: questionStates.NotRegistered,
                problems: not_registered,
                action_date: action_date,
                action_number: action_number
            })
        }
        if (in_work.length > 0) {
            let action_date = fields?.reg_at
            let action_number = fields?.income_number || fields?.reg_number
            res.push({
                state: "InWork",
                title: questionStates.InWork,
                problems: in_work,
                action_date: action_date,
                action_number: action_number})
        }
        if (in_work_ext.length > 0) {
            let rightNow = new Date();
            let action_date = rightNow.toISOString();
            let action_number = fields?.income_number || fields?.reg_number
            res.push({
                state: "InWorkExtended",
                title: questionStates.InWorkExtended,
                problems: in_work_ext,
                action_date: action_date,
                action_number: action_number})
        }
        if (transferred.length > 0){
            let [action_date, action_number] = getTransferForPerson(person, group)
            res.push({
                state: "Transferred",
                title: questionStates.Transferred,
                problems: transferred,
                action_date: action_date,
                action_number: action_number})
        }
        if (with_out_ans.length > 0) {
          let rightNow = new Date();
          let action_date = rightNow.toISOString();
          let action_number = fields?.income_number || fields?.reg_number
          res.push({
              state: "LeftWithoutAnswer",
              title: questionStates.LeftWithoutAnswer,
              problems: with_out_ans,
              action_date: action_date,
              action_number: action_number
          })
        }

        return res
    }

    const getAnswerActions = (answer, problems, person) =>{
        //analysis_result
        let state = "Answered"
        if (answer.analysis_result && answer.analysis_result==="analysis_acc") state = "Supported";
        if (answer.analysis_result && answer.analysis_result==="analysis_rej") state = "NotSupported";
        if (answer.analysis_result && answer.analysis_result==="analysis_cl") state = "Explained";
        if (answer.analysis_result){}
        const res = {
            state: state,
            answer: answer,
            problems: [...problems],
        }
        return res
    }

    const getAnswersWithFiles = (answers) => {
        if (answers&&answers.length>0){
            return answers.filter((item)=>{
                return (item.addition_fileinfo || item.signed_fileinfo) ? true : false
            })
        }
        return []
    }
    const getAnswersForPerson = (person, group) =>{
        let person_answers = []
        let person_problems = []
        if (fields.reg_number&&getAnswersWithFiles(person.answers).length>0){
            //Explained: 'Рассмотрено. Разъяснено',
            //Supported: 'Рассмотрено. Поддержано',
            //NotSupported: 'Рассмотрено. Не поддержано',
            //Answered: 'Дан ответ автору',
            sstu_problems.forEach((el, ind)=>{
                let item = Object.assign({}, el);
                const message_owner_id = fields.reg_organisation;
                const get_item_owner_id = () => {
                    //item?.resp_org
                    if (item.sstu_status && item.sstu_org_number){
                        return item?.resp_org?.id
                    } else if (item.message===messageId){
                        return message_owner_id
                    } else if (fields?.parent?.id===item.message){
                        return fields?.parent?.reg_organisation?.id
                    }
                    return null
                }

                const item_owner_id = get_item_owner_id()
                if(message_owner_id===item_owner_id) {
                    //проблема данной организации
                    if((item.sstu_responible===person.id)||(person?.unit?.org?.id===message_owner_id)) {
                        //назначено текущему исполнителю или организации
                        //if((item.sstu_recive_status!=="success")||(item.sstu_status!=="LeftWithoutAnswer")){
                        // todo: block after test then answered
                        //}
                        person_problems.push(item)
                    }
                }
                /*
                if((person.message===messageId)||(person?.unit?.org?.id===fields.reg_organisation)){
                    // уже назначено
                    if (item.sstu_status&&item.sstu_org_number){
                        if(person?.unit?.org?.id===fields.reg_organisation){
                            //назначено организации
                            person_problems.push(item)
                        }
                    }else if((item.message===person.message)&&(item.message===messageId)){
                        //не назначен исполнитель //проблема данной организации //локальный ответственный
                        person_problems.push(item)

                    }
                }*/
            })
            if(person_problems.length > 0){
                getAnswersWithFiles(person.answers).forEach((answer) => {
                    let action_date = answer.date_receipt
                    let action_number = answer.income_number ? answer.income_number : answer.sed_number
                    let p_answ = {
                            action_date: action_date,
                            action_number: action_number,
                            state: "Answered",
                            answer_title: questionStates.Answered,
                            file_info: null,
                            signed: false,
                            answer: answer,
                        }
                    if (answer.addition_fileinfo){
                        p_answ.file_info = answer.addition_fileinfo
                    }else if (answer.signed_fileinfo){
                        p_answ.file_info = answer.signed_fileinfo
                        p_answ.signed = true
                    }
                    if (answer.analysis_result && answer.analysis_result==="analysis_acc"){
                        p_answ.state = "Supported"
                        p_answ.answer_title = questionStates.Supported
                    }else if(answer.analysis_result && answer.analysis_result==="analysis_cl"){
                        p_answ.state = "Explained"
                        p_answ.answer_title = questionStates.Explained
                    }else if(answer.analysis_result && answer.analysis_result==="analysis_rej"){
                        p_answ.state = "NotSupported"
                        p_answ.answer_title = questionStates.NotSupported
                    }
                    p_answ.problems = person_problems.map(item => getProblemItemForPerson(item, p_answ.state, person, p_answ))
                    person_answers.push(p_answ)
                })
            }
        }

        return person_answers
    }

    const getActionsForGroups = () =>{
        let res_groups = []
        Object.entries(respGroups).forEach(([group_key, group]) => {

            if (group?.responsible.length > 0){
                let res_group = {
                    group: group.group,
                    key: group_key,
                    local: group.local,
                    title: group.title,
                    responsibles: []
                }
                group.responsible.forEach((item)=>{
                   let actions = getActionsForPerson(item, group)
                   let answers = getAnswersForPerson(item, group)
                   if((actions.length > 0)||(answers.length > 0)){
                       res_group.responsibles.push({
                           person: Object.assign({}, item),
                           actions: [...actions],
                           answers: [...answers]
                       })
                   }
                })
                res_groups.push(res_group)
            }
        })
        return res_groups
    }
    const res_groups = getActionsForGroups()

    return (
        <>
            {renderSendConfirm()}
            <div className="form-container">
                <div className="blocks-container">
                    <BlockContainer label='Данные по обращению'>
                        <div className="block-container-content">
                            <ReadField name={'post_at'}
                                       title={name => labels[name]}
                                       value={name => formatDate(fields[name])}/>
                            <ReadField name={'income_at'}
                                       title={name => labels[name]}
                                       value={name => formatDate(fields[name])}/>
                            <ReadField name={'income_number'}
                                       title={name => labels[name]}
                                       value={name => fields[name]}/>
                            <ReadField name={'reg_at'}
                                       title={name => labels[name]}
                                       value={name => formatDate(fields[name])}/>
                            <ReadField name={'reg_number'}
                                       title={name => labels[name]}
                                       value={name => fields[name]}/>
                            <ReadField name={'main_aplicant'}
                                       title={'Заявитель'}
                                       value={getApplicantFio()}/>
                            <ReadField name={'main_aplicant_address'}
                                       title={'Адрес заявителя'}
                                       value={getApplicantAddress()}/>
                            <ReadField name={'annotation'}
                                       title={name => labels[name]}
                                       value={name => fields[name]}/>
                            <ReadField name={'description'}
                                       title={name => labels[name]}
                                       value={name => fields[name]}/>
                            <Row>
                                <Col md={4} className={'fw-bold text-end'}>Вопросы в обращении</Col>
                            </Row>
                            {Object.entries(sstu_problems).map(([key, chield]) => {
                                return (
                                        <Row key={key} className={'py-1'}>
                                            <Col md={4} className={'fw-bold text-end'}>
                                                {chield?.problem?.code}
                                            </Col>
                                            <Col md={8}>
                                                {chield?.problem?.title}
                                            </Col>
                                            {chield.sstu_status ? <>
                                                {chield.sstu_recive_status==='success' ?
                                                    <>
                                                        <Col md={4} className={'text-success text-end'}>
                                                            {questionStates[chield.sstu_status]}{chield.sstu_sent_at? <span> ({formatDate(chield.sstu_sent_at)})</span>: null}
                                                        </Col>
                                                        <Col md={8}>
                                                            {chield.resp_org ? chield.resp_org.title: null} <br/>
                                                            {chield.sstu_org_number ? chield.sstu_org_number : null}
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col md={4} className={'text-danger text-end'}>Ошибка обмена с ССТУ.РФ<br/>
                                                        {chield.sstu_status ? questionStates[chield.sstu_status]:null}
                                                        </Col>
                                                        <Col md={8}>{chield.sstu_recive_status} <br/>
                                                        {chield.resp_org ? chield.resp_org.title: null} <br/>
                                                            {chield.sstu_org_number ? chield.sstu_org_number : null}
                                                        </Col>
                                                    </>
                                                }
                                                </>
                                                :<>
                                                    <Col md={4} className={'text-warning text-end'}>Не отправлено в ССТУ.РФ</Col>
                                                    <Col md={8}>{chield.sstu_recive_status} <br/>
                                                        {chield.resp_org ? chield.resp_org.title: null} <br/>
                                                            {chield.sstu_org_number ? chield.sstu_org_number : null}
                                                    </Col>
                                                </>

                                            }
                                        </Row>)}
                            )}
                        </div>
                    </BlockContainer>
                    {res_groups.map((group_item, group_key) =>{
                        if (group_item?.responsibles.length > 0){
                            return (
                            <BlockContainer key={group_key} label={group_item.title}>
                                {group_item?.responsibles.map((resp_item, resp_key) =>{
                                return (
                                    <div key={resp_key} className={'nav-tabs'}>
                                        <div className="row py-2">
                                            <label
                                                className="col-md-4 col-12 fw-bold text-end">{resp_item?.person?.unit?.short_fio}</label>
                                            <div className="col-md-8 col-12">
                                                {resp_item?.person?.unit?.position}
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <label className="col-md-4 col-12 fw-bold text-end">
                                                {resp_item?.person?.unit?.org?.title}</label>
                                            <div className="col-md-8 col-12">
                                                {resp_item?.person?.unit?.org?.sstu_number ? resp_item.person.unit.org.sstu_number :
                                                    <span className={'text-danger'}>В карточке организации необходимо заполнить поле "Орган на ССТУ.РФ"</span>}
                                            </div>
                                        </div>
                                        {resp_item?.actions?.map((action, action_key)=>{
                                            return (
                                                <div key={action_key}>
                                                    <div className="row py-2" >
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={' fw-bold'}>Установить "{action.title}"</span>
                                                            <br/>
                                                            <span>{action?.action_number} {action?.action_date ? formatDate(action.action_date): null}</span>
                                                        </div>
                                                            <div className={'col-md-8'}>
                                                                {action.problems.map((problem, p_key)=>{
                                                                    return(
                                                                        <div className={'form-check pb-2'} key={p_key}>
                                                                            <input className="form-check-input"
                                                                                   type="checkbox"
                                                                                   checked={problem.selected}
                                                                                   disabled={problem.disabled}
                                                                                   onChange={(e)=> handleSetProblem(resp_item.person, action, problem)}/>
                                                                            <label className="form-check-label">
                                                                                <span className={'fw-bold'}>{problem?.problem.code}</span>
                                                                                <span className={'ms-2'}>{problem?.problem.title}</span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                    </div>
                                            </div>
                                            )}
                                        )}
                                        {resp_item?.answers?.length > 0 ?
                                            <div className="row py-2">
                                                <div className="col-md-4 col-12 text-end">
                                                    <span className={'fw-bold text-success'}>Есть ответы исполнителя</span>
                                                </div>
                                                <div className={'col-md-8'}>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {resp_item?.answers?.map((answer, answer_key) => {
                                            return (
                                                <div key={answer_key}>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span
                                                                className={'fw-bold'}>Ответ № {answer.action_number} ({answer.action_date})</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            {answer.answer_title}
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Текст</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            <div
                                                              className={'html-read'}
                                                              dangerouslySetInnerHTML={{__html: answer.answer?.description}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Файл ответа</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            <a href={answer.file_info?.file}
                                                               target={'_blank'}> {answer.file_info?.name}</a>
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Подписант</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            {answer.answer?.specialist_title}
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span
                                                                className={''}>Отправить ответ по вопросам</span>
                                                            <br/>
                                                            <span></span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            {answer.problems.map((problem, p_key) => {
                                                                return (
                                                                    <div className={'form-check pb-2'} key={p_key}>
                                                                        <input className="form-check-input"
                                                                               type="checkbox"
                                                                               checked={problem.selected}
                                                                               disabled={problem.disabled}
                                                                               onChange={(e) => handleSetProblem(resp_item.person, answer, problem)}/>
                                                                        <label className="form-check-label">
                                                                            <span
                                                                                className={'fw-bold'}>{problem?.problem.code}</span>
                                                                            <span
                                                                                className={'ms-2'}>{problem?.problem.title}</span>
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        )}

                                    </div>
                                )
                                })}
                            </BlockContainer>
                            )
                        }
                    })}
                </div>
            </div>
            <div className={'d-flex justify-content-end py-2'}>
                <button className={'btn btn-primary'} onClick={hadleShowConfirm}>Выгрузить данные</button>
            </div>
        </>)
}
