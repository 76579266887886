import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../../constants";
import uniFetch from "../../../utils/uniFetch";
import {InputField} from "../../../components/Fields/InputField";
import {AsyncSelectField} from "../../../components/Fields/AsyncSelectField";
import {HighlightText} from "../../../components/HighlightText";
import React, {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import {ConfirmModal, FieldModal} from "../../../components/Modal";
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import {SvgIcon, svgType} from "../../../components/Icons";
import {PaginationInfo} from "../../../components/PaginationInfo";

export function AppealTable({name, fields, modelDefinition, messageId, onChange, onDelete, canEdit = true}) {
  const [isModal, setIsModal] = useState(false)
  const [newItem, setNewItem] = useState({})
  const [search, setSearch] = useState('')
  const [debounceSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);
  const [searchResult, setSearchResult] = useState({})
  const [errorFields, setErrorFields] = useState({});
  const [ready, setReady] = useState(true);
  const messageUrl = modelDefinition(name)?.model_definition.api_url
  const problemUrl = modelDefinition(name)?.model_definition['problem']?.data_mart
  const typeUrl = modelDefinition(name)?.model_definition['type']?.data_mart
  const selectedCls = "text-success-emphasis bg-success-subtle py-2";
  const searchErrorMessage = "Произошла ошибка поиска. Пожалуйста обновите страницу (F5) и попробуйте еще раз!";

  useEffect(() => {
    if (problemUrl && isModal && ready) {
      const params = new URLSearchParams();
      params.append('search', search);
      params.append('limit', QUERY_LIMIT);
      const paramsStr = `${params.toString() ? `?${params.toString()}` : ''}`;
      setReady(false);
      uniFetch(problemUrl + paramsStr)
        .then((data) => {
          setSearchResult(data);
          setReady(true);
          setErrorFields({});
        })
        .catch((error) => {
          setReady(true);
          setErrorFields(prevState => {
            return Object.assign({}, prevState, {'search': error})
          })
        });
    }
  }, [debounceSearch, isModal])

  const handlePagination = (url) => {
    setReady(false);
    uniFetch(url).then((data) => setSearchResult(data)).finally(()=>setReady(true))
  }

  useEffect(() => {
    if (isModal) {
      setNewItem({})
      setSearch('')
      setSearchResult({})
    }
  }, [isModal])

  const handleChange = (name, value) => {
    setNewItem(prevState => {
      return {...prevState, [name]: value}
    })
  }
  const loadOptions = (inputValue, callback) => {
    uniFetch(`${typeUrl}?code_title=${inputValue}`).then(data => {
      callback(data.results.map(item => {
        return {value: item.id, label: item.code + ' | ' + item.title}
      }))
    });
  };
  const loadOptionsNoLimit = (inputValue, callback) => {
    uniFetch(`${typeUrl}?limit=100&code_title=${inputValue}`).then(data => {
      callback(data.results.map(item => {
        return {value: item.id, label: item.code + ' | ' + item.title}
      }))
    });
  };
  const handleCreate = () => {
    uniFetch(messageUrl, 'POST', {...newItem, message: messageId}).then((data) => {
      onChange('problems', data)
      setIsModal(false)
    })
  }

  const handleCanCreate = () => {
    return !(newItem?.type && newItem?.problem)
  }

  const handleDelete = (id) => {
    uniFetch(messageUrl + id + '/', 'DELETE').then((data) => {
      onDelete('problems', id)
    })
  }
  return (
    <div className="py-0 px-0">
      {fields['problems']?.map(appeal => <div key={appeal.id} className="row block-table-row">
        <AppealRow
          messageId={messageId}
          appeal={appeal}
          onDelete={handleDelete}
          canEdit={canEdit}
        />
      </div>)}
      {canEdit && <div className="py-3 px-2rem text-center">
        <Button onClick={() => setIsModal(true)} bsStyle="success">
          + Добавить Характер обращения
        </Button>
      </div>}
      <FieldModal isOpen={isModal} onClose={() => setIsModal(false)} title={'Характеристика обращения'}>
        <div className={'py-2'}><AsyncSelectField
          name={'type'}
          value={name => newItem[name]}
          label={name => 'Тип'}
          required={true}
          column={1}
          loadOptions={loadOptionsNoLimit}
          debounceTimeout={DEBOUNCE_TIMEOUT}
          onChange={handleChange}
          currentValue={name => null}
          placeholder="Выберите..."
        /></div>
        <InputField
          name="seatch"
          value={() => search}
          label={name => 'Наименование'}
          column={1}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          placeholder="Введите..."
        />
        <div className="card my-3">
          <div className={'card-header py-3'}>
            <Row className={'fw-bold'}>
              <Col sm={3}>Код</Col>
              <Col sm={9}>Наименование</Col>
            </Row>
          </div>
          <div className="card-body select-area">
            {errorFields?.search ?
              <Row>
                <Col><h4 className={'text-center text-danger'}>{searchErrorMessage}</h4></Col>
              </Row> : null}
            {!errorFields?.search && ready && searchResult?.results?.map(item =>
              <Row key={item.id}
                   className={newItem['problem'] === item.id ? selectedCls : 'py-2 can-select'}
                   onClick={() => handleChange('problem', item.id)}>
                <Col sm={3}><HighlightText text={item.code} highlight={search}/></Col>
                <Col sm={9}><HighlightText text={item.title} highlight={search}/></Col>
              </Row>
            )}
            {!ready ?
              <div className="text-center">
                <div className="spinner-border text-primary m-5" role="status" style={{width: '3rem', height: '3rem'}}>
                  <span className="visually-hidden">Загрузка...</span>
                </div>
              </div>
              : null}
          </div>
          <div className={'card-footer'}>
            <Row>
              <Col md={4}>
              <PaginationInfo
                count={searchResult?.count}
                limit={searchResult?.limit}
                offset={searchResult?.offset}
              />
            </Col>
              <Col md={4}>
                <div className="d-flex justify-content-center">
                  <Button bsStyle="success"
                          className={'mx-2'}
                          disabled={!searchResult?.previous}
                          onClick={() => handlePagination(searchResult?.previous)}>{'<'}</Button>
                  <Button bsStyle="success"
                          disabled={!searchResult?.next}
                          className={'mx-2'}
                          onClick={() => handlePagination(searchResult?.next)}>{'>'}</Button>
                </div>
              </Col>
              <Col md={4}>
                <div className={'text-end'}>
                  <Button bsStyle="danger" className={'mx-2'} onClick={() => setIsModal(false)}>Отменить</Button>
                  <Button bsStyle="success" className={'mx-2'} disabled={handleCanCreate()}
                          onClick={handleCreate}>Сохранить</Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </FieldModal>
    </div>
  )
}

function AppealRow({messageId, appeal, onDelete, canEdit}) {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const canEditRow = (messageId === appeal.message) && canEdit;
  const handleDelete = () => {
    onDelete(appeal.id)
  }
  const handleCancel = () => {
    setIsDeleteModal(false)
  }
  const handleModal = () => {
    setIsDeleteModal(true)
  }
  return (
    <>
      {isDeleteModal && <ConfirmModal
        text={'Вы действительно собираетесь удалить характер обращения?'}
        label={'Удаление характера обращения'}
        handleConfirm={handleDelete}
        handleCancel={handleCancel}
      />}
      <div className="col-11">
        <div className="row">
          <div className="col-2 fw-bold">Код</div>
          <div className="col-5 fw-bold">Наименование</div>
          <div className="col-5 fw-bold">Тип</div>
        </div>
        <div className="row row py-1">
          <div className="col-2 wrap-words">{appeal.problem.code}</div>
          <div className="col-5">{appeal.problem.title}</div>
          <div className="col-5">{appeal.type.title}</div>
        </div>
      </div>
      {canEditRow && <div className="col-1 actions">
        <button type="button" className="btn btn-outline-danger w-100 my-2"
                onClick={handleModal}>
          <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
        </button>
      </div>}
    </>
  )
}
