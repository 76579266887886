import React, {useEffect, useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import FZOrganizationDetail from "./fzorganization-detail";
import FZOrganizationTemplates from "./fzorganization-templates";
import FZOrganizationUnits from "./fzorganization-units";
import FZOrganizationBlank from "./fzorganization-blanks";
import FZOrganizationUsersList from "./fzorganization-users-list";
import FZOrganizationUser from "./fzorganization-user";
import FzActionLogTab from "../fz-action-log-tab";
import uniFetch from "../../utils/uniFetch";

const tabData = {
  organization: {
    title: 'Организация',
    contentId: false,
  },
  templates: {
    title: 'Шаблоны',
    contentId: false,
  },
  blanks: {
    title: 'Бланки',
    contentId: false,
  },
  organizationUnit: {
    title: 'Должностные лица',
    contentId: false,
  },
};


const tabsId = 'fz-organization-detail-tabs';

export function formatPermissions(permissionsString) {
  try {
    const formattedString = permissionsString
      .replace(/'/g, '"')
      .replace(/False/g, 'false')
      .replace(/True/g, 'true');

    return JSON.parse(formattedString);
  } catch (e) {
    return null
  }
}

export default function OrganizationTabs({attrs}) {
  const organizationId = attrs.getNamedItem('organization-id').value;
  const orgunit_item = attrs.getNamedItem('orgunit');
  const orgunit = orgunit_item ? JSON.parse(attrs.getNamedItem('orgunit').value) : {};
  const [orgName, setOrgName] = useState('')
  const [orgParent, setOrgParent] = useState(null)
  const [activeTab, setActiveTab] = useState('organization');

  const is_same_org = (oid) => {
    return orgunit?.all_orgs?.some(obj => obj === Number(oid));
  }

  const canEdit = () => {
    if (orgunit?.permissions?.can_root) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org(organizationId) && orgunit?.permissions?.can_all) return true;
    if (orgParent && orgunit?.permissions?.can_all && is_same_org(orgParent)) return true;
    return false
  };

  if (canEdit()) {
    tabData.users = {
      title: 'Пользователи',
      contentId: false,
    }
    tabData.history = {
      title: 'История',
      contentId: false,
    }
  } else if (Number(organizationId) === Number(orgunit?.org?.id)) {
    tabData.user = {
      title: 'Пользователь',
      contentId: false,
    }
  }

const Breadcrumbs = ({ orgName }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/fz59/organization_list/">Список организаций</a></li>
      <li className="breadcrumb-item active" aria-current="page">
        {orgName ? orgName : "Новая организация"}
      </li>
    </ol>
  </nav>
);


  useEffect(() => {
    Object.keys(tabData).map(k => {
      const paneId = tabsId + '-pane-' + k;
      const pane = document.getElementById(paneId);
      const contentId = tabData[k].contentId;
      if (typeof contentId === 'boolean') return;
      const content = document.getElementById(contentId);
      if (!pane || !content) return;
      pane.prepend(content);
    });
    if (organizationId) {
      uniFetch(`/edw/api/entities/${organizationId}/`).then(data => {
        setOrgName(data.entity_name)
        setOrgParent(data.parent)
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    }
  }, []);


  const renderTabContent = (tabKey) => {
    switch (tabKey) {
      case 'organization':
        return <FZOrganizationDetail orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'templates':
        return <FZOrganizationTemplates orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'organizationUnit':
        return <FZOrganizationUnits orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'blanks':
        return <FZOrganizationBlank orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'users':
        return <FZOrganizationUsersList orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'user':
        return <FZOrganizationUser orgunit={orgunit} organizationId={organizationId} orgParent={orgParent}/>;
      case 'history':
        return <FzActionLogTab objectId={organizationId}/>;
      default:
        return null;
    }
  };

  return (
    <>
      <h1 className="mb-3">{orgName}</h1>
      <Breadcrumbs orgName={orgName} />
      <Tabs activeKey={activeTab} onSelect={setActiveTab} id={tabsId}>
        {Object.keys(tabData).map((k) => (
          <Tab
            key={k}
            title={tabData[k].title}
            eventKey={k}
            className={'show'}
            disabled={k !== "organization" ? !Boolean(organizationId) : null}
            mountOnEnter
            unmountOnExit
          >
            {renderTabContent(k)}
          </Tab>
        ))}
      </Tabs>
    </>
  );

}
