import React from 'react';
import {FieldContainer} from "./FieldContainer";

export function StaticField({name, value, label, onChange, column, row, required, hideLabel, errorMessages}) {
  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages}}>
      <span className="custom-text fw-bold">{value(name) ?? ''}</span>
    </FieldContainer>
  )
}
