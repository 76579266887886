import React, {useEffect, useState, useMemo} from 'react';
import cookie from 'react-cookies';
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Row, HelpBlock, Form as FM} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import CoAuthorsBlock from './CoAuthorsBlock';
import FileInput from './FileInput';
import uniFetch from "../../utils/uniFetch";
import {HTMLConfirmModal, LoaderModal} from "../../components/Modal";

// TODO use sendButtonCompnent
// https://github.com/ericgio/react-bootstrap-typeahead/tree/v3.4.7
const fullColumnSize = 12;
const halfColumnSize = fullColumnSize / 2;
const thirdColumnSize = fullColumnSize / 3;
const sixthColumnSize = fullColumnSize / 6;
const fourColumnSize = fullColumnSize / 4;

const helpTextRequired = '* Обязательное поле.';
const helpTextFileLimits = `* не более 5 файлов. Каждый файл не более 10MB.
  Формат файлов: .png, .gif, .jpeg, .bmp, .pdf, .mpeg`;
// TODO: disable whom if where is not chosen or whom has 0 or 1 option
const autoCompletePlaceholder = 'Начните вводить текст...';
const fieldLabels = {
  org_id: 'Куда',
  unit_id: 'Кому',
  first_name: 'Имя',
  last_name: 'Фамилия',
  middle_name: 'Отчество',
  email: 'Email',
  phone: 'Телефон',
  socialStatus: 'Социальное положение',
  preferentialCategory: 'Льготная категория, если имеется',
  isOrganisation: 'Я обращаюсь от имени организации (юридического лица)',
  isAgree: 'Я даю согласие на обработку персональных данных',
  organisationName: 'Наименование организации (юридического лица)',
  organisationINN: 'ИНН',
  postal_code: 'Почтовый индекс',
  postal_country: 'Страна',
  postal_region: 'Регион',
  postal_district: 'Район',
  postal_city: 'Населенный пункт',
  postal_house: 'Квартира',
  postal_street: 'Улица',
  postal_home: 'Дом',
  postal_corpus: 'Строение',
  message_text: 'Текст обращения',
  files: 'Файлы',
  error: 'Ошибка',
  agree: 'Соглашение',
  co_authors: 'Соавторы'
};
const placeHolders = {
  first_name: '',
  last_name: '',
  middle_name: '',
  email: 'your_email@mail.ru',
  phone: '+7ХХХХХХХХХХ',
};

const formHeading = 'Направить обращение';
const recipientInfoHeading =
  'Укажите получателя обращения:';
const applicantInfoHeading =
  'В электронной анкете в Вашем обращении укажите в именительном падеже Ваши:';
const organisationInfoHeading = 'Организация:';
const addressInfoHeading = 'Почтовый адрес:';
const coAuthorsHeading = 'Соавторы:';
const coAuthorsSubHeading =
  'Добавьте одного или нескольких соавторов Вашего обращения';
// TODO rename content to text for consistencty
const contentHeading = 'Напишите текст обращения';

// id="form-text-description"
// id="form-attachments-description"


function cleanPhoneNumber(phoneNumber) {
  if (phoneNumber === null || phoneNumber === undefined) {
    return ''; // или другое значение по умолчанию
  }
  return phoneNumber.replace(/[^\d]/g, '');
}


export function AuthorForm(props) {
  const {fields, setFields, coAuthors} = props;
  const [checkBoxValue, setCheckBoxValue] = useState(false)
  const index = coAuthors ? props.index.toString() : ""
  const phoneValue = `${!coAuthors ? fields?.author?.phone_number ?? "" : fields?.co_authors?.[index]?.phone_number ?? "" }`
   useEffect(() => {
    if (props.user?.phone_number) {
      props.user.phone_number = cleanPhoneNumber(props.user?.phone_number)
    }
    if (!coAuthors) {
      setFields(prevState => {
        return {...prevState, ["author"]: {...prevState["author"], ...props.user}}
      })
    }
  }, [props.user]);


  const handleCheckBoxOrg = (e, coAuthors, i) => {
    setCheckBoxValue(e.target.checked)
    handleAuthorsFields(e, coAuthors, i)
  }

  const handleAuthorsFields = (e, coAuthors, i) => {
    const {name, value} = e.target
    if (name === 'phone_number') {
      if (!props.validatePhoneNumber(value)) {
        props.setPhoneError('Введите корректный номер телефона, который начинается с 7 или +7 в формате +7ХХХХХХХХХХ');
      } else {
        props.setPhoneError(null);
      }
    }
    setFields(prevState => {
      if (coAuthors) {
        return {...prevState, ["co_authors"]: {...prevState["co_authors"], [i]: {...prevState["co_authors"]?.[i], [name]: value}}}
      }
      return {...prevState, ["author"]: {...prevState["author"], [name]: value}}
    })
  }

  const getValidationAuthorState = (f, i) => {
    if (typeof i === "number" && Object.keys(props.errorsFields).length > 0) {
      return props.errorsFields.co_authors?.[i] ?? [f] ? 'error' : 'success'
    } else if (i === null && Object.keys(props.errorsFields).length > 0) {
      return props.errorsFields[f] ? 'error' : 'success'
    }
    return null

  }

  function getSelectOptionFromSlug(options, slug) {
    if (Array.isArray(slug)) {
      return options?.filter(option => slug.includes(option.slug))
    } else {
      return options?.find(option => slug === option.slug) ?? []
    }
  }
  return (
    <>
      <Row className="mb-3">
        <Col sm={thirdColumnSize}>
          <FormGroup className="required" controlId={`group-last_name${coAuthors ? "-" + index : ""}`}
                     validationState={getValidationAuthorState('last_name', coAuthors ? Number(index) : null)}>
            <ControlLabel>{fieldLabels.last_name}</ControlLabel>
            <FormControl
              type="text"
              name="last_name"
              value={`${!coAuthors ? fields?.author?.last_name ?? "" : fields?.co_authors?.[index]?.last_name ?? "" }`}
              placeholder={placeHolders.last_name}
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={(!coAuthors && props?.user?.last_name) || !props.canEdit}
            />
            <FormControl.Feedback/>
            <HelpBlock key={index}>{coAuthors ? props.errorsFields.co_authors?.[index]?.last_name: props.errorsFields.last_name}</HelpBlock>
          </FormGroup>
        </Col>
        <Col sm={thirdColumnSize}>
          <FormGroup className="required" controlId={`group-first_name${coAuthors ? "-" + index : ""}`}
                     validationState={getValidationAuthorState('first_name', coAuthors ? Number(index) : null)}>
            <ControlLabel>{fieldLabels.first_name}</ControlLabel>
            <FormControl
              type="text"
              name="first_name"
              value={`${!coAuthors ? fields?.author?.first_name ?? "" : fields?.co_authors?.[index]?.first_name ?? "" }`}
              placeholder={placeHolders.first_name}
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={(!coAuthors && props?.user?.first_name) || !props.canEdit}
            />
            <FormControl.Feedback/>
            <HelpBlock key={index}>{coAuthors ? props.errorsFields.co_authors?.[index]?.first_name: props.errorsFields.first_name}</HelpBlock>
          </FormGroup>
        </Col>
        <Col sm={thirdColumnSize}>
          <FormGroup controlId={`group-middle_name${coAuthors ? "-" + index : ""}`} validationState={null}>
            <ControlLabel>{fieldLabels.middle_name}</ControlLabel>
            <FormControl
              type="text"
              name="middle_name"
              value={`${!coAuthors ? fields?.author?.middle_name ?? "" : fields?.co_authors?.[index]?.middle_name ?? "" }`}
              placeholder={placeHolders.middle_name}
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={(!coAuthors && props?.user?.middle_name) || !props.canEdit}
            />
            <FormControl.Feedback/>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={halfColumnSize}>
          <FormGroup className="required" controlId={`group-email${coAuthors ? "-" + index : ""}`}
                     validationState={getValidationAuthorState('email', coAuthors ? Number(index) : null)}>
            <ControlLabel>{fieldLabels.email}</ControlLabel>
            <FormControl
              type="email"
              name="email"
              value={`${!coAuthors ? fields?.author?.email ?? "" : fields?.co_authors?.[index]?.email ?? "" }`}
              placeholder={placeHolders.email}
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={(!coAuthors && props?.user?.email) || !props.canEdit}
            />
            <FormControl.Feedback/>
            <HelpBlock key={index}>{coAuthors ? props.errorsFields.co_authors?.[index]?.email: props.errorsFields.email}</HelpBlock>
          </FormGroup>
        </Col>
        <Col sm={halfColumnSize}>
          <FormGroup controlId={`group-phone_number${coAuthors ? "-" + index : ""}`}
                     validationState={null}>
            <ControlLabel>{fieldLabels.phone}</ControlLabel>
            <FormControl
              type="text"
              name="phone_number"
              value={phoneValue}
              placeholder={placeHolders.phone}
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={(!coAuthors && props?.user?.phone_number) || !props.canEdit}
            />
            {props.phoneError && <HelpBlock className={"validate-phone"}>{props.phoneError}</HelpBlock>}
            <HelpBlock key={index}>{coAuthors ? props.errorsFields.co_authors?.[index]?.phone_number: props.errorsFields.phone_number}</HelpBlock>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={halfColumnSize}>
        <FormGroup className="" controlId={`group-social-status${coAuthors ? "-" + index : ""}`} validationState={null}>
          <ControlLabel>{fieldLabels.socialStatus}</ControlLabel>
          <Typeahead
            multiple
            id={`field-social-status${coAuthors ? "-" + index : ""}`}
            labelKey="name"
            onChange={(selections) => {
              setFields(prevState => {
                if (coAuthors) {
                  return {...prevState, ["co_authors"]: {...prevState["co_authors"], [index]: {...prevState["co_authors"]?.[index], ["social_status"]: selections.map(selection => selection.slug)}}}
                }
                return {...prevState, ["author"]: {...prevState["author"], ["social_status"]: selections.map(selection => selection.slug)}}
              })
            }}
            options={props.socialStatuses??[]}
            placeholder={autoCompletePlaceholder}
            selected={getSelectOptionFromSlug(props.socialStatuses??[], coAuthors ? fields?.co_authors?.[index]?.social_status : fields?.author?.social_status??[])}
            disabled={!props.canEdit}
          />
        </FormGroup>
        </Col>
        <Col sm={halfColumnSize}>
        <FormGroup className="" controlId={`group-preferential-category${coAuthors ? "-" + index : ""}`} validationState={null}>
          <ControlLabel>{fieldLabels.preferentialCategory}</ControlLabel>
          <Typeahead
            multiple
            id={`field-preferential-category${coAuthors ? "-" + index : ""}`}
            labelKey="name"
            onChange={(selections) => {
              setFields(prevState => {
                if (coAuthors) {
                  return {...prevState, ["co_authors"]: {...prevState["co_authors"], [index]: {...prevState["co_authors"]?.[index], ["preference_status"]: selections.map(selection => selection.slug)}}}
                }
                return {...prevState, ["author"]: {...prevState["author"], ["preference_status"]: selections.map(selection => selection.slug)}}
              })
            }}
            options={props.categories??[]}
            placeholder={autoCompletePlaceholder}
            selected={getSelectOptionFromSlug(props.categories??[], coAuthors ? fields?.co_authors?.[index]?.preference_status : fields?.author?.preference_status??[])}
            disabled={!props.canEdit}
          />
        </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={fullColumnSize}>
          <Checkbox name="checkbox_org" onChange={(e) => handleCheckBoxOrg(e, coAuthors, index)} checked={checkBoxValue} disabled={!props.canEdit}>
            {fieldLabels.isOrganisation}
          </Checkbox>
        </Col>
        {checkBoxValue ? <Col sm={halfColumnSize}>
          <FormGroup className="required group-organisation-name pt-2 mb-3"
                     controlId={`group-orgname${coAuthors ? "-" + index : ""}`}
                     validationState={getValidationAuthorState('orgname', coAuthors ? Number(index) : null)}>
            <ControlLabel>{fieldLabels.organisationName}</ControlLabel>
            <FormControl
              name="orgname"
              type="text"
              value={`${!coAuthors ? fields?.author?.orgname ?? "" : fields?.co_authors?.[index]?.orgname ?? "" }`}
              placeholder=""
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={!props.canEdit}
            />
            <FormControl.Feedback/>
            <HelpBlock key={index}>{coAuthors ? props.errorsFields.co_authors?.[index]?.orgname: props.errorsFields.orgname}</HelpBlock>
          </FormGroup>
        </Col> : null}
        {checkBoxValue ? <Col sm={halfColumnSize}>
          <FormGroup className="group-organisation-inn pt-2"
                     controlId={`group-inn${coAuthors ? "-" + index : ""}`}
                     validationState={null}>
            <ControlLabel>{fieldLabels.organisationINN}</ControlLabel>
            <FormControl
              type="text"
              name="inn"
              value={`${!coAuthors ? fields?.author?.inn ?? "" : fields?.co_authors?.[index]?.inn ?? "" }`}
              placeholder=""
              onChange={(e) => handleAuthorsFields(e, coAuthors, index)}
              disabled={!props.canEdit}
            />
            <FormControl.Feedback/>
          </FormGroup>
        </Col> : null}
      </Row>
    </>
  )
}


const filterRecipients = (searchText, organizationList, org) => {
  if (org) {
    if (!searchText) {
      return organizationList
    }

    return organizationList.filter(({name}) =>
      name.toLowerCase().includes(searchText[0]?.toLowerCase())
    );
  }

  const unitsData = []
  organizationList.map((organization) => {
    organization.units.map((units) => {
      unitsData.push(units)
    })
    return unitsData
  })
  return unitsData.filter(({recipient}) =>
    recipient.toLowerCase().includes(searchText[0]?.toLowerCase())
  );

}

function checkFileSizes(files) {
    const totalSizeLimit = 20_000_000;

    let totalSize = 0;

    for (let item of files) {
        const fileSize = item.file.size;
        totalSize += fileSize;
    }

    if (totalSize > totalSizeLimit) {
        return "Общий размер файлов выше допустимого";
    } else {
        return null;
    }
}


export default function Form(props) {
  const {organizationList, org, unit, isPublic, isAuth, loginUrl, user} =props
  const [searchOrg, setSearchOrg] = useState('');
  const [searchUnit, setSearchUnit] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [errorsFields, setErrorsFields] = useState({});
  const [responseStatus, setResponseStatus] = useState(0);
  const [response, setResponse] = useState({});
  const [files, setFiles] = useState([]);
  const [fields, setFields] = useState({});
  const [agree, setAgree] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [phoneError, setPhoneError] = useState(null);
  const [agreeModal, setAgreeModal] = useState(false)
  const fileError = useMemo(()=>checkFileSizes(files), [files]);
  const canEdit = isPublic || isAuth;
  const privatePolicy =
  ['Информация о персональных данных авторов обращений, направленных в форме электронного документа, сведения, содержащиеся в обращениях авторов, а также сведения, касающиеся частной жизни авторов, хранятся и обрабатываются с соблюдением требований российского законодательства.',
  'В соответствии со статьей 9 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных» даю согласие на обработку персональных данных.']

  const formAttachDescEl = document.getElementById('form-attachments-description');
  const contentDescEl = document.getElementById('form-text-description');

  const acceptedFileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.presentation',
      'application/x-mspublisher',
      'application/pdf',
      'image/bmp',
      'audio/mpeg',
      'video/mp4',
      'audio/x-ms-wma',
      'video/x-msvideo',
      'video/x-matroska',
      'video/x-ms-wmv',
      'video/x-sgi-movie',
      'video/x-flv',
]

  useEffect(()=>{
    if(org && organizationList.length && !searchOrg){
      setSearchOrg([organizationList.find(item=>item.id == org)?.name])
    }
  },[org, organizationList])

  // Organization
  useEffect(() => {
    if(!searchOrg) return null
    const Debounce = setTimeout(() => {
      const filteredOrg = filterRecipients(searchOrg, organizationList, true);
      setSelectedOrganization(filteredOrg);

      if (filteredOrg.length === 0) {
        setSelectedUnits([])
      }

      filteredOrg.map((organization) => {
        let setUnit = null
        let setOrg = null
        if (organization.units.length > 1) {
          organization.units.some(item => {
            if (unit == item.id || (item.is_head && !unit)) {
              setSelectedUnits([item]);
              setUnit = item.id;
              setOrg = organization.id;
              return true;
            }
            return false;
          });

        } else {
          if (organization.units.length > 0) {
            setSelectedUnits([organization.units[0]]);
            setUnit = organization.units[0].id
          }
          setOrg = organization.id
        }
        setFields(prevState => {
          return {...prevState, ["unit_id"]: setUnit, ["org_id"]: setOrg}
        })
      })
    }, 300);

    return () => clearTimeout(Debounce);
  }, [searchOrg]);

  // Units
  useEffect(() => {
    const Debounce = setTimeout(() => {
      const filteredUnit = filterRecipients(searchUnit, organizationList, false);
      setSelectedUnits(filteredUnit);
      const unitId = filteredUnit[0]?.id
        if (unitId) {
          organizationList.forEach((organization) => {
            organization.units.forEach(unit => {
              if (unit.id === unitId) {
                setSelectedOrganization([organization])
                setFields(prevState => {
                  return {...prevState, ["unit_id"]: unitId, ["org_id"]: organization.id}
                })
              }
            })
          })
        }
    }, 300);

    return () => clearTimeout(Debounce);
  }, [searchUnit]);

  const handleFields = (e) => {
    const {name, value} = e.target;
    setFields(prevState => {
      return {...prevState, [name]: value}
    })
  }

  const organizationOptions = organizationList.map((organization, i) => {
    return organization.name
  })

  const unitsOptions = organizationList.reduce((acc, recipient) => {
    recipient.units.forEach(unit => {
      acc.push(unit.recipient);
    });
    return acc;
  }, []);


  const filterUnitByOrg = (option) => {

    if (selectedOrganization.length > 0) {
      const dft = organizationList.reduce((acc, organization) => {
        if (selectedOrganization[0]?.id === organization.id) {
          organization.units.forEach(unit => {
            acc.push(unit.recipient)
          })
        }
        return acc;
      }, []);
      return dft.indexOf(option) > -1
    } else {
      return true
    }
  }

  const normalUnitFilter = (option, props) =>{
    if (selectedOrganization.length > 0) {
      const c_org = organizationList.find((org)=>{ return org.id === selectedOrganization[0]?.id});
      const org_units = c_org.units.map((unit)=>{return unit.recipient})
      if (org_units){
        if (org_units.indexOf(option) > -1){
           if(org_units.includes(selectedUnits[0]?.recipient)) return true;
          if (props.text.length === 0) return true;
          if (option.indexOf(props.text) > -1) return true;
        }
      }
    }else{
      if (props.text.length === 0) return true;
      if (option.indexOf(props.text) > -1) return true;
    }

    return false;
  }

  const handleClearForm = () => {
    const userData = {...user}
    if (user?.phone_number) {
      userData.phone_number = cleanPhoneNumber(user?.phone_number)
    }
    if (user) {
      setFields(() => {
        return {["author"]: userData}
      })
    } else {
      setFields({})
    }
    setSelectedOrganization([])
    setSelectedUnits([])
    setFiles([])
    setResponseStatus(0)
    setErrorsFields({})
    if (org && organizationList.length) {
      setSearchOrg([organizationList.find(item => item.id == org)?.name])
    }
  }

  const handlePost = () => {
    if (!isLoading){
      setIsLoading(true);
      const data = {...fields}
      if (files.length) {
        data.active = false
      }
      uniFetch(`/fz59/federal-appeal/`, 'POST', data, false, true, true)
        .then(data => {
          // const callback = () => window.location.href = `/fz59/form-success/`;
           const callback = () => {
            setIsLoading(false);
            setResponseStatus(200);
            setResponse(data.data)
           };
          SendFiles(data.data.message_id, cookie.load('csrftoken'), data.data.hash, callback);
        })
        .catch(data => {
          if (data?.errors?.errors) {
            setErrorsFields(data.errors.errors);
          } else {
            setErrorsFields({error:'Ошибка сервера'})
          }

          setIsLoading(false);
        });
    };
  }

  const SendFiles = (msgId, csrftoken, form_hash, callback) => {
    const formData = new FormData()
    // let totalSizeFiles = 0
    const files_r = files.reverse()
    files_r.forEach((file, idx) => {
      // totalSizeFiles += file.file.size
      // if (totalSizeFiles > 10_000_000 || !acceptedFileTypes.includes(file.file.type)) {
      //   return
      // }
      formData.append('file-'+idx.toString(), file.file, file.file.name)
    });

    formData.append('message_id', msgId)
    formData.append('csrftoken', csrftoken)
    formData.append('form_hash', form_hash)
    if (files_r && files_r.length !== 0) {
      uniFetch('/fz59/api/file-form-upload/', 'POST', formData, false, false, true).then(data => {
        callback()
      }).catch(data => {
        if (data?.errors?.message) {
          setErrorsFields(prevErrorFields => {
            return {...prevErrorFields, files: data.errors.message}
          });
        } else {
          setErrorsFields({error: 'Ошибка сервера'})
        }
        setIsLoading(false);
      })
    } else {
      callback()
    }
  }

  const getValidationState = (f) => {
    if (Object.keys(errorsFields).length > 0) {
      return errorsFields[f] ? 'error' : 'success'
    }
    return null

  }
  const login = (url) => {
    window.location.href = url
  }

  const validatePhoneNumber = (phoneNumber) => {
    if(!phoneNumber) return true;
    const phoneRegex = /^\+?7\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  if (responseStatus === 200) {
    return (<div className="container pt-5 min-vh-70">
      <div className="alert alert-success form-success text-center">
        <p className="mt-2 mb-2">Ваше обращение направлено на рассмотрение!</p>
        {response.message_id && <p className="mt-2 mb-2">Идентификатор обращения {response.message_id}</p>}
        {isAuth && <p className="mt-2 mb-2">Статус рассмотрения Вашего обращения можно посмотреть в личном кабинете</p>}
      </div>
      <div className="d-flex justify-content-center">
        <Button onClick={() => handleClearForm()} bsStyle="primary">
          Направить новое обращение
        </Button>
      </div>
    </div>)
  }
  return (
    <div className="form-container">
      <h1>{formHeading}</h1>

      {/*Информационный блок с изменениями, в дальнейшем убрать*/}
      <div className="alert alert-info mb-4" style={{textAlign: 'justify'}}>
        <p><span className="red">ВНИМАНИЕ!</span> В связи с изменениями правил направления обращений граждан в
          государственные органы, органы местного самоуправления или должностному лицу в форме электронного документа в
          соответствии с Федеральным законом от 28.12.2024 № 547 «О внесении изменений в Федеральный закон «О порядке
          рассмотрения обращений граждан Российской Федерации» с 30 марта 2025 года к рассмотрению будут приниматься
          обращения, направленные с помощью федеральной государственной информационной системы «Единый портал
          государственных и муниципальных услуг (функций)», иной информационной системы государственного органа или
          органа местного самоуправления либо официального сайта государственного органа или органа местного
          самоуправления в информационно-телекоммуникационной сети «Интернет», обеспечивающих идентификацию и (или)
          аутентификацию граждан.
        </p>
        <p className='mb-0'>
          Обращения, направляемые по электронной почте в государственные органы, органы местного самоуправления или
          должностному лицу без идентификации и (или) аутентификации граждан, к рассмотрению приниматься не будут.
        </p>
      </div>
      {!isAuth && !isPublic &&
        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button onClick={() => login(loginUrl)} bsStyle="primary">
            Войти через госуслуги
          </Button>
        </div>}
      {canEdit && <p>Поля, отмеченные <span className="red">*</span>, обязательны для заполнения.</p>}
      {canEdit && <p>{privatePolicy[0]}</p>}
      {canEdit && <FM className="mt-4" noValidate>
      <div className="block-container mb-5">
        <label className="block-label">{recipientInfoHeading}</label>
        <div className="block-container-content">
          <Row className="mb-3">
              <FormGroup className="required" controlId="group-where" validationState={getValidationState('org_id')}>
                <ControlLabel>{fieldLabels.org_id}</ControlLabel>
                <Typeahead
                  clearButton
                  emptyLabel={"Ничего не найдено"}
                  paginationText={"Показать еще"}
                  id="field-where"
                  labelKey="name"
                  onChange={setSearchOrg}
                  options={organizationOptions}
                  placeholder={autoCompletePlaceholder}
                  selected={selectedOrganization}
                  disabled={!canEdit}
                />
                <HelpBlock>{errorsFields.org_id}</HelpBlock>
              </FormGroup>
          </Row>
          <Row className="mb-3">
              <FormGroup controlId="group-whom" validationState={getValidationState('unit_id')}>
                <ControlLabel>{fieldLabels.unit_id}</ControlLabel>
                <Typeahead
                  id="field-whom"
                  emptyLabel={"Ничего не найдено"}
                  paginationText={"Показать еще"}
                  labelKey="recipient"
                  onChange={setSearchUnit}
                  options={unitsOptions}
                  placeholder={autoCompletePlaceholder}
                  filterBy={normalUnitFilter}
                  selected={selectedUnits}
                  disabled={!canEdit}
                />
                <HelpBlock>{errorsFields.unit_id}</HelpBlock>
              </FormGroup>
          </Row>
        </div>
      </div>

      <div className="block-container mb-5">
        <label className="block-label">{applicantInfoHeading}</label>
        <div className="block-container-content">
          <AuthorForm coAuthors={false} fields={fields} setFields={setFields} errorsFields={errorsFields} setErrorsFields={errorsFields}
                      socialStatuses={props.statusList} categories={props.categoryList} validatePhoneNumber={validatePhoneNumber}
                      setPhoneError={setPhoneError} phoneError={phoneError} user={props.user} canEdit={canEdit}
          />
        </div>
      </div>

      <div className="block-container mb-5">
        <label className="block-label">{addressInfoHeading}</label>
        <div className="block-container-content">
          <Row>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_code" validationState={getValidationState('postal_code')}>
                <ControlLabel>{fieldLabels.postal_code}</ControlLabel>
                <FormControl
                  required
                  type="text"
                  name="postal_code"
                  value={fields?.postal_code ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_code}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_country" validationState={getValidationState('postal_country')}>
                <ControlLabel>{fieldLabels.postal_country}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_country"
                  value={fields?.postal_country ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_country}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_region" validationState={getValidationState('postal_region')}>
                <ControlLabel>{fieldLabels.postal_region}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_region"
                  value={fields?.postal_region ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_region}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_district" validationState={getValidationState('postal_district')}>
                <ControlLabel>{fieldLabels.postal_district}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_district"
                  value={fields?.postal_district ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_district}</HelpBlock>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_city" validationState={getValidationState('postal_city')}>
                <ControlLabel>{fieldLabels.postal_city}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_city"
                  value={fields?.postal_city ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_city}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={fourColumnSize}>
              <FormGroup controlId="postal_street" validationState={getValidationState('postal_street')}>
                <ControlLabel>{fieldLabels.postal_street}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_street"
                  value={fields?.postal_street ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_street}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={sixthColumnSize}>
              <FormGroup controlId="postal_home" validationState={getValidationState('postal_home')}>
                <ControlLabel>{fieldLabels.postal_home}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_home"
                  value={fields?.postal_home ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_home}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={sixthColumnSize}>
              <FormGroup controlId="postal_corpus" validationState={getValidationState('postal_corpus')}>
                <ControlLabel>{fieldLabels.postal_corpus}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_corpus"
                  value={fields?.postal_corpus ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_corpus}</HelpBlock>
              </FormGroup>
            </Col>
            <Col sm={sixthColumnSize}>
              <FormGroup controlId="postal_house" validationState={getValidationState('postal_house')}>
                <ControlLabel>{fieldLabels.postal_house}</ControlLabel>
                <FormControl
                  type="text"
                  name="postal_house"
                  value={fields?.postal_house ?? ""}
                  placeholder=""
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <FormControl.Feedback/>
                <HelpBlock>{errorsFields.postal_house}</HelpBlock>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>

      <div className="block-container mb-5">
        <label className="block-label">{coAuthorsHeading}</label>
        <div className="block-container-content pb-0"><p className="mb-0">{coAuthorsSubHeading}</p></div>
        <CoAuthorsBlock fields={fields} setFields={setFields} errorsFields={errorsFields} setErrorsFields={errorsFields}
                      socialStatuses={props.statusList} categories={props.categoryList} validatePhoneNumber={validatePhoneNumber}
                      setPhoneError={setPhoneError} phoneError={phoneError} canEdit={canEdit}
      />
      </div>
      <div className="block-container mb-5">
        <label id="form-text-heading" className="block-label">{contentHeading}</label>
        <div dangerouslySetInnerHTML={{ __html: contentDescEl.innerHTML }} />
        <div className="block-container-content">
          <Row id="form-text-row">
            <Col sm={fullColumnSize}>
              <FormGroup className="required" controlId="message_text" validationState={getValidationState('message_text')}>
                <FormControl
                  componentClass="textarea"
                  name="message_text"
                  value={fields?.message_text??""}
                  onChange={handleFields}
                  disabled={!canEdit}
                />
                <HelpBlock>{errorsFields.message_text}</HelpBlock>
              </FormGroup>
            </Col>
          </Row>
          <div dangerouslySetInnerHTML={{ __html: formAttachDescEl.innerHTML }} />
          <FileInput
          files={files}
          setFiles={setFiles}
          acceptedFileTypes={acceptedFileTypes}
          maxTotalFileSize={'20MB'}
          disabled={!canEdit}
          />
           <HelpBlock className="text-danger">{fileError}</HelpBlock>
          <HelpBlock className="text-danger">{errorsFields.files}</HelpBlock>
        </div>
      </div>
        <div className="d-flex justify-content-center">
          {Object.keys(errorsFields).length > 0 ?
          <HelpBlock className="send-error alert alert-danger mb-4">
            <p>Ошибка при заполнении формы:</p>
            {Object.keys(errorsFields).map(key =>
              <p key={key}>
                {fieldLabels?.[key] ?? key}:
                {typeof errorsFields?.[key] === 'object' ? ' Не все обязательные поля заполнены' : errorsFields?.[key]}
              </p>)}
          </HelpBlock> :
          null}
        </div>
        <div className="d-flex justify-content-center">
          {agreeModal &&
            <HTMLConfirmModal
              label={'Согласие на обработку персональных данных'}
              handleConfirm={() => {
                handlePost();
                setAgreeModal(false)
              }}
              handleCancel={() => {setAgreeModal(false); setAgree(false)}}
              validateConfirm={agree}
              confirmLabel={'Отправить'}
              cancelLabel={'Отменить'}
            >
              <Checkbox
                name={'agree'}
                onChange={e => setAgree(e.target.checked)}
                checked={agree}
              >
                <b>{fieldLabels.isAgree}</b>
              </Checkbox>
              <p className={'small text-secondary'}>{privatePolicy[0]}<br/>{privatePolicy[1]}</p>
            </HTMLConfirmModal>
          }
        <Button onClick={!agree && props?.showConfirm ? () => setAgreeModal(true): handlePost} bsStyle="primary"
                disabled={!!(isLoading || phoneError || fileError || !canEdit)}>
          {isLoading ? 'Загрузка...' : 'Направить обращение'}
        </Button>
      </div>
        {isLoading ? <LoaderModal/>: null}
    </FM>}
    </div>
  );
}
