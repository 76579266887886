import React from 'react';
import {generateUniqueId} from "../utils";

const RadioGroup = ({options, value, label, onChange, name, disabled, multiple, error}) => {
  const isUngrouped = !options?.[0]?.children.length;
  const containerClassName = `custom-radio-group-container ${isUngrouped ? 'ungrouped' : ''}`;

  const input = (option) => {
    const type = multiple ? 'checkbox' : 'radio';
    const checked = multiple ? value.includes(option.slug) : value === option.slug;
    const uniqId = `${name}-${option.slug}-${generateUniqueId()}`
    return (
      <div key={name + option.slug} className="custom-radio-option">
        <input
          type={type}
          id={uniqId}
          name={name}
          value={option.slug}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className="custom-radio-input form-check-input"
        />
        <label htmlFor={uniqId} className={"custom-radio-label" + (checked ? " fw-bold" : "")}>
          {option.name}
        </label>
      </div>
    );
  };

  return (
    <div className={containerClassName}>
      {isUngrouped ? (
        <div className="custom-radio-group">
          {options?.length > 6 ? <div className="two-columns">
            {options?.map(option => input(option))}
          </div> : options?.map(option => input(option))}
        </div>
      ) : (
        options?.map(option => (
          <div key={name + option.name} className="custom-radio-group">
            <label className={"custom-radio-group-label"}>{option.name}</label>
            <div className="two-columns">
              {option.children.map(child => input(child))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default RadioGroup;
