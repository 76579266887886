import React, {useState, useRef} from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


const labels = {
  labelInvalidField: "Поле недействительно",
  labelFileTypeNotAllowed: "Недопустимый формат файла",
  fileValidateTypeLabelExpectedTypes: "Файл загружен не будет",
  labelFileWaitingForSize: "Ожидание размера",
  labelFileSizeNotAvailable: "Размер недоступен",
  labelFileLoading: "Загрузка",
  labelFileLoadError: "Ошибка загрузки",
  labelFileProcessing: "Обработка",
  labelFileProcessingComplete: "Обработка завершена",
  labelFileProcessingAborted: "Обработка прервана",
  labelFileProcessingError: "Ошибка обработки",
  labelFileProcessingRevertError: "Ошибка отмены обработки",
  labelFileRemoveError: "Ошибка удаления",
  labelTapToCancel: "Нажмите для отмены",
  labelTapToRetry: "Нажмите для повтора",
  labelTapToUndo: "Нажмите для отмены",
  labelButtonRemoveItem: "Удалить",
  labelButtonAbortItemLoad: "Отмена",
  labelButtonRetryItemLoad: "Повторить",
  labelButtonAbortItemProcessing: "Отмена",
  labelButtonUndoItemProcessing: "Отмена",
  labelButtonRetryItemProcessing: "Повторить",
  labelButtonProcessItem: "Обработка",
  labelMaxTotalFileSizeExceeded: "Превышен максимальный размер файла",
  labelMaxTotalFileSize: "Максимальный общий размер файлов 100MB",
};

function getURL(input) {
  if (isValidURL(input)) {
    return input;
  } else {
    try {
      const obj = JSON.parse(input);
      if (obj.file) {
        return obj.file;
      }
    } catch (e) {
    }
  }
  return null;
}

function isValidURL(string) {
  try {
      if ((string.length > 0)&&(string[0]==='/'))return true;
      new URL(string);
      return true;
  } catch (_) {
    return false;
  }
}

function downloadFile(url) {
    const link = document.createElement('a');
    if (url?.serverId){
        link.href = getURL(url.serverId);
        link.setAttribute('download', url?.file?.name??'');
    }else{
        link.href = URL.createObjectURL(url.file);
        link.setAttribute('download', url.file.name);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function createControllablePromise() {
  let resolve;
  let reject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolve,
    reject
  };
}

export function SimpleFileInput(props) {
  const [showModal, setShowModal] = useState(false);
  const [loadFiles, setLoadFiles] = useState([])
  const promiseRef = useRef(null);
  const button_text = " + Добавить файл"
  const handleBeforeRemoveFile = (item) => {
    if(!props?.isConfirmDelete) return true;
    setShowModal(true);
    promiseRef.current = createControllablePromise();
    return promiseRef.current.promise.then((result) => {
      if (Object.keys(item?.getMetadata()).length && result) {
        props.beforeRemoveFile(item?.getMetadata()?.id)
      }
      return result;
    });
  };

  const handleConfirm = () => {
    promiseRef.current.resolve(true);
    setShowModal(false);
  };

  const handleCancel = () => {
    promiseRef.current.resolve(false);
    setShowModal(false);
  };

  const handleProcessFile = (error, file) => {
    setLoadFiles(prevState => [...prevState, file])
  };

  const handleProcessFiles = () =>{
    props.onChangeFile(loadFiles)
    setLoadFiles([])
  }

  const handleOnUpdateFiles = (files) =>{
      props.setFiles(files);
  }

  return (
    <>
      <FilePond
        className={'simple-file-input'}
        stylePanelLayout={'integrated'}
        files={props.files}
        onupdatefiles={handleOnUpdateFiles}
        allowMultiple={false}
        instantUpload={props?.instantUpload ?? false}
        acceptedFileTypes={props.acceptedFileTypes}
        styleButtonRemoveItemPosition="right"
        allowRemove={!props?.disabled ?? true}
        allowDrop={false}
        allowProcess={false}
        dropOnElement={false}
        maxFiles={1}
        maxTotalFileSize="100MB"
        maxParallelUploads={1}
        name={props?.name ?? "files"}
        server={props?.server}
        beforeRemoveFile={handleBeforeRemoveFile}
        onremovefile={props?.onremovefile}
        onprocessfile={props?.instantUpload && handleProcessFile}
        onprocessfiles={props?.instantUpload && handleProcessFiles}
        onactivatefile={(file) => props.allowDownload && downloadFile(file)}
        labelIdle={
          ' <span class="btn btn-outline-success w-100">' + button_text + '</span>'
        }
        {...labels}
        credits={false}
      />
    </>
  );
}
