import React from 'react';
import {render} from 'react-dom';
import FederalAppeal from './fzform/FederalAppeal';

const container = document.getElementById('federal-appeal-container');
const defaultApiUrl = '/api/federal-appeal/';

if (container) {
  const apiUrl = container.dataset.apiUrl ?? defaultApiUrl;
  const loginUrl = container.dataset?.loginUrl;
  const org = container.dataset?.org;
  const unit = container.dataset?.unit;
  const isPublic = JSON.parse(container.dataset?.isPublic);
  const isAuth = JSON.parse(container.dataset?.isAuth);
  const idHash = container.dataset?.idHash;
  const showConfirm = JSON.parse(container.dataset?.showConfirm);
  render(
    <FederalAppeal
      apiUrl={apiUrl}
      org={org}
      isPublic={isPublic}
      isAuth={isAuth}
      unit={unit}
      idHash={idHash}
      showConfirm={showConfirm}
      loginUrl={loginUrl}
    />, container
  );
}

// enable hot module reloading for the dev server
process.env.NODE_ENV === 'development' && module.hot.accept();
