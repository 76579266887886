import React, { useState, useEffect, } from 'react';
import {
  Row,
  Col,
  Button,
  HelpBlock,
} from 'react-bootstrap';

import { BlockContainer } from "../../components/Fields/BlockContainer";
import { InputField } from "../../components/Fields/InputField";
import { DateField } from "../../components/Fields/DateField";
import { CKEditorField } from "../../components/Fields/CKEditorField";
import { FilesField } from "../../components/Fields/FilesField";
import { FilePodServer, formatFiles, handleRemoveFiles } from "../../utils/filepond";
import uniFetch from "../../utils/uniFetch";
import { CodeInput } from "./responsible-group-tab";
import { CheckBoxField } from "../../components/Fields/CheckBoxField";
import { SimpleFileInput } from "../../components/Fields/SimpleFileField";
import {convertDateFormat} from "../../utils/common";

const fullColumnSize = 12;
const halfColumnSize = fullColumnSize / 2;


const labels = {
  reg_number: 'Регистрационный номер',
  post_at: 'Дата поступления',
  income_number: 'Исходящий номер документа из Управления Президента',
  income_at: 'Дата направления документа из Управления Президента',
  annotation: 'Аннотация',
  description: 'Текст обращения',
  files: 'Прикрепленные файлы',
  answer: 'Файлы ответов на обращение', // для обработки ошибки
  uid_work_correspondence: 'UID рабочей корреспонденции из Управления Президента',
  uid_response: 'UID ответа в Управления Президента',
  fio_signed_response: 'ФИО лица, подписавшего пакет ответа',
  unit: 'ФИО лица, подписавшего пакет ответа', // для обработки ошибки
  direction_at: 'Дата направления пакета ответа в Управление Президента',
  answer_file: 'Файл ответа',
  file: 'Пакет файлов'
}


export function ResponsePresidentTab(props) {
  const responsible = props.responsible;
  const [file, setFile] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [errorFields, setErrorFields] = useState({});
  const [errorsPatch, setErrorsPatch] = useState({});
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [answers, setAnswers] = useState([])
  const [currentPerson, setCurrentPerson] = useState({
    code: '',
    title: '',
    id: null,
  });
  const [fieldsResponse, setFieldsResponse] = useState({})
  const medoApiUrl = '/fz59/api/medo/'
  const personSearchUrl = '/edw/api/data-marts/fzorganisationunit-dm/entities/';

  const personSearchKeys = {codeKey: 'code', textKey: 'title', searchKey: 'full_name'};
  const personSearchTitles = [
    {title: 'Код', prop: 'code', col: 2},
    {title: 'Наименование', prop: 'short_fio', col: 5},
    {title: 'Должность', prop: 'position', col: 5}];

  const fields = props.fields
  const modelDefinition = props.modelDefinition
  const genRegisterButton = props.genRegisterButton
  const handleCreateFiles = props.handleCreateFiles
  const handleChangeFile = props.handleChangeFile
  const messageId = props.messageId
  const personRequestParams = {'org': fields?.reg_organisation}

  const handleSetPersonValue = (val) => {
    setCurrentPerson(val === null ? {code: '', title: '', id: null} : {
      code: val?.extra?.code,
      title: val?.extra?.short_fio,
      id: val?.id
    });
  }

  const formatFile = (source, name, size, type) => {
    return [
      {
        source,
        options: {
          type: 'local',
          file: {
            name,
            size,
            type,
          },
        },
      },
    ]

  }

  const fetchData = (messageIdsString) => {
    /*
    uniFetch(`${answerApiUrl}?message__in=${messageIdsString}&limit=250`, 'GET', '', false, true, true)
    .then(data => {
      setAnswers(data.results.map(answer => {
        return {
          id: answer.id,
          file: answer.signed_file || answer.addition_file,
          file_info: answer.signed_fileinfo || answer.addition_fileinfo,
          created_at: answer.created_at,
          unit_full_name: answer.unit_full_name,
          unit_position: answer.unit_position,
        }
      }))
    })
    .catch(data => {
      console.log('Error answer', data)
    }).finally(() => {
      setIsLoadingAnswers(false)
    })
    */
    uniFetch(`${medoApiUrl}?messages=${messageId}`, 'GET', '', false, true, true)
    .then(data => {
      setFieldsResponse(data.results?.[0] || {})
      setFieldsResponse(prevFields => ({
        ...prevFields,
        ['message_id']: messageId,
      }))
      if (data.results?.[0]) {
        const item = data.results?.[0]
        if (item.file !== null) {
          setFile(formatFile(item.file, item.file_fileinfo.name, item.file_fileinfo.size, item.file_fileinfo.type))
        }
      }
      const item = data.results?.[0]
      if (data.results?.[0]?.unit_info !== null) {
        setCurrentPerson({code: item.unit_info.code, id: item.unit_info.id, title: item.unit_info.short_fio})
      }
    })
    .catch(data => {
      console.log('Error medo', data)
    }).finally(() => {
      setIsLoading(false)
    })

  }
  //todo:del
  useEffect(() => {
    let messageIds = fields?.children.map(child => child.id);
    messageIds.push(messageId)
    const messageIdsString = messageIds.join(',');

    setIsLoading(true)
    fetchData(messageIdsString);

  }, []);

  useEffect(() => {
    setFieldsResponse(prevFields => ({
      ...prevFields,
      ['unit']: currentPerson.id,
    }));
  }, [currentPerson])

  const handleChangeDate = (e) => {
    const {name, value} = e.target;
    const formattedDateTime = value ? `${value}T08:00` : null;
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFieldsResponse(prevFields => ({
      ...prevFields,
      [name]: formattedDateTime,
    }));
  };

  const handleChange = (e) => {
    const {name, value} = e.target;

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFieldsResponse(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleCheckBox = (e, answerId) => {
    setFieldsResponse(prevFields => {
      const answer = prevFields.answer || [];
      if (e.target.checked) {
        return {
          ...prevFields,
          answer: [...answer, answerId],
        };
      } else {
        return {
          ...prevFields,
          answer: answer.filter(id => id !== answerId),
        };
      }
    });
  }

  const handlePatch = (e) => {
    setIsButtonLoading(true)
    setIsLoading(true)
    delete fieldsResponse?.active
    delete fieldsResponse?.messages
    const medoId = fieldsResponse.id
    uniFetch(`${medoApiUrl}${medoId}/`, 'PATCH', fieldsResponse, false, true, true)
    .then(data => {
      setFieldsResponse(data)
      setFieldsResponse(prevFields => ({
        ...prevFields,
        ['message_id']: messageId,
      }))
      setErrorsPatch({})
      setFile(formatFile(data.file, data.file_fileinfo.name, data.file_fileinfo.size, data.file_fileinfo.type))
    })
    .catch(data => {
      console.log('Error fetch data medo', data)
      setErrorsPatch(data)
    }).finally(() => {
      setIsLoading(false)
      setIsButtonLoading(false)
    });
  }
  if(Object.keys(fieldsResponse).length === 1) return (<></>)
  return (
    <>
      <div className="form-container">
        <div className="blocks-container">
          <BlockContainer label='Сведения обращения'>
            <Row>
              <Col sm={fullColumnSize}>
                <InputField
                  name={'reg_number'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  column={2}
                  onChange={handleChange}
                  // rightButton={genRegisterButton()}
                  errorMessages={name => errorFields?.[name]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <DateField
                  name={'post_at'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  column={2}
                  onChange={handleChangeDate}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <InputField
                  name={'income_number'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  column={2}
                  onChange={handleChange}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <DateField
                  name={'income_at'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  column={2}
                  onChange={handleChangeDate}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <InputField
                  name={'annotation'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  hideLabel={false}
                  column={2}
                  onChange={handleChange}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                {console.log("fieldsResponse[name]",fieldsResponse)}
                <CKEditorField
                  name={'description'}
                  disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  hideLabel={false}
                  column={1}
                  wrappable={false}
                  onChange={() => null}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <FilesField
                  name={'files'}
                  disabled={true}
                  paramName={'file'}
                  value={name => formatFiles(fields[name])}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  hideLabel={false}
                  column={1}
                  onChange={handleCreateFiles}
                  onChangeFile={handleChangeFile}
                  instantUpload={true}
                  allowDownload={true}
                  isConfirmDelete={true}
                  beforeRemoveFile={handleRemoveFiles}
                  server={FilePodServer(messageId, "/fz59/api/files/")}
                  errorMessages={name => null}
                />
              </Col>
            </Row>
          </BlockContainer>
          <BlockContainer label='Файлы ответов на обращение'>
            <>
            {responsible.map((resp, resp_key) =>{
              return (
                   <div key={resp_key}>
                     <Row>
                       <Col sm={4} className={'fw-bold'}>
                         <span className={'ms-3'}>{resp?.unit?.short_fio}</span>
                       </Col>
                       <Col sm={8}>{resp?.unit?.position} ({resp?.unit?.org?.title})</Col>
                     </Row>
                     {resp.answers.map((answer, aresp_key) =>{
                       return (
                           <Row key={aresp_key} className={'py-3'}>
                             <div className={'form-check pb-2 col-4'}>
                               <input className="form-check-input"
                                      type="checkbox"
                                      checked={fieldsResponse.answer?.includes(answer.id)}
                                      required={false}
                                      onChange={(e) => handleCheckBox(e, answer.id)}/>
                               <label className="form-check-label ms-3">
                                 <span className={'fw-bold'}>Файл ответа от {convertDateFormat(answer.created_at)}</span>
                                 <p className={''}>Подписант: {answer.specialist_title}</p>
                               </label>
                             </div>
                             <div className={'col-8'}>
                               {answer.addition_fileinfo ?
                                   <a href={answer.addition_fileinfo.file}>{answer.addition_fileinfo.name}</a> :
                                   <>{answer.signed_fileinfo ?
                                       <a href={answer.signed_fileinfo.file}>{answer.signed_fileinfo.name}</a> : null
                                   }</>
                               }
                             </div>
                           </Row>
                       )
                     })}
                   </div>
              )
            })}

              {isLoadingAnswers ?
                  <div className="text-center">
                    <div className="spinner-border text-primary m-5" role="status"
                         style={{width: '3rem', height: '3rem'}}>
                      <span className="visually-hidden">Загрузка...</span>
                    </div>
                  </div> : answers?.map(answer =>
                      <Row key={answer.id}>
                        <Col sm={1}>
                          <CheckBoxField
                              name={`answer_${answer.id}`}
                              value={fieldsResponse.answer?.includes(answer.id)}
                              label={name => labels[name]}
                              required={false}
                              column={1}
                              onChange={(e) => handleCheckBox(e, answer.id)}
                              errorMessages={name => errorsPatch?.errors?.["answer"] ? [errorsPatch?.errors?.["answer"]] : null}
                          />
                        </Col>
                        <Col sm={11}>
                          <p className={'fw-bold'}>Исполнитель: {answer.unit_full_name} - {answer.unit_position}</p>
                          <p><a href={answer.file}>Файл ответа от {convertDateFormat(answer.created_at)}</a></p>
                    </Col>
                  </Row>
                )
              }
            </>
          </BlockContainer>
          <BlockContainer label='Данные для формирования файла XML'>
            <Row>
              <Col sm={fullColumnSize}>
                <InputField
                  name={'uid_work_correspondence'}
                  // disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  hideLabel={false}
                  column={2}
                  onChange={handleChange}
                  errorMessages={name => errorsPatch?.errors?.[name] ? [errorsPatch?.errors?.[name]] : null}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <InputField
                  name={'uid_response'}
                  // disabled={disabled}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  hideLabel={false}
                  column={2}
                  onChange={handleChange}
                  errorMessages={name => errorsPatch?.errors?.[name] ? [errorsPatch?.errors?.[name]] : null}
                />
              </Col>
            </Row>
            <Row className={'py-2'}>
              <Col md={12}>
                <CodeInput
                  label={labels.fio_signed_response}
                  apiUrl={personSearchUrl}
                  initialValue={currentPerson}
                  searchKeys={personSearchKeys}
                  searchTitles={personSearchTitles}
                  resultFn={handleSetPersonValue}
                  requestParams={personRequestParams}
                  data_mart={true}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={fullColumnSize}>
                <DateField
                  name={'direction_at'}
                  value={name => fieldsResponse[name]}
                  label={name => labels[name]}
                  required={name => modelDefinition[name]?.required}
                  column={2}
                  onChange={handleChangeDate}
                  errorMessages={name => errorsPatch?.errors?.[name] ? [errorsPatch?.errors?.[name]] : null}
                />
              </Col>
            </Row>
          </BlockContainer>
          <BlockContainer label='Пакет файлов ответа в Управление Президента'>
            {
              file &&
              <Row className={'mt-3'}>
                <SimpleFileInput
                  name={'file'}
                  label={name => 'Файл'}
                  hideLabel={true}
                  files={formatFiles(file)}
                  setFiles={setFile}
                  server={FilePodServer(1)}
                  instantUpload={false}
                  allowDownload={true}
                  isConfirmDelete={false}
                  required={false}
                  column={1}
                  errorMessages={name => errorsPatch.errors?.[name]}
                  disabled={true}
                />
              </Row>
            }
            <Row>
              <Col sm={fullColumnSize} className='d-flex justify-content-end'>
                <Button
                  bsStyle="primary"
                  className={'mt-4'}
                  onClick={handlePatch}
                  disabled={isButtonLoading}
                >
                  {isButtonLoading ? 'Загрузка...' : 'Сформировать пакет'}
                </Button>
              </Col>
            </Row>
            <Row>
              {errorsPatch?.errors && (
                <>
                  <Col sm={12} className={'pt-2 pb-2 justify-content-center text-center'}>
                    {Object.entries(errorsPatch.errors).map(([key, error], index, array) => (
                      <HelpBlock className={"alert alert-danger d-flex justify-content-center"}>
                        <b>{labels[key]}:</b> {error}{index < array.length - 1 ? '.' : ''}
                      < /HelpBlock>
                    ))}
                  </Col>
                </>
              )}
            </Row>
          </BlockContainer>
        </div>
      </div>
      {isButtonLoading && (
        <div className="loader-overlay">
          <div className="loader-spinner"></div>
        </div>
      )}
    </>
  )
}
