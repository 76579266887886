import React from 'react';
import {FormControl} from 'react-bootstrap';
import {FieldContainer} from "./FieldContainer";

function getDate(isoString, hasTime) {
  if (!isoString) return '';

  const date = new Date(isoString);
  const year = String(date.getFullYear()).padStart(4, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  if (hasTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return `${year}-${month}-${day}`;
}

export function DateField({name, value, label, onChange, column, row, required, hideLabel, errorMessages, disabled, hasTime = false}) {
  const real_value = () =>{
    if(value){
      if (typeof value === 'function') {
        return getDate(value(name), hasTime)
      }
      return getDate(value, hasTime)
    }

    return ''
  }
  const handleClear = () => {
    onChange({target: {name, value: null}});
  };

  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages}}>
      <FormControl
        type={hasTime ? "datetime-local" : "date"}
        name={name}
        value={real_value()}
        placeholder={"Введите..."}
        onChange={onChange}
        disabled={disabled}
      />
      {real_value() && !disabled && <div className='text-end date-clear-button'>
        <div onClick={handleClear}>
          &times;
        </div>
      </div>}

    </FieldContainer>
  )
}
