import React, {useState, useEffect,} from 'react';
import uniFetch from "../../utils/uniFetch"
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import {InputField} from "../../components/Fields/InputField";
import {CKEditorField} from "../../components/Fields/CKEditorField";
import {SelectField} from "../../components/Fields/SelectField";
import {AsyncSelectField} from "../../components/Fields/AsyncSelectField";
import {CheckBoxField} from "../../components/Fields/CheckBoxField";
import {filteredFields, formatDateToSelectOptions, getSelectOptionFromSlug} from "../../utils/common";
import {ConfirmModal, LoaderModal, ResponseModal, FieldModal} from "../../components/Modal";
import {ResponseAlert} from "../../components/ResponseAlert";
import {SvgIcon, svgType} from "../../components/Icons";


const fullColumnSize = 12;

const labels = {
  approved: 'Данные по организации актуализированы',
  notapproved: 'Данные по организации не актуализированы',
  parent: 'Родительская организация',
  title: 'Наименование',
  medo_uuid: 'МЭДО идентификатор',
  can_registrate: 'Регистрирующая организация',
  is_public: 'Публичная организация',
  registration_format: 'Формат регистрационного номера',
  code: 'Код',
  postal_address: 'Адрес',
  postal_code: 'Индекс',
  resolution_title: 'Заголовок для резолюций',
  income_type: 'Тип организации',
  sstu_number: 'ID органа на ССТУ.РФ',
  phone_number: 'Номер телефона',
  email: 'Электронная почта'
}

function FZSSTUSearchModal(props) {
  const {title, sstu_number, onClose, onConfirm} = props;
  const selectedCls = "text-success-emphasis bg-success-subtle py-2";
  const [dataList, setDataList] = useState([]);
  const [errorsFields, setErrorsFields] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [unit_title, setUnitTitle] = useState(title);
  const [selected, setSelected] = useState(null);

  const totalItems = dataList.length;
  const search_url = "/fz59/sstu_api_search";
  const min_len = 5;

  const fetchData = () => {
    if (unit_title.length >= min_len){
      setIsLoading(true);
      let url = `${search_url}/?search=${unit_title}`
       uniFetch(url)
      .then(data => {
        setDataList(data.objects);
        setErrorsFields({});
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setErrorsFields(error)
      })
      .finally(() => {
        setIsLoading(false);
        setSelected(null);
      });
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleSelect = (val) => {
    if(!isLoading){
      setSelected(val);
    }
  };

  const handleModelClose = () => {
    setSelected(null);
    setShowConfirm(false);
    onClose();
  };

  const handleConfirm = () =>{
    if(!isLoading&&selected){
      if(sstu_number&&sstu_number.length>1){
        setShowConfirm(true);
      }else{
        setShowConfirm(false);
        onConfirm(Object.assign({}, selected));
      }
    }
  }

  const disabled = () =>{
    if (isLoading) return true;
    if (unit_title.length < min_len) return true;
    return false;
  }

  return (
       <FieldModal isOpen={true} onClose={handleModelClose} title={'Поиск организации на ССТУ.РФ'}>

        {!showConfirm?
        <>
          <div className={'row'}>
          <div className={'col-10'}>
            <InputField name={'search'}
                        onChange={e => {setUnitTitle(e.target.value)}}
                        isClearable={false}
                        label={() => 'Организация'}
                        value={() => unit_title}
                        disabled={isLoading}
                        placeholder={`наименование организации не менее ${min_len} символов...`}/>
          </div>
          <div className={'col-2'}>
            <button className={disabled() ? 'btn btn-outline-primary w-100 disabled':'btn btn-outline-primary w-100'}
                    onClick={handleSearch}>Поиск
            </button>
          </div>
        </div>
          <div className="card margin my-3 height-50em">
          <div className={'card-header py-3'}>
            <Row className={'fw-bold'}>
              <Col sm={4}>Код</Col>
              <Col sm={8}>Наименование организации на ССТУ.РФ</Col>
            </Row>
          </div>
          <div className="card-body select-area">
            {!isLoading && dataList?.map(item =>
              <Row key={item.id}
                   className={selected?.id===item.id ? selectedCls : 'py-2 can-select'}
                   onClick={() => handleSelect(item)}>
                <Col sm={4}>{item?.id}</Col>
                <Col sm={8}>{item?.name}</Col>
              </Row>
            )}
            {isLoading ?
              <div className="text-center">
                <div className="spinner-border text-primary m-5" role="status"
                     style={{width: '3rem', height: '3rem'}}>
                  <span className="visually-hidden">Загрузка...</span>
                </div>
              </div>
              : null}
            {errorsFields?.code ?
              <Row>
                <Col><h4 className={'text-center text-danger'}>{errorsFields?.text} {errorsFields?.code}</h4></Col>
              </Row> : null}
          </div>
          <div className={'card-footer'}>
            <Row>
              <Col sm={6}>
                Всего: {totalItems}
              </Col>
              <Col sm={6} className={'text-end'}>
                <button className={(isLoading||!selected) ? 'btn btn-success me-3 disabled': 'btn btn-success me-3' } onClick={handleConfirm}>Установить номер ССТУ</button>
                <button className={'btn btn-danger'} onClick={handleModelClose}>Закрыть</button>
              </Col>
            </Row>
          </div>
        </div>
        </>
        :<>
              <div className={'row'}>
                <h4 className={'text-center text-danger'}>Вы действительно хотите изменить номер органа в ССТУ.РФ?</h4>
                <h6>Организация: {selected?.name}</h6>
                <h5>Текущий номер: {sstu_number}</h5>
                <h5>Новый номер: {selected?.id}</h5>
              </div>
              <div className={'card-footer py-4'}>
                <Row>
                  <Col sm={8}>
                  </Col>
                  <Col sm={4} className={'text-end'}>
                    <button
                        className={(isLoading || !selected) ? 'btn btn-success me-3 disabled' : 'btn btn-success me-3'}
                        onClick={()=>onConfirm(Object.assign({}, selected))}>Подтвердить
                    </button>
                    <button className={'btn btn-danger'} onClick={handleModelClose}>Отмена</button>
                  </Col>
                </Row>
              </div></>
        }
       </FieldModal>
  );
}


function FZOrganizationDetail(props) {
  const [fields, setFields] = useState({})
  const [checkBoxValue, setCheckBoxValue] = useState(false)
  const [responseStatus, setResponseStatus] = useState(0)
  const [errorFields, setErrorFields] = useState({});
  const [modelDefinition, setModelDefinition] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [showSSTUModal, setShowSSTUModal] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const organizationId = props.organizationId
  const orgunit = props?.orgunit

  const is_same_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === Number(organizationId));
  }
  const is_parent_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === Number(organizationId));
  }

  const canEdit = () => {
    if (orgunit?.permissions?.can_root) return true;
    if (!organizationId && orgunit?.permissions?.can_all) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org()) return true;
    if (fields?.parent && orgunit?.permissions?.can_all && orgunit?.all_orgs?.some(obj => obj === fields?.parent)) return true;

    return false
  };

  const canEditValue = canEdit();

  useEffect(() => {
    setIsLoadingDetail(true)
    if (!organizationId) {
      uniFetch('/edw/api/data-marts/fzorganisation-dm/entities/').then(data => {
        setModelDefinition(data.results.meta?.extra?.model_definition);
        setFields(prevFields => ({
          ...prevFields,
          registration_format: "{{code}}-{{date|date:'y'}}-{{number}}{% if count %}/{{count}}{% endif %}{% if verbal %}-У{% endif %}"
        }));
      })
        .catch(data => {
          console.error(data)
          if (data?.code && data?.errors) {
            setResponseStatus(data?.code)
            setErrorFields(data?.errors);
          } else {
            setResponseStatus(500)
            setErrorFields({error: 'Ошибка сервера'});
          }
        })
        .finally(()=>setIsLoadingDetail(false));
      return
    }
    uniFetch(`/edw/api/entities/${organizationId}/`,'GET', '', false, true, true).then(data => {
      const extractedFields = Object.keys(labels).reduce((acc, field) => {
        if (data.hasOwnProperty(field)) {
          acc[field] = data[field];
        }
        return acc;
      }, {});

      setFields(prevFields => ({...prevFields, ...extractedFields}));
      setModelDefinition(data['model_definition']);
    }).catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(()=>setIsLoadingDetail(false));
  }, []);

  const handleChangeDescription = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeSelect = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const {name, value} = e.target;

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handlePatch = () => {
    let REQURL;
    let METHOD;
    if (organizationId) {
      REQURL = `/edw/api/entities/${organizationId}/`
      METHOD = 'PATCH'
    } else {
      REQURL = `/edw/api/data-marts/fzorganisation-dm/entities/`
      METHOD = 'POST'
    }
    const fieldsCopy = {...fields};
    Object.keys(modelDefinition).forEach((key) => {
      if (modelDefinition[key]?.read_only || modelDefinition[key]?.field_type === 'related_model' || key === 'files') {
        delete fieldsCopy[key]
      }
    })
    setIsLoading(true)
    uniFetch(REQURL, METHOD, fieldsCopy, false, true, true).then(data => {
      if (METHOD === 'POST') {
        const id = data.id
        window.location.href = `/fz59/organization_detail/${id}/`
      } else {
        setFields(data)
        setResponseStatus(200)
      }
    }).catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => {
      setIsLoading(false)
    });
  };

  const handleCancel = () => {
    window.location.reload()
  };

  const handleCheckBoxOrg = (e) => {
    setCheckBoxValue(e.target.checked)
    const name = e.target.name
    const value = e.target.checked

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  }

  const fetchDatamart = (value, slug) => {
    const param = value ? `&title=${value}` : '';
    return uniFetch(`/edw/api/data-marts/${slug}/entities/?format=json` + param).then(data => {
      const options = data.results.objects.map(item => ({
        value: item.id,
        label: item.entity_name
      }));
      return options;
    });
  };

  const loadOptions = (inputValue, callback, slug) => {
    fetchDatamart(inputValue, slug).then(options => callback(options));
  };

  const handleCloseResponseModal = () =>{
    setResponseStatus(0)
  }

  const handleSelectSSTUNumber = (item) => {
    setShowSSTUModal(false);
    if (item?.id){
      setFields(prevFields => (Object.assign({},prevFields, {sstu_number: item.id})));
    }


  }
  const handleCloseSSTUModal = () => {
    setShowSSTUModal(false);
  }
  const handleShowSSTUModal = () => {
    setShowSSTUModal(true);
  }

  if (!Object.keys(fields).length && [403, 404, 500].includes(responseStatus)) return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <div className="text-center">
        <h2 className={'text-danger'}>{errorFields?.detail ?? errorFields?.error}</h2>
      </div>
    </div>
  )
  return (
    <div className="form-container">
      {isLoadingDetail ? <LoaderModal/> : null}
      <ResponseModal onClose={handleCloseResponseModal}
                     status={(!Object.keys(fields).length && responseStatus !== 403) ? responseStatus : null}>
        <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
      </ResponseModal>
      <div className="block-container mb-5">
        <label className="block-label">Основные данные</label>
          {showSSTUModal&&<FZSSTUSearchModal
              title={fields?.title}
              sstu_number={fields?.sstu_number}
              onClose={handleCloseSSTUModal}
              onConfirm={handleSelectSSTUNumber}
          />}
         <Row>
          <Col sm={12}>
            <CheckBoxField
              name={'approved'}
              value={name => fields[name]}
              label={name =><>
                    <span className={fields[name] ? 'me-2 text-success': 'me-2 text-danger'}>
                      <SvgIcon type={svgType.approved} size={{width: 16, height: 16}}/>
                    </span>
                {fields[name] ?
                    <span className={'text-success'}>{labels[name]}</span> :
                    <span className={'text-danger'}>{labels['notapproved']}</span>
                }
                :
              </>}
              required={false}
              column={3}
              onChange={handleCheckBoxOrg}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
         </Row>
        <AsyncSelectField
          name={'parent'}
          value={name => fields[name]}
          label={name => labels[name]}
          required={false}
          column={1}
          onChange={handleChangeSelect}
          loadOptions={(value, callback) => loadOptions(value, callback, 'fzorganisation-dm')}
          currentValue={name => modelDefinition[name]?.current_value}
          errorMessages={name => errorFields?.[name]}
          placeholder="Выберите..."
          disabled={!canEditValue}
        />
        <SelectField
          name={'income_type'}
          value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
          label={name => labels[name]}
          required={true}
          column={1}
          options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
          onChange={handleChangeSelect}
          errorMessages={name => errorFields?.[name]}
          placeholder="Выберите..."
          disabled={!canEditValue}
        />
        <InputField
          name={'title'}
          value={name => fields[name]}
          label={name => labels[name]}
          required={true}
          column={1}
          onChange={handleChange}
          errorMessages={name => errorFields?.[name]}
          disabled={!canEditValue}
        />
        <InputField
          name={'medo_uuid'}
          value={name => fields[name]}
          label={name => labels[name]}
          required={false}
          column={1}
          onChange={handleChange}
          errorMessages={name => errorFields?.[name]}
          disabled={!canEditValue}
        />
        <Row>
          <Col sm={4}>
            <InputField
              name={'code'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={3}
              onChange={handleChange}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
          <Col sm={4}>
            <CheckBoxField
              name={'can_registrate'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={3}
              onChange={handleCheckBoxOrg}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
          <Col sm={4}>
            <CheckBoxField
              name={'is_public'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={3}
              onChange={handleCheckBoxOrg}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
        </Row>

        {fields.can_registrate ?
          <InputField
            name={'registration_format'}
            value={name => fields[name]}
            label={name => labels[name]}
            required={true}
            column={1}
            onChange={handleChange}
            errorMessages={name => errorFields?.[name]}
            disabled={!canEditValue}
          />
          : null
        }
        <Row>
          <Col sm={10}>
          <InputField
            name={'sstu_number'}
            value={name => fields[name]}
            label={name => labels[name]}
            required={false}
            column={1}
            onChange={handleChange}
            errorMessages={name => errorFields?.[name]}
            disabled={!canEditValue}
          />
          </Col>
          <Col sm={2}>
            <div className={'btn btn-outline-primary w-100'} onClick={handleShowSSTUModal}>
              Поиск
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputField
              name={'phone_number'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={2}
              onChange={handleChange}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
          <Col sm={6}>
            <InputField
              name={'email'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={2}
              onChange={handleChange}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
        </Row>
        <Row>
          <label className={'my-4'}><b>Обратный адрес для конвертов</b></label>
          <Col sm={4}>
            <InputField
              name={'postal_code'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={3}
              onChange={handleChange}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
          <Col sm={8}>
            <InputField
              name={'postal_address'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={false}
              column={1}
              onChange={handleChange}
              errorMessages={name => errorFields?.[name]}
              disabled={!canEditValue}
            />
          </Col>
        </Row>
        <CKEditorField
          name={'resolution_title'}
          value={name => fields[name]}
          label={name => labels[name]}
          required={false}
          hideLabel={false}
          column={1}
          wrappable={true}
          onChange={handleChangeDescription}
          errorMessages={name => errorFields?.[name]}
          disabled={!canEditValue}
        />
        {!!responseStatus && <Row>
          <Col sm={fullColumnSize}>
            <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
              <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
            </ResponseModal>
          </Col>
        </Row>}
        <Row>
          {
            canEditValue &&
            <div className="d-flex justify-content-end">
              <Button onClick={handleCancel} className={'mx-2 my-3'} bsStyle="danger" disabled={isLoading}>
                Отменить
              </Button>
              <Button onClick={handlePatch} className={'mx-2 my-3'} bsStyle="success" disabled={isLoading}>
                Сохранить
              </Button>
            </div>
          }
        </Row>
      </div>
    </div>
  )

}


export default FZOrganizationDetail
