import React, {useState} from 'react';
import {BlockContainer} from "../../components/Fields/BlockContainer";
import {HTMLConfirmModal} from "../../components/Modal";


const formatDate = (date_str) =>{
      let res = '';
      if(date_str){
        let dd = new Date(date_str),
            day = dd.getDate() > 9 ? dd.getDate().toString(10) : '0' + dd.getDate().toString(10),
            mn = dd.getMonth() + 1
            mn =  mn > 9 ? mn.toString(10) : '0' + mn.toString(10);
          res = '' + day + '.' + mn + '.' + dd.getFullYear().toString(10);
      }

      return res
}

function ReadField({name, title, value}) {
  const realValue = typeof value === 'function' ? value(name) : value;
  const realTitle = typeof title === 'function' ? title(name) : title;

  return (
      <div className="row py-2">
          <label className="col-md-4 col-12 fw-bold text-end">{realTitle}</label>
          <div className="col-md-8 col-12"><span dangerouslySetInnerHTML={{__html: realValue}}></span>
          </div>
      </div>
    )
}

export function POSTab({messageId, fields, labels, orgunit, responsible, respGroups, apiCommand, canEdit = true}) {
    const main_aplicant = fields['applicants'].length > 0 ? fields['applicants'][0] : null;
    const [selectedAnswers, setSelectedAnswers] = useState(new Map());
    const [showSendConfirm, setShowSendConfirm] = useState(false);

    const getApplicantAddress = ()=> {
        if (main_aplicant){
            let res = []
            if (main_aplicant.postal_code) res.push(main_aplicant.postal_code);
            if (main_aplicant.postal_country) res.push(main_aplicant.postal_country);
            if (main_aplicant.postal_region) res.push(main_aplicant.postal_region);
            if (main_aplicant.postal_district) res.push(main_aplicant.postal_district);
            if (main_aplicant.postal_city) res.push(main_aplicant.postal_city);
            if (main_aplicant.postal_street) res.push(main_aplicant.postal_street);
            if (main_aplicant.postal_house) res.push('д. ' + main_aplicant.postal_house);
            if (main_aplicant.postal_corpus) res.push('к. ' + main_aplicant.postal_corpus);
            if (main_aplicant.postal_home) res.push('кв. ' + main_aplicant.postal_home);
            return res.join(', ')
        }
        return ''
    }

    const getApplicantFio = () => {
        if (main_aplicant){
            let res = []
            if (main_aplicant.last_name) res.push(main_aplicant.last_name);
            if (main_aplicant.first_name) res.push(main_aplicant.first_name);
            if (main_aplicant.middle_name) res.push(main_aplicant.middle_name);
            return res.join(' ')
        }
        return ''
    }

    const handleSetAnswer = (person, answer_data) => {
        let selected = !answer_data.selected;
        let new_selected = new Map();
        if(!selected){
            new_selected.delete(answer_data.answer.id)
        }else{
            let val = {
                pos_responible_title: person?.unit?.short_fio,
                pos_org_title: person?.unit?.org?.title,
                action_date: answer_data.action_date,
                action_number: answer_data.action_number,
                action_answer: answer_data.answer?.id,
                selected: true,
                disabled: false
            }
            new_selected.set(answer_data.answer.id, val)
        }
        setSelectedAnswers(new_selected)
    }

    const handleShowConfirm = () =>{
        setShowSendConfirm(true)
    }

    const sendToPOS = () => {
        let data_for_send = {};
        selectedAnswers.forEach((value, key, map) => {
            data_for_send = value;
        })
        apiCommand("command_send_pos", data_for_send);
        setShowSendConfirm(false);
    }

    const renderSendConfirm = () =>{
      if (!showSendConfirm) return null

        let valid = true;
        let err_message = '';

        if (selectedAnswers.size === 0){
            valid = false;
            err_message = 'Не выбран ответ для выгрузки'
        }

        let send_info = []
        selectedAnswers.forEach((value, key, map) =>{
            send_info.push(Object.assign({key: key}, value))
        })

      return (
          <HTMLConfirmModal label={'Выгрузить ответ в ПОС?'}
                          handleConfirm={()=> sendToPOS()}
                          handleCancel={()=> setShowSendConfirm(false)}
                          validateConfirm={valid}
                          >
              {valid ?
                  <>
                      <h4>Вы действительно хотите отправить данный ответ в ПОС?</h4>
                      {send_info.map((item, key)=> {
                          return(
                              <div key={key} className={'row py-2'}>
                                  <div className={'col-4 fw-bold'}>Ответ № {item?.action_number} {item?.action_date}</div>
                                  <div className={'col-8'}>{item?.pos_responible_title} ({item?.pos_org_title})</div>
                              </div>
                          )
                      })}
                  </>
                  :
                  <h4 className={'text-danger'}>{err_message}</h4>
              }
        </HTMLConfirmModal>

      )
    }

    const getAnswersWithFiles = (answers) => {
        if (answers&&answers.length>0){
            return answers.filter((item)=>{
                return (item.addition_fileinfo || item.signed_fileinfo) ? true : false
            })
        }
        return []
    }

    const getAnswersForPerson = (person, group) =>{
        let person_answers = []
        if (fields.reg_number&&getAnswersWithFiles(person.answers).length>0){
            getAnswersWithFiles(person.answers).forEach((answer) => {
                let action_date = answer.date_receipt
                let action_number = answer.income_number ? answer.income_number : answer.sed_number
                let p_answ = {
                        action_date: action_date,
                        action_number: action_number,
                        file_info: null,
                        signed: false,
                        answer: answer,
                        selected: false,
                        disabled: false
                    }
                let pos_history =  answer.data !== null ? answer.data['pos_history'] : null
                if (pos_history) {
                    p_answ.pos_sended_date = pos_history.queued_date ? formatDate(pos_history.queued_date) : ''
                    p_answ.pos_sended_status = pos_history.status ? pos_history.status : 'success'
                    p_answ.pos_sended_description = pos_history.description ? pos_history.description : ''
                    p_answ.disabled = p_answ.pos_sended_status === 'success'
                    p_answ.selected = p_answ.disabled
                }
                let has_in_selected = selectedAnswers.get(answer.id)
                if (has_in_selected) {
                    p_answ.selected = has_in_selected.selected
                    p_answ.disabled = has_in_selected.disabled
                }
                if (answer.addition_fileinfo){
                    p_answ.file_info = answer.addition_fileinfo
                }else if (answer.signed_fileinfo){
                    p_answ.file_info = answer.signed_fileinfo
                    p_answ.signed = true
                }
                person_answers.push(p_answ)
            })
        }

        return person_answers
    }

    const getActionsForGroups = () =>{
        let res_groups = []
        Object.entries(respGroups).forEach(([group_key, group]) => {

            if (group?.responsible.length > 0){
                let res_group = {
                    key: group_key,
                    group: group.group,
                    local: group.local,
                    title: group.title,
                    responsibles: []
                }
                group.responsible.forEach((item)=>{
                   let answers = getAnswersForPerson(item, group)
                   res_group.responsibles.push({
                       person: Object.assign({}, item),
                       answers: [...answers]
                   })
                })
                res_groups.push(res_group)
            }
        })
        return res_groups
    }
    const res_groups = getActionsForGroups()

    return (
        <>
            {renderSendConfirm()}
            <div className="form-container">
                <div className="blocks-container">
                    <BlockContainer label='Данные по обращению'>
                        <ReadField name={'post_at'}
                                   title={name => labels[name]}
                                   value={name => formatDate(fields[name])}/>
                        <ReadField name={'income_at'}
                                   title={name => labels[name]}
                                   value={name => formatDate(fields[name])}/>
                        <ReadField name={'income_number'}
                                   title={name => labels[name]}
                                   value={name => fields[name]}/>
                        <ReadField name={'reg_at'}
                                   title={name => labels[name]}
                                   value={name => formatDate(fields[name])}/>
                        <ReadField name={'reg_number'}
                                   title={name => labels[name]}
                                   value={name => fields[name]}/>
                        <ReadField name={'main_aplicant'}
                                   title={'Заявитель'}
                                   value={getApplicantFio()}/>
                        <ReadField name={'main_aplicant_address'}
                                   title={'Адрес заявителя'}
                                   value={getApplicantAddress()}/>
                        <ReadField name={'annotation'}
                                   title={name => labels[name]}
                                   value={name => fields[name]}/>
                        <div className="row py-2">
                            <div className="col-md-4 col-12 text-end">
                              <label className="col-md-4 col-12 fw-bold text-end">Описание</label>
                            </div>
                            <div className={'col-md-8'}>
                                <div className={'html-read'} dangerouslySetInnerHTML={{ __html: fields['description'] }}/>
                            </div>
                        </div>
                    </BlockContainer>
                    {res_groups.map((group_item, group_key) =>{
                        if (group_item?.responsibles.length > 0){
                            return (
                            <BlockContainer key={group_key} label={group_item.title}>
                                {group_item?.responsibles.map((resp_item, resp_key) =>{
                                return (
                                    <div key={resp_key} className={'nav-tabs'}>
                                        <div className="row py-2">
                                            <label
                                                className="col-md-4 col-12 fw-bold text-end">{resp_item?.person?.unit?.short_fio}</label>
                                            <div className="col-md-8 col-12">
                                                {resp_item?.person?.unit?.position}
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <label className="col-md-4 col-12 fw-bold text-end">
                                                {resp_item?.person?.unit?.org?.title}</label>
                                            <div className="col-md-8 col-12">
                                                {resp_item?.person?.unit?.org?.pos_cabinet_number ? resp_item.person.unit.org.pos_cabinet_number :
                                                    <span className={'text-danger'}>В карточке организации необходимо заполнить поле "Номер кабинета ПОС"</span>}
                                            </div>
                                        </div>
                                        {resp_item?.answers?.map((answer_data, answer_key) => {
                                            return (
                                                <div key={answer_key}>
                                                    <hr/>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={'fw-bold'}>Ответ № {answer_data.action_number} ({answer_data.action_date})</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            <input className="form-check-input"
                                                               type="checkbox"
                                                               checked={answer_data.selected}
                                                               disabled={answer_data.disabled}
                                                               onChange={(e) => handleSetAnswer(resp_item.person, answer_data)}
                                                            />
                                                            &nbsp;{answer_data.pos_sended_date && answer_data.pos_sended_status === 'success' ? <span className={'text-success'}>выгружен  {answer_data.pos_sended_date}</span> : <span className={'text-danger'}>{answer_data.pos_sended_date} {answer_data.pos_sended_description} </span>}
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Текст</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            <div className={'html-read'} dangerouslySetInnerHTML={{ __html: answer_data.answer?.description }}/>
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Приложенный файл</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            <a href={answer_data.file_info?.file}
                                                               target={'_blank'}> {answer_data.file_info?.name}</a>
                                                        </div>
                                                    </div>
                                                    <div className="row py-2">
                                                        <div className="col-md-4 col-12 text-end">
                                                            <span className={''}>Подписант</span>
                                                        </div>
                                                        <div className={'col-md-8'}>
                                                            {answer_data.answer?.specialist_title}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        )}
                                    </div>
                                )
                                })}
                            </BlockContainer>
                            )
                        }
                    })}
                </div>
            </div>
            <div className={'d-flex justify-content-end'}>
                <button className={'btn btn-primary'} disabled={fields?.status_pos==="not_synchronized"} onClick={handleShowConfirm}>Выгрузить данные</button>
            </div>
        </>)
}