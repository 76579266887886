import React, {useState, useEffect} from 'react';
import {SelectField} from "../../../components/Fields/SelectField";
import {
  convertDateFormat,
  formatDateToSelectOptions,
  getCurrentDate,
  getSelectOptionFromSlug
} from "../../../utils/common";
import {InputField} from "../../../components/Fields/InputField";
import {DateField} from "../../../components/Fields/DateField";
import {BlockContainer} from "../../../components/Fields/BlockContainer";
import uniFetch from "../../../utils/uniFetch";
import {attachedFile} from "../../../utils/filepond";
import {CKEditorField} from "../../../components/Fields/CKEditorField";

import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
} from 'react-bootstrap';
import {ResponseAlert} from "../../../components/ResponseAlert";
import {ButtonGroupField} from "../../../components/Fields/ButtonGroupField";
import {SimpleFileInput} from "../../../components/Fields/SimpleFileField";
import {ConfirmModal, HTMLConfirmModal, ResponseModal} from "../../../components/Modal";
import {AsyncSelectField} from "../../../components/Fields/AsyncSelectField";
import {DEBOUNCE_TIMEOUT} from "../../../constants";
import {getCert, signFile} from "../../../utils/crypto";
import {CodeInput} from "../responsible-group-tab";
import {SvgIcon, SvgIconCls, svgType} from "../../../components/Icons";

const fullColumnSize = 12;
const halfColumnSize = fullColumnSize / 2;

const lableAnswer = {
  id: "",
  analysis_result: 'Результаты анализа',
  way_result: 'Способ достижения результата',
  measures_influence: 'Меры воздействия',
  typical_reasons: 'Типичные причины',
  evaluation_registrar_response: 'Оценка ответа регистратором',
  evaluation_applicant_response: 'Оценка ответа заявителем',
  date_receipt: 'Дата поступления',
  sed_number: 'Номер в СЭД',
  income_number: 'Исходящий номер организации источника',
  description: 'Ответ',
}

const checkNullFields = [
    'analysis_result', 'way_result', 'measures_influence', 'typical_reasons',
    'evaluation_registrar_response', 'evaluation_applicant_response', 'sed_number'
]

const labels = {
  fio: 'ФИО',
  postal_address: 'Почтовый адрес',
  reg_number: 'Регистрационный номер',
  reg_at: 'Дата регистрации',
  date_receipt: 'Дата ответа',
  income_number: 'Исходящий номер',
  income_at: 'Дата поступления организации источника',
  sed_number: 'Номер в СЭД',
  annotation: 'Аннотация',
  frequency_type: 'Частота обращения',
  income_type: 'Тип автора',
  appeal_loan_type: 'Тип рассмотрения обращения',
  applicant_type: 'Тип заявителя',
  income_source_type: 'Тип обращения по источнику',
  destination_type: 'Адресат по типу',
  region_type: 'Регион',
  appeal_cont_type: 'Тип обращения',
  appeal_chars_type: 'Характеристика',
  expired_at: 'Дата истечения',
  closed_at: 'Дата закрытия',
  state: 'Статус',
  reg_organisation: 'Организация регистрации',
  registrator: 'Регистратор',
  income_organisation: 'Организация источник',
  description: 'Ответ',
  file: 'Файл',
  file_info: 'Информация о файле',
  position_signed_response: 'Должность подписавшего ответ',
  fio_signed_response: 'Ф.И.О. подписавшего ответ',
  analysis_result: 'Результаты анализа',
  way_result: 'Способ достижения результата',
  measures_influence: 'Меры воздействия',
  typical_reasons: 'Типичные причины',
  evaluation_registrar_response: 'Оценка ответа регистратор',
  evaluation_applicant_response: 'Оценка ответа заявитель',
};

function filterFields(fields, lableAnswer) {
  const labledAnswerKeys = Object.keys(lableAnswer);
  for (const key in fields) {
    if (!labledAnswerKeys.includes(key)) {
      delete fields[key];
    }
  }
  return fields;
}


const formatFile = (source, name, size, type) => {
    return [
      {
        source,
        options: {
          type: 'local',
          file: {
            name,
            size,
            type,
          },
        },
      },
    ]

  }

const deleteButton = (callback, disabled = false) => {
  return (
    <button type="button" className="btn btn-outline-danger"
            disabled={disabled}
            title={'Удаление'}
            onClick={e => {
              e.stopPropagation()
              callback()
            }}>
      <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
    </button>)
}
export default function ApplicantAnswers(props) {
  const {
    messageId,
    responsible,
    orgunit,
    entity_name,
    updateCommand,
    modelDefinition,
    reg_organisation
  } = props;

  const answers = responsible?.answers;

  const first_tab = answers?.length > 0 ? 'answer_tab_' + answers[0].id: ''
  const [activeTab, setActiveTab] = useState(first_tab);

  const handleActiveTab = (active) => {
    setActiveTab(active)
  }
  const getAnswerTitle = (answer) => {
    let base_name = convertDateFormat(answer.date_receipt) + ' / ' + answer.income_number
    if (answer.send) {
      base_name += ' (Отправлено)'
    }

    if (answer?.wait_sign === 'ASKED'){
      return (
          <>
          <SvgIconCls type={svgType.askSign} size={{width: 24, height: 24}} customCls={'text-danger me-3'}/>
          <span className={'text-danger'}>{base_name} (ожидает подписания)</span>
      </>
      )
    }
    if (answer?.wait_sign === 'SIGNED'){
      return (
          <>
          <SvgIconCls type={svgType.wasSigned} size={{width: 24, height: 24}} customCls={'me-3'}/>
          <span>{base_name} (подписан)</span>
      </>
      )
    }
    return base_name

  }
  //setActiveTab('answer_tab_'+data.results[0]?.id)
  //useEffect(() => {
  //}, []);


  return (<>
    {((answers?.length > 0 && modelDefinition?.['answers']) || (activeTab === 'answer_tab_new')) ?
      <Tabs id="fz-message-detail-applicant-answers-tabs"
            onSelect={handleActiveTab}
            activeKey={activeTab}>
        {answers?.map(answer => <Tab
          key={'resp_' + answer.id}
          title={getAnswerTitle(answer)}

          eventKey={"answer_tab_" + answer.id}
          className={activeTab === 'answer_tab_' + answer.id ? 'show active' : ''}
          mountOnEnter unmountOnExit>
          {activeTab === 'answer_tab_' + answer.id ?
            <ApplicantAnswerForm
              responsible={responsible}
              orgunit={orgunit}
              reg_organisation={reg_organisation}
              answer={answer}
              entity_name={entity_name}
              updateCommand={updateCommand}
              modelDefinition={modelDefinition?.['answers']}
              messageId={messageId}
              setActiveTab={setActiveTab}
            /> :
            null}
        </Tab>)}
        <Tab title={'Новый'}
             eventKey={"answer_tab_new"}
             className={activeTab === 'answer_tab_new' ? 'show active' : ''}>
          {activeTab === 'answer_tab_new' ?
          <ApplicantAnswerForm
            responsible={responsible}
            orgunit={orgunit}
            reg_organisation={reg_organisation}
            answer={{}}
            entity_name={entity_name}
            updateCommand={updateCommand}
            modelDefinition={modelDefinition['answers']}
            messageId={messageId}
            setActiveTab={setActiveTab}
          />: null}
        </Tab>
      </Tabs>
      :
      <Row>
        <button className={'btn btn-outline-success'}
                onClick={(e) => {
                  handleActiveTab("answer_tab_new")
                }}>
          Добавить ответ
        </button>
      </Row>
    }
  </>)

}

function ApplicantAnswerForm(props) {
  const default_date = getCurrentDate();
  const {responsible, updateCommand, entity_name, answer, modelDefinition, orgunit, reg_organisation, messageId, setActiveTab} = props;
  const wait_sign = answer?.wait_sign;
  const isCreate = Object.keys(answer).length === 0

  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [errorFields, setErrorFields] = useState({});
  const [fields, setFields] = useState({})
  //useState(answer?.date_receipt ? answer : Object.assign({}, answer, {date_receipt: default_date}))
  const [file, setFile] = useState({})
  const [fileChanged, setFileChanged] = useState(false);
  //useState(formatFile(answer?.addition_fileinfo, answer?.addition_fileinfo?.name, answer?.addition_fileinfo?.size, answer?.addition_fileinfo?.type))
  const [responseStatus, setResponseStatus] = useState(0);
  const [showConfirm, setShowConfirm] = useState(null)
  const [selectedCert, setSelectedCert] = useState('')
  const [showSignModal, setShowSignModal] = useState(false)
  const [certs, setCerts] = useState([])
  const [isCryptoPlugin, setIsCryptoPlugin] = useState(null)
  const [currentBlank, setCurrentBlank] = useState({code: '', title: ''});
  const [currentTemplate, setCurrentTemplate] = useState({code: '', title: ''});
  const [currentPerson, setCurrentPerson] = useState({code: '', title: ''});
  const [deleteAnswerModal, setDeleteAnswerModal] = useState(false)
  const [isAskModal, setisAskModal] = useState(false);

  useEffect(() => {
      setFields(answer?.date_receipt ? answer : Object.assign({}, answer, {date_receipt: default_date}))
      setFile(answer?.addition_fileinfo);
      //formatFile(answer?.addition_fileinfo, answer?.addition_fileinfo?.name, answer?.addition_fileinfo?.size, answer?.addition_fileinfo?.type))
      setCurrentBlank({
        code: answer?.blank_code || '',
        title: answer?.blank_title || ''
      })
      setCurrentTemplate({
        code: answer?.template_code || '',
        title: answer?.template_title || ''
      })
      setCurrentPerson({
        code: answer?.specialist_code || '',
        title: answer?.specialist_title || ''
      })

  },[answer])

  const handleCreateFiles = (value) => {
        if (value.length > 0) {
            if ((value[0]?.file?.size !== file?.size)&&(value[0]?.file?.name !== file?.name)){
                setFile(value[0]);
                setFileChanged(true);
            }
        } else if(file) {
            setFile(null);
            setFileChanged(true);
        }
    };

  const blankSearchUrl = "/fz59/api/organizationblank/";
  const templateSearchUrl = "/fz59/api/organizationtemplate/";
  const personSearchUrl = '/edw/api/data-marts/fzorganisationunit-dm/entities/';

  const blankRequestParams = {'org': reg_organisation}
  const templateRequestParams = {'org': reg_organisation}
  const personRequestParams = {'org': reg_organisation, 'is_specialist': true}

  const blankSearchTitles = [
    {title: 'Код', prop: 'code', col: 2},
    {title: 'Наименование', prop: 'title', col: 5},
    {title: 'Файл', prop: 'blank_fileinfo', col: 5}];

  const templateSearchTitles = [
    {title: 'Код', prop: 'code', col: 2},
    {title: 'Наименование', prop: 'title', col: 3},
    {title: 'Текст', prop: 'short_text', col: 7}];
  const personSearchTitles = [
    {title: 'Код', prop: 'code', col: 2},
    {title: 'Наименование', prop: 'short_fio', col: 5},
    {title: 'Должность', prop: 'position', col: 5}];

  const blankSearchKeys = {codeKey: 'code', textKey: 'title', searchKey: 'code_title'};
  const templateSearchKeys = {codeKey: 'code', textKey: 'title', searchKey: 'code_title'};
  const personSearchKeys = {codeKey: 'code', textKey: 'title', searchKey: 'full_name'};

  const isCanSign = () => {
    if (selectedCert
      && currentBlank.code !== '' && currentBlank.title !== ''
      && currentTemplate.code !== '' && currentTemplate.title !== ''
      && currentPerson.code !== '' && currentPerson.title !== '') return true;
    return false;
  }
  const canSign = isCanSign();
  const canAskSign = currentPerson?.title!=='';
  const allDisabled = (wait_sign === 'ASKED' || wait_sign==="SIGNED")
  &&(answer?.specialist_signed!==orgunit?.id)&&(!orgunit?.permissions?.can_root) ? true: false;
  const canAnulate =  (wait_sign==="SIGNED")
  &&(answer?.specialist_signed===orgunit?.id || orgunit?.permissions?.can_root) ? true: false;

  const saveApplicant = (form_data, callBackFn) => {
    setIsLoading(true)
    const send_url = answer.id ? `/fz59/api/${entity_name}/` + answer.id + '/' : `/fz59/api/${entity_name}/`;
    const send_method = answer.id ? 'PATCH' : 'POST';

    uniFetch(send_url, send_method, form_data, false, false, true).then((data) => {
      setErrorFields({});
      setResponseStatus(200)
      setChanged(false);
      if (callBackFn) callBackFn(data);

      //if(!answer?.id){
      //  setFields({});
      //  if(data?.id) setActiveTab('answer_tab_' + data.id);
      //}else {
        //setFields(data)//todo: check;
      //}
      setIsLoading(false);
      setActiveTab('answer_tab_' + data.id);
    }).catch(data => {
      setIsLoading(false);
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
            }
    });
  }

  const canAnswerFn = () => {
    let res = (
      orgunit?.permissions?.can_root ||
      orgunit?.permissions?.can_all ||
      orgunit?.permissions?.can_register ||
      orgunit?.permissions?.can_moderate ||
      orgunit?.permissions?.can_answer
    ) && (!fields?.send)
    //todo check state
    return res;
  };
  const canAnswer = canAnswerFn();

  const canDeleteAnswer = !fields?.send && fields?.id &&
  (orgunit?.permissions?.can_root ||
  (orgunit?.permissions?.can_all && orgunit?.all_orgs[0] === responsible?.unit?.org?.id) ||
  responsible?.unit?.id === orgunit.id)
  const handleChange = (e) => {
    const {name, value} = e.target;

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeDescription = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeSelect = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: Array.isArray(value) ? value : [value],
    }));
  };

  const handleChangeCert = (name, value) => {
    setSelectedCert(value)
  }

  const handleChangeDate = (e) => {
    const {name, value} = e.target;
    const formattedDateTime = value ? `${value}T08:00` : null;
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: formattedDateTime,
    }));
  };

  const handleRadioChange = (name, e) => {
    let {value} = e.target;
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => {
      const currentValue = prevFields[name];

      if (Array.isArray(currentValue)) {
        value = currentValue.includes(value)
          ? currentValue.filter(item => item !== value)
          : [...currentValue, value];
      }

      return {
        ...prevFields,
        [name]: value,
      };
    });
  };

  const updateMessageData = (data) => {
    updateCommand(`command_update_${entity_name}`, Object.assign({}, data));
  }

  const deleteAnswer = () => {
    updateCommand(`command_delete_answer`, {id:fields?.id, responsible:responsible?.id});
  }

  const getFormData =(send) =>{
    const fieldsCopy = {...fields};
    delete fieldsCopy['files']
    Object.keys(modelDefinition).forEach((key) => {
      if (modelDefinition[key]?.read_only || modelDefinition[key]?.field_type === 'related_model' || key === 'files') {
        delete fieldsCopy[key]
      }
    })

    filterFields(fieldsCopy, lableAnswer);

    for (const element of checkNullFields) {
      if (fieldsCopy[element] === null) {
        fieldsCopy[element] = ''
      }
    }

    const formData = new FormData()

    if (fileChanged) {
        if (file?.file instanceof File) {
          formData.append('addition_file', file?.file)
        } else if (!file?.file) {
          formData.append('addition_file_delete', true)
        }
    }

    formData.append('blank_code', currentBlank.code || '');
    formData.append('blank_title', currentBlank.title || '');
    formData.append('template_code', currentTemplate.code || '');
    formData.append('template_title', currentTemplate.title || '');
    formData.append('specialist_code', currentPerson.code || '');
    formData.append('specialist_title', currentPerson.title || '');

    fieldsCopy.responsible = responsible?.id
    fieldsCopy.active = true

    for (const key in fieldsCopy) {
      if (Array.isArray(fieldsCopy[key])) {
        const arrayLength = fieldsCopy[key].length
        if (arrayLength > 0) {
          fieldsCopy[key].forEach(value => {
            formData.append(key, value);
          });
        } else {
          formData.append(key, [])
        }
      } else {
        formData.append(key, fieldsCopy[key]);
      }
    }
    if (send) {
      formData.append('send_command', true);
    }
    return formData
  }

  const handleSave = () => {
    const formData = getFormData(false)
    saveApplicant(formData, updateMessageData)
  };

  const handleSend = () => {
    const formData = getFormData(true)
    saveApplicant(formData, updateMessageData)
  }

  const handleAskSign = (data) =>{
    const ask_data = data ? data: wait_sign === 'ASKED' ? 'CANCEL': 'ASKED';
    let formData = getFormData();
    formData.append('ask_command', ask_data);
    //todo:
    saveApplicant(formData, (data) => {
          updateMessageData(data);
    });
    setisAskModal(false);
  }

  const handleSign = () => {
    const formData = getFormData();
    saveApplicant(formData, (data) => {
      updateMessageData(data);
      handleECP(data.id)
    });
  }

  const handleECP = async (id) => {
    if (!selectedCert) return;

    try {
      const cert = certs?.find(cert => cert.cn === selectedCert);
      const selected_cert = {...cert}
      if (!cert) throw new Error('Certificate not found');

      selected_cert.validTo = convertDateFormat(cert.validTo);
      selected_cert.validFrom = convertDateFormat(cert.validFrom);

      const data = await uniFetch(`/fz59/api/${entity_name}/${id}/make_preview/`, 'POST', selected_cert, false, true, true);
      const url = data?.signed_fileinfo?.file;
      const filename = data?.signed_fileinfo?.name;

      if (url) {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], filename, {type: blob.type});

        const sigFile = await signFile(selectedCert, file);
        const formData = new FormData();
        formData.append('sig_file', sigFile);
        formData.append('message', messageId);
        formData.append('cert_name', selected_cert.cn);

        const res = await uniFetch(`/fz59/api/${entity_name}/${id}/`, 'PATCH', formData, false, false);
        updateMessageData(res);
      }
    } catch (error) {
      console.error(error);
      if (error?.errors && error?.code) {
        setErrorFields(error?.errors)
        setResponseStatus(error?.code)
      } else {
        const errorMessage = typeof error === 'object' && error !== null
          ? error.text ?? error.message ?? 'Ошибка сервера'
          : typeof error === 'string'
            ? error
            : 'Ошибка сервера';
        setErrorFields({error: errorMessage})
        setResponseStatus(500)
      }
    }
  };

  //todo: rebuild
  const confirmMessage = () => {
    if (showConfirm === 'send_to_applicant') {
      setShowConfirm(null);
      setFields(prevFields => ({
        ...prevFields,
        ['send_command']: true,
      }));
      handleSend();
    }
  }

  const renderConfirmMessage = () => {
    let errors = [];

    if (showConfirm === 'send_to_applicant'){
      if (!hasFiles()) errors.push("Для отправки ответа заявителю необходимо приложить файл ответа или подписать ответ при помощи ЭЦП");

      if (errors.length===0){
        return (
            <>
              <h4 className={'text-center'}>Вы действительно хотите отправить ответ на обращение заявителям?</h4>
              <p className={'text-center'}>После отправки ответа его редактирование будет недоступно</p>
            </>
          )
      }else{
        return (
            <>
              <h4 className={'text-center text-danger'}>Внимание!</h4>
              {errors.map((item, ikey)=>{
                  return (
                      <p key={ikey} className={'text-center fs-5'}>{item}</p>
                  )
              })}

            </>
        )
      }
    }
  }

  const hasFiles = () => {

    if (fields?.signed_file) return true
    if (!fileChanged && fields?.addition_file) return true
    if (fileChanged && file?.file) return true

    return false
  }

  const validateSendToApplicant = () => {

    let can_send = hasFiles()

    return can_send;
  }

  const renderConfirmModal = () => {
    if (showConfirm === null) return null;
    return (
      <HTMLConfirmModal label={'Отправить ответ заявителю?'}
                        handleConfirm={() => confirmMessage()}
                        handleCancel={() => setShowConfirm(null)}
                        validateConfirm={validateSendToApplicant()}
      >
        {renderConfirmMessage()}
      </HTMLConfirmModal>
    )
  }

  const hasValidKey = (obj, key) => {
    if (!obj) {
      return false
    }
    return key in obj && obj[key] !== null && obj[key] !== undefined;
  }

  const handleCloseResponseModal = () => {
    setResponseStatus(0)
  }

  const formatOptions = (certs) => {
    return certs?.map(cert => ({value: cert.cn, label: cert.cn}))
  }

  const loadCertOptions = (value, callback, setIsCryptoPlugin) => {
    getCert().then(certs => {
      setCerts(certs);
      setIsCryptoPlugin(true)
      return callback(formatOptions(certs));
    }).catch((e) => {
      setIsCryptoPlugin(false)
      setShowSignModal(true)
      return callback([])
    })
  };

  const handleOnMenuOpen = () => {
    if (isCryptoPlugin === false) {
      setShowSignModal(true)
    }
  }

  const handleHideSignModal = () => {
    setShowSignModal(false)
  }

  const handleSetTemplateValue = (val) => {
    setCurrentTemplate(val === null ? {code: '', title: ''} : val);
    setChanged(true);
  }

  const handleSetBlankValue = (val) => {
    setCurrentBlank(val === null ? {code: '', title: ''} : val);
    setChanged(true);
  }

  const handleSetPersonValue = (val) => {
    setCurrentPerson(val === null ? {code: '', title: ''} : {code: val?.extra?.code, title: val?.extra?.short_fio});
    setChanged(true);
  }

  const handleOpenFile = (url) => {
    window.open(url, '_blank', 'width=900,height=1600')
  }

  const handleMakePreview = (id) => {
    uniFetch(`/fz59/api/${entity_name}/${id}/make_preview/`, 'POST', null, false, false, true)
      .then(res => handleOpenFile(res.preview_fileinfo?.file))
      .catch((e) => {
        if (e?.errors && e?.code) {
          setErrorFields(e?.errors)
          setResponseStatus(e?.code)
        } else {
          setErrorFields({error: 'Ошибка сервера'})
          setResponseStatus(500)
        }
      })
  }

  const handlePreviewTemplate = (e) => {
    const formData = getFormData();
    saveApplicant(formData, (data) => {
      updateMessageData(data);
      handleMakePreview(data.id)
    });
  }

  const answerDeleteHandler = () => {
    setIsLoading(true)
    uniFetch(`/fz59/api/answer/${fields?.id}/`, 'DELETE', null, false, true, true).then((data) => {
      if (data?.status === 403) {
         setResponseStatus(403)
         setErrorFields({error: 'Нет прав'})
      } else {
        setResponseStatus(204)
        deleteAnswer();
        setDeleteAnswerModal(false)
      }
    }).catch((e) => {
      if (e?.errors && e?.code) {
        setErrorFields(e?.errors)
        setResponseStatus(e?.code)
      } else {
        setErrorFields({error: 'Ошибка сервера'})
        setResponseStatus(500)
      }
    }).finally(()=>setIsLoading(false))
  }
  const handleGenNumber = () => {
    const formData = getFormData();
    formData.append('command_gen_incomenumber', true)
    saveApplicant(formData, (data) => {
      updateMessageData(data);
    });
  }

    const genRegisterButton = () =>{
    return (
        <>
          <button className="btn btn-outline-success"
                  type="button"
                  onClick={handleGenNumber}
                  disabled={fields?.send}
                  title={'Сгенерировать исходящий номер'}>
            <SvgIcon type={svgType.register} size={{width: 16, height: 16}}/>
          </button>
        </>
    )
  }
  return (
    <>
      {renderConfirmModal()}
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-spinner"></div>
        </div>
      )}
      {deleteAnswerModal &&
        <ConfirmModal
          label={'Удаление ответа'}
          text={'Вы действительно собираетесь удалить сохраненный ответ?'}
          handleConfirm={answerDeleteHandler}
          handleCancel={() => setDeleteAnswerModal(false)}
        />
      }
      {isAskModal &&
        <ConfirmModal
          label={wait_sign==='ASKED' ? `Отменить запрос подписания` : `Отправка руководителю на подпись`}
          text={wait_sign==='ASKED' ? `Вы действительно собираетесь ОТМЕНИТЬ запрос подписания?` :
              `Вы действительно собираетесь отправить ответ на подпись: ${currentPerson?.title}?`
        }
          handleConfirm={()=>handleAskSign(wait_sign==='ASKED' ? 'CANCEL': 'ASKED')}
          handleCancel={() => setisAskModal(false)}
        />
      }
      <BlockContainer
        label={fields?.send ? 'Ответ исполнителя (Отправлен)' : 'Ответ исполнителя'}
        canHide={false}
        rightAction={canDeleteAnswer && deleteButton(()=>setDeleteAnswerModal(true))}
      >
        <div className="block-container-content">
          <Row className={"mb-3"}>
            <Col sm={6}>
              <InputField
                name={'income_number'}
                value={name => fields[name]}
                label={name => labels[name]}
                required={name => modelDefinition[name]?.required}
                column={2}
                rightButton={genRegisterButton()}
                onChange={handleChange}
                disabled={fields?.send}
                errorMessages={name => errorFields?.[name]}
              />
            </Col>
            <Col sm={6}>
              <InputField
                name={'sed_number'}
                value={name => fields[name]}
                label={name => labels[name]}
                required={name => modelDefinition[name]?.required}
                column={2}
                onChange={handleChange}
                disabled={fields?.send}
                errorMessages={name => errorFields?.[name]}
              />
            </Col>
          </Row>
          <Row className={"mb-3"}>
            <Col sm={6}>
              <DateField
                name={'date_receipt'}
                value={name => fields[name]}
                label={name => labels[name]}
                required={name => modelDefinition[name]?.required}
                column={2}
                onChange={handleChangeDate}
                errorMessages={name => errorFields?.[name]}
                disabled={fields?.send}
              />
            </Col>
            <Col sm={6}>
              <CodeInput
                name={'sender'}
                label={'Подписант'}
                apiUrl={personSearchUrl}
                initialValue={currentPerson}
                searchKeys={personSearchKeys}
                searchTitles={personSearchTitles}
                resultFn={handleSetPersonValue}
                requestParams={personRequestParams}
                data_mart={true}
                errorMessages={name => errorFields?.[name]}
                disabled={fields?.send}
              />
            </Col>
          </Row>
          <CKEditorField
            name={'description'}
            value={answer?.description}
            label={name => labels[name]}
            required={name => modelDefinition[name]?.required}
            hideLabel={false}
            column={1}
            row={2}
            wrappable={false}
            onChange={handleChangeDescription}
            disabled={fields?.send}
            errorMessages={name => errorFields?.[name]}
          />
          {!fields?.send && <Row className={'form-group mt-4'}>
            <Col sm={12} className={'text-success text-center fw-bold'}>
              В случае подписания ответа с использованием ЭЦП
            </Col>
          </Row>}
          <Row className={"my-4"}>
            <Col sm={6}>
              <CodeInput
              name={'blank'}
              label={'Бланк'}
              apiUrl={blankSearchUrl}
              initialValue={currentBlank}
              searchKeys={blankSearchKeys}
              searchTitles={blankSearchTitles}
              resultFn={handleSetBlankValue}
              requestParams={blankRequestParams}
              errorMessages={name => errorFields?.[name]}
              disabled={fields?.send}
              />
            </Col>
            <Col sm={6}>
              <CodeInput
                name={'template'}
                label={'Шаблон'}
                apiUrl={templateSearchUrl}
                initialValue={currentTemplate}
                searchKeys={templateSearchKeys}
                searchTitles={templateSearchTitles}
                resultFn={handleSetTemplateValue}
                previewFn={handlePreviewTemplate}
                requestParams={templateRequestParams}
                disabledPreview={!currentPerson?.code}
                errorMessages={name => errorFields?.[name]}
                disabled={fields?.send}
                />
            </Col>
          </Row>
          {!fields?.send && <Row>
            <Col sm={8}>
              <AsyncSelectField
                name={'cert'}
                value={name => selectedCert}
                label={name => 'Сертификат'}
                column={1}
                debounceTimeout={DEBOUNCE_TIMEOUT}
                onChange={handleChangeCert}
                loadOptions={(value, callback) => loadCertOptions(value, callback, setIsCryptoPlugin)}
                placeholder="Выберите сертификат..."
                noOptionsMessage={() => 'Не найдено действующих сертификатов'}
                loadingMessage={() => 'Поиск сертификатов...'}
                onMenuOpen={handleOnMenuOpen}
                loadAfterOpen={true}
              />
            </Col>
            <Col sm={4}>
              <button className={'btn btn-outline-primary w-100'}
                      disabled={!canSign}
                      onClick={handleSign}>Подписать с ЭЦП
              </button>
            </Col>
          </Row>}
          {answer?.signed_fileinfo?.file || answer?.sig_fileinfo?.file ?
          <Row className={'mt-4'}>
              {answer?.signed_fileinfo?.file ?
                <span>
                        <b>Подписанный файл</b>
                        <a className={'ml-2'} href={answer?.signed_fileinfo.file}
                           target="_blank">{answer?.signed_fileinfo.name}
                           </a>
                </span> : null}
              {answer?.sig_fileinfo?.file ?
                <span>
                      <b>Подпись</b>
                       <a className={'ml-2'} href={answer?.sig_fileinfo.file}
                          target="_blank">{answer?.sig_fileinfo.name}
                          </a>
                </span> : null}
          </Row> : null}
          {showSignModal && (
              <ConfirmModal
                  label={'Подпись ЭЦП'}
                  text={'Для работы КриптоПро ЭЦП Browser plugin на данном сайте необходимо расширение для браузера. Убедитесь, что оно у Вас включено или установите его'}
              handleCancel={handleHideSignModal}
              isAlert={true}
            />
          )}
          {!fields?.send ?
              <>
                <Row className={'form-group mb-2 mt-4'}>
                  <Col sm={12} className={'text-danger text-center fw-bold'}>
                    В случае подписания ответа без ЭЦП прикрепите файл ответа подписанный вручную
                  </Col>
                </Row>
                <Row className={'form-group'}>
                <Col sm={6} className={'control-label pt-4'}>Файл ответа без ЭЦП</Col>
                <Col sm={6}>
                  <SimpleFileInput
                    name={'file'}
                    label={name => 'Подписано без ЭЦП'}
                    files={attachedFile(file)}
                    setFiles={handleCreateFiles}
                    instantUpload={false}
                    allowDownload={true}
                    isConfirmDelete={false}
                    required={true}
                    column={1}
                    disabled={fields?.send}
                    errorMessages={name => errorFields?.[name]}
                  />
                </Col>
              </Row>
                <Row className={'form-group mb-3 mt-2'}>
                  <Col sm={12} className={'text-center text-muted'}>
                    Допустимые расширения файла — .doc, .docx, .zip, .rar, .pdf, .jpg, .jpeg, .tif, .tiff, .bmp, .png,
                    .gif. <br/>
                    Максимальный размер прилагаемого файла — 10 МБ.
                  </Col>
                </Row>
              </> :
              <>
                {fields?.addition_fileinfo ?
                    <Row className={'form-group py-4'}>
                      <Col sm={2} className={'control-label'}>Файл ответа без ЭЦП</Col>
                      <Col sm={10} className={'control-label'}>
                        <a className="w-100 mr-1" target="_blank" href={fields.addition_fileinfo?.file}>
                          <span className={'wrap-words'}>{fields.addition_fileinfo?.name}</span>
                        </a>
                      </Col>
                    </Row> : null}
              </>
        }
        {!fields?.send ?
              <Row className={'form-group py-2'}>
                {wait_sign!=="SIGNED"?<>
                    <Col sm={4} className={'control-label'}>Попросить подписать</Col>
                    {wait_sign!=='ASKED' ?
                    <Col sm={4}></Col>: null}
                    <Col sm={4}>
                        <button className={wait_sign==='ASKED' ? 'btn btn-outline-danger w-100' : 'btn btn-outline-primary w-100'}
                                disabled={!canAskSign}
                                onClick={()=> setisAskModal(true)}>
                            {wait_sign==='ASKED' ? `Отменить запрос подписания` : `Отправить на подпись руководителю`}
                        </button>
                    </Col>
                   {wait_sign==='ASKED'?
                      <Col sm={4}>
                      <button className={'btn btn-success w-100 py-2'}
                              disabled={isLoading || !canAnswer}
                              onClick={()=>handleAskSign('SIGNED')}>
                          Отметить подписанным
                      </button>
                      </Col>: null}
                    </>:<>
                    {canAnulate?
                    <>
                      <Col sm={4} className={'control-label'}>{currentPerson?.title}</Col>
                      <Col sm={4}></Col>

                          <Col sm={4}>
                              <button className={'btn btn-outline-danger w-100'}
                              disabled={!canAskSign}
                              onClick={() => handleAskSign('ANULATE')}>Отозвать подпись
                              </button>
                          </Col>
                    </>: null}
                    </>
                }
              </Row>:
          null}
        </div>
      </BlockContainer>
      <BlockContainer
        label='Анализ ответа'
        canHide={false}
      >
        <div className="block-container-content">
          <div className={"mb-3"}>
            <ButtonGroupField
              name={'analysis_result'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={name => modelDefinition[name]?.required}
              defaultHide={true}
              column={1}
              onChange={handleRadioChange}
              children={name => modelDefinition[name]?.children}
              errorMessages={name => errorFields?.[name]}
            />
          </div>
          <div className={"mb-3"}>
            <SelectField
              name={'way_result'}
              value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
              label={name => labels[name]}
              required={name => modelDefinition[name]?.required}
              column={1}
              isMulti={true}
              options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
              onChange={handleChangeSelect}
              errorMessages={name => errorFields?.[name]}
              placeholder="Выберите..."
            />
          </div>
          <div className={"mb-3"}>
            <SelectField
              name={'measures_influence'}
              value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
              label={name => labels[name]}
              required={name => modelDefinition[name]?.required}
              column={1}
              isMulti={true}
              options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
              onChange={handleChangeSelect}
              errorMessages={name => errorFields?.[name]}
              placeholder="Выберите..."
            />
          </div>
          <SelectField
            name={'typical_reasons'}
            value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
            label={name => labels[name]}
            required={name => modelDefinition[name]?.required}
            column={1}
            isMulti={true}
            options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
            onChange={handleChangeSelect}
            errorMessages={name => errorFields?.[name]}
            placeholder="Выберите..."
          />
        </div>
      </BlockContainer>
      <BlockContainer
        label='Оценка ответа'
        canHide={false}>
        <div className="block-container-content">
          <div className={"mb-3"}>
            <ButtonGroupField
              name={'evaluation_registrar_response'}
              value={name => fields[name]}
              label={name => labels[name]}
              required={name => modelDefinition[name]?.required}
              defaultHide={true}
              column={2}
              onChange={handleRadioChange}
              children={name => modelDefinition[name]?.children}
              errorMessages={name => errorFields?.[name]}
            />
          </div>
          <ButtonGroupField
            name={'evaluation_applicant_response'}
            value={name => fields[name]}
            label={name => labels[name]}
            required={name => modelDefinition[name]?.required}
            defaultHide={true}
            column={2}
            onChange={handleRadioChange}
            children={name => modelDefinition[name]?.children}
            errorMessages={name => errorFields?.[name]}
          />
        </div>
      </BlockContainer>
      <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
          <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
        </ResponseModal>
      <Row>
        <Col sm={6} className={'text-start'}>
          {!isCreate ?
            <Button onClick={() => setShowConfirm('send_to_applicant')} bsStyle="success"
                    disabled={isLoading || !canAnswer}>
              {fields?.send ? 'Отправлен ответ заявителю' : 'Отправить ответ заявителю'}
            </Button> :
            null
          }
        </Col>
        <Col sm={6} className={'text-end'}>
          <Button onClick={handleSave} bsStyle="primary" disabled={isLoading}>
            Сохранить ответ
          </Button>
        </Col>
      </Row>

    </>
  )
}
