import React, {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {FieldContainer} from "./FieldContainer";
import {useDebouncedCallback} from 'use-debounce';

export function AsyncSelectField({
name,
value,
label,
onChange,
column,
row,
required,
hideLabel,
disabled,
currentValue,
loadOptions,
errorMessages,
placeholder,
noOptionsMessage,
loadingMessage,
onMenuOpen,
clearOnEmpty = false,
isClearable = true,
loadAfterOpen = true,
debounceTimeout = 500,
initialOtions = []
}) {
  const current = typeof currentValue === 'function' ? currentValue(name): currentValue;
  const currentVal = typeof value === 'function' ? value(name): value;
  const [selectedValue, setSelectedValue] = useState(current ? {value: currentVal, label: current} :null);
  const [isOpen, setIsOpen] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState(initialOtions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (current) {
      setSelectedValue({value: currentVal, label: current});
    } else if(clearOnEmpty){
      setSelectedValue(null);
      //setDefaultOptions([]);
    }
  }, [current]);

  useEffect(() => {
    if (!currentVal && selectedValue?.value && selectedValue?.label) {
      setSelectedValue(null)
    }
  }, [currentVal])

  useEffect(() => {
    if (loadAfterOpen && isOpen && !defaultOptions?.length) {
      setIsLoading(true);
      loadOptions('', (options) => {
        setDefaultOptions(options);
        setIsLoading(false);
      });
    }
  }, [isOpen, loadOptions])

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange(name, selectedOption ? selectedOption.value : null, selectedOption?.label);
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };
  const debouncedLoadOptions = useDebouncedCallback(loadOptions, debounceTimeout);
  return (
    <FieldContainer {...{name, label: label? label(name): '', column, row, required, hideLabel, errorMessages}}>
      <AsyncSelect
        cacheOptions
        {...(loadAfterOpen ? { defaultOptions } : { defaultOptions: true })}
        className={`custom-input ${disabled ? 'disabled' : ''}`}
        loadOptions={debouncedLoadOptions}
        onChange={handleChange}
        value={selectedValue}
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={isClearable}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        isLoading={isLoading}
        onMenuOpen={() => {
          handleMenuOpen();
          onMenuOpen && onMenuOpen()
        }}
        onMenuClose={handleMenuClose}
      />
    </FieldContainer>
  );
}


export function AsyncSelectField2({
name,
value,
label,
onChange,
column,
row,
required,
hideLabel,
disabled,
currentValue,
loadOptions,
errorMessages,
placeholder
}) {
  const [selectedValue, setSelectedValue] = useState(null);
  const current = currentValue

  useEffect(() => {
    if (current) {
      setSelectedValue({value: value, label: current});
    }
  }, [current]);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange(name, selectedOption ? selectedOption.value : null);
  };

  return (
    <FieldContainer {...{name, label: label, column, row, required, hideLabel, errorMessages}}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        isClearable
        className={`custom-input ${disabled ? 'disabled' : ''}`}
        className={`custom-input ${disabled ? 'disabled' : ''}`}
        loadOptions={loadOptions}
        onChange={handleChange}
        value={selectedValue}
        isDisabled={disabled}
        placeholder={placeholder}
      />
    </FieldContainer>
  );
}
