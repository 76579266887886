import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Collapse } from 'react-bootstrap';
import uniFetch from '../utils/uniFetch';
import {QUERY_LIMIT} from "../constants";
import {SvgIcon, svgType} from "../components/Icons";


function ActionHistoryTab(props) {
    const objectId = props.objectId
    const limit = QUERY_LIMIT;

    const [actionLogs, setActionLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [openRows, setOpenRows] = useState({});
    const [openRowsShow, setOpenRowsShow] = useState({});

    const toggleRow = (action) => {
        if (Object.keys(action.data).length<= 0)
            return
        setOpenRows((prev) => ({ ...prev, [action.id]: !prev[action.id] }));
    };

    const toggleRowShow = (id) => {
        setOpenRowsShow((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const fetchData = (page) => {
        setIsLoading(true);
        const offset = (page - 1) * limit;
        let url = `/fz59/action-history/?limit=${limit}&offset=${offset}&object_id=${objectId}`

        uniFetch(url)
          .then(data => {
            setTotalItems(data.count);
            setActionLogs(data.results)
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

    useEffect(() => {
          fetchData(currentPage);
          setOpenRows({});
          setOpenRowsShow({});
      }, [currentPage]);

    const totalPages = totalItems && limit ? Math.ceil(totalItems / limit) : 1;

    const handlePageChange = (page) => {
        setCurrentPage(page)
      };

    const renderPagination = () => {
        const pages = [];
        const addPage = (page) => {
          pages.push(
            <li key={page} className={currentPage === page ? 'ex-active' : ''}>
              {currentPage !== page ? <a href="#" onClick={() => handlePageChange(page)}>{page}</a> : <span>{page}</span>}
            </li>
          );
        };

        const addEllipsis = (key) => {
          pages.push(<li key={key} className="ex-disabled"><span>...</span></li>);
        };

        if (totalPages > 1) {
          [1, 2].forEach(addPage);
        } else {
          addPage(1);
        }

        if (currentPage > 4 && totalPages > 5) {
          addEllipsis('start-ellipsis');
        }

        const startPage = Math.max(3, currentPage - 2);
        const endPage = Math.min(totalPages - 2, currentPage + 2);
        for (let i = startPage; i <= endPage; i++) {
          addPage(i);
        }

        if (currentPage < totalPages - 3 && totalPages > 5) {
          addEllipsis('end-ellipsis');
        }

        if (totalPages > 4) {
          [totalPages - 1, totalPages].forEach(addPage);
        } else if (totalPages === 4) {
          [3, 4].forEach(addPage);
        } else if (totalPages === 3) {
          addPage(3);
        }

        return (
          <ul className="ex-pagination" style={{margin: '5px 0 0 0'}}>
            <li className={currentPage === 1 ? 'ex-disabled' : ''}>
            <span onClick={() => currentPage !== 1 && handlePageChange(currentPage - 1)}>
              <i className="fa fa-chevron-left"></i>
            </span>
            </li>
            {pages}
            <li className={currentPage === totalPages ? 'ex-disabled' : ''}>
            <span onClick={() => currentPage !== totalPages && handlePageChange(currentPage + 1)}>
              <i className="fa fa-chevron-right"></i>
            </span>
            </li>
          </ul>
        );
      };

    const optionsDate = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Moscow'
      };

    const formatedDate = (strDate) => {
        if (strDate === null || strDate === '') {
          return null
        }

        const date = new Date(strDate)
        return date.toLocaleString('ru-RU', optionsDate).replace(' г.', 'г.')
      }

    return (
        <div className="form-container">
          <div className="blocks-container">
            <div className="block-container full-width mb-5 w-100">
              <label className="block-label d-flex justify-content-between align-items-center">История действий</label>
              <div className="card margin my-3 height-50em">
                <div className={'card-header py-3'}>
                  <Row className={'fw-bold'}>
                      <Col sm={6}>Действие</Col>
                      <Col sm={2} className={'text-center'}>Должностное лицо</Col>
                      <Col sm={3} className={'text-center'}>Дата и время</Col>
                      <Col sm={1}></Col>
                  </Row>
                </div>
                <div className="card-body select-area">
                  {!isLoading && actionLogs?.map(action =>
                      <Row key={action.id} className={'py-2'}>
                          <div className={`d-flex ${Object.keys(action.data).length > 0 && 'can-select'}`}
                               onClick={() => toggleRow(action)}
                               aria-expanded={!!openRows[action.id]}
                               aria-controls={'collapsedData' + action.id}
                          >
                              <Col sm={6}>{action.annotation}</Col>
                              <Col sm={2} className={'text-center'}>{action.orgunit_short_fio || action.orgunit_id}</Col>
                              <Col sm={3} className={'text-center'}>{formatedDate(action.created_at) ?? "-"}</Col>
                              <Col sm={1} className={'text-center'}>
                                  {Object.keys(action.data).length > 0 && (
                                      <SvgIcon type={!!openRows[action.id] ? svgType.chevronUp: svgType.chevronDown}
                                               size={{ width: 16, height: 16 }}
                                      />
                                  )}
                              </Col>
                          </div>
                          <Collapse in={!!openRows[action.id]}
                                    onEntering={() => toggleRowShow(action.id)}
                                    onExited={() => toggleRowShow(action.id)}
                                    className={'pb-3'}
                          >
                              <div className={!!openRowsShow[action.id] && 'show'}
                                   id={'collapsedData' + action.id}
                              >
                                  <hr className="my-2 pb-3"/>
                                  {Object.entries(action.data).reduce((acc, [key, value], index, array) => {
                                    const isSmallData = Object.entries(action.data).length < 6;
                                    if (index % (isSmallData ? 1 : 2) === 0) {
                                        const next = array[index + 1] || [];

                                        const formatValue = (val) =>
                                            typeof val === "string" ? val : JSON.stringify(val);

                                        acc.push(
                                            <Row key={key}
                                                 className="border-bottom py-2"
                                                 style={{ backgroundColor: 'inherit', margin: '0 20px' }}>
                                                <Col lg={isSmallData ? 6 : 3} sm={6} style={{fontWeight: '600'}}>{key}</Col>
                                                <Col lg={isSmallData ? 6 : 3} sm={6}>{formatValue(value)}</Col>
                                                {!isSmallData && (
                                                    <>
                                                        <Col lg={3} sm={6} style={{fontWeight: '600'}}>{next[0] || ''}</Col>
                                                        <Col lg={3} sm={6}>{next[1] ? formatValue(next[1]) : ''}</Col>
                                                    </>
                                                )}
                                            </Row>
                                        );
                                    }
                                    return acc;
                                }, [])}
                              </div>
                          </Collapse>
                      </Row>
                  )}
                  {isLoading ?
                      <div className="text-center">
                          <div className="spinner-border text-primary m-5" role="status"
                               style={{width: '3rem', height: '3rem'}}>
                              <span className="visually-hidden">Загрузка...</span>
                          </div>
                      </div>
                      : null}
                </div>
                  <div className={'card-footer ex-catalog-pagination'}>
                      <Row>
                          <Col md={4} className={'align-content-center'}>
                              <b className={'mr-2'}>Записей:</b>
                              {(currentPage - 1) * limit + 1} - {(currentPage * limit) <= totalItems?
                              (currentPage * limit): totalItems} из {totalItems}
                          </Col>
                          <Col md={4}>
                              <div className={'d-flex justify-content-center'}>
                                  {renderPagination()}
                              </div>
                          </Col>
                      </Row>

                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default ActionHistoryTab;
