import React from 'react';

export function ResponseAlert({ status, result, labels, successTitle }) {
  if (status === 0 || !result) return null;

  const isSuccess = status === 200 || status === 204;
  const className = isSuccess ? "alert alert-success text-center" : "text-danger";

  const errorMessage = typeof result?.error === 'string' ? result.error : result?.error?.join(', ');

  const missingFields = Object.keys(result)
    ?.filter(key => key !== 'error')
    ?.map(key => labels[key] ?? key)
    ?.join(', ');

  return (
    <div className={className} role="alert">
      <div>
        {result.detail ? result.detail : status === 204 ? "Удалено" : isSuccess ? successTitle ?? "Сохранено" : missingFields ? `Не все поля заполнены или заполнены некорректно: ${missingFields}` : ""}
      </div>
      <div>
        {!isSuccess && errorMessage && errorMessage}
      </div>
    </div>
  );
}
