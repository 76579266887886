import React, {useEffect, useState} from 'react';
import {Tabs, Tab, Button} from 'react-bootstrap';
import Form from './Form';
import {MyMessageList} from "../fzmessage/List/MyMessageList";

const tabData = {
  info: {
    title: 'Информация об обращении',
    contentId: 'appeal-information',
  },
  legal: {
    title: 'Правовая база',
    contentId: 'legal-basis',
  },
  form: {
    title: 'Направить обращение',
    contentId: false,
  },
};

const tabsId = 'federal_appeal_tabs';

const sendButtonText = 'Направить обращение';


function SendButton(props) {
  return (
    <div className="d-flex justify-content-center">
      <Button onClick={props.onClick} bsStyle="primary">
        {props?.text || sendButtonText}
      </Button>
    </div>
  );
}

const login = (url) => {
  window.location.href = url
}

const RenderElement = ({contentId}) => {
  const content = document.getElementById(contentId);
  if (!contentId) return null;
  return (<div dangerouslySetInnerHTML={{__html: content.innerHTML}}/>)
}

export default function FederalAppeal(props) {
  const [organizationList, setOrganizationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [user, setUser] = useState(null);

  const [activeTab, setActiveTab] = useState(props?.idHash ? 'cabinet' : 'form');

  useEffect(() => {
    // TODO use proper fetch with error handling and loading in the UI
    fetch(props.apiUrl)
      .then(r => {
        if (!r.ok) {
          throw new Error('Request is not okay!');
        }
        r.json()
          .then(j => {
            setOrganizationList(j.recipients)
            setStatusList(j.social_status)
            setCategoryList(j.category)
            setUser(j.user ?? null)
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);



  return (
    // @ts-expect-error: No overload matches this call
    <Tabs activeKey={activeTab} onSelect={setActiveTab} id={tabsId}>
      {Object.keys(tabData).map((k) => (
        <Tab key={k} title={tabData[k].title} eventKey={k} className={'show'}>
          {k === 'form' ?
              <Form
                organizationList={organizationList}
                statusList={statusList}
                categoryList={categoryList}
                user={user}
                firstUnit={true}
                org={props?.org}
                unit={props?.unit}
                isPublic={props?.isPublic}
                isAuth={props?.isAuth}
                idHash={props?.idHash}
                showConfirm={props?.showConfirm}
                loginUrl={props?.loginUrl}
              /> :
              <>
                <RenderElement contentId={tabData[k].contentId}/>
                <SendButton
                  onClick={() => {
                    setActiveTab('form');
                  }}
                />
              </>
          }
        </Tab>
      ))}
        {user &&
      <Tab key={'cabinet'} title={'Личный кабинет'} eventKey={'cabinet'} className={'show'}>
        {activeTab === 'cabinet' &&
          <>
            <div className="content container">
              <div className="d-flex justify-content-between align-items-center">
                <h1>Мои обращения</h1>
              </div>
              <MyMessageList
                attrs={{datamart: "/fz59/api/applicant-messages/", idHash: props?.idHash, loginUrl: props?.loginUrl}}/>
            </div>
            <SendButton
              text={!props?.isAuth ? 'Войти через госуслуги' : ''}
              onClick={() => {
                !props?.isAuth ? login(props?.loginUrl) : setActiveTab('form');
              }}
            />
          </>
        }
      </Tab>}
    </Tabs>
  );
}
