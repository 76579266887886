import React, {useState, useEffect} from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';
import uniFetch from "../../../utils/uniFetch";
import {convertDateFormat, getCurrentDateTime} from "../../../utils/common";
import {getCert, signFile} from "../../../utils/crypto";
import {DateField} from "../../../components/Fields/DateField";
import {InputField} from "../../../components/Fields/InputField";
import {AsyncSelectField} from "../../../components/Fields/AsyncSelectField";
import {DEBOUNCE_TIMEOUT} from "../../../constants";
import {ConfirmModal, LoaderModal, ResponseModal} from "../../../components/Modal";
import {SimpleFileInput} from "../../../components/Fields/SimpleFileField";
import {attachedFile} from "../../../utils/filepond";
import {CodeInput} from "../responsible-group-tab";
import {ResponseAlert} from "../../../components/ResponseAlert";

export function AddEntityForm(props){
    const {groupID, reg_organisation, orgunit, messageId, entity, entity_name, person, labels,
        sendCallback, exclude_fields, updateCommand, extra_data, files_required= true} = props;

    const wait_sign = entity?.wait_sign;

    const [isChanged, setChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notNeed, setNotNeed] = useState(entity?.not_need || false);
    const [currentBlank, setCurrentBlank] = useState({
        code: entity?.blank_code || '',
        title: entity?.blank_title || ''
    });
    const [currentTemplate, setCurrentTemplate] = useState({
        code: entity?.template_code || '',
        title: entity?.template_title || ''
    });
    const [currentPerson, setCurrentPerson] = useState({
        code: entity?.specialist_code || '',
        title: entity?.specialist_title || ''
    });
    const [send_date, setSendDate] = useState(entity?.send_date || getCurrentDateTime());
    const [errorFields, setErrorFields] = useState({});
    const [attachmentFile, setАttachmentFile] = useState(entity?.addition_fileinfo || null);
    const [attachmentFileChanged, setАttachmentFileChanged] = useState(false);
    const [additionText, setAdditionText] = useState(entity?.additional || '');
    const [sendByPost, setSendByPost] = useState(entity?.send_by_post || '');

    const [certs, setCerts] = useState([])
    const [selectedCert, setSelectedCert] = useState('')
    const [isCryptoPlugin, setIsCryptoPlugin] = useState(null)
    const [showSignModal, setShowSignModal] = useState(false)
    const [showSendModal, setShowSendModal] = useState(false)

    const [responseStatus, setResponseStatus] = useState(0);
    const [successTitle, setSuccessTitle] = useState(null);
    const [isAskModal, setisAskModal] = useState(false);

    useEffect(() => {
        setCurrentBlank({
            code: entity?.blank_code || '',
            title: entity?.blank_title || ''
        });
        setCurrentTemplate({
            code: entity?.template_code || '',
            title: entity?.template_title || ''
        });
        setCurrentPerson({
            code: entity?.specialist_code || '',
            title: entity?.specialist_title || ''
        });
        setSendDate(entity?.send_date || getCurrentDateTime());
        setАttachmentFile(entity?.addition_fileinfo || null);
        setАttachmentFileChanged(false);
        setAdditionText(entity?.additional || '');
        setSendByPost(entity?.send_by_post || '');
        setSelectedCert('')
    }, [entity]);


    const blankSearchUrl = "/fz59/api/organizationblank/";
    const templateSearchUrl = "/fz59/api/organizationtemplate/";
    const personSearchUrl = '/edw/api/data-marts/fzorganisationunit-dm/entities/';

    const blankRequestParams = {'org': reg_organisation}
    const templateRequestParams = {'org': reg_organisation}
    const personRequestParams = {'org': reg_organisation, 'is_specialist': true}

    const blankSearchTitles = [
        {title: 'Код', prop: 'code', col: 2},
        {title: 'Наименование', prop: 'title', col: 5},
        {title: 'Файл', prop: 'blank_fileinfo', col: 5}];

    const templateSearchTitles = [
        {title: 'Код', prop: 'code', col: 2},
        {title: 'Наименование', prop: 'title', col: 3},
        {title: 'Текст', prop: 'short_text', col: 7}];
    const personSearchTitles = [
        {title: 'Код', prop: 'code', col: 2},
        {title: 'Наименование', prop: 'short_fio', col: 5},
        {title: 'Должность', prop: 'position', col: 5}];

    const blankSearchKeys= {codeKey: 'code', textKey: 'title', searchKey: 'code_title'};
    const templateSearchKeys= {codeKey: 'code', textKey: 'title', searchKey: 'code_title'};
    const personSearchKeys = {codeKey: 'code', textKey: 'title', searchKey: 'full_name'};

    const isValid = (value) => value !== null && value !== undefined && value !== '';

    const isCanSign = () => {

        if (!notNeed && send_date && selectedCert
          && isValid(currentBlank.code) && isValid(currentBlank.title)
          && isValid(currentTemplate.code) && isValid(currentTemplate.title)
          && isValid(currentPerson.code) && isValid(currentPerson.title)) {
            return true;
        }
        return false;
    }
    useEffect(() => {
        setErrorFields(prevErrorFields => {
            if (!isValid(currentPerson.code) && isValid(currentPerson.title) || isValid(currentPerson.code) && !isValid(currentPerson.title)) {
                return {
                    ...prevErrorFields,
                    errors: {
                        ...prevErrorFields?.errors,
                        sender: ['Выбран неправильный специалист']
                    }
                };
            } else {
                const {sender, ...restErrors} = prevErrorFields?.errors || {};
                return {
                    ...prevErrorFields,
                    errors: restErrors
                };
            }
        });
    }, [currentPerson])



    const canSign = isCanSign();
    const canAskSign = currentPerson?.title!=='';
    const allDisabled = (wait_sign === 'ASKED' || wait_sign==="SIGNED")
    &&(entity?.specialist_signed!==orgunit?.id)&&(!orgunit?.permissions?.can_root) ? true: false;
    const canAnulate =  (wait_sign==="SIGNED")
    &&(entity?.specialist_signed===orgunit?.id || orgunit?.permissions?.can_root) ? true: false;

    const getFormData = () => {
        const formData = new FormData();
        if (person?.id) formData.append('responsible', person.id);
        formData.append('active', true);
        formData.append('group', groupID);
        formData.append('message', messageId);
        if (send_date) formData.append('send_date', send_date);
        formData.append('blank_code', currentBlank.code || '');
        formData.append('blank_title', currentBlank.title || '');
        formData.append('template_code', currentTemplate.code || '');
        formData.append('template_title', currentTemplate.title || '');
        if (currentTemplate?.description) {
            formData.append('description', currentTemplate.description);
        }
        formData.append('specialist_code', currentPerson.code || '');
        formData.append('specialist_title', currentPerson.title || '');
        if (!exclude_fields?.includes('additional')) {
            formData.append('additional', additionText || '');
        }
        formData.append('send_by_post', sendByPost || '');
        formData.append('not_need', notNeed || false);
        if (extra_data){
            Object.entries(extra_data).forEach(([key, value]) => {
              formData.append(`${key}`, value);
            });
        }
        return formData
    }

    const saveEntity = (form_data, callBackFn) =>{
        const send_url = entity.id ? `/fz59/api/${entity_name}/` + entity.id +'/' : `/fz59/api/${entity_name}/`;
        const send_method = entity.id ? 'PATCH' : 'POST';
        setIsLoading(true);

        if (attachmentFileChanged) {
            if (!attachmentFile?.file) {
                form_data.append('addition_file_delete', true)
            } else {
                form_data.append('addition_file', attachmentFile?.file)
            }
        }
        uniFetch(send_url, send_method, form_data, false, false, true).then((data) => {
            setErrorFields({});
            setResponseStatus(200);
            setChanged(false);
            if(callBackFn) callBackFn(data);
        }).catch(data => {
            console.log("error", data);
            if (data?.code && data?.errors) {
                setResponseStatus(data?.code)
                setErrorFields(data?.errors);
            } else {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            }

        }).finally(()=> {
            setIsLoading(false);
            setShowSendModal(false);
        });
    }

    const handleChangeDate = (e, setFn) => {
        const {name, value} = e.target;
        const formattedDateTime = value ? `${value}T08:00` : null;
        setFn(formattedDateTime);
        setChanged(true);
        return false;
    }

    const handleChangeAddition = (e) => {
        const {name, value} = e.target;
        setAdditionText(value);
        setChanged(true);
        return false;
    }
    const handleChangeSendByPost = (e) => {
        const {name, value} = e.target;
        setSendByPost(value);
        setChanged(true);
        return false;
    }

    const handleSetTemplateValue = (val) => {
        setCurrentTemplate(val===null ? {code: '', title: ''} : val);
        setChanged(true);
    }

    const handleSetBlankValue = (val) => {
        setCurrentBlank(val===null ? {code: '', title: ''} : val);
        setChanged(true);
    }

    const handleSetPersonValue = (val) => {
        setCurrentPerson(val===null ? {code: '', title: ''} : {code: val?.extra?.code, title: val?.extra?.short_fio});
        setChanged(true);
    }

    const handleOpenFile = (url) => {
        window.open(url, '_blank', 'width=900,height=1600')
    }

    const handleMakePreview = (id) => {
         setIsLoading(true);
        uniFetch(`/fz59/api/${entity_name}/${id}/make_preview/`, 'POST', null, false, false, true)
          .then(res => handleOpenFile(res.preview_fileinfo?.file))
          .catch(data => {
            if (data?.code && data?.errors) {
                setResponseStatus(data?.code)
                setErrorFields(data?.errors);
            } else {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            }

        }).finally(()=> setIsLoading(false))
    }

    const handlePreviewTemplate = (e) => {
        if (isChanged) {
            const formData = getFormData();
            saveEntity(formData, (data) => {
                updateMessageData(data);
                handleMakePreview(data.id);
            });
        } else {
            entity?.id && handleMakePreview(entity.id)
        }
    }

    const handleCreateFiles = (value) => {
        if (value.length > 0 && !value[0]?.serverId) {
            if ((value[0]?.file?.size !== attachmentFile?.size) && (value[0]?.file?.name !== attachmentFile?.name)) {
                setАttachmentFile(value[0]);
                setАttachmentFileChanged(true);
                setChanged(true);
            }
        } else if (value?.length === 0) {
            setАttachmentFile(null);
            setАttachmentFileChanged(true);
            setChanged(true);
        }
    };


    const handleAskSign = (data) =>{
        const ask_data = data ? data: wait_sign === 'ASKED' ? 'CANCEL': 'ASKED';
        let formData = getFormData();
        formData.append('ask_command', ask_data);
        saveEntity(formData, (data) => {
            updateMessageData(data);
        });
        setisAskModal(false);
    }

    const handleSign = () =>{
        if (isChanged) {
            const formData = getFormData();
            saveEntity(formData, (data) => {
                updateMessageData(data);
                handleECP(data.id)
            });
        } else {
            entity?.id && handleECP(entity.id)
        }
    }

    //todo: check ASYNC!!!
    const handleECP = async (id) => {
        if (!selectedCert) return;

        try {
            setIsLoading(true);
            const cert = certs?.find(cert => cert.cn === selectedCert);
            const selected_cert = {...cert}
            if (!cert) throw new Error('Certificate not found');

            selected_cert.validTo = convertDateFormat(cert.validTo);
            selected_cert.validFrom = convertDateFormat(cert.validFrom);

            const data = await uniFetch(`/fz59/api/${entity_name}/${id}/make_preview/`, 'POST', selected_cert);
            const url = data?.signed_fileinfo?.file;
            const filename = data?.signed_fileinfo?.name;

            if (url) {
                const response = await fetch(url);
                const blob = await response.blob();
                const file = new File([blob], filename, {type: blob.type});

                const sigFile = await signFile(selectedCert, file);
                let formData = new FormData();
                formData.append('sig_file', sigFile);
                formData.append('message', messageId);
                const res = await uniFetch(`/fz59/api/${entity_name}/${id}/`, 'PATCH', formData, false, false);
                updateMessageData(res);
                setResponseStatus(200);
            }
        } catch (error) {
            setIsLoading(false);
            if (error?.code == 500) {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            } else {
                setResponseStatus(400)
                setErrorFields({error});
            }
            console.error(error);
        }
         setIsLoading(false);
    };

    const handleNotNeed = (e) => {
        setNotNeed(!notNeed);
        setChanged(true);
    }

    const updateMessageData = (data) =>{
        updateCommand(`command_update_${entity_name}`, Object.assign({}, data));
    }

    const sendMessageData = (data) =>{
        updateCommand(`command_send_${entity_name}`, Object.assign({}, data));
    }

    const handleSave = (e) => {
        e.preventDefault();
        const formData = getFormData();
        saveEntity(formData, (data) => {
            sendMessageData(data);
        });
    }


    const handleSend = (e) => {
        e.preventDefault();
        const formData = getFormData();
        formData.append('send_command', true);
        saveEntity(formData, (data) => {
            sendMessageData(data);
            handleCancelSendModal();
            setSuccessTitle(labels.success_send);
            sendCallback && sendCallback()
        });
    }

    const handleChangeSelect = (name, value) => {
        setSelectedCert(value)
    }
    const formatOptions = (certs) => {
        return certs?.map(cert => ({value: cert.cn, label: cert.cn}))
    }
    const loadCertOptions = (value, callback, setIsCryptoPlugin) => {
        getCert().then(certs => {
            setCerts(certs);
            setIsCryptoPlugin(true)
            return callback(formatOptions(certs));
        }).catch((e)=>{
            setIsCryptoPlugin(false)
            setShowSignModal(true)
            return callback([])
        })
    };

    const handleOnMenuOpen = () => {
        if (isCryptoPlugin === false) {
            setShowSignModal(true)
        }
    }

    const handleHideSignModal = () => {
        setShowSignModal(false)
    }

    const handleCancelSendModal = () => {
        setShowSendModal(false)
    }

    const handleOpenSendModal = () => {
        setShowSendModal(true)
    }
    const handleCloseResponseModal = () => {
        setResponseStatus(0)
        setSuccessTitle(null)
    }

    const askLabel = entity?.is_notification ? `${labels.confirm_ask_notes}` : `${labels.confirm_ask}`;
    return (
        <>
            {isLoading ? <LoaderModal/>: null}
            <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
                <ResponseAlert status={responseStatus} result={errorFields} labels={labels} successTitle={successTitle}/>
            </ResponseModal>
            {isAskModal &&
              <ConfirmModal
                label={wait_sign==='ASKED' ? `Отменить запрос подписания` : `Отправка руководителю на подпись`}
                text={wait_sign==='ASKED' ? `Вы действительно собираетесь ОТМЕНИТЬ запрос подписания?` :
                    `${askLabel} ${currentPerson?.title}?`
              }
                handleConfirm={()=>handleAskSign(wait_sign==='ASKED' ? 'CANCEL': 'ASKED')}
                handleCancel={() => setisAskModal(false)}
              />
            }
            {!exclude_fields?.includes('notNeed')&&!allDisabled ? <Row className={'pb-1'}>
                <Col sm={4}></Col>
                <Col sm={8}>
                    <div className="form-check">
                        <input className="form-check-input"
                               type="checkbox"
                               checked={notNeed}
                               onChange={handleNotNeed}/>
                        <label className="form-check-label fw-bold">
                            {labels?.not_need ?? 'Не требуется'}
                        </label>
                    </div>
                </Col>
            </Row>: null}
            {!notNeed ? <>
            <Row className={'pb-2'}>
                <Col md={12}>
                    <DateField
                        name={'send_date'}
                        value={send_date}
                        label={()=> {return 'Дата'}}
                        hideLabel={false}
                        required={false}
                        column={2}
                        onChange={(e)=>{handleChangeDate(e, setSendDate)}}
                        errorMessages={name=>errorFields?.[name]}
                        disabled={allDisabled}
                      />
                </Col>
            </Row>
                <Row className={'py-2'}>
                    <Col md={12}>
                        <CodeInput
                          label={'Бланк'}
                          name={'blank'}
                          apiUrl={blankSearchUrl}
                          initialValue={currentBlank}
                          searchKeys={blankSearchKeys}
                          searchTitles={blankSearchTitles}
                          resultFn={handleSetBlankValue}
                          requestParams={blankRequestParams}
                          disabled={allDisabled}
                          errorMessages={name=>errorFields?.[name]}
                        />
                    </Col>
                </Row>
                <Row className={'py-2'}>
                    <Col md={12}>
                        <CodeInput
                          label={'Шаблон'}
                          name={'template'}
                          apiUrl={templateSearchUrl}
                          initialValue={currentTemplate}
                          searchKeys={templateSearchKeys}
                          searchTitles={templateSearchTitles}
                          resultFn={handleSetTemplateValue}
                          previewFn={handlePreviewTemplate}
                          requestParams={templateRequestParams}
                          disabled={allDisabled}
                          disabledPreview={!currentPerson?.code}
                          errorMessages={name=>errorFields?.[name]}
                          description={entity?.description}
                        />
                    </Col>
                </Row>
                <Row className={'py-2'}>
                    <Col md={12}>
                        <CodeInput
                          name={'sender'}
                          label={'Специалист'}
                          apiUrl={personSearchUrl}
                          initialValue={currentPerson}
                          searchKeys={personSearchKeys}
                          searchTitles={personSearchTitles}
                          resultFn={handleSetPersonValue}
                          requestParams={personRequestParams}
                          disabled={allDisabled}
                          data_mart={true}
                          errorMessages={name=>errorFields?.[name]}
                        />
                    </Col>
                </Row>
                {canAnulate ?
                    <Row className={'form-group py-2'}>
                        <Col sm={4} className={'control-label'}>{currentPerson?.title}</Col>
                        <Col sm={8}>
                            <button className={'btn btn-outline-danger w-100'}
                            disabled={!canAskSign}
                            onClick={() => handleAskSign('ANULATE')}>Отозвать подпись
                            </button>
                        </Col>
                    </Row>: null
                }
                {wait_sign!=="SIGNED"?
                <Row className={'form-group py-2'}>
                <Col sm={4} className={'control-label'}>Попросить подписать</Col>
                <Col sm={8}>
                    <button className={wait_sign==='ASKED' ? 'btn btn-outline-danger w-100' : 'btn btn-outline-primary w-100'}
                            disabled={!canAskSign}
                            onClick={()=> setisAskModal(true)}>
                        {wait_sign==='ASKED' ? `Отменить запрос подписания` : `Отправить на подпись специалисту`}
                    </button>

                        </Col>
                        </Row>: null}
                    {!exclude_fields?.includes('additional') ?
                        <Row className={'py-2'}>
                            <Col md={12}>
                                <InputField
                                    name={'additional'}
                                    value={() => additionText}
                                    label={() => 'Приложение'}
                                    disabled={allDisabled}
                            column={2}
                            onChange={handleChangeAddition}
                            errorMessages={name=>errorFields?.[name]}
                          />
                      </Col>
                  </Row> : null}
                <AsyncSelectField
                  name={'cert'}
                  value={name => selectedCert}
                  label={name => 'Сертификат'}
                  column={2}
                  debounceTimeout={DEBOUNCE_TIMEOUT}
                  onChange={handleChangeSelect}
                  loadOptions={(value, callback) => loadCertOptions(value, callback, setIsCryptoPlugin)}
                  placeholder="Выберите сертификат..."
                  noOptionsMessage={()=>'Не найдено действующих сертификатов'}
                  loadingMessage={()=>'Поиск сертификатов...'}
                  onMenuOpen={handleOnMenuOpen}
                  required={true}
                  loadAfterOpen={true}
                />
                {showSignModal && (
                  <ConfirmModal
                    label={'Подпись ЭЦП'}
                    text={'Для работы КриптоПро ЭЦП Browser plugin на данном сайте необходимо расширение для браузера. Убедитесь, что оно у Вас включено или установите его'}
                    handleCancel={handleHideSignModal}
                    isAlert={true}
                  />
      )}
                <Row className={'form-group py-2'}>
                    <Col sm={4} className={'control-label'}>ЭЦП</Col>
                    <Col sm={8}>
                        <button className={'btn btn-outline-primary w-100'}
                                disabled={!canSign}
                                onClick={handleSign}>Подписать с ЭЦП
                        </button>
                    </Col>
                </Row>
                {entity?.signed_fileinfo?.file || entity?.sig_fileinfo?.file ?
                  <Row className={'py-2'}>
                      {entity?.signed_fileinfo?.file ?
                        <span>
                      <b>Подписанный файл</b>
                      <a className={'ml-2'} href={entity?.signed_fileinfo.file}
                         target="_blank">{entity?.signed_fileinfo.name}
                         </a>
                                                </span> :
                        null}
                      {entity?.sig_fileinfo?.file ?
                        <span>
                    <b>Подпись</b>
                     <a className={'ml-2'} href={entity?.sig_fileinfo.file}
                        target="_blank">{entity?.sig_fileinfo.name}
                        </a>
                                                                       </span> :
                        null}
                  </Row> : null
                }
                <Row className={'form-group py-2'}> <Col className={'text-small text-muted'}>
                    Вложение добавляется в случае ручной подписи
                </Col>
                </Row>
            <Row className={'form-group py-2'}>
                    <Col sm={4} className={'control-label'}>Подписано без ЭЦП</Col>
                    <Col sm={8}>
                        <SimpleFileInput
                        name={'attachment_file'}
                        files={attachedFile(attachmentFile)}
                        setFiles={handleCreateFiles}
                        instantUpload={false}
                        allowDownload={true}
                        isConfirmDelete={false}
                        />
                    </Col>
            </Row>
            <Row className={'form-group py-2'}>
                <Col className={'text-small text-muted'}>
                    В случае почтового отправления укажите номер отправления
                </Col>
            </Row>
            <Row className={'py-2'}>
                <Col sm={12}>
                    <InputField
                      name={'send_by_post'}
                      value={() => sendByPost}
                      label={() => 'Отправлено по почте'}
                      column={2}
                      disabled={allDisabled}
                      onChange={handleChangeSendByPost}
                      errorMessages={name => errorFields[name]}
                    />
                </Col>
            </Row>
            </> : null}
            <Row className={'form-group pt-2 pb-4'}>
                <Col sm={6}>
                    {!notNeed&&wait_sign!=='ASKED'?
                        <button className={'btn btn-success'}
                                disabled={files_required && !attachmentFile && !entity?.sig_fileinfo?.file}
                                onClick={handleOpenSendModal}>
                            Отправить
                        </button>: null}
                    {!notNeed&&wait_sign==='ASKED'?
                    <button className={'btn btn-success'}
                            disabled={files_required && !attachmentFile && !entity?.sig_fileinfo?.file}
                            onClick={()=>handleAskSign('SIGNED')}>
                        Отметить подписанным
                    </button>: null}
                </Col>

                <Col sm={6} className={'text-end'}>

                    <button className={'btn btn-primary ml-2'}
                            disabled={!isChanged}
                            onClick={handleSave}>Сохранить
                    </button>
                </Col>
                {showSendModal && (
                  <ConfirmModal
                    label={labels?.confirm_send_label ?? 'Отправка'}
                    text={labels?.confirm_send_text ?? 'Вы действительно собираетесь отправить?'}
                    handleConfirm={handleSend}
                    handleCancel={handleCancelSendModal}
                  />)}
            </Row>
        </>
    )
}
