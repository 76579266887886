import React, { useState } from 'react';

export function BlockContainer({label, canHide = false, hide= true,children, rightAction, leftAction, hideOnLabel, hideOffLabel}) {
  const [isHide, setHide] = useState(canHide && hide)
  const base_cls = 'hide-btn text-nowrap'
  const classname = isHide ? `${base_cls} dropdown-toggle`: `${base_cls}`;

  const toggleHide = () =>{
    setHide(prevState => !prevState)
  }

  return (
    <div className="block-container full-width mb-5 w-100">
      <div className="block-label d-flex justify-content-between align-items-center">
        <label
        >
          {label}
        </label>
        {leftAction}
        {canHide ? <span className={classname} role="button" onClick={canHide ? toggleHide : null}>{isHide ? hideOffLabel??'Развернуть' : hideOnLabel??'Свернуть'}</span> : null}
        {rightAction}
      </div>
      {!isHide && children}
    </div>
  );
};
