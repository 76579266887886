import React from 'react';

import {FormControl} from 'react-bootstrap';
import {FieldContainer} from "./FieldContainer";

export function CheckBoxField({name, value, label, onChange, column, row, required, hideLabel, errorMessages, disabled = false}) {
  const realValue =  typeof value === 'function' ? value(name) : value;
  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages}}>
      <FormControl
        type="checkbox"
        className="form-check-input"
        name={name}
        checked={!!realValue}
        onChange={onChange}
        disabled={disabled}
      />
    </FieldContainer>
  )
}
