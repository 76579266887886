import React from 'react';
import {FormControl} from 'react-bootstrap';
import {FieldContainer} from "./FieldContainer";
import {SvgIcon, svgType} from "../Icons";

export function InputField({
                             name,
                             value,
                             label,
                             onChange,
                             column,
                             row,
                             required,
                             hideLabel,
                             errorMessages,
                             placeholder,
                             isClearable = false,
                             disabled = false,
                             canDisabledClear = false,
                             type = "text",
                             rightButton,
                             onEnter,
                             clearCallback,
                             customFieldClassName,
                             lableClassName,
                           }) {
  const fieldClassName = "d-flex align-items-start ";

  const handleClear = () => {
    clearCallback && clearCallback(name)
    onChange && onChange({target: {name, value: ''}});
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      onEnter && onEnter()
    }
  }
  return (
    <FieldContainer {...{
      name,
      label: label ? label(name): '',
      column,
      row,
      required,
      hideLabel,
      errorMessages,
      inputClassName: fieldClassName + customFieldClassName ?? '',
      lableClassName: lableClassName
    }}>
      <div className={'w-100 input-group'}>
        <FormControl
          type={type}
          name={name}
          disabled={disabled}
          value={value(name) ?? ''}
          placeholder={placeholder ?? ''}
          onChange={onChange}
          onKeyPress={handleEnterPress}
        />
        {isClearable && value(name) && (!disabled || canDisabledClear) &&
             <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={handleClear}
          title={'Очистить'}>
           <SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
        </button>
        }
        {rightButton}
      </div>
    </FieldContainer>
  )
}
