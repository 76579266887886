import React, {useEffect, useState} from 'react';
import { Row, Col, Button, HelpBlock } from 'react-bootstrap';

import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../constants";
import uniFetch from "../../utils/uniFetch";
import {SvgIcon, svgType} from "../../components/Icons";
import {InputField} from "../../components/Fields/InputField";
import {ConfirmModal, LoaderModal, ResponseModal} from "../../components/Modal";
import {PaginationInfo} from "../../components/PaginationInfo";
import {useDebounce} from 'use-debounce';
import {ResponseAlert} from "../../components/ResponseAlert";
import {labels} from "../fzmessage/fzmessage-detail";

function FieldModal({isOpen, onClose, children, title}) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content content container w-50" onClick={(e) => e.stopPropagation()}>
        <div
          className="block-label d-flex justify-content-between align-items-center modal-label">
          <label>
            {title}
          </label>
          <Button
              variant="secondary"
              type="button"
              className="btn btn-sm btn-outline-light"
              onClick={onClose}
              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
            </Button>
        </div>
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  );
}


function UserModal(props) {
  const [errorsPasswordFields, setErrorsPasswordFields] = useState({})
  const [fields, setFields] = useState({username: props.userLogin, password: '', password2: ''})
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeFields = (fname, e) => {
    const {name, value} = e.target;
    setFields(prevState => {
      return {...prevState, [fname]: value};
    });
  }

  const handleCancelButton = () => {
    setFields({})
    setErrorsPasswordFields({})
    props.onClose()
  }

  const handleSave = () => {
    setIsLoading(true)
    let cpfields = Object.assign({}, fields, {user_id: props.userId})
    if (cpfields.password === "") {
      delete cpfields['password']
    } else if (fields.password2 === "") {
      delete cpfields['password2']
    }
    uniFetch('/fz59/change-user-data/', 'POST', cpfields, false, true, true)
    .then(data => {
      setIsLoading(false);
      handleCancelButton()
    })
    .catch((data) => {
      setIsLoading(false);
      setErrorsPasswordFields({errors: data.errors})
    })
  }

  return (
      <FieldModal isOpen={props.isOpen} onClose={props.onClose} title="Изменить данные">
      <Row>
        {isLoading ?
          <div className="text-center">
            <div className="spinner-border text-primary m-5" role="status"
                 style={{width: '3rem', height: '3rem'}}>
              <span className="visually-hidden">Загрузка...</span>
            </div>
          </div> :
          <>
            {/*hack против автозаполнения бразуером полей при рендеринге*/}
            <input type="text" style={{display: 'none'}}/>
            <input type="password" style={{display: 'none'}}/>
            <Col sm={12} className={'fw-bold'}>Имя пользователя</Col>
            <Col sm={12} className={'py-2'}>{props.userLogin}</Col>
            <Col sm={12}>
            <InputField
                name="not"
                value={name => fields["username"]}
                label={name => "Новый логин"}
                type="text"
                required={false}
                onChange={(e) => handleChangeFields("username", e)}
                placeholder={'Можно оставить пустым'}
                column={2}
                row={2}
                autoComplete={'none'}
                errorMessages={name => errorsPasswordFields?.errors?.username ? [errorsPasswordFields?.errors?.username] : null}
            />
          </Col>
          <Col sm={12} className={'pt-2'}>
          <ul>
            <li>Логин можно оставить пустым, если менять его не нужно.</li>
          </ul>
        </Col>
          <Col sm={12}>
            <InputField
              name="notname"
              value={name => fields["password"]}
              label={name => "Пароль"}
              type="password"
              required={true}
              onChange={(e) => handleChangeFields("password", e)}
              column={2}
              row={2}
              errorMessages={name => errorsPasswordFields?.errors?.password ? [errorsPasswordFields?.errors?.password] : null}
            />
          </Col>
          <Col sm={12} className={'mt-3'}>
            <InputField
              name="notpr"
              value={name => fields["password2"]}
              label={name => "Повторите пароль"}
              type="password"
              required={true}
              onChange={(e) => handleChangeFields("password2", e)}
              column={2}
              row={2}
              errorMessages={name => errorsPasswordFields?.errors?.password2 ? [errorsPasswordFields?.errors?.password2] : null}
            />
          </Col>
        </>}
      </Row>
      <Row>
        <Col sm={12} className={'pt-2'}>
          <ul>
            <li>Пароль должен содержать как минимум 6 символов.</li>
            <li>Пароль должен содержать как минимум одну цифру.</li>
            <li>Пароль должен содержать как минимум одну букву в верхнем регистре и нижнем регистре.</li>
          </ul>
        </Col>
      </Row>
        <Row>
          {errorsPasswordFields.errors?.error && (
            <Col sm={12} className={'pt-4'}>
              <HelpBlock
                className={"alert alert-danger d-flex justify-content-center"}>{errorsPasswordFields.errors.error}</HelpBlock>
            </Col>
          )}
          <div className="d-flex justify-content-end">
            <Button bsStyle="danger" disabled={isLoading} className={'mx-2 my-3'} onClick={handleCancelButton}>Отменить</Button>
          <Button bsStyle="success" disabled={isLoading} className={'my-3'} onClick={handleSave}>Сохранить</Button>
        </div>
      </Row>
      </FieldModal>
)
}


function FZOrganizationUsersList(props) {
  const organizationId = props.organizationId;
  const limit = QUERY_LIMIT;

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userLogin, setUserLogin] = useState('');
  const [forDel, setForDel] = useState(null)
  const [errorsForDel, setErrorsForDel] = useState({})
  const [errorsFields, setErrorsFields] = useState({});
  const [unit_title, setUnitTitle] = useState('');
  const [responseStatus, setResponseStatus] = useState(0)
  const [search_title] = useDebounce(unit_title, DEBOUNCE_TIMEOUT);

  const fetchData = (page) => {
    setIsLoading(true);
    const offset = (page - 1) * limit;
    let url = `/fz59/auth-users/?limit=${limit}&offset=${offset}&organisation_id=${organizationId}`
    if (unit_title.length > 0) {
      url += `&username=${unit_title}`
    }
    uniFetch(url, 'GET', '', false, true, true)
      .then(data => {
        setTotalItems(data.count);
        setUsers(data.results)
      })
      .catch(data => {
      console.error(data)
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorsFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorsFields({error: 'Ошибка сервера'});
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(1);
  }, [search_title]);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / limit)) {
      setCurrentPage(currentPage + 1);
      fetchData(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchData(currentPage - 1);
    }
  };

  const handleChange = (user) => {
    setOpenModal(true)
    setUserId(user.id)
    setUserLogin(user.username)
  }

  const handleDelete = (id) => {
    setForDel(id)
  }

  const handleCancelDelete = (e) => {
    setForDel(null);
  }

  const handleConfirmDelete = () => {
    setIsLoading(true);
    const data = {
      user_id: forDel,
      is_active: false
    }
    uniFetch('/fz59/change-user-data/', 'POST', data, false, true, true)
    .then((data) => {
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === forDel ? { ...user, is_active: false } : user
        )
      );
    })
      .catch(data => {
        console.error(data)
        setErrorsForDel(data)
        if (data?.code && data?.errors) {
          setResponseStatus(data?.code)
          setErrorsFields(data?.errors);
        } else {
          setResponseStatus(500)
          setErrorsFields({error: 'Ошибка сервера'});
        }
      })
    .finally(() => {
      setIsLoading(false);
    });
    setForDel(null);
  }

  const handleModalClose = () => {
    setOpenModal(false)
    setUserId(null)
    setUserLogin('')
  }

  const optionsDate = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Moscow'
  };

  const formatedDate = (strDate) => {
    if (strDate === null || strDate === '') {
      return null
    }

    const date = new Date(strDate)
    return date.toLocaleString('ru-RU', optionsDate).replace(' г.', 'г.')
  }
  const handleCloseResponseModal = () => {
    setResponseStatus(0)
  }
  if (!users?.length && [403, 404, 500].includes(responseStatus)) return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <div className="text-center">
        <h2 className={'text-danger'}>{errorsFields?.detail ?? errorsFields?.error}</h2>
      </div>
    </div>
  )
  return (
    <div className="form-container">
      {isLoading ? <LoaderModal/> : null}
      <ResponseModal onClose={handleCloseResponseModal}
                     status={(!users?.length && responseStatus !== 403) ? responseStatus : null}>
        <ResponseAlert status={responseStatus} result={errorsFields} labels={labels}/>
      </ResponseModal>
      <div className="blocks-container">
        <div className="block-container full-width mb-5 w-100">
          <label className="block-label d-flex justify-content-between align-items-center">Пользователи</label>
          <InputField
            name={'code_title'}
            onChange={e => {
              setUnitTitle(e.target.value)
            }}
            isClearable={true}
            label={() => 'Пользователь'}
            value={() => unit_title}
            placeholder={"Введите..."}
          />
          <div className="card margin my-3 height-50em">
            <div className={'card-header py-3'}>
              <Row className={'fw-bold text-center'}>
                <Col sm={3}>Логин</Col>
                <Col sm={3}>Активен</Col>
                <Col sm={4}>Дата последнего входа</Col>
              </Row>
            </div>
            <div className="card-body select-area">
              {!isLoading && users?.map(user =>
                  <Row key={user.id} className={'py-2 can-select text-center'}>
                    <Col sm={3}>{user.username}</Col>
                    <Col sm={3}>{user.is_active ? "Да" : "Нет"}</Col>
                    <Col sm={4}>{formatedDate(user.last_login) ?? "-"}</Col>
                    <Col sm={2} className={'actions d-flex align-items-start justify-content-end'}>
                      <button type="button" className="btn btn-outline-success"
                              onClick={() => handleChange(user)}>
                        <SvgIcon type={svgType.edit} size={{width: 16, height: 16}}/>
                      </button>
                      <button type="button" className="btn btn-outline-danger ml-2"
                              onClick={() => handleDelete(user.id)}>
                        <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                      </button>
                    </Col>
                  </Row>
              )}
              {errorsForDel.errors && (
              <>
                <Row>
                  <Col sm={12} className={'pt-5 pb-4 justify-content-center text-center'}>
                    <HelpBlock className={"alert alert-danger d-flex justify-content-center"}>Произошла ошибка при снятии активности пользователя</HelpBlock>
                  </Col>
                </Row>
              </>
              )}
              {isLoading ?
                  <div className="text-center">
                    <div className="spinner-border text-primary m-5" role="status"
                         style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                    </div>
                  </div>
                  : null}
            </div>
            <div className={'card-footer'}>
              <Row>
                <Col md={4}>
                  <PaginationInfo
                    count={totalItems}
                    limit={limit}
                    offset={(currentPage - 1) * limit}
                  />
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-center">
                    <Button
                        onClick={handlePrevPage}
                        bsStyle="success"
                        className={'mx-2'}
                        disabled={currentPage === 1}>{'<'}
                    </Button>
                    <Button
                        onClick={handleNextPage}
                        bsStyle="success"
                        className={'mx-2'}
                        disabled={currentPage === Math.ceil(totalItems / limit)}>{'>'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {openModal? <UserModal isOpen={openModal} onClose={handleModalClose} userId={userId} userLogin={userLogin}/>: null}
          { forDel && (
            <ConfirmModal
              label={'Снятие активности пользователя'}
              text={'Вы действительно собираетесь снять активность пользователя?'}
              handleConfirm={handleConfirmDelete}
              handleCancel={handleCancelDelete}
            />
          )}
        </div>
      </div>
    </div>
  )

}

export default FZOrganizationUsersList
