import React from 'react';

export default function Pagination({count, limit, offset, callback}) {
  const totalPages = Math.ceil(count / limit);
  const currentPage = Math.floor(offset / limit) + 1;
  const pages = [];

  const handlePageChange = (page) => {
    const currentOffset = (page - 1) * limit
    callback(currentOffset)
  };

  const addPage = (page) => {
    pages.push(
      <li key={page} className={currentPage === page ? 'ex-active' : ''}>
        {currentPage !== page ? <a href="#" onClick={() => handlePageChange(page)}>{page}</a> : <span>{page}</span>}
      </li>
    );
  };

  const addEllipsis = (key) => {
    pages.push(<li key={key} className="ex-disabled"><span>...</span></li>);
  };

  if (totalPages > 1) {
    [1, 2].forEach(addPage);
  } else {
    addPage(1);
  }

  if (currentPage > 4 && totalPages > 5) {
    addEllipsis('start-ellipsis');
  }

  const startPage = Math.max(3, currentPage - 2);
  const endPage = Math.min(totalPages - 2, currentPage + 2);
  for (let i = startPage; i <= endPage; i++) {
    addPage(i);
  }

  if (currentPage < totalPages - 3 && totalPages > 5) {
    addEllipsis('end-ellipsis');
  }

  if (totalPages > 4) {
    [totalPages - 1, totalPages].forEach(addPage);
  } else if (totalPages === 4) {
    [3, 4].forEach(addPage);
  } else if (totalPages === 3) {
    addPage(3);
  }

  return (
    <ul className="ex-pagination" style={{margin: '5px 0 0 0'}}>
      <li className={currentPage === 1 ? 'ex-disabled' : ''}>
        <span onClick={() => currentPage !== 1 && handlePageChange(currentPage - 1)}>
          <i className="fa fa-chevron-left"></i>
        </span>
      </li>
      {pages}
      <li className={currentPage === totalPages ? 'ex-disabled' : ''}>
        <span onClick={() => currentPage !== totalPages && handlePageChange(currentPage + 1)}>
          <i className="fa fa-chevron-right"></i>
        </span>
      </li>
    </ul>
  );
};
