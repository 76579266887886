import React, {useState, useRef, useEffect} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/ru';
import {FieldContainer} from "./FieldContainer";
import {Button} from 'react-bootstrap';

export function CKEditorField({name, value, label, onChange, column, row, required, hideLabel, errorMessages, wrappable, disabled}) {
  const [wrapped, setIsWrapped] = useState(true);
  const editorConfiguration = {
    toolbar: ["undo", "redo", '|', "heading", '|', "bold", "italic", '|', 'alignment', '|', 'bulletedList', 'numberedList', 'outdent', 'indent'],
    // removePlugins: ['Heading'],
    language: 'ru',
    initialData: typeof value === 'function' ? value(name): value
  };

  const className = wrapped ? '' : 'wrapped-ck';
  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages, className}}>
      <CKEditor
        disabled={disabled}
        editor={DecoupledEditor}
        onInit={editor => {
          editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
        }}
        config={editorConfiguration}
        styles={{
          menu: provided => ({...provided, zIndex: 9999})
        }}
        onBlur={(e, editor) => {
          onChange(name, editor.getData());
        }}
      />
      {wrappable ?
      <div className="d-flex justify-content-end">
        <Button onClick={() => setIsWrapped(prevState => !prevState)}>
          {wrapped ? "Развернуть" : "Свернуть"}
        </Button>
      </div>
       :
       null}
    </FieldContainer>
  )
}
