import React, {useState} from 'react';
import uniFetch from "../../../utils/uniFetch";
import {ConfirmModal, FieldModal, LoaderModal, ResponseModal} from "../../../components/Modal";
import {ApplicantForm} from "./ApplicantForm";
import {SvgIcon, svgType} from "../../../components/Icons";
import {ResponseAlert} from "../../../components/ResponseAlert";

function formatApplicant(applicant, labels, modelDefinition) {

  const filteredApplicant = {};
  const postalFields = [];
  for (const key in applicant) {
    if (labels.hasOwnProperty(key)) {
      const children = modelDefinition?.model_definition?.[key]?.children;
      if (children?.length) {
        filteredApplicant[key] = children
          ?.filter(child => {
            if (child.slug) {
              if (Array.isArray(applicant[key])) {
                return applicant[key].some(item => item === child.slug);
              } else if (typeof applicant[key] === 'string') {
                return applicant[key] === child.slug;
              }
            }
            return false;
          })
          ?.map(item => item.name)
          ?.join(', ');
      }
      else {
        filteredApplicant[key] = applicant[key];
      }
    } else if (key.startsWith('postal_')) {
      postalFields.push(applicant[key]);
    }
  }

  if (postalFields.length > 0) {
    filteredApplicant.postal = postalFields.filter(item=>item).join(', ');
  }
  return filteredApplicant;
};

function defaultApplicantFields(data) {
  const defaultValues = {};

  for (const key in data) {
    if (data[key].default !== undefined) {
      defaultValues[key] = data[key].default;
    }
  }
  return defaultValues
}

function formatApplicantFields(data, definition) {
  for (const key in data) {
    if (data[key] == null && definition[key]?.default !== undefined) {
      data[key] = definition[key].default;
    }
  }
  return data
}
export function ApplicantTable({name, value, label, messageId, modelDefinition, onChange, onDelete, labels, canEdit = true}) {
  const applicants = value(name)
  const [isModal, setIsModal] = useState(false)
  const [editApplicant, setEditApplicant] = useState(defaultApplicantFields(modelDefinition(name)?.model_definition))
  const messageUrl = modelDefinition(name)?.model_definition.api_url
  const [responseStatus, setResponseStatus] = useState(0);
  const [errorFields, setErrorFields] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = (id, callback) => {
    setIsLoading(true)
    uniFetch(messageUrl + id + '/', 'DELETE').then((data) => {
      setResponseStatus(204)
      callback()
      onDelete(name, id)
    }).catch(data => {
      callback()
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => setIsLoading(false))
  }
  const handleCreate = () =>{
    setIsModal(true);
    setEditApplicant(defaultApplicantFields(modelDefinition(name)?.model_definition))
  }

  const handleChange = (item) =>{
    setIsModal(true);
    setEditApplicant(formatApplicantFields(item, modelDefinition(name)?.model_definition))
  }

  const handleCloseResponseModal = () => {
    setResponseStatus(0)
  }

  return (<div className="py-0 px-0">
       {isLoading ? <LoaderModal/>: null}
      <ResponseModal onClose={handleCloseResponseModal}
                     status={responseStatus}>
        <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
      </ResponseModal>
    <FieldModal isOpen={isModal} onClose={() => setIsModal(false)} title={'Информация о заявителе'}>
      <ApplicantForm
          item={editApplicant}
          name={name}
          labels={labels}
          modelDefinition={modelDefinition(name)}
          apiUrl={messageUrl}
          onClose={()=>setIsModal(false)}
          messageId={messageId}
          applicantId={editApplicant?.id}
          onChange={(name, newItem) => {
              setIsModal(false);
              onChange(name, newItem);
              setResponseStatus(200)
          }}/>
    </FieldModal>
    {applicants?.map(applicant => <div key={applicant.id} className="row block-table-row">
    <ApplicantRow
      applicant={applicant}
      tableApplicant={formatApplicant(applicant, label, modelDefinition(name))}
      label={label}
      onDelete={handleDelete}
      onChange={handleChange}
      canEdit={canEdit}
    />
    </div>)}
    <div className="py-3 px-2rem text-center">
      {canEdit ? <button type="button" className="btn btn-success" onClick={handleCreate}>+ Добавить заявителя</button>: null}
    </div>
  </div>)
}

function ApplicantRow({applicant, tableApplicant, label, onDelete, onChange, canEdit}) {
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const handleDelete = () =>{
   onDelete(applicant.id, ()=>setIsDeleteModal(false))
  }
  const handleCancel = () =>{
   setIsDeleteModal(false)
  }
  const handleModal = () =>{
   setIsDeleteModal(true)
  }

  return (
    <>
      {isDeleteModal && <ConfirmModal
        text={'Вы действительно собираетесь удалить заявителя?'}
        label={'Удаление заявителя'}
        handleConfirm={handleDelete}
        handleCancel={handleCancel}
      />}
      <div className="col-11">
        <div className="row">
          <div className="col-2 fw-bold">{label.last_name}</div>
          <div className="col-2 fw-bold">{label.first_name}</div>
          <div className="col-2 fw-bold">{label.middle_name}</div>
          <div className="col-1 fw-bold">{label.gender}</div>
          <div className="col-2 fw-bold">{label.phone_number}</div>
          <div className="col-3 fw-bold">{label.email}</div>
        </div>
        <div className="row row pt-1 pb-3">
          <div className="col-2">{tableApplicant.last_name || "Не установлено"}</div>
          <div className="col-2">{tableApplicant.first_name || "Не установлено"}</div>
          <div className="col-2">{tableApplicant.middle_name || "Не установлено"}</div>
          <div className="col-1">{tableApplicant.gender || "Не установлено"}</div>
          <div className="col-2">{tableApplicant.phone_number || "Не установлено"}</div>
          <div className="col-3">{tableApplicant.email || "Не установлено"}</div>
        </div>
        {applicant?.orgname ?
          <>
            <div className="row pt-2 pb-1">
              <div className="col-4 fw-bold">{label.orgname}</div>
              <div className="col-2 fw-bold">{label.inn }</div>
              <div className="col-6 fw-bold">{label.postal}</div>
            </div>
            <div className="row py-1">
              <div className="col-4">{tableApplicant.orgname || "Не установлено"}</div>
              <div className="col-2">{tableApplicant.inn || "Не установлено"}</div>
              <div className="col-6">{tableApplicant.postal || "Не установлено"}</div>
            </div>
          </> :
          <>
            <div className="row pt-2 pb-1">
              <div className="col-2 fw-bold">{label.citizenship}</div>
              <div className="col-2 fw-bold">{label.social_status}</div>
              <div className="col-2 fw-bold">{label.preference_status}</div>
              <div className="col-6 fw-bold">{label.postal}</div>

            </div>
            <div className="row py-1">
              <div className="col-2">{tableApplicant.citizenship || "Не установлено"}</div>
              <div className="col-2">{tableApplicant.social_status || "Не установлено"}</div>
              <div className="col-2">{tableApplicant.preference_status || "Не установлено"}</div>
              <div className="col-6">{tableApplicant.postal || "Не установлено"}
              </div>
            </div>
          </>
        }
      </div>
      { canEdit ? <div className="col-1 actions">
        <button className="btn btn-outline-success w-100" onClick={() => onChange(applicant)}>
          <SvgIcon type={svgType.edit} size={{width: 16, height: 16}}/>
        </button>
        <button type="button" className="btn btn-outline-danger w-100 my-2"
                onClick={handleModal}>
          <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
        </button>
      </div> : null}
    </>

  )
}
