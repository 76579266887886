import React, { useState, useRef, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { ConfirmModal } from "../../components/Modal";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const dragAndDropText = 'Перетащите файлы в эту область или';
const browseText = 'кликните по ней для добавления файла';

function getURL(input) {
  if (isValidURL(input)) {
    return input;
  } else {
    try {
      const obj = JSON.parse(input);
      if (obj.file) {
        return obj.file;
      }
    } catch (e) {
    }
  }
  return null;
}

function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

function downloadFile(file) {
  const link = document.createElement('a');
  link.href = getURL(file.serverId);
  link.setAttribute('download', file?.file?.name??'');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function createControllablePromise() {
  let resolve;
  let reject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolve,
    reject
  };
}

export default function FileInput(props) {

  const labels = {
    labelInvalidField: "Поле недействительно",
    labelFileTypeNotAllowed: "Недопустимый формат файла",
    fileValidateTypeLabelExpectedTypes: "Файл загружен не будет",
    labelFileWaitingForSize: "Ожидание размера",
    labelFileSizeNotAvailable: "Размер недоступен",
    labelFileLoading: "Загрузка",
    labelFileLoadError: "Ошибка загрузки",
    labelFileProcessing: "Обработка",
    labelFileProcessingComplete: "Обработка завершена",
    labelFileProcessingAborted: "Обработка прервана",
    labelFileProcessingError: "Ошибка обработки",
    labelFileProcessingRevertError: "Ошибка отмены обработки",
    labelFileRemoveError: "Ошибка удаления",
    labelTapToCancel: "Нажмите для отмены",
    labelTapToRetry: "Нажмите для повтора",
    labelTapToUndo: "Нажмите для отмены",
    labelButtonRemoveItem: "Удалить",
    labelButtonAbortItemLoad: "Отмена",
    labelButtonRetryItemLoad: "Повторить",
    labelButtonAbortItemProcessing: "Отмена",
    labelButtonUndoItemProcessing: "Отмена",
    labelButtonRetryItemProcessing: "Повторить",
    labelButtonProcessItem: "Обработка",
    labelMaxTotalFileSizeExceeded: "Превышен общий максимальный размер файлов",
    labelMaxFileSizeExceeded: "Превышен максимальный размер файла",
    labelMaxTotalFileSize: `Максимальный общий размер файлов ${props?.maxTotalFileSize ?? "100MB"}`,
    labelMaxFileSize: `Максимальный размер файла ${props?.maxFileSize ?? "100MB"}`
  };
  const [showModal, setShowModal] = useState(false);
  const [loadFiles, setLoadFiles] = useState([])
  const promiseRef = useRef(null);

  const handleBeforeRemoveFile = (item) => {
    if(!props?.isConfirmDelete) return true;
    setShowModal(true);
    promiseRef.current = createControllablePromise();
    return promiseRef.current.promise.then((result) => {
      if (Object.keys(item?.getMetadata()).length && result) {
        props.beforeRemoveFile(item?.getMetadata()?.id)
      }
      return result;
    });
  };

  const handleConfirm = () => {
    promiseRef.current.resolve(true);
    setShowModal(false);
  };

  const handleCancel = () => {
    promiseRef.current.resolve(false);
    setShowModal(false);
  };

  const handleProcessFile = (error, file) => {
    setLoadFiles(prevState => [...prevState, file])
  };

  const handleProcessFiles = (error, file) =>{

    props.onChangeFile(loadFiles)
    setLoadFiles([])
  }
  const handleAddFile = (error, file) => {

  };

  const handleOnUpdateFiles = (files) =>{
  props.setFiles(files);
  }
  return (
    <>
      <FilePond
        className={`file-input ${props?.disabled? 'disabled': ''}`}
        files={props.files}
        onupdatefiles={handleOnUpdateFiles}
        allowMultiple={true}
        allowRemove={!props?.disabled}
        allowAddFile={!props?.disabled}
        allowDrop={!props?.disabled}
        allowProcess={!props?.disabled}
        dropOnElement={!props?.disabled}
        instantUpload={props?.instantUpload ?? false}
        acceptedFileTypes={props.acceptedFileTypes}
        styleButtonRemoveItemPosition="right"
        maxFiles={10}
        maxTotalFileSize={props?.maxTotalFileSize ?? "100MB"}
        maxFileSize={props?.maxFileSize ?? "100MB"}
        maxParallelUploads={10}
        name={props?.name ?? "files"}
        server={props?.server}
        beforeRemoveFile={handleBeforeRemoveFile}
        onremovefile={props?.onremovefile}
        onprocessfile={props?.instantUpload && handleProcessFile}
        onprocessfiles={props?.instantUpload && handleProcessFiles}
        onactivatefile={(file) => props.allowDownload && downloadFile(file)}
        onaddfile={handleAddFile}
        labelIdle={props?.disabled ? '':
          dragAndDropText +
          ' <span class="filepond--label-action">' +
          browseText +
          '</span>'
        }
        {...labels}
        credits={false}
      />
      {showModal && (
        <ConfirmModal
          label={'Удаление файла'}
          text={'Вы действительно собираетесь удалить файл?'}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
}
