function getParamValue(paramString, paramName) {
  const pattern = new RegExp(`${paramName}=([^,]+)`);
  try {
    const match = paramString.match(pattern);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export function getCert() {
  return new Promise(function (resolve, reject) {
    cadesplugin.async_spawn(function* (args) {
      try {
        const certs = [];
        var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
        yield oStore.Open(
          cadesplugin.CADESCOM_CONTAINER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
        );

        var oCertificates = yield oStore.Certificates;
        var count = yield oCertificates.Count;
        var hasValidCert = false;
        for (let i = 1; i <= count; i++) {
          var cert = yield oCertificates.Item(i);
          try {
            var SerialNumber = yield cert.SerialNumber;
            var SubjectName = yield cert.SubjectName;
            var ValidFromDate = yield cert.ValidFromDate;
            var ValidToDate = yield cert.ValidToDate;
            var IsValid = yield cert.IsValid();
            var isValid = yield IsValid.Result;

            if (isValid) {
              certs.push({
                cn: getParamValue(SubjectName, 'CN'),
                sn: SerialNumber,
                validFrom: ValidFromDate,
                validTo: ValidToDate,
              });
              hasValidCert = isValid;
            }
          } catch (err) {
            continue;
          }
        }
        resolve(certs); // Передаем массив certs в Promise
      } catch (err) {
        reject(cadesplugin.getLastError(err)); // Передаем ошибку в Promise
      }
    }, resolve, reject);
  });
}

export function signFile(cert_name, file) {
  return new Promise((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      if (!window.FileReader) {
        reject('The File APIs are not fully supported in this browser.');
        return;
      }
      var oFile = file;
      var oFReader = new FileReader();

      if (typeof (oFReader.readAsDataURL) != "function") {
        reject("Method readAsDataURL() is not supported in FileReader.");
        return;
      }

      oFReader.readAsDataURL(oFile);

      oFReader.onload = function (oFREvent) {
        cadesplugin.async_spawn(function* (args) {
          var header = ";base64,";
          var sFileData = oFREvent.target.result;
          var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

          var sCertName = cert_name;
          if ("" == sCertName) {
            reject("Введите имя сертификата (CN).");
            return;
          }
          var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          yield oStore.Open(cadesplugin.CAPICOM_CURRENT_USER_STORE, cadesplugin.CAPICOM_MY_STORE,
            cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

          var oStoreCerts = yield oStore.Certificates;
          var oCertificates = yield oStoreCerts.Find(
            cadesplugin.CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, sCertName);
          var certsCount = yield oCertificates.Count;
          if (certsCount === 0) {
            reject("Certificate not found: " + sCertName);
            return;
          }
          var oCertificate = yield oCertificates.Item(1);
          var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          yield oSigner.propset_Certificate(oCertificate);
          yield oSigner.propset_CheckCertificate(true);

          var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
          yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
          yield oSignedData.propset_Content(sBase64Data);

          try {
            var sSignedMessage = yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES, true);
          } catch (err) {
            reject("Failed to create signature. Error: " + cadesplugin.getLastError(err));
            return;
          }

          yield oStore.Close();

          // Создаем файл с подписью
          var signedFile = new File([sSignedMessage], file.name + ".sig", { type: "text/plain" });
          resolve(signedFile); // Передаем файл с подписью в Promise
        });
      };
    });
  });
}


export function validateSignedFile(dataFile, signatureFile) {
  return new Promise((resolve, reject) => {
    cadesplugin.async_spawn(function* (args) {
      if (!window.FileReader) {
        reject('The File APIs are not fully supported in this browser.');
        return;
      }

      // Читаем файл с данными
      var dataReader = new FileReader();
      dataReader.readAsDataURL(dataFile);

      dataReader.onload = function (dataEvent) {
        cadesplugin.async_spawn(function* (args) {
          var header = ";base64,";
          var sDataFileData = dataEvent.target.result;
          var sBase64Data = sDataFileData.substr(sDataFileData.indexOf(header) + header.length);

          // Читаем файл с подписью
          var signatureReader = new FileReader();
          signatureReader.readAsText(signatureFile);

          signatureReader.onload = function (signatureEvent) {
            cadesplugin.async_spawn(function* (args) {
              var sSignedMessage = signatureEvent.target.result;

              var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
              try {
                yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
                yield oSignedData.propset_Content(sBase64Data);
                yield oSignedData.VerifyCades(sSignedMessage, cadesplugin.CADESCOM_CADES_BES, true);

                // Проверка подписи успешна
                resolve("Signature is valid.");
              } catch (err) {
                reject("Failed to verify signature. Error: " + cadesplugin.getLastError(err));
              }
            });
          };
        });
      };
    });
  });
}

