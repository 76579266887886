import React, {useState, useEffect} from 'react';
import {convertDateFormat} from "../../../utils/common";
import Pagination from "../../../components/Pagination";
import uniFetch from "../../../utils/uniFetch";
import {ConfirmModal, LoaderModal, ResponseModal} from "../../../components/Modal";
import {ResponseAlert} from "../../../components/ResponseAlert";
import {labels} from "../fzmessage-detail";
import { Row, Col, Button, Collapse } from 'react-bootstrap';
import {SvgIcon, svgType} from "../../../components/Icons";
import {ButtonGroupField} from "../../../components/Fields/ButtonGroupField";

const headers = [
  {key: 'reg_number', label: 'Рег. № / Дата', width: '10%'},
  {key: 'post_at', label: 'Дата поступления', width: '15%'},
  {key: 'status', label: 'Статус', width: '15%'},
  {key: 'reg_org', label: 'Регистратор', width: '30%'},
  {key: 'has_notifications', label: 'Есть уведомления', width: '15%'},
  {key: 'has_answers', label: 'Есть ответы', width: '15%'},
];

const status = {
  draft: {
    label: "Черновик", color: "primary"
  }, new: {
    label: "Новое обращение", color: "primary"
  }, registration: {
    label: "На регистрации", color: "warning"
  }, moderation: {
    label: "На модерации", color: "warning"
  }, work: {
    label: "В работе", color: "success"
  }, signing: {
    label: "На подписании", color: "warning"
  }, answered: {
    label: "Дан ответ исполнителем", color: "primary"
  }, closed: {
    label: "Закрыто", color: "danger"
  }
  };

function replaceDashesWithDots(dateString) {
  return dateString.replace(/-/g, ".");
}

function Answer({answer, message_hash, setMessage, setIsLoading, setErrorFields, setResponseStatus}) {
  const [selectedEvaluate, setSelectedEvaluate] = useState(null);

  const answerEvaluateHandler = () => {
    const formData = new FormData();
    formData.append('answer_id_hash', answer.id_hash)
    formData.append('evaluation_applicant_response', selectedEvaluate)
    const data = {answer_id_hash: answer.id_hash, evaluation_applicant_response: selectedEvaluate}
    const url = `/fz59/api/applicant-messages/${message_hash}/evaluate-answer/`;
    setIsLoading(true)
    uniFetch(url, 'PATCH', data, false, true, true)
      .then(() => {
        setSelectedEvaluate(null)
        setResponseStatus(200);
        setMessage((prev) => ({
          ...prev,
          answers: prev.answers.map(item => item.id_hash === answer.id_hash ? {
            ...item,
            can_evaluate: false
          } : item)

        }));
      })
      .catch(data => {
        if (data?.code && data?.errors) {
          setResponseStatus(data?.code)
          setErrorFields(data?.errors);
        } else {
          setResponseStatus(500)
          setErrorFields({error: 'Ошибка сервера'});
        }
      }).finally(() => setIsLoading(false));

  }
  const handleButtonChange = (name, e) => {
  let {value} = e.target;
  setSelectedEvaluate(value)

  }
  return (
    <div className={'mb-1'}>
      {selectedEvaluate &&
        <ConfirmModal
          label={'Оценка ответа'}
          text={'Вы действительно собираетесь оценить ответ?'}
          handleConfirm={answerEvaluateHandler}
          handleCancel={() => setSelectedEvaluate(null)}
        />
      }
      <span><b>{!!answer.income_number ? `№ ${answer.income_number}` :''} {!!answer.date_receipt ? `от ${replaceDashesWithDots(answer.date_receipt)}` :''}</b></span>
      <br/><span><b>Файлы: </b>
      {!!answer.addition_file && <a
        href={answer.addition_file}>{answer.addition_fileinfo.name}{answer.signed_file ? ',' : ''} </a>}
      {!!answer.signed_file && <a href={answer.signed_file}>{answer.signed_fileinfo.name} </a>}
                      </span>
      {answer?.can_evaluate && <div>
        <div>Оценка ответа</div>
        <ButtonGroupField
          name={'term'}
          value={name => selectedEvaluate}
          label={name => ''}
          hideLabel={true}
          column={2}
          onChange={handleButtonChange}
          children={name => answer?.evaluation_applicant_response_terms}
        />
      </div>}
    </div>)
}
export function MyMessageList({attrs}) {
  const attrUrl = attrs && attrs.getNamedItem && attrs.getNamedItem('data-mart-url') ? attrs.getNamedItem('data-mart-url').nodeValue : attrs.datamart ?? null;
  const idHash = attrs && attrs.getNamedItem && attrs.getNamedItem('data-id-hash') ? attrs.getNamedItem('data-id-hash').nodeValue : attrs.idHash ?? null;

  const [datamart, setDatamart] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [responseStatus, setResponseStatus] = useState(0);

  const handlePageChange = (offset) => {
    getList(offset)
  };

  const getList = (offset) => {
    const url = `${attrUrl}?limit=${datamart?.limit ?? 20}${offset ? `&offset=${offset}` : ''}${idHash ? `&id_hash=${idHash}` : ''}`;

    setIsLoading(true)
    uniFetch(url, 'GET', null, true, true, true).then((data) => {
      setDatamart(data);
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => setIsLoading(false));

  };

  useEffect(()=>{
    getList()
  },[])

  const handleCloseResponseModal = () => {
    setResponseStatus(0)
  }

  if (isLoading && !Object.keys(datamart).length) return (<LoaderModal/>)
  if (!Object.keys(datamart).length && [401, 403, 404, 500].includes(responseStatus)) return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <div className="text-center">
        <h2 className={'text-danger'}>{responseStatus == 401 ? 'Авторизируйтесь для просмотра своих обращений': errorFields?.detail ?? errorFields?.error}</h2>
      </div>
    </div>
  )
  if (!Object.keys(datamart).length) return (<></>)
  return (
    <div className="table-container">
      {isLoading ? <LoaderModal/> : null}
      <ResponseModal onClose={handleCloseResponseModal}
                     status={(Object.keys(datamart).length) ? responseStatus : null}>
        <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
      </ResponseModal>
      <table className="table table-striped">
        <thead>
        <tr>
          {headers.map((header) => (
            <th
              key={header.key}
              scope="col"
              style={{width: header.width || 'auto'}}>
              {header.label}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {datamart?.results?.map((item, rowIndex) => (
          <MessageItem
            key={rowIndex}
            item={item}
            attrUrl={attrUrl}
            setIsLoading={setIsLoading}
            setErrorFields={setErrorFields}
            setResponseStatus={setResponseStatus}
          />
        ))}
        </tbody>
        <tfoot>
        <tr>
          <th>Записей</th>
          <td>{(datamart?.count ? datamart?.offset : -1) + 1} - {Math.min(datamart?.offset + datamart?.limit || 0, datamart?.count || 0)} из {datamart?.count || 0}</td>
        </tr>
        </tfoot>
        <caption>
          <div className="ex-catalog-pagination">
            <Pagination
              count={datamart?.count}
              limit={datamart?.limit}
              offset={datamart?.offset}
              callback={handlePageChange}
            />
          </div>
        </caption>
      </table>
    </div>)
}

function MessageItem({item, attrUrl, setIsLoading, setErrorFields, setResponseStatus}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenShow, setIsOpenShow] = useState(false);
  const [detail, setDetail] = useState({})

  const toggleRow = (id) => {
    if (!isOpen) {
      setIsLoading(true)
      uniFetch(`${attrUrl + id}/`, 'GET', '', false, true, true).then((data) => {
        setIsOpen(true);
        setDetail(data);
      }).catch(data => {
        if (data?.code && data?.errors) {
          setResponseStatus(data?.code)
          setErrorFields(data?.errors);
        } else {
          setResponseStatus(500)
          setErrorFields({error: 'Ошибка сервера'});
        }
      }).finally(() => setIsLoading(false));
    } else {
      setIsOpen(false);
    }
  };
  const toggleRowShow = () => {
    setIsOpenShow(prevState => !prevState);
  };
  return (<React.Fragment>
    <tr
      className={(item?.has_notifications || item?.has_answers || item?.has_children) ? 'can-select' : ''}
      onClick={() => (item?.has_notifications || item?.has_answers || item?.has_children) && toggleRow(item?.id_hash)}
      aria-expanded={isOpen}
      aria-controls={'collapsedData' + item.id_hash}
    >
      <th scope="row">
        <span className={'text-block-ellipsis'}
              title={item?.reg_number || 'Детальная информация'}>{item?.reg_number || item?.id || 'НЕ ЗАДАН'}</span>
        {item?.reg_number && item?.reg_at ? <>
                    <span className={`text-muted`}
                          title={'Дата регистрации'}> от {convertDateFormat(item?.reg_at)}</span></>
          : null}
        {item?.income_numder && item?.income_at ?
          <div className={'pt-3 overflow-hidden'}>
                      <span className={`text-small`}
                            title={'Дата регистрации'}>
                        {item?.income_numder}
                      </span><br/>
            <span className={`text-muted`}
                  title={'Дата регистрации'}> от {convertDateFormat(item?.income_at)}</span>

          </div> : null}
      </th>
      <td>
        {item?.post_at ?
          convertDateFormat(item?.post_at)
          :
          '- '
        }
      </td>
      <td className={`table-${status[item?.status_for_applicant]?.color}`}
          title={status[item?.status_for_applicant]?.label}>
        {status[item?.status_for_applicant]?.label}

      </td>
      <td>{item?.reg_org}</td>
      <td>{item?.has_notifications ? 'Да' : 'Нет'}</td>
      <td>
        <div className={'d-flex justify-content-between'}>
          <div>{item?.has_answers ? 'Да' : 'Нет'}</div>
          {(item?.has_notifications || item?.has_answers || item?.has_children) && <div>
            <SvgIcon type={isOpen ? svgType.chevronUp : svgType.chevronDown}
                     size={{width: 16, height: 16}}
            />
          </div>}
        </div>
      </td>
    </tr>
    <tr></tr>
    <Collapse
      in={isOpen}
      onEntering={() => toggleRowShow()}
      onExited={() => toggleRowShow()}
    >
      <tr className={isOpenShow && 'show'} id={'collapsedData' + item.id_hash}>
        <td className='expanded-info' colSpan={12}>
          {!!detail?.id && <DetailMessage
            initialMessage={detail}
            setErrorFields={setErrorFields}
            setResponseStatus={setResponseStatus}
            setIsLoading={setIsLoading}
          />}
        </td>
      </tr>
    </Collapse>
  </React.Fragment>)
}

function DetailMessage({initialMessage, setErrorFields, setResponseStatus, setIsLoading}) {
  const [message, setMessage] = useState(initialMessage)
  return (
    <>
      {((!!message?.income_number) && (!!message?.answers.length || !!message?.notifications.length))}
      <Row>
        {!!message?.answers.length && <Col sm={6}>
          <div className={'mb-3'}><b className='d-block mb-2'>Ответы</b>
            {message?.answers.map(answer =>
              <Answer
                key={answer.id_hash}
                answer={answer}
                message_hash={message.id_hash}
                setMessage={setMessage}
                setErrorFields={setErrorFields}
                setResponseStatus={setResponseStatus}
                setIsLoading={setIsLoading}
              />)}
          </div>
        </Col>}
        {!!message?.notifications.length && <Col sm={6}>
          <div className={'mb-3'}><b className='d-block mb-2'>Уведомления</b>
            {message?.notifications.map(notification =>
              <div className={'mb-1'} key={notification.id_hash}>
                {!!notification.send_date &&
                  <span><b>Дата: </b> {replaceDashesWithDots(notification.send_date)} </span>}
                <br/><span><b>Файлы: </b>
                {!!notification.addition_file && <a
                  href={notification.addition_file}>{notification.addition_fileinfo.name}{notification.signed_file ? ',' : ''} </a>}
                {!!notification.signed_file &&
                  <a href={notification.signed_file}>{notification.signed_fileinfo.name} </a>}
                      </span>
              </div>)}
          </div>
        </Col>}
      </Row>
      {!!message?.children.length && <Row className={'mx-0'}>
        <b className={'p-0'}>Дочерние обращения</b>
        <table className="table table-striped">
          <tbody>
          {message?.children?.map(child =>
            <ChildMessage
              key={child.id_hash}
              message={child}
              setErrorFields={setErrorFields}
              setResponseStatus={setResponseStatus}
              setIsLoading={setIsLoading}
              isChild={true}
            />)}
          </tbody>
        </table>
      </Row>}
    </>)
}

function ChildMessage({message, setErrorFields, setResponseStatus, setIsLoading, isChild}) {
  return (
    <>
      <tr className={'border'}>
        <th scope="row" style={{width: '10%'}}>
            <span className={'text-block-ellipsis'}
                  title={message?.reg_number || 'Детальная информация'}>{message?.reg_number || message?.id || 'НЕ ЗАДАН'}</span>
          {message?.reg_number && message?.reg_at ? <>
                    <span className={`text-muted`}
                          title={'Дата регистрации'}> от {convertDateFormat(message?.reg_at)}</span></>
            : null}
          {message?.income_numder && message?.income_at ?
            <div className={'pt-3 overflow-hidden'}>
                      <span className={`text-small`}
                            title={'Дата регистрации'}>
                        {message?.income_numder}
                      </span><br/>
              <span className={`text-muted`}
                    title={'Дата регистрации'}> от {convertDateFormat(message?.income_at)}</span>

            </div> : null}
        </th>
        <td style={{width: '15%'}}>
          {message?.post_at ?
            convertDateFormat(message?.post_at)
            :
            '- '
          }
        </td>
        <td style={{width: '15%'}} className={`table-${status[message?.status_for_applicant]?.color}`}
            title={status[message?.status_for_applicant]?.label}>
          {status[message?.status_for_applicant]?.label}

        </td>
        <td style={{width: '30%'}}>{message?.reg_org}</td>
        <td style={{width: '15%'}}>{message?.notifications.length ? 'Да' : 'Нет'}</td>
        <td style={{width: '15%'}}>
          <div className={'d-flex justify-content-between'}>
            <div>{message?.answers.length ? 'Да' : 'Нет'}</div>
          </div>
        </td>
      </tr>
      <tr/>
      <tr className={'border'}>
        {(!!message?.answers.length || !!message?.notifications.length || !!message?.children.length) &&
          <td className={'expanded-info' + isChild ? `px-0${!!message?.children.length ? ' pb-0':''}`:''} colSpan={12}>
            <DetailMessage
              initialMessage={message}
              setErrorFields={setErrorFields}
              setResponseStatus={setResponseStatus}
              setIsLoading={setIsLoading}
            />
          </td>}
      </tr>
    </>
  )
}
