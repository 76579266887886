import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../../constants";
import uniFetch from "../../../utils/uniFetch";
import {InputField} from "../../../components/Fields/InputField";
import {HighlightText} from "../../../components/HighlightText";
import React, {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import { FieldModal} from "../../../components/Modal";
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import {convertDateFormat} from "../../../utils/common";
import {PaginationInfo} from "../../../components/PaginationInfo";

export function DuplicateForm({ messageId, isModal, setIsModal, permsUnit, orgunit, firstApplicant, onSave}) {
  const [newItem, setNewItem] = useState({})
  const [search, setSearch] = useState(firstApplicant?.last_name ?? '')
  const [debounceSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);
  const [searchResult, setSearchResult] = useState({})
  const [errorFields, setErrorFields] = useState({});
  const [ready, setReady] = useState(true);
  const duplicateUrl = '/edw/api/data-marts/fzmessage-dm/entities/';
  const selectedCls = "text-success-emphasis bg-success-subtle py-2";
  const searchErrorMessage = "Произошла ошибка поиска. Пожалуйста обновите страницу (F5) и попробуйте еще раз!";

  useEffect(() => {
    if (duplicateUrl && isModal && ready) {
      const params = new URLSearchParams();
      params.append('search', search);
      params.append('limit', QUERY_LIMIT);
      params.append('current_year', true);
      params.append('exclude_self', messageId);

      if(!permsUnit?.can_root) {
         params.append('r_o', orgunit.org.id);
      }
      const paramsStr = `${params.toString() ? `?${params.toString()}` : ''}`;
      setReady(false);
      uniFetch(duplicateUrl + paramsStr)
        .then((data) => {
          setSearchResult(data);
          setReady(true);
          setErrorFields({});
        })
        .catch((error) => {
          setReady(true);
          setErrorFields(prevState => {
            return Object.assign({}, prevState, {'search': error})
          })
        });
    }
  }, [debounceSearch, isModal])

  const handlePagination = (url) => {
    uniFetch(url).then((data) => setSearchResult(data))
  }

  useEffect(() => {
    if (isModal) {
      setNewItem({})
      setSearch(firstApplicant?.last_name ?? '')
      setSearchResult({})
    }
  }, [isModal])

  const handleChange = (name, value) => {
    setNewItem(prevState => {
      return {...prevState, [name]: value}
    })
  }

  const handlePatch = () => {
    onSave(newItem?.duplicate)
  }

  const handleCanCreate = () => {
    return !newItem?.duplicate
  }

  return (
    <FieldModal isOpen={isModal} onClose={() => setIsModal(false)} title={'Установка повторного'}>
      <InputField
        name="search"
        value={() => search}
        label={name => 'Поиск'}
        required={true}
        column={1}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        placeholder="Введите..."
      />
      <Row>
        <h4>Сделать повторным для для</h4>
      </Row>
      <div className="card my-3">
        <div className={'card-header py-3'}>
          <Row className={'fw-bold'}>
            <Col sm={2}>Номер</Col>
            <Col sm={2}>ФИО</Col>
            <Col sm={3}>Аннотация</Col>
            <Col sm={3}>Адрес</Col>
            <Col sm={2}>Дата</Col>
          </Row>
        </div>
        <div className="card-body select-area">
          {errorFields?.search ?
            <Row>
              <Col><h4 className={'text-center text-danger'}>{searchErrorMessage}</h4></Col>
            </Row> : null}
          {!errorFields?.search && ready && searchResult?.results?.objects?.map(item =>
            <Row key={item.id}
                 className={newItem['duplicate'] === item.id ? selectedCls : 'py-2 can-select'}
                 onClick={() => handleChange('duplicate', item.id)}>
              <Col sm={2}><HighlightText text={item.extra?.reg_number ?? ''} highlight={search}/></Col>
              <Col sm={2}><HighlightText text={item.extra?.fio ?? ''} highlight={search}/></Col>
              <Col sm={3}>{item.extra?.annotation ?? ''}</Col>
              <Col sm={3}>{item.extra?.postal_address ?? ''}</Col>
              <Col sm={2}>{convertDateFormat(item.extra?.reg_at)}</Col>
            </Row>
          )}
          {!ready ?
            <div className="text-center">
              <div className="spinner-border text-primary m-5" role="status" style={{width: '3rem', height: '3rem'}}>
                <span className="visually-hidden">Загрузка...</span>
              </div>
            </div>
            : null}
        </div>
        <div className={'card-footer'}>
          <Row>
            <Col md={4}>
              <PaginationInfo
                count={searchResult?.count}
                limit={searchResult?.limit}
                offset={searchResult?.offset}
              />
            </Col>
            <Col md={4}>
              <div className="d-flex justify-content-center">
                <Button bsStyle="success"
                        className={'mx-2'}
                        disabled={!searchResult?.previous}
                        onClick={() => handlePagination(searchResult?.previous)}>{'<'}</Button>
                <Button bsStyle="success"
                        disabled={!searchResult?.next}
                        className={'mx-2'}
                        onClick={() => handlePagination(searchResult?.next)}>{'>'}</Button>
              </div>
            </Col>
            <Col md={4}>
              <div className={'text-end'}>
                <Button bsStyle="danger" className={'mx-2'} onClick={() => setIsModal(false)}>Отменить</Button>
                <Button bsStyle="success" className={'mx-2'} disabled={handleCanCreate()}
                        onClick={handlePatch}>Установить</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </FieldModal>
  )
}
