import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  Row,
  HelpBlock,
} from 'react-bootstrap';
import uniFetch from "../../utils/uniFetch";
import {CKEditorField} from "../../components/Fields/CKEditorField";
import {SvgIcon, svgType} from "../../components/Icons";
import {InputField} from "../../components/Fields/InputField";
import {ConfirmModal} from "../../components/Modal";
import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../constants";
import {useDebounce} from 'use-debounce';
import {PaginationInfo} from "../../components/PaginationInfo";

const fullColumnSize = 12

function FieldModal({isOpen, onClose, children, title}) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content content container" onClick={(e) => e.stopPropagation()}>
        <div
          className="block-label d-flex justify-content-between align-items-center modal-label">
          <label>
            {title}
          </label>
          <Button
              variant="secondary"
              type="button"
              className="btn btn-sm btn-outline-light"
              onClick={onClose}
              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
            </Button>
        </div>
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  );
};


function truncateText(text, maxLength) {
  if (!text) {
    return '-';
  }
  if (text?.length <= maxLength) {
    return text;
  }
  let truncatedText = text.slice(0, maxLength);
  let lastSpaceIndex = truncatedText.lastIndexOf(' ');
  if (lastSpaceIndex !== -1) {
    truncatedText = truncatedText.slice(0, lastSpaceIndex);
  }
  return truncatedText + '...';
}

function FZOrganizationTemplates(props) {
  const [dataList, setDataList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [forDel, setForDel] = useState(null)
  const [errorsFields, setErrorsFields] = useState({});
  const [fieldsCreate, setFieldsCreate] = useState({})
  const [editTmp, setEditTmp] = useState({})
  const [paginationInfo, setPaginationInfo] = useState([])
  const [paginationUrls, setPaginationUrls] = useState([null, null])
  const [unit_title, setUnitTitle] = useState('');

  const organizationId = props.organizationId
  const orgunit = props?.orgunit
  const orgParent = props?.orgParent

  const [search_title] = useDebounce(unit_title, DEBOUNCE_TIMEOUT);

  const is_same_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === Number(organizationId));
  }

  const canEdit = () => {
    if (orgunit?.permissions?.can_root) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org()) return true;
    if (orgParent && orgunit?.permissions?.can_all && orgunit?.all_orgs?.some(obj => obj === orgParent)) return true;
    return false
  };

  const canEditValue = canEdit();
  const fetchData = () => {
    setIsLoading(true);
    let url = `/fz59/api/organizationtemplate/?limit=${QUERY_LIMIT}&org=` + organizationId;
    if (unit_title.length > 0) {
      url += `&code_title=${unit_title}`
    }
    uniFetch(url).then(data => {
      setDataList(data.results);
      setPaginationUrls([data.previous, data.next])
      setPaginationInfo([data?.count, data?.limit, data?.offset])
    }).catch(error => {
      console.error("Error fetching data:", error);
    }).finally(()=>setIsLoading(false));
  }

  useEffect(() => {
    fetchData();
  }, [search_title]);

  useEffect(() => {
    if (isModal) {
      setFieldsCreate({})
    }
  }, [isModal])

  const handleConfirmDelete = () => {
    setIsLoading(true);
    uniFetch('/fz59/api/organizationtemplate/' + forDel + '/', 'DELETE').then((data) => {
      setDataList(dataList.filter(item => item.id !== forDel))
    }).finally(()=>setIsLoading(false))
    setForDel(null);
  }

  const handleCancelDelete = (e) => {
    setForDel(null);
  }

  const handleModelClose = () => {
    setIsModal(false)
    setFieldsCreate({})
    setEditTmp({})
  }

  const handleFields = (e) => {
    const {name, value} = e.target;
    setFieldsCreate(prevState => {
      return {...prevState, [name]: value}
    })
  }

  const handleChangeDescription = (name, value) => {
    setFieldsCreate(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleDelete = (id) => {
    setForDel(id)
  }

  const handleCreate = () => {
    setIsModal(true);
  }

  const handleCreatePost = () => {
    fieldsCreate['organization'] = organizationId
    setIsLoading(true);
    uniFetch('/fz59/api/organizationtemplate/', 'POST', fieldsCreate, false, true, true).then((data) => {
      setDataList(prevDataList => [...prevDataList, data]);
      setIsModal(false)
      setErrorsFields({})
    }).catch(data => {
      setErrorsFields({errors: data.errors})
    }).finally(()=>setIsLoading(false))
  }

  const handleChange = (item) => {
    setEditTmp(item)
    setIsModal(true);
  }

  const handleChangePatch = (item) => {
    setIsLoading(true);
    uniFetch('/fz59/api/organizationtemplate/' + item.id + '/', 'PATCH', fieldsCreate, false, true, true).then((data) => {
      handleModelClose()
      setDataList(prevDataList =>
        prevDataList.map(item => (item.id === data.id ? data : item))
      );
      setErrorsFields({})
    }).catch(data => setErrorsFields({errors: data.errors}
    )).finally(()=>setIsLoading(false))
  }

  const removeHtmlTags = (text) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const handlePagination = (url) => {
    setIsLoading(true);
    uniFetch(url).then(data => {
      setDataList(data.results);
      setPaginationUrls([data.previous, data.next])
      setPaginationInfo([data?.count, data?.limit, data?.offset])
    }).catch(error => {
      console.error("Error fetching data:", error);
    }).finally(()=>setIsLoading(false));
  }
  return (
    <div className="form-container">
      <div className="blocks-container">
        {
          canEditValue &&
          <div className="w-100">
            <div className={'d-flex justify-content-end'}>
              <button type="button" className="btn btn-success mb-3"
                      onClick={handleCreate}>
                + Добавить шаблон
              </button>
            </div>
          </div>
        }
        <div className="block-container full-width mb-5 w-100">
          <label className="block-label d-flex justify-content-between align-items-center">Список бланков резолюций,
            ответов и напоминаний организации</label>
          <InputField
            name={'code_title'}
            onChange={e => {
              setUnitTitle(e.target.value)
            }}
            isClearable={true}
            label={() => 'Поиск'}
            value={() => unit_title}
            placeholder={"Введите..."}
          />
          <div className="card margin my-3 height-50em">
            <div className={'card-header py-3'}>
              <Row className={'fw-bold'}>
                <Col sm={2}>Код</Col>
                <Col sm={4}>Наименование</Col>
                <Col sm={4}>Текст</Col>
              </Row>
            </div>
            <div className="card-body select-area">
              {!isLoading ? dataList?.map(item =>
                <Row key={item.id}
                     className={'py-2 can-select'}
                >
                  <Col sm={2}>{item.code}</Col>
                  <Col sm={4}>{item.title}</Col>
                  <Col sm={4}>{removeHtmlTags(truncateText(item.short_text, 70))}</Col>
                  <Col sm={2} className={'actions d-flex align-items-start'}>
                    <button type="button" className="btn btn-outline-success"
                            onClick={() => handleChange(item)}>
                      <SvgIcon type={!canEditValue ? svgType.eye : svgType.edit} size={{width: 16, height: 16}}/>
                    </button>
                    {
                      canEditValue &&
                      <button type="button" className="btn btn-outline-danger ml-2"
                              onClick={() => handleDelete(item.id)}>
                        <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                      </button>
                    }
                  </Col>
                </Row>
              ):<div className="text-center">
                  <div className="spinner-border text-primary m-5" role="status"
                       style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                  </div>
                </div>}
            </div>
            <div className={'card-footer'}>
              <Row>
                <Col md={4}>
                  <PaginationInfo
                    count={paginationInfo?.[0]}
                    limit={paginationInfo?.[1]}
                    offset={paginationInfo?.[2]}
                  />
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-center">
                    <Button bsStyle="success"
                            className={'mx-2'}
                            disabled={!paginationUrls[0]}
                            onClick={() => handlePagination(paginationUrls[0])}>{'<'}</Button>
                    <Button bsStyle="success"
                            disabled={!paginationUrls[1]}
                            className={'mx-2'}
                            onClick={() => handlePagination(paginationUrls[1])}>{'>'}</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <FieldModal isOpen={isModal} onClose={handleModelClose} title={'Добавить шаблон'}>
              <Row className="mb-3">
                <Col sm={6}>
                  <InputField
                    name="code"
                    value={name => fieldsCreate?.[name] ?? editTmp?.[name] ?? ""}
                    label={name => 'Код'}
                    required={true}
                    column={2}
                    errorMessages={name => errorsFields.errors?.[name]}
                    onChange={handleFields}
                    disabled={!canEditValue}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    name="title"
                    value={name => fieldsCreate?.[name] ?? editTmp?.[name] ?? ""}
                    label={name => 'Наименование'}
                    required={true}
                    column={2}
                    errorMessages={name => errorsFields.errors?.[name]}
                    onChange={handleFields}
                    disabled={!canEditValue}
                  />
                </Col>
              </Row>
              <CKEditorField
                name={'text'}
                value={name => fieldsCreate[name] ?? editTmp[name]}
                label={name => 'Текст'}
                required={true}
                hideLabel={false}
                column={1}
                wrappable={true}
                onChange={handleChangeDescription}
                errorMessages={name => errorsFields.errors?.[name]}
                disabled={!canEditValue}
              />
              {errorsFields.errors &&
                <HelpBlock className="alert alert-danger">Произошла ошибка при отправке данных.</HelpBlock>}
            <Row>
              <div className="d-flex justify-content-end">
                {canEditValue ?
                  <>
                    <Button
                      bsStyle="danger"
                      className={'mx-2 my-3'}
                      onClick={() => {
                        setIsModal(false)
                        setErrorsFields({})
                        setEditTmp({})
                      }}>
                      Отменить
                    </Button>
                    {Object.keys(editTmp).length > 0 ?
                      <Button
                        bsStyle="success"
                        className={'my-3'}
                        onClick={() => handleChangePatch(editTmp)}>
                        Сохранить
                      </Button>
                      : <Button
                        bsStyle="success"
                        className={'my-3'}
                        onClick={handleCreatePost}>
                        Сохранить
                      </Button>
                    }
                  </> :
                  <Button
                    bsStyle="primary"
                    className={'my-3'}
                    onClick={() => {
                      setIsModal(false)
                    }}>Закрыть
                  </Button>
                }
              </div>
            </Row>
            </FieldModal>
        </div>
        { forDel && (
          <ConfirmModal
            label={'Удаление шаблона'}
            text={'Вы действительно собираетесь удалить шаблон?'}
            handleConfirm={handleConfirmDelete}
            handleCancel={handleCancelDelete}
          />
        )}
      </div>
    </div>
  )
}

export default FZOrganizationTemplates
