import uniFetch from "./uniFetch";
import cookie from 'react-cookies';

export const filepondLabels = {
  labelInvalidField: "Поле недействительно",
  labelFileTypeNotAllowed: "Недопустимый формат файла",
  fileValidateTypeLabelExpectedTypes: "Файл загружен не будет",
  labelFileWaitingForSize: "Ожидание размера",
  labelFileSizeNotAvailable: "Размер недоступен",
  labelFileLoading: "Загрузка",
  labelFileLoadError: "Ошибка загрузки",
  labelFileProcessing: "Обработка",
  labelFileProcessingComplete: "Обработка завершена",
  labelFileProcessingAborted: "Обработка прервана",
  labelFileProcessingError: "Ошибка обработки",
  labelFileProcessingRevertError: "Ошибка отмены обработки",
  labelFileRemoveError: "Ошибка удаления",
  labelTapToCancel: "Нажмите для отмены",
  labelTapToRetry: "Нажмите для повтора",
  labelTapToUndo: "Нажмите для отмены",
  labelButtonRemoveItem: "Удалить",
  labelButtonAbortItemLoad: "Отмена",
  labelButtonRetryItemLoad: "Повторить",
  labelButtonAbortItemProcessing: "Отмена",
  labelButtonUndoItemProcessing: "Отмена",
  labelButtonRetryItemProcessing: "Повторить",
  labelButtonProcessItem: "Обработка",
  labelMaxTotalFileSizeExceeded: "Превышен максимальный размер файла",
  labelMaxTotalFileSize: "Максимальный общий размер файлов 10MB",
};

export function FilePodServer(entityId, url = "/edw/api/entities-files/") {
  const params = {
    url,
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRFToken': '',
    }
  }
  params.headers['X-CSRFToken'] = cookie.load('csrftoken');
  return {
    process: {
      ...params,
      withCredentials: false,
      ondata: (formData) => {
        formData.append('entity', entityId);
        return formData;
      },
    },
    revert: (uniqueFileId, load, error) => {
      const fileId = JSON.parse(uniqueFileId).id
      const deleteUrl = `${url}${fileId}/`;
      fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'X-CSRFToken': cookie.load('csrftoken'),
        },
        credentials: 'include',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('File deletion failed');
          }
          load();
        })
        .catch(err => {
          error(err);
        });
    },
  }
}

export const handleRemoveFiles = async (id) => {
  const response = await uniFetch(`/fz59/api/files/${id}/`, 'DELETE', null, true, false);
  if (response?.status !== 204) {
    return false
  } else {
    return true
  }
};


export function attachedFile(metadata) {
  if(!metadata) return [];
  return [{
    source: metadata.file,
    options: {
      type: 'local',
      file: {
        name: metadata.name,
        size: metadata.size,
        type: metadata.type,
      },
      metadata: {
        original_filename: metadata.name,
        file_size: metadata.size,
        mime_type: metadata.type,
        file: metadata.file
      },
    },
  }];
}

export function formattedFile(metadata) {
  return {
    source: metadata.file,
    options: {
      type: 'local',
      file: {
        name: metadata.original_filename,
        size: metadata.file_size,
        type: metadata.mime_type,
      },
      metadata: {
        id: metadata.id,
        original_filename: metadata.original_filename,
        file_size: metadata.file_size,
        mime_type: metadata.mime_type,
        file_serial: metadata.file_serial,
        file: metadata.file
      },
    },
  };
}
export function formatFiles(files) {
  if(!files?.length) return [];
  return files.map(file => {
    const metadata = file?.options?.metadata ? file.options.metadata : file?.getMetadata ? file.getMetadata() : file;
    if(!metadata?.id) {
      return file
    }
    return formattedFile(metadata)
  });
}
