import React from 'react';
import {DuplicateTable} from "./DuplicateTable";
import {BlockContainer} from "../../../components/Fields/BlockContainer";
import {
  Row,
} from 'react-bootstrap';
import {AppealTable} from "./AppealTable";
import {ApplicantTable} from "./ApplicantTable";
import {applicantTableLabels} from "../fzmessage-detail";
import {ResponsibleTable} from "../responsible-group-tab";
import {convertDateFormat} from "../../../utils/common";

export function findNamesBySlugs(obj, targetSlugs) {
  let result = [];

  function traverse(node) {
    if (node && node?.slug && targetSlugs.includes(node?.slug)) {
      result.push(node.name);
    }

    if (node?.children && node.children.length > 0) {
      for (let child of node?.children) {
        traverse(child);
      }
    }
  }

  traverse(obj);
  return result.length ? result.join(', ') : targetSlugs.join(', ');
}


function ReadField({name, title, value}) {
  const realValue = typeof value === 'function' ? value(name) : value;
  const realTitle = typeof title === 'function' ? title(name) : title;

  return (<div className="row pt-4">
    <div className="col-sm-12">
      <div className="row"><label className="col-md-4 col-12 fw-bold text-end">{realTitle}</label>
        <div className="col-md-8 col-12"><span dangerouslySetInnerHTML={{__html: realValue}}></span></div>
      </div>
    </div>
  </div>)
}

function getResponsibleGroupsData(responsible) {
  let res = {};
  let max = 0;


  responsible.forEach(item => {
    if (item.group > max) {
      max = item.group
    }
    if (Object.hasOwn(res, item.group)) {
      res[item.group].responsible.push(item)
    } else {
      res[item.group] = {
        title: 'Группа исполнителей ' + item.group,
        responsible: [item],
        notifications: [],
        can_delete: false
      }
    }
  });
  return res
}

export function ReadDetail({fields, responsible, labels, modelDefinition, messageId}) {
  return (
    <>
      <BlockContainer label='Сведения обращения'>
        <ReadField
          name={'reg_organisation'}
          title={name => labels[name]}
          value={name => modelDefinition[name]?.current_value}
        />
        <ReadField
          name={'state'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'reg_number'}
          title={name => labels[name]}
          value={name => fields[name]}
        />
        <ReadField
          name={'post_at'}
          title={name => labels[name]}
          value={name => convertDateFormat(fields[name])}
        />
        <ReadField
          name={'appeal_loan_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'applicant_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'income_number'}
          title={name => labels[name]}
          value={name => fields[name]}
        />
        <ReadField
          name={'postal_address'}
          title={name => labels[name]}
          value={name => fields[name]}
        />
        <ReadField
          name={'destination_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'frequency_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'income_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'region_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], [fields[name]])}
        />
        <ReadField
          name={'appeal_chars_type'}
          title={name => labels[name]}
          value={name => findNamesBySlugs(modelDefinition[name], fields[name])}
        />
        <ReadField
          name={'annotation'}
          title={name => labels[name]}
          value={name => fields[name]}
        />
        <ReadField
          name={'description'}
          title={name => labels[name]}
          value={name => fields[name]}
        />
      </BlockContainer>
      <BlockContainer label='Прикрепленные файлы'>
        {fields?.['files']?.map(file => <Row key={file?.serverId}><a href={file?.serverId} target='_blank'>{file?.filename}</a></Row>)}
      </BlockContainer>
      <BlockContainer label='Повторные сообщения'>
        <DuplicateTable
          duplicates={fields['duplicates']}
          modelDefinition={modelDefinition}/>
      </BlockContainer>
      <BlockContainer label='Характер обращения'>
        <AppealTable
          name={'problems'}
          fields={fields}
          modelDefinition={name => modelDefinition[name]}
          messageId={messageId}
          canEdit={false}
        />
      </BlockContainer>
      <BlockContainer label='Заявители'>
        <ApplicantTable
          name="applicants"
          value={name => fields[name]}
          label={applicantTableLabels}
          messageId={messageId}
          modelDefinition={name => modelDefinition[name]}
          canEdit={false}
        />
      </BlockContainer>

      {Object.entries(getResponsibleGroupsData(responsible)).map(([key, value]) =>
        <BlockContainer label={value.title} key={key}>
          <ResponsibleTable
            name="responsible"
            value={value?.responsible}
            message_problems={fields['problems']}
            label={applicantTableLabels}
            messageId={messageId}
            canEdit={false}
            />
        </BlockContainer>)}

    </>
  )
}
