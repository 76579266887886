import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  Row,
  HelpBlock,
} from 'react-bootstrap';
import uniFetch from "../../utils/uniFetch";
import {SvgIcon, svgType} from "../../components/Icons";
import {InputField} from "../../components/Fields/InputField";
import {ConfirmModal} from "../../components/Modal";
import {FilePodServer, formatFiles} from "../../utils/filepond";
import {SimpleFileInput} from "../../components/Fields/SimpleFileField";
import {PaginationInfo} from "../../components/PaginationInfo";
import {DEBOUNCE_TIMEOUT} from "../../constants";
import {useDebounce} from 'use-debounce';

const fullColumnSize = 12

function FieldModal({isOpen, onClose, children, title}) {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content content container" onClick={(e) => e.stopPropagation()}>
        <div
          className="block-label d-flex justify-content-between align-items-center modal-label">
          <label>
            {title}
          </label>
          <Button
              variant="secondary"
              type="button"
              className="btn btn-sm btn-outline-light"
              onClick={onClose}
              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
            </Button>
        </div>
        <div className="row">
          {children}
        </div>
      </div>
    </div>
  );
};

function FZOrganizationBlank(props) {
  const [file, setFile] = useState(null)
  const [dataList, setDataList] = useState([]);
  const [isModal, setIsModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [forDel, setForDel] = useState(null)
  const [errorsFields, setErrorsFields] = useState({});
  const [fieldsCreate, setFieldsCreate] = useState({})
  const [editBlank, setEditBlank] = useState({})
  const [change, setChange] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState([])
  const [paginationUrls, setPaginationUrls] = useState([null, null])
  const [unit_title, setUnitTitle] = useState('');
  const organizationId = props.organizationId
  const orgunit = props?.orgunit
  const orgParent = props?.orgParent

  const [search_title] = useDebounce(unit_title, DEBOUNCE_TIMEOUT);
  const is_same_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === Number(organizationId));
  }

  const canEdit = () => {
    if (orgunit?.permissions?.can_root) return true;
    if (Number(orgunit?.org?.id) === Number(organizationId) && orgunit?.permissions?.can_all) return true;
    if (is_same_org()) return true;
    if (orgParent && orgunit?.permissions?.can_all && orgunit?.all_orgs?.some(obj => obj === orgParent)) return true;
    return false
  };

  const canEditValue = canEdit();

  const fetchData = () => {
    setIsLoading(true);
    let url = `/fz59/api/organizationblank/?org=` + organizationId;
    if (unit_title.length > 0) {
      url += `&code_title=${unit_title}`
    }
    uniFetch(url).then(data => {
      setDataList(data.results);
      setPaginationUrls([data.previous, data.next])
      setPaginationInfo([data?.count, data?.limit, data?.offset])
    }).catch(error => {
      console.error("Error fetching data:", error);
    }).finally(()=>setIsLoading(false));
  }

  useEffect(() => {
    fetchData();
  }, [search_title]);

  useEffect(() => {
    if (isModal) {
      setFieldsCreate({})
    }
  }, [isModal])


  const handleConfirmDelete = () => {
    setIsLoading(true);
    uniFetch('/fz59/api/organizationblank/' + forDel + '/', 'DELETE').then((data) => {
      setDataList(dataList.filter(item => item.id !== forDel))
      setFile(null)
    }).finally(()=>setIsLoading(false))
    setForDel(null);
  }

  const handleCancelDelete = (e) => {
    setForDel(null);
  }

  const handleModelClose = () => {
    setIsModal(false)
    setEditBlank({})
    setFile(null)
    setErrorsFields({})
    setChange(false)
  }

  const handleFields = (e) => {
    const {name, value} = e.target;
    setFieldsCreate(prevState => {
      return {...prevState, [name]: value}
    })
  }
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDelete = (id) => {
    setForDel(id)
  }

  const handleCreate = () => {
    setIsModal(true);
  }

  const handleCreatePost = (event) => {
    setIsLoading(true);
    event.preventDefault()
    fieldsCreate['organization'] = organizationId
    const formData = new FormData()
    formData.append('file', file[0]?.file)
    formData.append('active', true)
    for (const key in fieldsCreate) {
      formData.append(key, fieldsCreate[key]);
    }
    uniFetch('/fz59/api/organizationblank/', 'POST', formData, false, false, true).then((data) => {
      setDataList(prevDataList => [...prevDataList, data]);
      setPaginationUrls([data.previous, data.next])
      setPaginationInfo([data?.count, data?.limit, data?.offset])
      handleModelClose();
      setErrorsFields({})
    }).catch(data => {
      setErrorsFields({errors: data.errors})
    }).finally(()=>setIsLoading(false))
  }

  const handleChange = (item, id) => {
    setEditBlank(item)
    setIsModal(true)
    setFile(formatFile(item.file, item.blank_fileinfo.name, item.blank_fileinfo.size, item.blank_fileinfo.type))
    setChange(true)
  }

  const formatFile = (source, name, size, type) => {
    return [
      {
        source,
        options: {
          type: 'local',
          file: {
            name,
            size,
            type,
          },
        },
      },
    ]

  }

  const handleChangePatch = (item) => {
    setIsLoading(true);
    const formData = new FormData();
    if (file?.[0]?.file instanceof File) {
      formData.append('file', file[0]?.file)
    }
    for (const key in fieldsCreate) {
      formData.append(key, fieldsCreate[key]);
    }

    uniFetch('/fz59/api/organizationblank/' + item.id + '/', 'PATCH', formData, false, false, true).then((data) => {
      handleModelClose()
      setDataList(prevDataList =>
        prevDataList.map(item => (item.id === data.id ? data : item))
      );
      setErrorsFields({})
    }).catch(data => setErrorsFields({errors: data.errors}
    )).finally(()=>setIsLoading(false))
  }

  const handlePagination = (url) => {
    setIsLoading(true);
    uniFetch(url).then(data => {
      setDataList(data.results);
      setPaginationUrls([data.previous, data.next])
      setPaginationInfo([data?.count, data?.limit, data?.offset])
    }).catch(error => {
      console.error("Error fetching data:", error);
    }).finally(()=>setIsLoading(false));
  }
  return (
    <div className="form-container">
      <div className="blocks-container">
        {
          canEditValue &&
          <div className="w-100">
            <div className={'d-flex justify-content-end'}>
              <button type="button" className="btn btn-success mb-3"
                      onClick={handleCreate}>
                + Добавить бланк
              </button>
            </div>
          </div>
        }
        <div className="block-container full-width mb-5 w-100">
          <label className="block-label d-flex justify-content-between align-items-center">Бланки</label>
          <InputField
            name={'code_title'}
            onChange={e => {
              setUnitTitle(e.target.value)
            }}
            isClearable={true}
            label={() => 'Поиск'}
            value={() => unit_title}
            placeholder={"Введите..."}
          />
          <div className="card margin my-3 height-50em">
            <div className={'card-header py-3'}>
              <Row className={'fw-bold'}>
                <Col sm={2}>Код</Col>
                <Col sm={4}>Наименование</Col>
                <Col sm={4}>Файл</Col>
              </Row>
            </div>
            <div className="card-body select-area">
              {!isLoading ? dataList?.map(item =>
                <Row key={item.id}
                     className={'py-2 can-select'}
                >
                  <Col sm={2}>{item.code}</Col>
                  <Col sm={4}>{item.title}</Col>
                  <Col sm={4}><a href={item.file} target="_blank" rel="noopener noreferrer">Скачать {item.blank_fileinfo.name}</a></Col>
                  <Col sm={2} className={'actions d-flex align-items-start'}>
                    <button type="button" className="btn btn-outline-success"
                            onClick={() => handleChange(item)}>
                      <SvgIcon type={!canEditValue ? svgType.eye : svgType.edit} size={{width: 16, height: 16}}/>
                    </button>
                    {
                      canEditValue &&
                      <button type="button" className="btn btn-outline-danger ml-2"
                              onClick={() => handleDelete(item.id)}>
                        <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                      </button>
                    }
                  </Col>
                </Row>
              ):<div className="text-center">
                  <div className="spinner-border text-primary m-5" role="status"
                       style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                  </div>
                </div>}
            </div>
            <div className={'card-footer'}>
              <Row>
                <Col md={4}>
                  <PaginationInfo
                    count={paginationInfo?.[0]}
                    limit={paginationInfo?.[1]}
                    offset={paginationInfo?.[2]}
                  />
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-center">
                    <Button bsStyle="success"
                            className={'mx-2'}
                            disabled={!paginationUrls[0]}
                            onClick={() => handlePagination(paginationUrls[0])}>{'<'}</Button>
                    <Button bsStyle="success"
                            disabled={!paginationUrls[1]}
                            className={'mx-2'}
                            onClick={() => handlePagination(paginationUrls[1])}>{'>'}</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
            <FieldModal
              isOpen={isModal}
              onClose={handleModelClose}
              title={!change ? 'Добавить бланк' : 'Редактировать бланк'}
            >
              <Row>
                <Col sm={6}>
                  <InputField
                    name="code"
                    value={name => fieldsCreate?.[name] ?? editBlank?.[name] ?? ""}
                    label={name => 'Код'}
                    required={true}
                    column={2}
                    errorMessages={name => errorsFields.errors?.[name]}
                    onChange={handleFields}
                    disabled={!canEditValue}
                  />
                </Col>
                <Col sm={6}>
                  <InputField
                    name="title"
                    value={name => fieldsCreate?.[name] ?? editBlank?.[name] ?? ""}
                    label={name => 'Наименование'}
                    required={true}
                    column={2}
                    errorMessages={name => errorsFields.errors?.[name]}
                    onChange={handleFields}
                    disabled={!canEditValue}
                  />
                </Col>
              </Row>
              <Row className={'mt-3'}>
              <SimpleFileInput
                name={'file'}
                label={name => 'Файл'}
                files={formatFiles(file)}
                setFiles={setFile}
                acceptedFileTypes={['text/html']}
                server={FilePodServer(organizationId)}
                instantUpload={false}
                allowDownload={true}
                isConfirmDelete={false}
                required={true}
                column={1}
                errorMessages={name => errorsFields.errors?.[name]}
                disabled={!canEditValue}
              />
              </Row>
              <Col sm={fullColumnSize} className='mt-4 text-center'>
                {errorsFields.errors &&
                  <HelpBlock className="alert alert-danger">Произошла ошибка при отправке данных.</HelpBlock>}
              </Col>
              <Row>
                <div className="d-flex justify-content-end">
                  {canEditValue ? <>
                      <Button
                        bsStyle="danger"
                        className={'mx-2 my-3'}
                        onClick={() => {
                          setIsModal(false)
                          setErrorsFields({})
                          setEditBlank({})
                          setChange(false)
                        }}>Отменить</Button>
                      {Object.keys(editBlank).length > 0 ?
                        <Button
                          bsStyle="success"
                          className={'my-3'}
                          onClick={() => handleChangePatch(editBlank)}>
                          Сохранить
                        </Button>
                        : <Button
                          bsStyle="success"
                          className={'my-3'}
                          onClick={handleCreatePost}>
                          Сохранить
                        </Button>}
                    </> :
                    <Button
                      bsStyle="primary"
                      className={'my-3'}
                      onClick={() => {
                        setIsModal(false)
                      }}>Закрыть</Button>
                  }
                </div>
              </Row>

            </FieldModal>
          { forDel && (
            <ConfirmModal
              label={'Удаление бланка'}
              text={'Вы действительно собираетесь удалить бланк?'}
              handleConfirm={handleConfirmDelete}
              handleCancel={handleCancelDelete}
            />
          )}
        </div>
      </div>
    </div>

  )
}

export default FZOrganizationBlank
