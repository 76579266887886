import React, { useEffect } from 'react';
import {SvgIcon, svgType} from "./Icons";
import {Button} from 'react-bootstrap';

export const ConfirmModal = ({label, text, handleConfirm, handleCancel, isAlert = false}) => {
    return (
        <div className="modal-overlay modal confirm-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content min-width-500">
                    <div className="modal-header">
                        <h5 className="modal-title">{label}</h5>
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn btn-sm btn-outline-light"
                         onClick={(e) => handleCancel(e)}
                        title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
                      </Button>
                    </div>
                    <div className="modal-body fs-5 text-center py-5">
                        {text}
                    </div>
                  <div className="modal-footer">
                    {!isAlert ?
                      <>
                        <button type="button" className="btn btn-danger" onClick={(e) => handleCancel(e)}>Нет</button>
                        <button type="button" className="btn btn-success"
                                onClick={(e) => handleConfirm(e)}>Да
                        </button>
                      </>
                      :
                      <button type="button" className="btn btn-primary" onClick={(e) => handleCancel(e)}>Ок</button>}
                  </div>
                </div>
            </div>
        </div>
    );
};

export const HTMLConfirmModal = ({label, handleConfirm, handleCancel, validateConfirm, children, cancelLabel, confirmLabel}) => {
    return (
        <div className="modal-overlay modal confirm-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{label}</h5>
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn btn-sm btn-outline-light"
                        onClick={(e) => handleCancel(e)}
                        title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
                      </Button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={(e) => handleCancel(e)}>{cancelLabel ?? "Нет"}</button>
                      {handleConfirm && <button type="button" className="btn btn-success" onClick={(e) => handleConfirm(e)}
                               disabled={!validateConfirm}>{confirmLabel ?? "Да"}</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoaderModal = ({label, text_cls}) => {
    const textCls = text_cls ? text_cls : 'text-primary'
    return (
        <div className="modal-overlay modal confirm-modal modal-overlay-loader" tabIndex="-1">
            <div className="text-center">
                <div className={'spinner-border m-5 ' + textCls} role="status" style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                </div>
            </div>
        </div>
    );
};

export function FieldModal({isOpen, onClose, children, title, className}) {
  if (!isOpen) return null;
  return (
    <div className="block-container">
      <div className="modal-overlay">
        <div className={"modal-content content container " + className} onClick={(e) => e.stopPropagation()}>
          <div
            className="block-label d-flex justify-content-between align-items-center modal-label">
            <label>{title}</label>

            <Button
              variant="secondary"
              type="button"
              className="btn btn-sm btn-outline-light"
              onClick={onClose}
              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
            </Button>
          </div>
          <div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export function ResponseModal({ status, onClose, children }) {
  useEffect(() => {
    if (status == 200 || status == 204) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [status, onClose]);

  if (!status) return null;
  return (status == 200 || status == 204 ?
    <div className="container mt-15 fixed-top" onClick={(e) => e.stopPropagation()}>
      {children}
    </div>:
    <ConfirmModal label={'Ошибка'} handleCancel={()=>onClose()} text={children} isAlert={true}/>
  );
}
