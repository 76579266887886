import React from 'react';
import {convertDateFormat} from "../../../utils/common";

export function DuplicateTable({duplicates, modelDefinition}) {
  return (
    <>
      {duplicates?.map(duplicate => <div key={duplicate.id} className="row block-table-row">
        <DuplicateRow
          duplicate={duplicate}
          modelDefinition={modelDefinition}
        />
      </div>)}
    </>
  )
}


function DuplicateRow({duplicate, modelDefinition}) {
  return (
    <div className="col-11">
      <div className="row">
        <div className="col-2 fw-bold">Номер</div>
        <div className="col-2 fw-bold">Фио</div>
        <div className="col-3 fw-bold">Аннотация</div>
        <div className="col-4 fw-bold">Адрес</div>
        <div className="col-1 fw-bold">Дата</div>
      </div>

      <div className="row row pt-1 pb-3">
        <div className="col-2">
          <a href={`/fz59/message_detail/${duplicate.id}`}>
            {duplicate?.reg_number}
          </a>
          <br/>
          {modelDefinition?.state?.children?.find(child => child.slug == duplicate?.status).name}
        </div>
        <div className="col-2">{duplicate?.fio}</div>
        <div className="col-3">{duplicate?.annotation}</div>
        <div className="col-4">{duplicate?.postal_address}</div>
        <div className="col-1">{convertDateFormat(duplicate?.reg_at)}</div>
      </div>
    </div>
  )
}
