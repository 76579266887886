import React from 'react';
import CreatableSelect from 'react-select/creatable';
import {FieldContainer} from "./FieldContainer";

export function CreateSelectField({name, value, label, onChange, column, row, required, hideLabel, disabled, options, isMulti= false, defaultValue, errorMessages, placeholder, isClearable = true}) {
  const handleChange = (selectedOption) => {
    if (isMulti) {
      onChange(name, selectedOption?.map(option=>option.value)??[]);
    } else {
      onChange(name, selectedOption?.value ?? "");
    }
  };

  return (
    <FieldContainer {...{name, label: label(name), column, row, required, hideLabel, errorMessages}}>
      <CreatableSelect
        className={`custom-input ${disabled ? 'disabled' : ''}`}
        options={options(name)}
        menuPlacement="auto"
        {...(isMulti ? {isMulti} : {})}
        onChange={handleChange}
        value={value(name)}
        isDisabled={disabled}
        placeholder={placeholder}
        formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
        isClearable={isClearable}
      />
    </FieldContainer>
  );
}
