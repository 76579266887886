import React, {useState, useEffect, useRef} from 'react';
import {useDebounce, useDebouncedCallback} from 'use-debounce';

import {
    FormControl,
    Row,
    Col,
    Tabs,
    Tab,
    Button,
    Table,
    Modal
} from 'react-bootstrap';

import {BlockContainer} from "../../components/Fields/BlockContainer";
import {DateField} from "../../components/Fields/DateField";
import {AsyncSelectField2} from "../../components/Fields/AsyncSelectField";
import {HighlightText} from "../../components/HighlightText";
import {ConfirmModal, LoaderModal, FieldModal, ResponseModal} from "../../components/Modal";
import {convertDateFormat, loadOptions} from "../../utils/common"
import uniFetch from "../../utils/uniFetch";
import {BIG_DEBOUNCE_TIMEOUT, DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../constants"
import {SvgIcon, SvgIconCls, svgType} from "../../components/Icons";
import {AddEntityForm} from "./DetailComponents/AddEntityForm";
import ApplicantAnswers from "./DetailComponents/ApplicantAnswers";
import {CheckBoxField} from "../../components/Fields/CheckBoxField";
import {PaginationInfo} from "../../components/PaginationInfo";
import {ResponseAlert} from "../../components/ResponseAlert";
import {labels} from "./fzmessage-detail";
import {findNamesBySlugs} from "./DetailComponents/ReadDetail";
import {CKEditorField} from "../../components/Fields/CKEditorField";

export const notificationFormLabels = {
    not_need: 'Уведомление не требуется',
    confirm_send_label: 'Отправка уведомления',
    confirm_send_text: 'Вы действительно собираетесь отправить уведомление?',
    success_send: 'Уведомление отправлено',
    sender: 'Cпециалист',
    confirm_ask: 'Вы действительно собираетесь отправить уведомление на подпись',
    confirm_ask_notes: 'Вы действительно собираетесь отправить уведомление на подпись'
};

export const resolutionFormLabels = {
    not_need: 'Резолюция не требуется',
    confirm_send_label: 'Отправка резолюции',
    confirm_send_text: 'Вы действительно собираетесь отправить резолюцию?',
    success_send: 'Резолюция отправлена',
    sender: 'Cпециалист',
    confirm_ask: 'Вы действительно собираетесь отправить резолюцию на подпись',
    confirm_ask_notes: 'Вы действительно собираетесь отправить уведомление на подпись'
};


const emailSendStatus = {
    0: 'Отправлен',
    1: 'Ошибка',
    2: 'В очереди',
    3: 'Повторно отправлен',
};

const formatDate = (date_str) => {
    let res = '';
    if (date_str) {
        let dd = new Date(date_str),
          day = dd.getDate() > 9 ? dd.getDate().toString(10) : '0' + dd.getDate().toString(10),
          mn = dd.getMonth() + 1
        mn = mn > 9 ? mn.toString(10) : '0' + mn.toString(10);
        res = '' + day + '.' + mn + '.' + dd.getFullYear().toString(10);
    }
    return res
}

const deleteButton = (callback, disabled = false) => {
    return (
      <button type="button" className="btn btn-outline-danger btn-sm"
              disabled={disabled}
              title={'Удаление'}
              onClick={e => {
                  e.stopPropagation()
                  callback()
              }}>
          <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
      </button>)
}

export function ResponsibleTable({local, name, value, message_problems, apiCommand, onChange, onDelete, onShowAdd, canEdit = true}) {

    const handleCreate = (e) => {
        onShowAdd(e);
    }

    const getProblem = (id) => {
        return message_problems.find((element) => element.id === id);
    }
    const titleSpan = (text)=>{
        return (<span className={'pe-3'}>{text}</span>)
    }

    function isFirstCombineOnly(value) {
        if (value.length === 0) {
            return false;
        }

        if (value[0].for_combine !== true) {
            return false;
        }

        for (let i = 1; i < value.length; i++) {
            if (value[i].for_combine !== false) {
                return false;
            }
        }
        return true;
    }

    const setForCombineForFirst = (e) => {
        const {checked} = e.target;
        const dataSave = {
            "id": value[0]?.id,
            "for_combine": checked,
        }
        apiCommand("command_set_responsible_for_combine", dataSave);
    }

    const changeResponsiblePosition = (id, is_up) => {
        const dataSave = {
            "id": id,
            "is_up": is_up,
        }
        apiCommand("command_edit_responsible", dataSave);
    }

    const movePositionButtons = (responsible) => {

        const positionButton = (is_up, className) => {

            return (<button
              className={"btn btn-outline-secondary "+ className}
              onClick={() => changeResponsiblePosition(responsible.id, is_up)}
            >
                <SvgIcon type={is_up ? svgType.chevronUp : svgType.chevronDown} size={{width: 16, height: 16}}/>
            </button>)
        }
        const isLast = value[value.length - 1]?.id === responsible.id;
        const isFirst = value[0]?.id === responsible.id

        return (
          <>
              {!isLast && !isFirst ? (
                <div className="d-flex justify-content-between">
                    {positionButton(true)}
                    {positionButton(false, 'ml-1')}
                </div>
              ) : (
                isFirst && isLast ? null : (
                  <>
                      {isFirst && positionButton(false, 'w-100')}
                      {isLast && positionButton(true, 'w-100')}
                  </>
                )
              )}
          </>
        )
    }

    return (
        <>
            {value?.map(responsible =>
                <div key={responsible.id}
                     className="row block-table-row">
                    <div className="col-11">
                        <div className="row">
                            <div className="col-6">ФИО исполнителя</div>
                            <div className="col-6 fw-bold">
                                {responsible.for_combine ? titleSpan('ОБОБЩЕНИЕ') : ''}
                                {responsible.for_known ? titleSpan('ДЛЯ СВЕДЕНИЯ') : ''}
                                {responsible.for_affiliation ? titleSpan('ПО ПРИНАДЛЕЖНОСТИ') : ''}
                            </div>
                        </div>
                        <div className="row row pt-1 pb-0">
                            <div className="col-6 fw-bold">
                                {responsible.unit.approved ?
                                    <span className={'me-2 text-success'}><SvgIcon type={svgType.approved} size={{
                                        width: 16,
                                        height: 16
                                    }}/></span> : null}
                                {responsible.unit.last_name} {responsible.unit.first_name} {responsible.unit.middle_name}<br/>
                                {responsible.unit?.email || responsible.unit?.org?.email ?
                                    <span className={'text-muted'}>
                                        {responsible.unit?.email || responsible.unit?.org?.email}
                                    </span>
                                    :
                                    <span className={'text-danger'}>Email не указан</span>
                                }
                            </div>
                            <div className="col-6 fw-bold">
                                {responsible.unit?.org.title}
                            </div>
                        </div>
                        <div className="row row pt-1 pb-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-muted">
                                {responsible.unit.position}
                            </div>
                        </div>
                        <div className="row pt-2 pb-1">
                            <div className="col-2">Дата направления</div>
                            <div className="col-2">Срок контроля</div>
                            <div className="col-2">Дата продления</div>
                            <div className="col-2">Дата выполнения</div>
                            <div className="col-2" title={'Дополнительный контроль'}>Доп. контроль</div>
                            <div className="col-2" title={'Дата выполнения дополнительного контроля'}>Дата выполнения
                                ДК
                            </div>
                        </div>
                        <div className="row py-1">
                            <div className="col-2 fw-bold">{formatDate(responsible.resolution_date)}</div>
                            <div className="col-2 fw-bold">{formatDate(responsible.control_date)}</div>
                            <div className="col-2 fw-bold">{formatDate(responsible.renewal_date)}</div>
                            <div className="col-2 fw-bold">{formatDate(responsible.execution_date)}</div>
                            <div className="col-2 fw-bold">{formatDate(responsible.more_control_date)}</div>
                            <div className="col-2 fw-bold">{formatDate(responsible.more_control_execution_date)}</div>
                        </div>
                        {responsible.problems.length > 0 ?
                            <>
                                <div className={'row pt-3'}>
                                    <div className={'col-3'}><h6 className={'fw-bold'}>По вопросам</h6></div>
                                </div>
                                {responsible.problems.map(problem_id => {
                                        let problem = getProblem(problem_id);
                                        return (
                                            problem ?
                                                <div className={'row small'} key={'problem' + problem.id}>
                                                    <div className={'col-2'}>{problem.problem.code}</div>
                                                    <div className={'col-5'}>{problem.problem.title}</div>
                                                    <div
                                                        className={'col-5'}>({problem.type.code}) {problem.type.title}</div>
                                                </div> : null
                                        )
                                    }
                                )}
                            </> : null}
                    </div>
                    {local && canEdit && (
                        <div className="col-1 actions">
                            <button
                                className="btn btn-outline-success w-100"
                                onClick={() => onChange(responsible.id)}
                            >
                              <SvgIcon type={svgType.edit} size={{width: 16, height: 16}}/>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger w-100 my-2"
                            onClick={() => onDelete({id: responsible.id})}
                          >
                              <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                          </button>
                          {movePositionButtons(responsible)}
                      </div>
                    )}


                </div>)}
            {value?.length > 0 ?
            <Row>
                <CheckBoxField
                  name={'for_combine'}
                  value={name =>isFirstCombineOnly(value)}
                  label={name => 'Обобщение по первому'}
                  required={false}
                  column={2}
                  onChange={setForCombineForFirst}
                  disabled={!canEdit}
                />
            </Row>: null}
            {local && canEdit && <div className="py-3 text-center">
                <button type="button"
                        className="btn btn-success"
                        onClick={(e) => handleCreate(e)}>
                    + Добавить исполнителя
                </button>
            </div>}
        </>)
}


function ResponsibleDetail({local, orgunit, person, reg_organisation, message_problems, resolutions, notifications, index, apiCommand, updateCommand, modelDefinition, isLoading,setIsLoading, messageId, setActiveTab, groupID, canEdit}){
    const [for_combine, setForCombine] = useState(person.for_combine);
    const [for_known, setForKnown] = useState(person.for_known);
    const [for_affiliation, setForAffilation] = useState(person.for_affiliation);
    const [has_defection, setHasDefection] = useState(person.has_defection);
    const [forward_in_time, setForwardInTime] = useState(person.forward_in_time);
    const [errorFields, setErrorFields] = useState({});
    const [responseStatus, setResponseStatus] = useState(0);
    const [control_date, setControlDate] = useState(person.control_date);
    const [renewal_date, setRenewalDate] = useState(person.renewal_date);
    const [execution_date, setExecutionDate] = useState(person.execution_date);
    const [middle_answer_date, setMiddleAnswerDate] = useState(person.middle_answer_date);
    const [more_control_date, setMoreControlDate] = useState(person.more_control_date);
    const [more_control_execution_date, setMoreControlExecutionDate] = useState(person.more_control_execution_date);
    const [more_control_reason, setMoreControlReason] = useState(person.more_control_reason);
    const [person_problems, setPersonProblems] = useState([...person.problems])

    const [isNotificationModal, setIsNotificationModal] = useState(false)
    const [isResolutionModal, setIsResolutionModal] = useState(false)
    const sentResolutions = resolutions.filter((element) => element.send && element.responsible===person?.id&&!element.is_notification) || [];
    const activeResolution = resolutions.find((element) => !element.send && element.responsible===person?.id&&!element.is_notification) || {};

    const sentResolutionsNotes = resolutions.filter((element) => element.send && element.responsible===person?.id&&element.is_notification) || [];
    const activeResolutionNote = resolutions.find((element) => !element.send && element.responsible===person?.id&&element.is_notification) || {};

    const forward_options = [
          { value: '', label: 'не установлено' },
          { value: 'less_7', label: 'в 7 дней' },
          { value: 'more_7', label: 'позднее 7 дней' }
        ]

    const isSelectedProblem = (id) => {
        return person_problems.find((element) => element === id) !== undefined ? true : false;
    }

    const resetFields = () => {
        setActiveTab("resp_common" + groupID);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handleChangeProblem = (id) =>{
        let new_problems = []
        if(isSelectedProblem(id)){
            new_problems = [...person_problems.filter((problem) => problem!==id)]
        }else{
            new_problems = [...person_problems]
            new_problems.push(id)
        }
        setPersonProblems(new_problems)
    }

    const handleChangeDate = (e, setFn) => {
        const {name, value} = e.target;
        setFn(value);
        return false;
      };

    const handleResendEmail = (data)=>{
        apiCommand("command_resend_email", data);
    }

    const saveDetail = (e) => {
        const dataSave = {
            "id": person.id,
            "for_combine": for_combine,
            "for_known": for_known,
            "for_affiliation": for_affiliation,
            "has_defection": has_defection,
            "forward_in_time": forward_in_time,
            "control_date": control_date,
            "renewal_date": renewal_date,
            "middle_answer_date": middle_answer_date,
            "execution_date": execution_date,
            "more_control_date": more_control_date,
            "more_control_execution_date": more_control_execution_date,
            "more_control_reason": more_control_reason,
            "problems": person_problems
        }
        apiCommand("command_edit_responsible", dataSave);
    }

    const sendAnswers = person.answers.filter((answer) => answer.data).reverse()

    const deleteHandler = (id, type) => {
        setIsLoading(true)
        uniFetch(`/fz59/api/${type}/${id}/`, 'DELETE').then((data) => {
            updateCommand(`command_delete_${type}`, id);
            setResponseStatus(204);
        }).catch(data => {
            if (data?.code && data?.errors) {
                setResponseStatus(data?.code)
                setErrorFields(data?.errors);
            } else {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            }
        }).finally(data => {
            setIsLoading(false);
            setIsNotificationModal(false);
            setIsResolutionModal(false);
        });
    }

    const handleCloseResponseModal = () => {
        setResponseStatus(0)
    }

    return(
        <>
            <BlockContainer label='Исполнитель'>
                {isLoading ? <LoaderModal label={'Сохранение...'}/>: null}
                <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
                    <ResponseAlert status={responseStatus} result={errorFields} labels={labels}
                                   successTitle={'Удалено'}/>
                </ResponseModal>
                <div className="block-container-content">
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Фамилия Имя Отчество</h6>
                        </Col>
                        <Col md={3}>
                            {person.unit.approved ? <span className={'me-2 text-success'}><SvgIcon type={svgType.approved} size={{width: 16, height: 16}}/></span> : null}
                            {person.unit.last_name} {person.unit.first_name} {person.unit.middle_name}
                        </Col>
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Контакты</h6>
                        </Col>
                        <Col md={3}>
                            {person.unit.phone_number}
                            {person.unit.phone_number && person.unit.email ? <br/>: null}
                            {person.unit.email}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Должность</h6>
                        </Col>
                        <Col md={9}>
                            {person.unit.position}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата направления</h6>
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col className={'col'}>
                                    {formatDate(person.resolution_date)}
                                </Col>
                                <Col className={'col'}>
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               disabled={!canEdit}
                                               type="checkbox" id={'for_combine_' + index}
                                               checked={for_combine}
                                               onChange={() =>setForCombine(!for_combine)}/>
                                        <label className="form-check-label" htmlFor={'for_combine_' + index}>
                                            Обобщение
                                        </label>
                                    </div>
                                </Col>
                                <Col className={'col'}>
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               disabled={!canEdit}
                                               type="checkbox" id={'for_known_' + index}
                                               checked={for_known}
                                               onChange={() =>setForKnown(!for_known)}/>
                                        <label className="form-check-label" htmlFor={'for_known_' + index}>
                                            Для сведения
                                        </label>
                                    </div>
                                </Col>
                                <Col className={'col'}>
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               disabled={!canEdit}
                                               type="checkbox"
                                               id={'for_affiliation_' + index}
                                               checked={for_affiliation}
                                               onChange={() =>setForAffilation(!for_affiliation)}/>
                                        <label className="form-check-label" htmlFor={'for_affiliation_' + index}>
                                            По принадлежности
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Срок перенаправления</h6>
                        </Col>
                        <Col md={9}>
                            <Row>
                            {forward_options.map((item, ind)=>{
                                return (
                                    <Col className={'col-3'} key={'forward_options_'+ind}>
                                        <div className="form-check form-check-inline" >
                                          <input className="form-check-input"
                                                 disabled={!canEdit}
                                                 type="radio"
                                                 id={'forward_options_'+ind}
                                                 value={item.value}
                                                 checked={item.value===forward_in_time}
                                                 onChange={()=>setForwardInTime(item.value)}
                                          />
                                          <label className="form-check-label" htmlFor={'forward_options_'+ind}>{item.label}</label>
                                        </div>
                                    </Col>
                                )
                            }
                            )}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата контроля</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'control_date'}
                                value={control_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setControlDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата промежуточного ответа</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'middle_answer_date'}
                                value={middle_answer_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setMiddleAnswerDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата продления</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'renewal_date'}
                                value={renewal_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setRenewalDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата выполнения</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'execution_date'}
                                value={execution_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setExecutionDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата дополнительного контроля</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'more_control_date'}
                                value={more_control_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setMoreControlDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Дата выполнения дополнительного контроля</h6>
                        </Col>
                        <Col md={3}>
                            <DateField
                                disabled={!canEdit}
                                name={'more_control_execution_date'}
                                value={more_control_execution_date}
                                label={()=> {return ''}}
                                hideLabel={true}
                                required={false}
                                column={2}
                                onChange={(e)=>{handleChangeDate(e, setMoreControlExecutionDate)}}
                                errorMessages={name=>errorFields[name]}
                              />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>Причина доп. контроля</h6>
                        </Col>
                        <Col md={9}>
                            <input type={'text'}
                                   disabled={!canEdit}
                                   className={'form-control'}
                                   value={more_control_reason ? more_control_reason: ''}
                                   onChange={(e=>{setMoreControlReason(e.target.value)})}
                                   required={false}
                            />
                        </Col>
                     </Row>
                    <Row className="mb-2">
                        <Col md={3} className={'text-end'}>
                            <h6 className={'fw-bold'}>С нарушением</h6>
                        </Col>
                        <Col md={9}>
                            <div className="form-check">
                                <input className="form-check-input"
                                       disabled={!canEdit}
                                       type="checkbox"
                                       id={'has_defection_' + index}
                                       checked={has_defection}
                                       onChange={() => setHasDefection(!has_defection)}
                                />
                            </div>
                        </Col>
                    </Row>
                    { canEdit ?
                    <Row className={'text-end'}>
                        <Col md={12}>
                            <button type="button" className="btn btn-danger me-3" onClick={resetFields}>
                                Отменить
                            </button>
                            <button type="button" className="btn btn-primary" onClick={saveDetail}>
                                Сохранить
                            </button>
                        </Col>
                    </Row>: null
                    }
                </div>
            </BlockContainer>
            <BlockContainer label='По вопросам'>
                <div className="block-container-content">
                {message_problems.map((problem, pind) => {
                    return(
                        <Row className={"mb-3"} key={pind}>
                            <Col md={1}>
                                <div className="form-check">
                                    <input className="form-check-input"
                                           disabled={!canEdit}
                                           type="checkbox"
                                           checked={isSelectedProblem(problem.id)}
                                           onChange={(e) => handleChangeProblem(problem.id)}
                                    />
                                </div>
                            </Col>
                            <Col md={11}>
                                <span className={'fw-bold'}>{problem.problem.code}</span> {problem.problem.title}<br/>
                                <span className={'small'}> {problem.type.title}</span>
                            </Col>
                        </Row>
                    )
                })}
                </div>
                {canEdit ?
                <Row className={'text-end'}>
                        <Col md={12}>
                            <button type="button" className="btn btn-primary" onClick={saveDetail}>
                                Сохранить
                            </button>
                        </Col>
                </Row>: null
                }
            </BlockContainer>
            <Row>
                <Col md={6}>
                    { sentResolutions.length > 0 ?
                      <BlockContainer label={`Отправленные резолюции (${sentResolutions.length})`} canHide={true}>
                          {sentResolutions.map((resolution, index) => {
                              return (
                                <SentEntity
                                key={index}
                                entity={resolution}
                                apiCommand={apiCommand}
                                updateCommand={updateCommand}
                                canDelete={orgunit?.permissions?.can_root || orgunit?.permissions?.can_all }
                                entity_name={'resolution'}/>
                              )
                          })}
                      </BlockContainer>
                        : null
                    }
                    {isResolutionModal &&
                      <ConfirmModal
                        label={'Удаление резолюции'}
                        text={'Вы действительно собираетесь удалить сохраненную резолюцию?'}
                        handleConfirm={()=>deleteHandler(activeResolution.id, 'resolution' )}
                        handleCancel={() => setIsResolutionModal(false)}
                      />
                    }
                    {canEdit ?<BlockContainer
                      label={activeResolution?.wait_sign === 'ASKED' ?
                      <>
                          <SvgIconCls type={svgType.askSign} size={{width: 24, height: 24}} customCls={'text-danger me-3'}/>
                          <span className={'text-danger'}>Резолюция исполнителю (ожидает подписания)</span>
                      </> :<>
                      {activeResolution?.wait_sign === 'SIGNED' ?
                          <>
                              <SvgIconCls type={svgType.wasSigned} size={{width: 24, height: 24}} customCls={'me-3'}/>
                              <span>Резолюция исполнителю (подписана)</span>
                          </> : <span>Добавить резолюцию исполнителю</span>
                      }</>
                    }
                      canHide={activeResolution?.wait_sign !== 'ASKED'&&activeResolution?.wait_sign !== 'SIGNED'}
                      rightAction={activeResolution.id && deleteButton(()=>setIsResolutionModal(true))}
                    >
                        <AddEntityForm
                            groupID={person.group}
                            person={person}
                            reg_organisation={reg_organisation}
                            orgunit={orgunit}
                            messageId={messageId}
                            entity={activeResolution}
                            entity_name={'resolution'}
                            exclude_fields={['notNeed']}
                            apiCommand={apiCommand}
                            updateCommand={updateCommand}
                            labels={resolutionFormLabels}
                        />
                    </BlockContainer>: null}
                </Col>
                <Col md={6}>
                    { sentResolutionsNotes.length > 0 ?
                      <BlockContainer label={`Отправленные уведомления (${sentResolutionsNotes.length})`} canHide={true}>
                          {sentResolutionsNotes.map((resolution, index) => {
                              return (
                                <SentEntity
                                key={index}
                                entity={resolution}
                                apiCommand={apiCommand}
                                updateCommand={updateCommand}
                                canDelete={orgunit?.permissions?.can_root || orgunit?.permissions?.can_all }
                                entity_name={'resolution'}/>
                              )
                          })}
                      </BlockContainer>
                        : null
                    }
                    {isNotificationModal &&
                      <ConfirmModal
                        label={'Удаление уведомления'}
                        text={'Вы действительно собираетесь удалить сохраненное уведомление?'}
                        handleConfirm={()=> deleteHandler(activeResolutionNote.id, 'resolution' )}
                        handleCancel={() => setIsNotificationModal(false)}
                      />
                    }
                    {canEdit ?<BlockContainer
                      label={activeResolutionNote?.wait_sign === 'ASKED' ?
                      <>
                          <SvgIconCls type={svgType.askSign} size={{width: 24, height: 24}} customCls={'text-danger me-3'}/>
                          <span className={'text-danger'}>Уведомление исполнителю (ожидает подписания)</span>
                      </> :<>
                      {activeResolutionNote?.wait_sign === 'SIGNED' ?
                          <>
                              <SvgIconCls type={svgType.wasSigned} size={{width: 24, height: 24}} customCls={'me-3'}/>
                              <span>Уведомление исполнителю (подписана)</span>
                          </> : <span>Добавить уведомлению исполнителю</span>
                      }</>
                    }
                      canHide={activeResolutionNote?.wait_sign !== 'ASKED'&&activeResolutionNote?.wait_sign !== 'SIGNED'}
                      rightAction={activeResolutionNote.id && deleteButton(()=>setIsNotificationModal(true))}
                    >
                        <AddEntityForm
                            groupID={person.group}
                            person={person}
                            reg_organisation={reg_organisation}
                            orgunit={orgunit}
                            messageId={messageId}
                            entity={activeResolutionNote}
                            entity_name={'resolution'}
                            exclude_fields={['notNeed']}
                            apiCommand={apiCommand}
                            updateCommand={updateCommand}
                            labels={notificationFormLabels}
                            extra_data={{'is_notification': true}}
                            files_required={false}
                        />
                    </BlockContainer>: null}
                </Col>
            </Row>
            {sendAnswers && sendAnswers.length > 0 ?
                <BlockContainer label='Отправленные ответы' canHide={true} hide={false}>
                    {sendAnswers.map((answer, index)=> {
                        return (
                          <Row key={index} className='row block-table-row'>
                              <Row>
                                  <h5>№ {answer?.id}</h5>
                              </Row>
                                <Row className={'mb-3'}>
                                <Col sm={12} className={'fw-bold'}><h5><u>Ответ {answer.date_receipt ? convertDateFormat(answer.date_receipt) :
                                        null} / {answer.income_number ? answer.income_number : null}</u></h5>
                                </Col>
                                </Row>

                                <Row className={'mb-3'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Дата ответа
                                    </Col>
                                    <Col sm={4}>
                                        {convertDateFormat(answer.date_receipt) || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Исходящий номер
                                    </Col>
                                    <Col sm={4}>
                                        {answer.income_number|| '-'}
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>

                                    <Col sm={2} className={'fw-bold'}>
                                        Номер в СЭД
                                    </Col>
                                    <Col sm={4}>
                                        {answer.sed_number || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Подписант
                                    </Col>
                                    <Col sm={4}>
                                        {answer.specialist_code || '-'} | {answer.specialist_title || '-'}
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Бланк
                                    </Col>
                                    <Col sm={4}>
                                        {answer.blank_code || '-'} | {answer.blank_title || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Шаблон
                                    </Col>
                                    <Col sm={4}>
                                         {answer.template_code || '-'} | {answer.template_title || '-'}
                                    </Col>
                                </Row>
                                <Row className={'mb-4'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Ответ
                                    </Col>
                                    <Col sm={10} >
                                         <div dangerouslySetInnerHTML={{ __html: answer.description }}/>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col sm={2} className={'fw-bold'}>
                                       <h5>Анализ ответа</h5>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Результаты анализа
                                    </Col>
                                    <Col sm={4}>
                                        {findNamesBySlugs(modelDefinition['answers']['analysis_result'], [answer.analysis_result]) || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Способ достижения результата
                                    </Col>
                                    <Col sm={4}>
                                         {findNamesBySlugs(modelDefinition['answers']['way_result'], answer.way_result) || '-'}
                                    </Col>
                                </Row>
                                <Row className={'mb-4'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Меры воздействия
                                    </Col>
                                    <Col sm={4}>
                                        {findNamesBySlugs(modelDefinition['answers']['measures_influence'], answer.measures_influence) || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Типичные причины
                                    </Col>
                                    <Col sm={4}>
                                        {findNamesBySlugs(modelDefinition['answers']['typical_reasons'], answer.typical_reasons) || '-'}
                                    </Col>
                                </Row>
                                 <Row className={'mb-3'}>
                                    <Col sm={12} className={'fw-bold'}>
                                        <h5>Оценка ответа</h5>
                                    </Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col sm={2} className={'fw-bold'}>
                                        Оценка ответа регистратор
                                    </Col>
                                    <Col sm={4}>
                                        {findNamesBySlugs(modelDefinition['answers']['evaluation_registrar_response'],[answer.evaluation_registrar_response]) || '-'}
                                    </Col>
                                    <Col sm={2} className={'fw-bold'}>
                                        Оценка ответа заявитель
                                    </Col>
                                    <Col sm={4}>
                                         {findNamesBySlugs(modelDefinition['answers']['evaluation_applicant_response'],[answer.evaluation_applicant_response]) || '-'}
                                    </Col>
                                </Row>
                                {(answer.addition_fileinfo || answer.signed_fileinfo) ? <Row className={'my-3'}>
                                  <Col sm={2} className={'fw-bold'}>Файлы</Col>
                                  <Col sm={10}>
                                      {answer.addition_fileinfo ?
                                        <Row>
                                            <a className={'w-100'}
                                               target={'_blank'}
                                               href={answer.addition_fileinfo.file}>
                                                <span>{answer.addition_fileinfo.name}</span>
                                            </a></Row> : null}
                                      {answer.signed_fileinfo ?
                                        <Row><a className={'w-100'}
                                                target={'_blank'}
                                                href={answer.signed_fileinfo.file}>
                                            <span>{answer.signed_fileinfo.name}</span>
                                        </a></Row> : null}
                                      {answer.sig_fileinfo ?
                                        <Row><a className={'w-100'}
                                                target={'_blank'}
                                                href={answer.sig_fileinfo.file}>
                                            <span>{answer.sig_fileinfo.name}</span>
                                        </a></Row> : null}
                                  </Col>
                              </Row>
                              : null
                            }
                            <EmailSendResultBlock
                                data={answer.data}
                                entity_type='answer'
                                entity_id={answer.id}
                                resendEmail={handleResendEmail}
                            >
                            </EmailSendResultBlock>
                        </Row>)
                    })}
                </BlockContainer> : null
            }
            <BlockContainer label='Ответ'>
                <ApplicantAnswers
                  entity_name={'answer'}
                  responsible={person}
                  reg_organisation={reg_organisation}
                  orgunit={orgunit}
                  messageId={messageId}
                  modelDefinition={modelDefinition}
                  updateCommand={updateCommand}/>
            </BlockContainer>
        </>

    )
}

function AddResponsible(props){
    const {onClose, onAdd, reg_organisation, reg_org_name} = props
    const selectedCls = "text-success-emphasis bg-success-subtle py-2";
    const unitUrl = '/edw/api/data-marts/fzorganisationunit-dm/entities/'
    const searchErrorMessage = "Произошла ошибка поиска. Пожалуйста обновите страницу (F5) и попробуйте еще раз!"

    const [organisation, setOrganisation] = useState(reg_organisation);
    const [unit_title, setUnitTitle] = useState('');
    const [searchResult, setSearchResult] = useState({});
    const [ready, setReady] = useState(true);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [errorFields, setErrorFields] = useState({});
    const [search_title] = useDebounce(unit_title, DEBOUNCE_TIMEOUT);

    useEffect(() => {
        if (ready && unitUrl) {
            let search_url = unitUrl;
            let params = ["limit="+QUERY_LIMIT];
            setReady(false);
            if(organisation) params.push("org="+organisation);
            if(unit_title) params.push("full_name="+unit_title);
            if (params.length>0) search_url+='?'+ params.join("&");
            setSelectedUnit({});

            uniFetch(search_url)
                .then((data) => {
                    setSearchResult(data);
                    setReady(true);
                    setErrorFields({});
                })
                .catch((error) => {
                    setReady(true);
                    setErrorFields(prevState => {
                        return Object.assign({}, prevState, {'search': error})
                    })
                });
            }
    }, [search_title, organisation])

    const handlePagination = (url) => {
        setReady(false);
        setSelectedUnit({});
        uniFetch(url).then((data) => {
            setSearchResult(data);
            setReady(true);
            setErrorFields({});
        }).catch((error) => {
            setReady(true);
            setErrorFields(prevState => {
                return Object.assign({}, prevState, {'search': error})
            })
        });
    }

    const handleChangeSelect = (name, value) => {
        setOrganisation(value);
    };

    const handleCancel = (e) =>{
        setReady(false);
        setUnitTitle('');
        setOrganisation(null);
        setSelectedUnit({});
        setReady(true);
        onClose(e);
    }
    const handleOk = (e) => {
        const data_for_add = {'unit': selectedUnit.id}
        onAdd(data_for_add);
    }

    const handleSelectUnit = (unit)=>{
        setSelectedUnit({'id': unit.id, 'full_name': unit.extra?.full_name, 'position': unit.extra?.position});
    }

    const debouncedLoadOptions = useDebouncedCallback(
        (value, callback) => {
            loadOptions(value, callback, 'fzorganisation-dm', 'title');
            }, QUERY_LIMIT
    );

    return (
      <div>
            <AsyncSelectField2
                name={'reg_organisation'}
                value={organisation??''}
                label={'Организация'}
                required={false}
                column={1}
                onChange={handleChangeSelect}
                loadOptions={(value, callback) => debouncedLoadOptions(value, callback)}
                currentValue={reg_org_name}
                errorMessages={name => errorFields[name]}
                placeholder="Выберите организацию..."/>
            <Row className='mb-3 mt-2'>
                <Col md={2} className={'fw-bold'}>ФИО</Col>
                <Col md={10} className={'fw-bold'}>
                    <FormControl
                        type="text"
                        name={'search'}
                        value={unit_title}
                        placeholder={"Введите ФИО..."}
                        onChange={(e) => {setUnitTitle(e.target.value)}}/>
                </Col>
            </Row>
            <div className="card">
                <div className={'card-header py-3'}>
                    <Row className={'fw-bold'}>
                        <Col sm={4}>ФИО</Col>
                        <Col sm={4}>Должность</Col>
                        <Col sm={4}>Организация</Col>
                    </Row>
                </div>
                <div className="card-body select-area">
                    {errorFields?.search ?
                    <Row>
                        <Col><h4 className={'text-center text-danger'}>{searchErrorMessage}</h4></Col>
                    </Row>: null}
                    {!errorFields?.search && ready && searchResult?.results?.objects?.map(item =>
                    <Row key={item.id}
                         className={selectedUnit.id===item.id ? selectedCls : 'py-2 can-select'}
                         onClick={() => handleSelectUnit(item)}>
                        <Col sm={4}>
                            {item.extra?.approved ? <span className={'me-2 text-success'}><SvgIcon type={svgType.approved} size={{width: 16, height: 16}}/></span> : null}
                            <HighlightText text={item.extra?.full_name} highlight={unit_title}/>
                        </Col>
                        <Col sm={4}>{item.extra?.position}</Col>
                        <Col sm={4}>{item.extra?.org_name}</Col>
                    </Row>
                    )}
                    {!ready ?
                    <div className="text-center">
                        <div className="spinner-border text-primary m-5" role="status"  style={{width: '3rem', height: '3rem'}}>
                            <span className="visually-hidden">Загрузка...</span>
                        </div>
                    </div>
                    : null}
                </div>
                <div className={'card-footer'}>
                    <Row>
                        <Col md={4}>
                            <PaginationInfo
                              count={searchResult?.count}
                              limit={searchResult?.limit}
                              offset={searchResult?.offset}
                            />
                        </Col>
                        <Col md={4}>
                            <div className="d-flex justify-content-center">
                                <Button bsStyle="success"
                                        className={'mx-2'}
                                        disabled={!searchResult?.previous}
                                        onClick={() => handlePagination(searchResult?.previous)}>{'<'}</Button>
                                <Button bsStyle="success"
                                        disabled={!searchResult?.next}
                                        className={'mx-2'}
                                        onClick={() => handlePagination(searchResult?.next)}>{'>'}</Button>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={'text-end'}>
                                <button className={'btn btn-danger mx-3'}
                                        onClick={(e) => handleCancel(e)}>Отмена</button>
                                <button className={'btn btn-success'}
                                        disabled={Object.keys(selectedUnit).length === 0}
                                        onClick={(e) => handleOk(e)}>Добавить</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export const CodeInput = ({
                              name,
                              label,
                              apiUrl,
                              initialValue,
                              requestParams,
                              searchKeys,
                              searchTitles,
                              resultFn,
                              previewFn,
                              disabledPreview = false,
                              disabled = false,
                              data_mart = false,
                              hasIndividualText = true,
                              searchModalTitle = 'Выбор',
                              errorMessages,
                              description
                          }) => {
    const selectedCls = "text-success-emphasis bg-success-subtle py-2";
    const [ready, setReady] = useState(true);

    const textEmpty = '';
    const codeType = 'text';
    const textType = 'text';
    const textDisabled = true;

    const {codeKey, textKey, searchKey} = searchKeys
    const placeholder = "Код";

    const [codeValue, setCodeValue] = useState(initialValue[`${codeKey}`]);
    const textValue = initialValue[`${textKey}`];
    const [debounceCodeValue] = useDebounce(codeValue, BIG_DEBOUNCE_TIMEOUT);

    const hasPreview = previewFn ? true : false;
    const previewEnabled = hasPreview && !disabledPreview && !disabled && (textValue!==textEmpty);

    const [searchResult, setSearchResult] = useState({});
    const [searchSelected, setSearchSelected] = useState({});
    const [isSearchModal, setIsSearchModal] = useState(false);
    const [searchModalQuery, setSearchModalQuery] = useState(textEmpty);
    const [debounceSearchModalQuery] = useDebounce(searchModalQuery, BIG_DEBOUNCE_TIMEOUT);
    const [activeTab, setActiveTab] = useState('template_tab')
    const [errorFields, setErrorFields] = useState({});
    const [textTemplate, setTextTemplate] = useState(description ?? '')
    const errors = typeof errorMessages == 'function' ? errorMessages(name) : [];

    const resultCallback = (res)=> {
        if(resultFn) {
            resultFn(res);
        }
    }

    const fetchData = (mode, raw_url) => {
        let realUrl = raw_url
        if(!realUrl){
            const params = new URLSearchParams();
            for (const [key, value] of Object.entries(requestParams)) {
                params.append(`${key}`, `${value}`);
            }
            if(mode==='code'){
                params.append(`${codeKey}`, codeValue);
                params.append('limit', 1);
            }else{
                params.append(`${searchKey}`, searchModalQuery);
                params.append('limit', QUERY_LIMIT);
            }
            params.append('code__isnull', false)
            const paramsStr = `${params.toString() ? `?${params.toString()}` : ''}`;
            realUrl = apiUrl + paramsStr

        }
        setReady(false);

        uniFetch(realUrl)
            .then((data) => {
                setReady(true);
                if(mode==='code'){
                    let res_data = {};
                    if (data_mart){
                        if(data?.results && data.results?.objects.length > 0){
                            res_data = data.results.objects[0]
                        }else{
                            res_data['extra'] = {}
                            res_data['extra'][`${codeKey}`] = codeValue
                            res_data['extra'][`${textKey}`] = textEmpty
                        }
                    }else{
                        res_data[`${codeKey}`] = codeValue;
                        res_data[`${textKey}`] = textEmpty;
                        if (data?.results.length > 0){
                            res_data = data.results[0]
                        }
                    }
                    resultCallback(res_data);
                }else{
                    setSearchResult(data);
                }
            })
            .catch((error) => {
              setReady(true);
              console.log("CATCH", error) //todo:
              //setTextValue("Ошибка загрузки");
            });
    }

    const handlePagination = (url) => {
        setSearchSelected({});
        fetchData('search', url);
    }

    useEffect(() => {
        if (ready&&(codeValue!==initialValue[`${codeKey}`])){
            if(codeValue!==textEmpty){
                fetchData('code');
            }else if (initialValue[`${codeKey}`]!==textEmpty){
                resultCallback(null)
            }
        }
  }, [debounceCodeValue])


  useEffect(() => {
        if(isSearchModal&&ready){
            fetchData('search');
        }
    }, [debounceSearchModalQuery, isSearchModal])

    useEffect(() => {
        if(codeValue!==initialValue[`${codeKey}`]){
            setCodeValue(initialValue[`${codeKey}`]);
        }

    }, [initialValue])

    //initialValue
    const onCodeChange = (e) => {
        const {name, value} = e.target;
        setCodeValue(value);
    }

    const onShowCatalog = (e) => {
        if(ready){
            setActiveTab(description !== undefined && codeValue == '000' ? 'text_tab': 'template_tab')
            setIsSearchModal(true);
        }
    }

    const onPreview = (e) => {
        if(previewFn){
            previewFn(e);
        }
    }

    const handleCancelModal = (e) =>{
        setIsSearchModal(false);
    }

    const handleOkModal = (e) =>{
        setIsSearchModal(false);
        if(Object.keys(searchSelected).length > 0){
            resultCallback(searchSelected);
        }
    }

    const getText = (col_item, item) => {
        const current_item = data_mart ? item?.extra: item;
        return col_item?.prop?.includes('_fileinfo') ? current_item[`${col_item?.prop}`]?.name : current_item[`${col_item?.prop}`]
    }

    const SelectInput = () => {
        return (
          <>
              <Row className={'py-3'}>
                  <Col md={2} className={'fw-bold'}>{label}</Col>
                  <Col md={10} className={'fw-bold'}>
                      <FormControl
                        type="text"
                        name={'search_template'}
                        value={searchModalQuery}
                        placeholder={"Введите наименование..."}
                        onChange={(e) => {
                            setSearchModalQuery(e.target.value)
                        }}/>
                  </Col>
              </Row>
              <div className="card mb-3">
                  <div className={'card-header py-3'}>
                      <Row className={'fw-bold'}>
                          {searchTitles.map((col_item, col_ind) =>
                            <Col key={col_ind} sm={col_item.col}>{col_item.title}</Col>)
                          }
                      </Row>
                  </div>
                  <div className="card-body select-area">
                      {errorFields?.search_template ?
                        <Row>
                            <Col><h4 className={'text-center text-danger'}>{errorFields.search_template}</h4></Col>
                        </Row> : null}
                      {!errorFields?.search_template && data_mart && ready && searchResult?.results?.objects?.map(item =>
                        <Row key={item.id}
                             className={searchSelected.id === item.id ? selectedCls : 'py-2 can-select'}
                             onClick={() => setSearchSelected(item)}>
                            {searchTitles.map((col_item, col_ind) =>
                              <Col key={col_ind} sm={col_item.col}>
                                  <HighlightText text={getText(col_item, item)} highlight={searchModalQuery}/>
                              </Col>)
                            }
                        </Row>
                      )}
                      {!errorFields?.search_template && !data_mart && ready && searchResult?.results?.map(item =>
                        <Row key={item.id}
                             className={searchSelected.id === item.id ? selectedCls : 'py-2 can-select'}
                             onClick={() => setSearchSelected(item)}>
                            {searchTitles.map((col_item, col_ind) =>
                              <Col key={col_ind} sm={col_item.col}>
                                  <HighlightText text={getText(col_item, item)} highlight={searchModalQuery}/>
                              </Col>)
                            }
                        </Row>
                      )}
                      {!ready ?
                        <div className="text-center">
                            <div className="spinner-border text-primary m-5" role="status"
                                 style={{width: '3rem', height: '3rem'}}>
                                <span className="visually-hidden">Загрузка...</span>
                            </div>
                        </div>
                        : null}
                  </div>
                  <div className={'card-footer'}>
                      <Row>
                          <Col md={4}>
                              <PaginationInfo
                                count={searchResult?.count}
                                limit={searchResult?.limit}
                                offset={searchResult?.offset}
                              />
                          </Col>
                          <Col md={4}>
                              <div className="d-flex justify-content-center">
                                  <Button bsStyle="success"
                                          className={'mx-2'}
                                          disabled={!searchResult?.previous}
                                          onClick={() => handlePagination(searchResult?.previous)}>{'<'}</Button>
                                  <Button bsStyle="success"
                                          disabled={!searchResult?.next}
                                          className={'mx-2'}
                                          onClick={() => handlePagination(searchResult?.next)}>{'>'}</Button>
                              </div>
                          </Col>
                          <Col md={4}>
                              <div className={'text-end'}>
                                  <button className={'btn btn-danger mx-3'}
                                          onClick={handleCancelModal}>Отмена
                                  </button>
                                  <button className={'btn btn-success'}
                                          disabled={Object.keys(searchSelected).length === 0}
                                          onClick={handleOkModal}>Выбрать
                                  </button>
                              </div>
                          </Col>
                      </Row>
                  </div>
              </div>
          </>
        )
    }

    const handleSelectTab = (key) => {
        setActiveTab(key);
    }
    const handleChangeText = (name, value) => {
        setTextTemplate(value)
    }
    const handleConfirm = () => {
        setIsSearchModal(false);
        if (textTemplate) {
            resultCallback({description: textTemplate, code: '000', title: 'Индивидуальный текст'});
        }
    }
  return(
      <div className="form-group row form-group has-feedback">
          {label && <label htmlFor={'code_name'} className={`col-md-2 col-form-label control-label${errors?.length ? ' text-danger' : ''}`}>{label}</label>}
            <div className="col-md-2">
                <input name={'code_name'}
                       placeholder={placeholder}
                       type={codeType}
                       value={codeValue??''}
                       onChange={onCodeChange}
                       disabled={disabled}
                       className="form-control"/>
            </div>
          <div className={hasPreview ? 'col-md-6': 'col-md-8'}>
              <div className={'input-group'}>
                  <input type={textType}
                         value={textValue??''}
                         onChange={onCodeChange}
                         name={'text_name'}
                         disabled={textDisabled || disabled}
                         className={`form-control ${textDisabled || disabled ? 'disabled' : ''}`}
                  />
              <button className="btn btn-outline-primary add-on" type="button" onClick={onShowCatalog} disabled={disabled}>
                  {ready ? <SvgIcon type={svgType.folder2open} size={{width: 16, height: 16}}/>
                      :
                      <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  }
              </button>
          </div>
          {errors?.length ? errors?.map((error, index) => <p key={index} className="text-danger">{error}</p>) : null}
      </div>
    {
        hasPreview ?
            <div className={'col-2'}>
                <button className={`btn btn-outline-success add-on w-100 ${!previewEnabled ? 'disabled' : ''}`}
                        type="button"
                        onClick={onPreview}>
                    <SvgIcon type={svgType.eye} size={{width: 16, height: 16}}/>
                </button>
            </div> : null}

          <FieldModal isOpen={isSearchModal} onClose={handleCancelModal} title={searchModalTitle}>
              {name === 'template' && hasIndividualText ?
                <Tabs id='fz-template-tabs' onSelect={handleSelectTab} activeKey={activeTab}>
                    <Tab title={'Шаблон'}
                         eventKey="template_tab"
                         className={activeTab === 'template_tab' ? 'show active' : ''}>
                        {activeTab === 'template_tab' && SelectInput()}
                    </Tab>
                    <Tab title={'Индивидуальный текст'}
                         eventKey="text_tab"
                         className={activeTab === 'text_tab' ? 'show active' : ''}>
                        {activeTab === 'text_tab' && <div>
                            <CKEditorField
                              name={'text'}
                              value={() => textTemplate ?? ''}
                              label={() => ''}
                              hideLabel={true}
                              wrappable={true}
                              onChange={handleChangeText}
                            />
                            <div className={'text-end my-3'}>

                                <button className={'btn btn-danger mx-3'}
                                        onClick={handleCancelModal}>Отмена
                                </button>
                                <button className={'btn btn-success'}
                                        disabled={!textDisabled}
                                        onClick={handleConfirm}>Выбрать
                                </button>
                            </div>
                        </div>}
                    </Tab>
                </Tabs> :
                SelectInput()
              }

          </FieldModal>
      </div>
  );
};
/////////
////////

export function EmailLogModal(props) {
    const {history_data,} = props
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const log_style = {
        minHeight:'400px',
        minWidth:'400px',
        padding:'9px',
        boxSizing:'none',
        fontSize:'13px'
    };
    const modal_style = {
        paddingTop:'20px',
    };
    return (
        <>
            <Button
                variant="primary"
                type="button"
                className="btn btn-sm btn-outline-success mr-2"
                onClick={handleShow}
            >
            <SvgIcon type={svgType.eye} size={{width: 16, height: 16}}/>
            </Button>
            <Modal
                {...props}
                show={show}
                onHide={handleClose}
                size="sm"
                backdrop={false}
                keyboard={true}
                animation={false}
                style={modal_style}
            >
            <Modal.Header>
                Журнал отправки электронной почты
                <Button
                    variant="secondary"
                    type="button"
                    className="btn btn-sm btn-outline-light"
                    onClick={handleClose}
                    title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
                </Button>
            </Modal.Header>
            <Modal.Body>
                {history_data.logs && history_data.logs.length > 0 ?
                <Table className='table table-sm table-striped table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th>Данные отправки</th>
                            <th>Состояние</th>
                            <th>Сообщение</th>
                        </tr>
                    </thead>
                    <tbody>
                    {history_data.logs.map((log_data, index) => {
                        return (
                            <tr key={index}>
                                <td>{history_data.email}</td>
                                <td><span style={log_data.status === 1 ? {'color': 'red'}: {'color': 'green'}}>{log_data.send_date}<br/>{emailSendStatus[log_data.status]}</span></td>
                                <td>
                                    <textarea
                                        readOnly
                                        maxLength="25000"
                                        className="col-12 form-control-sm mt-0"
                                        style={log_style}
                                        value={log_data.message}
                                    ></textarea>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </Table>
                : null}
            </Modal.Body>
            </Modal>
        </>
    );
}

function EmailSendResultBlock({data, entity_type, entity_id, resendEmail}){
    return(
        <>
        {data && data.emails_history && data.emails_history.length > 0 ?
        <Col sm={12} className={'ps-0 pe-0'}><table className='table'>
            <thead>
                <tr>
                    <th scope="col">Электронная почта</th>
                    <th scope="col">Дата отправки</th>
                    <th scope="col">Состояние</th>
                    <th scope="col">Действия</th>
                </tr>
            </thead>
            <tbody>
            {data.emails_history.map((history_data, index) => {
                return (
                    <tr key={index}>
                        <td><span className={'wrap-words'}>{history_data.email}</span></td>
                        <td>{history_data.queued_date}</td>
                        <td><span style={history_data.status === 1 ? {'color': 'red'}: {'color': 'green'}}>{emailSendStatus[history_data.status]}</span></td>
                        <td>
                            <EmailLogModal history_data={history_data}></EmailLogModal>
                            {history_data.status === 1 ?
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => resendEmail({
                                    entity_type: entity_type,
                                    entity_id: entity_id,
                                    email_id: history_data.id
                                })}
                            >
                            <SvgIcon type={svgType.arrowClockwise} size={{width: 16, height: 16}}/>
                            </button> : null}
                        </td>
                    </tr>)
                })}
                </tbody>
            </table></Col>
        : null
        }
    </>
    )
}

const SentEntity = ({entity, apiCommand, updateCommand, entity_name, canDelete = false}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [responseStatus, setResponseStatus] = useState(0);
    const [unActiveModal, setUnActiveModal] = useState(false)

    const handleResendEmail = (data) => {
        apiCommand("command_resend_email", data);
    }

    const deleteHandler = () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('active', false)
        uniFetch(`/fz59/api/${entity_name}/${entity.id}/`, 'PATCH', formData, false, false, true).then((data) => {
            updateCommand(`command_delete_${entity_name}`, entity.id);
            setResponseStatus(200);
        }).catch(data => {
            if (data?.code && data?.errors) {
                setResponseStatus(data?.code)
                setErrorFields(data?.errors);
            } else {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            }
        }).finally(data => {
            setIsLoading(false);
            setUnActiveModal(false);
        });
    }
    const handleCloseResponseModal = () =>{
        setResponseStatus(0)
    }


    return (<div className="row block-table-row">
        {isLoading ? <LoaderModal/> : null}
        <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
            <ResponseAlert status={responseStatus} result={errorFields} labels={labels} successTitle={'Удалено'}/>
        </ResponseModal>
        {unActiveModal && (
          <ConfirmModal
            label={'Удаление'}
            text={`Вы действительно хотите удалить ${entity_name === 'resolution' ? 'резолюцию' : 'уведомление'}?`}
            handleCancel={() => setUnActiveModal(false)}
            handleConfirm={() => deleteHandler()}
          />)}
            <Row className={'pb-3 pe-0'}>
                <Col sm={4} className={'fw-bold'}><h5>№ {entity.id}</h5></Col>
                 <Col sm={8} className={'d-flex justify-content-end p-0'}>
                     {deleteButton(()=>setUnActiveModal(true), !canDelete)}
                </Col>
            </Row>
            <Row className={'pb-3'}>
                <Col sm={4} className={'fw-bold'}>Дата</Col>
                <Col sm={8}>
                    {entity.send_date ? convertDateFormat(entity.send_date) : null}
                </Col>
            </Row>
            {entity.specialist_title ? <Row className={'pb-3'}>
                <Col sm={4} className={'fw-bold'}>Специалист</Col>
                <Col sm={8}>{entity.specialist_code} | {entity.specialist_title}</Col>
            </Row> : null}
            {entity.blank_title ? <Row className={'pb-3'}>
                <Col sm={4} className={'fw-bold'}>Бланк</Col>
                <Col sm={8}>{entity.blank_code} | {entity.blank_title}</Col>
            </Row> : null}
            {entity.template_title ? <Row className={'pb-3'}>
                <Col sm={4} className={'fw-bold'}>Шаблон</Col>
                <Col sm={8}>{entity.template_code} | {entity.template_title}</Col>
            </Row> : null}
            {entity.additional ? <Row className={'pb-3'}>
                <Col sm={4} className={'fw-bold'}>Приложение</Col>
                <Col sm={8}>{entity.additional}</Col>
            </Row> : null}
            {(entity.addition_fileinfo || entity.signed_fileinfo) ? <Row className={'pb-3'}>
                  <Col sm={4} className={'fw-bold'}>Файлы</Col>
                  <Col sm={8}>
                      {entity.addition_fileinfo ?
                        <Row>
                            <a className={'w-100'}
                               target={'_blank'}
                               href={entity.addition_fileinfo.file}>
                                <span>{entity.addition_fileinfo.name}</span>
                            </a></Row> : null}
                      {entity.signed_fileinfo ?
                        <Row><a className={'w-100'}
                                target={'_blank'}
                                href={entity.signed_fileinfo.file}>
                            <span>{entity.signed_fileinfo.name}</span>
                        </a></Row> : null}
                      {entity.sig_fileinfo ?
                        <Row><a className={'w-100'}
                                target={'_blank'}
                                href={entity.sig_fileinfo.file}>
                            <span>{entity.sig_fileinfo.name}</span>
                        </a></Row> : null}
                  </Col>
              </Row>
              : null
            }
            <EmailSendResultBlock
              data={entity.data}
              entity_type={entity_name}
              entity_id={entity.id}
              resendEmail={handleResendEmail}
            >
            </EmailSendResultBlock>
            {entity.send_by_post ? <Row>
                  <Col sm={4}>Почтовым отправлением</Col>
                  <Col sm={8}>{entity.send_by_post}</Col>
              </Row>
              : null
            }
    </div>)
}
///////////////

//onremovefile={onremovefile}
//onChangeFile={onChangeFile}
//beforeRemoveFile={beforeRemoveFile}
//setSearch(e.target.value)
export function ResponsibleGroup({attrs, gid, orgunit, reg_organisation, message_problems, apiCommand, updateCommand, modelDefinition, isLoading, setIsLoading, messageId, canEdit}) {

    const {group, local, title, responsible, resolutions, notifications, can_delete} = attrs;
    const [activeTab, setActiveTab] = useState("resp_common" + gid)//useState("resp_common" + gid)
    const [showAddModal, setShowAddModal] = useState(false)
    const [forDel, setForDel] = useState(null)
    const [clearAll, setClearAll] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [responseStatus, setResponseStatus] = useState(0);
    const [isNotificationModal, setIsNotificationModal] = useState(false);
    const [isResolutionModal, setIsResolutionModal] = useState(false);
    const sentNotificationsBottomRef = useRef(null)
    const applicantTableLabels = []
    //todo: activeResolution check not_need

    const sentResolutions = resolutions.filter((element) => element.send && !element.responsible)?.reverse() || [];
    const sentNotifications = notifications.filter((element) => element.send)?.reverse() || [];
    const activeResolution = resolutions.find((element) => !element.send && !element.responsible) || {};
    const activeNotification = notifications.find((element) => !element.send) || {};

    const scrollToNotificationsEnd = () => {
        if (sentNotificationsBottomRef.current) {
            const lastItemRect = sentNotificationsBottomRef.current.getBoundingClientRect();
            const offset = lastItemRect.top + window.scrollY;

            window.scrollTo({
                top: offset - 80,
                behavior: 'smooth'
            });
        }
    };

    const handleChangeList = (intem_id) => {
        handleSelectTab('resp_detail_' + gid + '_' + intem_id);
    }
    const handleDelete = (data) => {
        setForDel(data);
    }
    const handleConfirmDelete = (e) => {
        apiCommand("command_delete_responsible", Object.assign({}, forDel));
        setForDel(null);
    }
    const handleCancleDelete = (e) => {
        setForDel(null);
    }

    const handleConfirmClear = (e) => {
        apiCommand("command_clear_responsible", group);
        setClearAll(false);
    }
    const handleCancleClear= (e) => {
        setClearAll(false);
    }

    const handleSelectTab = (key) => {
        setActiveTab(key);
    }

    const handleShowAddResponsible = (e) =>{
        setShowAddModal(true);
    }

    const handleCancelResponsible = (e) =>{
        setShowAddModal(false);
    }

    const handleAddResponsible = (data)=>{
        apiCommand("command_add_responsible", Object.assign({}, {'group': group}, data));
        setShowAddModal(false);
    }

    const renderAddModal = ()=>{
        if (!showAddModal) return null;
          return (
              <div className="block-container">
                  <div className="modal-overlay">
                      <div className="modal-content content container" onClick={(e) => e.stopPropagation()}>
                          <div
                            className="block-label d-flex justify-content-between align-items-center modal-label">
                          <label>
                              Добавить Исполнителя
                          </label>
                          <Button
                              variant="secondary"
                              type="button"
                              className="btn btn-sm btn-outline-light"
                              onClick={(e) =>{handleCancelResponsible(e)}}
                              title='Закрыть'><SvgIcon type={svgType.close} size={{width: 16, height: 16}}/>
                            </Button>
                          </div>
                          <div className="row pb-4">
                              <AddResponsible
                                onClose={handleCancelResponsible}
                                onAdd={handleAddResponsible}
                                reg_organisation={reg_organisation}
                                reg_org_name={modelDefinition['reg_organisation'].current_value}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          );
    }
    const renderConfirmDeleteResponsibleModal = () =>{
        if(!forDel) return null;
        return (<ConfirmModal
            label={'Удаление ответственного'}
            text={'Вы действительно собираетесь удалить ответственного?'}
            handleConfirm={handleConfirmDelete}
            handleCancel={handleCancleDelete}/>);
    }
    const renderConfirmClearAllModal = () =>{
        if(!clearAll) return null;
        return (<ConfirmModal
            label={'Удаление группы ответственных'}
            text={'Внимание будут удалены все ответственные из данной группы. Вы действительно собираетесь удалить группу?'}
            handleConfirm={handleConfirmClear}
            handleCancel={handleCancleClear}/>);
    }

    const renderAddResolution = ()=>{
        return(
            <BlockContainer label='Добавить Резолюцию'>

            </BlockContainer>
        )
    }

    const deleteGroupButton = () => {
        return (
          <button type="button" className="btn btn-outline-danger"
                  title={'Удаление группы'}
                  onClick={e => {
                      e.stopPropagation()
                      setClearAll(true)
                  }}>
              <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
          </button>)
    }
    const handleCloseResponseModal = () => {
        setResponseStatus(0)
    }

    const deleteHandler = (id, type) => {
        setIsLoading(true)
        uniFetch(`/fz59/api/${type}/${id}/`, 'DELETE').then((data) => {
            updateCommand(`command_delete_${type}`, id);
            setResponseStatus(204);
        }).catch(data => {
            if (data?.code && data?.errors) {
                setResponseStatus(data?.code)
                setErrorFields(data?.errors);
            } else {
                setResponseStatus(500)
                setErrorFields({error: 'Ошибка сервера'});
            }
        }).finally(data => {
            setIsLoading(false);
            setIsNotificationModal(false);
            setIsResolutionModal(false);
        });
    }
    const checkAnswer = (data) => {
        let status = 'empty';
        if (data?.answers?.length) {
            status = 'not_send';
            if (data.answers.some(answer => answer.send)) {
                status = 'send';
            }
        }
        return status;
    };

      const checkAskSign = (data) => {
        let res = resolutions?.some(item => { return item?.wait_sign==="ASKED"&&data?.id=== item?.responsible});
        if (res) return res;
        res = data?.answers?.some(item => { return item?.wait_sign==="ASKED" });
        if (res) return res;

        return false;
      }
    const checkAskSignGroup = () => {
        let res = resolutions?.some(item => { return item?.wait_sign==="ASKED"&&!item?.responsible});
        if (res) return res;
        res = notifications?.some(item => { return item?.wait_sign==="ASKED" });
        if (res) return res;

        return false;
      }

    const hasAskSignGroup = checkAskSignGroup();
    return (
        <div className="custom-r">
            {renderConfirmDeleteResponsibleModal()}
            {renderConfirmClearAllModal()}
            {renderAddModal()}
            <Tabs id={'resp-group-detail-' + gid} onSelect={handleSelectTab} activeKey={activeTab}>
                <Tab title={<>{'Исполнители'}{hasAskSignGroup ?
                          <span className={'position-absolute top-0 start-100 translate-middle'}>
                              <SvgIcon
                                  type={svgType.askSign}
                                  size={{width: 16, height: 16}}
                                  color={'#e73751'}/>
                          </span>: null}
                      </>}
                     className={activeTab === "resp_common" + gid ? 'position-relative show active' : ''}
                     eventKey={"resp_common" + gid}
                     key={'respdetail'}>
                    {activeTab === "resp_common" + gid ?
                      <>
                          {isLoading ? <LoaderModal label={'Сохранение...'}/> : null}
                          <ResponseModal onClose={handleCloseResponseModal} status={responseStatus}>
                              <ResponseAlert status={responseStatus} result={errorFields} labels={labels} successTitle={'Удалено'}/>
                          </ResponseModal>
                          <BlockContainer label='Исполнители'
                                          rightAction={can_delete && deleteGroupButton()}>
                                <ResponsibleTable
                                  name="responsible"
                                    local={local}
                                    value={responsible}
                                    message_problems={message_problems}
                                    label={applicantTableLabels}
                                    onChange={handleChangeList}
                                    onDelete={handleDelete}
                                    messageId={messageId}
                                    apiCommand={apiCommand}
                                    onShowAdd={handleShowAddResponsible}
                                    canEdit={canEdit}
                                />
                            </BlockContainer>
                            <Row>
                                <div ref={sentNotificationsBottomRef}></div>
                                <Col md={6}>
                                    <BlockContainer label='Отправленные резолюции'>
                                        {sentResolutions.length > 0 ? sentResolutions.map((resolution, index) => {
                                            return (
                                              <SentEntity
                                              key={index}
                                              entity={resolution}
                                              apiCommand={apiCommand}
                                              updateCommand={updateCommand}
                                              canDelete={orgunit?.permissions?.can_root || orgunit?.permissions?.can_all }
                                              entity_name={'resolution'}/>
                                            )
                                        }) : <Row><p className='mb-0'>Нет отправленных резолюций</p></Row>}
                                    </BlockContainer>
                                </Col>
                                <Col md={6}>
                                    <BlockContainer label='Отправленные уведомления заявителю'>
                                        {sentNotifications.length > 0 ? sentNotifications.map((notification, index) => {
                                            return (
                                              <SentEntity
                                              key={index}
                                              entity={notification}
                                              apiCommand={apiCommand}
                                              updateCommand={updateCommand}
                                              canDelete={orgunit?.permissions?.can_root || orgunit?.permissions?.can_all }
                                              entity_name={'notification'}/>
                                            )
                                        }) : <Row><p className='mb-0'>Нет отправленных уведомлений</p></Row>}
                                        </BlockContainer>
                                </Col>
                            </Row>
                            {canEdit ? <Row>
                                <Col md={6}>
                                    {isResolutionModal &&
                                      <ConfirmModal
                                        label={'Удаление резолюции'}
                                        text={'Вы действительно собираетесь удалить сохраненную резолюцию?'}
                                        handleConfirm={() => deleteHandler(activeResolution.id, 'resolution')}
                                        handleCancel={() => setIsResolutionModal(false)}
                                      />
                                    }
                                    <BlockContainer
                                      label={
                                          activeResolution?.wait_sign === 'ASKED' ?
                                              <>
                                                  <SvgIconCls type={svgType.askSign} size={{width: 24, height: 24}} customCls={'text-danger me-3'}/>
                                                  <span className={'text-danger'}>Резолюция исполнителям (ожидает подписания)</span>
                                              </> :<>
                                              {activeResolution?.wait_sign === 'SIGNED' ?
                                                  <>
                                                      <SvgIconCls type={svgType.wasSigned} size={{width: 24, height: 24}} customCls={'me-3'}/>
                                                      <span>Резолюция исполнителям (подписана)</span>
                                                  </> : <span>Добавить резолюцию исполнителям</span>
                                              }</>
                                      }
                                      rightAction={activeResolution.id && deleteButton(() => setIsResolutionModal(true))}
                                    >
                                        <AddEntityForm
                                            groupID={group}
                                            reg_organisation={reg_organisation}
                                            orgunit={orgunit}
                                            messageId={messageId}
                                            entity={activeResolution}
                                          entity_name={'resolution'}
                                          sendCallback={()=>scrollToNotificationsEnd()}
                                          apiCommand={apiCommand}
                                          updateCommand={updateCommand}
                                          labels={resolutionFormLabels}
                                        />
                                    </BlockContainer>
                                </Col>
                                <Col md={6}>
                                    {isNotificationModal &&
                                      <ConfirmModal
                                        label={'Удаление уведомления'}
                                        text={'Вы действительно собираетесь удалить сохраненное уведомление?'}
                                        handleConfirm={() => deleteHandler(activeNotification.id, 'notification')}
                                        handleCancel={() => setIsNotificationModal(false)}
                                      />
                                    }
                                    <BlockContainer
                                      label={activeNotification?.wait_sign === 'ASKED' ?
                                              <>
                                                  <SvgIconCls type={svgType.askSign} size={{width: 24, height: 24}} customCls={'text-danger me-3'}/>
                                                  <span className={'text-danger'}>Уведомление заявителям (ожидает подписания)</span>
                                              </> :<>
                                              {activeNotification?.wait_sign === 'SIGNED' ?
                                                  <>
                                                      <SvgIconCls type={svgType.wasSigned} size={{width: 24, height: 24}} customCls={'me-3'}/>
                                                      <span>Уведомление заявителям (подписана)</span>
                                                  </> : <span>Добавить уведомление заявителям</span>
                                              }</>
                                      }
                                      rightAction={activeNotification.id && deleteButton(()=>setIsNotificationModal(true))}>
                                        <AddEntityForm
                                          groupID={group}
                                          reg_organisation={reg_organisation}
                                          orgunit={orgunit}
                                          messageId={messageId}
                                          entity={activeNotification}
                                          entity_name={'notification'}
                                          apiCommand={apiCommand}
                                          sendCallback={()=>scrollToNotificationsEnd()}
                                          updateCommand={updateCommand}
                                          exclude_fields={['additional']}
                                          labels={notificationFormLabels}
                                        />
                                    </BlockContainer>
                                </Col>
                            </Row>: null}
                        </> : null}
                </Tab>
                {responsible.map((resp_person, index) => {
                      const hasAnswer = checkAnswer(resp_person)
                      const hasAskSign = checkAskSign(resp_person);
                      return (
                        <Tab
                          title={
                              <>
                                  {resp_person.unit.short_fio}
                                  <span className={'position-absolute top-0 start-100 translate-middle'}>
                                    {hasAskSign ?
                                      <SvgIcon
                                      type={svgType.askSign}
                                      size={{width: 16, height: 16}}
                                      color={'#e73751'}/> : null}
                                    { hasAskSign ||hasAnswer === 'empty' ? null :
                                      <SvgIcon
                                        type={svgType.envelope}
                                        size={{width: 16, height: 16}}
                                        color={hasAnswer === 'send' ? '#44D686':'#e73751'}
                                      />}
                                </span>
                              </>}
                          className={activeTab === 'resp_detail_' + gid + '_' + resp_person.id ? 'show active' : ''}
                          eventKey={'resp_detail_' + gid + '_' + resp_person.id} key={'respdetail_' + index}>
                            {activeTab === 'resp_detail_' + gid + '_' + resp_person.id ?
                              <ResponsibleDetail
                                local={local}
                                canEdit={canEdit}
                                orgunit={orgunit}
                                person={resp_person}
                                reg_organisation={reg_organisation}
                                message_problems={message_problems}
                                resolutions={resolutions}
                                index={index}
                                apiCommand={apiCommand}
                                updateCommand={updateCommand}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                messageId={messageId}
                                modelDefinition={modelDefinition}
                                setActiveTab={setActiveTab}
                                groupID={gid}
                              />
                              : null}
                        </Tab>
                      )
                  }
                )}
            </Tabs>
        </div>
    );
}
