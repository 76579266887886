import React, {useState, useEffect, useRef, useMemo} from 'react';
import cookie from 'react-cookies';
import uniFetch from "../../utils/uniFetch";
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  HelpBlock,
} from 'react-bootstrap';
import {BlockContainer} from "../../components/Fields/BlockContainer";
import {AsyncSelectField} from "../../components/Fields/AsyncSelectField";
import {StaticField} from "../../components/Fields/StaticField";
import {DateField} from "../../components/Fields/DateField";
import {InputField} from "../../components/Fields/InputField";
import {ButtonGroupField} from "../../components/Fields/ButtonGroupField";
import {SelectField} from "../../components/Fields/SelectField";
import {CKEditorField} from "../../components/Fields/CKEditorField";
import {FilesField} from "../../components/Fields/FilesField";
import {ResponsibleGroup} from "./responsible-group-tab";
import {DEBOUNCE_TIMEOUT, QUERY_LIMIT} from "../../constants"
import {
  getStamp,
  isDateExpired,
  convertDateFormat,
  filteredFields,
  formatDateToSelectOptions,
  getSelectOptionFromSlug,
  getCurrentDateTime
} from "../../utils/common";
import {AppealTable} from "./DetailComponents/AppealTable";
import {ApplicantTable} from "./DetailComponents/ApplicantTable";
import {ResponseAlert} from "../../components/ResponseAlert";
import {FilePodServer, formatFiles, formattedFile, handleRemoveFiles} from "../../utils/filepond";
import {ReadDetail} from "./DetailComponents/ReadDetail";
import {SvgIcon, svgType} from "../../components/Icons";
import {ApplicantAnswers} from "./DetailComponents/ApplicantAnswers";
import { LoaderModal, ResponseModal, HTMLConfirmModal, ConfirmModal, FieldModal } from "../../components/Modal";
import {DuplicateForm} from "./DetailComponents/DuplicateForm";
import {DuplicateTable} from "./DetailComponents/DuplicateTable";
import {formatPermissions} from "../fzorganization/organization-tabs";
import { SimpleFileInput } from "../../components/Fields/SimpleFileField";
import { ResponsePresidentTab } from "./response-president-tab";
import {SSTUTab} from "./sstu-tab";
import {POSTab} from "./pos-tab";
import FzActionLogTab from "../fz-action-log-tab";

const fullColumnSize = 12;
const halfColumnSize = fullColumnSize / 2;

export const labels = {
  fio: 'ФИО',
  postal_address: 'Почтовый адрес',
  reg_number: 'Регистрационный номер',
  reg_at: 'Дата регистрации',
  reg_at_reception: 'Время приема',
  reg_at__gte: 'Дата регистрации c',
  reg_at__lte: 'Дата регистрации по',
  post_at: 'Дата поступления',
  post_at__gte: 'Дата поступления c',
  post_at__lte: 'Дата поступления по',
  recept_at: 'Дата записи',
  income_number: 'Исходящий номер организации источника',
  income_at: 'Дата поступления организации источника',
  income_at__gte: 'Дата поступления организации источника c',
  income_at__lte: 'Дата поступления организации источника по',
  expired_at__lte: 'Дата истечения',
  sed_number: 'Номер в СЭД',
  annotation: 'Аннотация',
  frequency_type: 'Частота обращения',
  income_type: 'Тип автора',
  appeal_loan_type: 'Тип рассмотрения обращения',
  applicant_type: 'Тип заявителя',
  income_source_type: 'Тип обращения по источнику',
  destination_type: 'Адресат по типу',
  region_type: 'Регион',
  appeal_cont_type: 'Тип обращения',
  appeal_chars_type: 'Характеристика',
  expired_at: 'Дата истечения',
  closed_at: 'Дата закрытия',
  state: 'Статус',
  reg_organisation: 'Организация регистрации',
  registrator: 'Регистриратор',
  income_organisation: 'Организация источник',
  description: 'Описание',
  files: 'Файлы',
  applicants: 'Заявители',
  problems: 'Проблемы',
  duplicates: 'Дупликаты',
  creator: 'Создатель',
  duplicate_for: 'Дубликат для',
  status_reason: 'Причина отправки на регистрацию',
  parent: 'Исходное сообщение',
  children: 'Дочерние обращения',
  source_type: 'Тип источника',
  uid_work_correspondence: 'UID рабочей корреспонденции из Управления Президента',
  uid_response: 'UID ответа в Управления Президента',
  sstu: 'Данные ССТУ',
  status_sstu: 'статус ССТУ',
  status_pos: 'статус ПОС',
  active: 'Активное',
  qsearch: "Поиск",
  asked_delete: 'Запрошено удаление'
};

export const applicantTableLabels = {
  id: '#',
  last_name: 'Фамилия',
  last_name_dp: 'Фамилия ДП',
  first_name: 'Имя',
  middle_name: 'Отчество',
  orgname: 'Организация',
  citizenship: 'Гражданство',
  social_status: 'Социальный статус',
  preference_status: 'Льготный состав',
  inn: 'ИНН',
  phone_number: 'Телефон',
  email: 'Email',
  gender: 'Пол',
  postal: 'Почта',
  region_type: 'Район'
};

const { id, ...newLabels } = applicantTableLabels;

export const applicantLabels = {
  ...newLabels,
  postal_code: 'Индекс',
  postal_country: 'Страна',
  postal_region: 'Регион',
  postal_district: 'Район',
  postal_city: 'Населенный пункт',
  postal_street: 'Улица',
  postal_home: 'Дом',
  postal_corpus: 'Корпус',
  postal_house: 'Квартира',
};

const handleOpenPrintTemplate = (id) => {
  window.open(`/fz59/envelope_print/${id}`, '_blank', 'width=900,height=1600')
}
const handleOpenPrintCard = (id) => {
  window.open(`/fz59/card_print/${id}`, '_blank', 'width=900,height=1600')
}
const handleOpenPrintMessage = (id) => {
  window.open(`/fz59/message_print/${id}`, '_blank', 'width=900,height=1600')
}

function DownloadMedoModal({
         isModal, handleModelClose, file, setFile, modelDefinition,
         handleSendFileMedo, errorFieldsMedo, isButtonMedoLoading
}) {

  return (
    <FieldModal
      className={'w-50'}
      isOpen={isModal}
      onClose={handleModelClose}
      title={'Загрузка файла, полученного по каналам МЭДО'}
    >
      <Row className="mb-3">
        <Col sm={12}>
          <InputField
            name="reg_organisation"
            value={name => modelDefinition[name]?.current_value}
            label={name => 'Регистрирующая организация'}
            required={false}
            column={2}
            row={2}
            errorMessages={name => null}
            onChange={null}
            disabled={true}
          />
        </Col>
        <Col sm={12} className={'mt-3'}>
          <SimpleFileInput
            name={'file'}
            label={name => 'Файл'}
            files={formatFiles(file)}
            setFiles={setFile}
            server={FilePodServer(0)}
            instantUpload={false}
            allowDownload={true}
            isConfirmDelete={false}
            required={true}
            column={1}
            row={2}
            errorMessages={name => null}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={fullColumnSize} className='mt-4 mb-2 text-center'>
          {errorFieldsMedo?.message &&
            <HelpBlock className="alert alert-danger d-flex justify-content-center">{errorFieldsMedo.message} {errorFieldsMedo.data}</HelpBlock>}
        </Col>
      </Row>
      <Row className="mb-3">
      <div className="d-flex justify-content-end">
        <Button
          bsStyle="danger"
          className={'mx-2 my-3'}
          onClick={handleModelClose}
          >
          Отменить
        </Button>
        <Button
          bsStyle="success"
          className={'my-3'}
          disabled={isButtonMedoLoading}
          onClick={handleSendFileMedo}>
          Загрузить
        </Button>
      </div>
    </Row>
    {isButtonMedoLoading && (
      <div className="loader-overlay">
        <div className="loader-spinner"></div>
      </div>
    )}
</FieldModal>
)
}

function ActionButtons({
                         canAdd,
                         canEdit,
                         isEditing,
                         setIsEditing,
                         id,
                         modalMedo,
                         modalDuplicate,
                         modalDuplicateFor,
                         state,
                         reg_number,
                         reg_at,
                         post_at,
                         expired_at,
                         is_reception,
                         can_delete,
                         deleteFn,
                       }) {

  const regDate = reg_at ? convertDateFormat(reg_at): null
  const postDate = post_at ? convertDateFormat(post_at): null
  const expiredDate = expired_at ? convertDateFormat(expired_at): null
  const isExpired = isDateExpired(expired_at, 1);

  return (
      <div className="row pt-5 pb-2">
        <div className="col-sm-6">
          { !is_reception ?
              <h2>{reg_number ? `Обращение №${reg_number} от ${regDate}` : `Незарегистрированное  ${id} от ${postDate}`}</h2> :
              <h2>Запись на прием {postDate}</h2>
          }
          { !is_reception&&expiredDate ?
              <h5>Срок до: <span className={isExpired ? 'text-danger': ''}>{expiredDate}</span></h5>: null
          }
        </div>
        <div className="col-sm-6">
          <div className="d-flex justify-content-end general-actions">
            { canEdit ? <button className="btn btn-outline-success me-2" onClick={() => modalDuplicateFor(true)}>Проверить
              повторное</button>: null}
            { !is_reception && canAdd && (state === 'draft' || state === 'new') ?
            <button className="btn btn-outline-secondary me-2" onClick={() => modalDuplicate(true)}>Создать на основе</button> : null
            }
            {!is_reception && canAdd && (state === 'draft' || state === 'new') ?
            <button className="btn btn-outline-secondary me-2" onClick={() => modalMedo(true)}>Загрузить из МЭДО</button> : null
            }
            {isEditing ? <button className="btn btn-success" onClick={() => setIsEditing(false)}>
                <SvgIcon type={svgType.eye} size={{width: 16, height: 16}}/>
              </button> :
              <button className="btn btn-primary" onClick={() => setIsEditing(true)}>
                <SvgIcon type={svgType.edit} size={{width: 16, height: 16}}/>
              </button>
            }
            <button
              className="btn btn-secondary ml-2 dropdown-toggle"
              data-bs-toggle="dropdown">
              <SvgIcon type={svgType.printer} size={{width: 16, height: 16}}/>
            </button>
            <ul className="dropdown-menu dropdown-menu-dark">
              <li><a className="dropdown-item" href="#" onClick={() => handleOpenPrintMessage(id)}>Печать</a></li>
              <li><a className="dropdown-item" href="#" onClick={() => handleOpenPrintCard(id)}>Карточка приема</a></li>
              <li><a className="dropdown-item" href="#" onClick={() => handleOpenPrintTemplate(id)}>Конверт заявителю</a>
              </li>
              <li><a className="dropdown-item" href="#">Штрихкод</a></li>
            </ul>
            {can_delete ?
                <button className="btn btn-danger ml-2" onClick={deleteFn}>
                  <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                </button>
                : null}
          </div>
        </div>
      </div>
  )
}

function FZMessageDetail({attrs}) {
  const [fields, setFields] = useState({});
  const [responsible, setResponsible] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [modelDefinition, setModelDefinition] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [responseStatus, setResponseStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [isDuplicateDelModal, setIsDuplicateDelModal] = useState(false);
  const [isAddDuplicateModal, setIsAddDuplicateModal] = useState(false);
  const orgunit_item = attrs.getNamedItem('orgunit');
  const orgunit = orgunit_item ? JSON.parse(attrs.getNamedItem('orgunit').value) : {};
  const messageId = attrs.getNamedItem('message-id').value ? parseInt(attrs.getNamedItem('message-id').value): 0;
  const permsUnit = formatPermissions(attrs.getNamedItem('perms-unit').value); //todo: rebuild from orgunit
  const messageApiUrl = `/edw/api/entities/${messageId}/`;
  const some_has_auth_user = fields['applicants']?.some(applicant => applicant?.auth_user)
  const is_reception = fields['appeal_loan_type'] === 'loan_type_recept'
  const isClosed = fields?.state === 'closed';
  const [showStatusConfirm, setShowStatusConfirm] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteChildConfirm, setShowDeleteChildConfirm] = useState(false);
  const [showDeleteChildDetailConfirm, setShowDeleteChildDetailConfirm] = useState(false);
  const [showDeleteAskCancelConfirm, setShowDeleteAskCancelConfirm] = useState(false);
  const [showDeleteAskCancelDetailConfirm, setShowDeleteAskCancelDetailConfirm] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [file, setFile] = useState(null);
  const [orgOptions, setOrgOptions] = useState([]);
  const [errorFieldsMedo, setErrorFieldsMedo] = useState({});
  const [isButtonMedoLoading, setIsButtonMedoLoading] = useState(false);
  const [hideDuplicates, setHideDuplicates] = useState(true)
  const [checkDeleteMessage, setCheckDeleteMessage] = useState({})
  const prevFieldsRef = useRef();

  const isChanged = useMemo(() => {
    if (Object.keys(fields)?.length) {
      const {files: _, ...changeFields} = {...fields}
      if (prevFieldsRef.current) {
        const change = {
          previous: prevFieldsRef.current,
          current: changeFields
        };
        return JSON.stringify(change?.previous) != JSON.stringify(change?.current)
      }
      prevFieldsRef.current = changeFields;
    }
    return false;
  }, [fields])

  const handleModelClose = () => {
    setIsModal(false)
    setFile(null)
    setErrorFieldsMedo({})
  }

  const handleSendFileMedo = () => {
    setIsButtonMedoLoading(true)
    const formData = new FormData()
    if (file === null) {
      setErrorFieldsMedo({message: "Файл не был загружен."})
      setIsButtonMedoLoading(false)
      return
    }
    formData.append('file', file[0].file, file[0].file.name)
    formData.append('message_id', messageId)
    formData.append('medo', true)
    formData.append('csrftoken', cookie.load('csrftoken'))
    uniFetch('/fz59/api/file-upload/', 'POST', formData, false, false, true).then(data => {
      handleModelClose()
      window.location.href = `/fz59/message_detail/${messageId}/`
    }).catch(data => {
      setErrorFieldsMedo({message: "Произошла ошибка при загрузке файла.", data: data.errors?.message})
    }).finally(() => setIsButtonMedoLoading(false))
  }
  //permissions
  //can_root, can_all, can_register, can_moderate, can_report, can_answer

  const is_same_org = () => {
    return orgunit?.all_orgs?.some(obj => obj === fields?.reg_organisation);
  }

  const is_parent_org = () => {
    return orgunit?.org?.children?.some(obj => obj === fields?.reg_organisation);
  }

  const showHistoryFn = () => {
    if(orgunit?.permissions?.can_root) return true;
    if((is_same_org() || is_parent_org())
        &&(orgunit?.permissions?.can_all || orgunit?.permissions?.can_moderate)) return true;
  }
  const showHistory = showHistoryFn();

  const canAddFn = () => {
    if(orgunit?.permissions?.can_root) return true;
    if(fields?.creator===orgunit?.id) return true;
    if(is_same_org()) return true;

    return false;
  };
  const canAdd = canAddFn();



  const canEditFn = () => {
    if ((fields.state==='closed')||(!fields?.active)) return false;
    if(orgunit?.permissions?.can_root) return isEditing;
    if(fields?.creator===orgunit?.id) return isEditing;
    if(is_same_org()) return isEditing;

    return false;
  };

  const canReturnToWorkFn = () => {
    if (!fields?.active) return false;
    if (orgunit?.permissions?.can_root) return isEditing;
    if (fields?.creator === orgunit?.id) return isEditing;
    if (orgunit?.permissions?.can_all && fields?.reg_organisation === orgunit?.all_orgs[0]) return isEditing;
  }
  const canReturnToWork = canReturnToWorkFn();

  const canEdit = canEditFn();
  const canDeleteFn = () => {
    if(orgunit?.permissions?.can_root) return isEditing;
    if(fields?.creator===orgunit?.id) return isEditing;
    if(is_same_org()&&(orgunit?.permissions?.can_all || orgunit?.permissions?.can_moderate)) return isEditing;

    return false;
  };

  const canDeleteMessageFn = () => {
    if(orgunit?.permissions?.can_root) return canEdit;

    return false;
  };

  const canDeleteMessage = canDeleteMessageFn();

  const canDelete = canDeleteFn();

  const canEditRegOrg = orgunit?.permissions?.can_root; //todo: if sub orgs orgs
  const canRoot = orgunit?.permissions?.can_root;

  const canEditRegDateFn = () => {
    if (orgunit?.permissions?.can_root) return true;

    let res = canEdit && (
        orgunit?.permissions?.can_all ||
        orgunit?.permissions?.can_register ||
        orgunit?.permissions?.can_moderate
    ) && (
        (fields.state==='registration')||(fields.state==='moderation')||(is_reception&&fields.state!=='closed')
    )
    return res;
  };

  const canModerateFn = () => {
    let res = canEdit && (
        orgunit?.permissions?.can_root ||
        orgunit?.permissions?.can_all ||
        orgunit?.permissions?.can_moderate
    ) && (
        fields.state==='moderation'
    )
    return res;
  };

  const canAnswerFn = () => {
    let res = canEdit && (
        orgunit?.permissions?.can_root ||
        orgunit?.permissions?.can_all ||
        orgunit?.permissions?.can_moderate ||
        orgunit?.permissions?.can_answer
    ) && (
        fields.state==='work'
    )
    return res;
  };

  const canCloseFn = () => {
    let res = canEdit && (
        orgunit?.permissions?.can_root ||
        orgunit?.permissions?.can_all ||
        orgunit?.permissions?.can_moderate
    ) && (is_reception || (
        fields.state==='work'||
        fields.state==='answered'
    ))
    return res;
  };

  const canEditRegDate = canEditRegDateFn();
  const canEditRegNumber = canEditRegDateFn();
  const canRegistrate = canEditRegDateFn();
  const canModerate = canModerateFn();

  const canClose = canCloseFn();
  const canAnswer = canAnswerFn();
  const [active_tab, setActiveTab] = useState('detail_tab')

  const updateData = (data) => {
    setFields(filteredFields(data, labels, data['model_definition']));
    setModelDefinition(data['model_definition']);
    setResponsible(data['responsible'] || []);
    setResolutions(data['resolutions']||[]);
    setNotifications(data['notifications']||[]);
  }

  const handlePatchDuplicate = (duplicate) => {
    setIsLoading(true);
    const messageUrl = `/edw/api/entities/${messageId}/?${getStamp()}`;
    let data = {set_duplicate_for: duplicate}
    if (isChanged) {
      const fieldsCopy = getMessageData();
      data = {...data, ...fieldsCopy}
    }
    uniFetch(messageUrl, 'PATCH', data).then((data) => {
      updateData(data)
      setIsDuplicateModal(false)
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(()=>setIsLoading(false))
  }

  const handlePatchAddDuplicate = (duplicate) => {
    handleApiCommand('command_add_duplicate', duplicate, ()=>setIsAddDuplicateModal(false))
  }


  useEffect(() => {
    setIsLoading(true)
    const url = messageApiUrl + '?' + getStamp()
    uniFetch(url, 'GET', null,true, true, true).then(data => {
      if (data?.state === 'draft') {
        data.post_at = getCurrentDateTime()
      }
      updateData(data);
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(data => {
      setIsLoading(false);
    });
  }, []);

  const getResponsibleGroupsData = () =>{
      let res = {};
      let max = 0;
      let local_title = 'Группа исполнителей ';
      let ext_title = 'Входящая группа ';

    //message
    //{1: {
    //      responsible: [],
    //      resolutions: [],
    //      notifications: []
    //    }}

      responsible.forEach(item => {
        const is_local = messageId===item.message ? true : false;
        const gkey = is_local ? `l${item.group}` : `e${item.group}`;

        if (item.group > max){ max = item.group}

        if(Object.hasOwn(res, gkey)){
          res[gkey].responsible.push(item)
        }else{
          res[gkey] = {
            group: item.group,
            local: is_local,
            title: is_local ? local_title + item.group : ext_title + item.group,
            responsible: [item],
            resolutions: [],
            notifications: [],
            can_delete: canEdit && is_local
          }
        }
      });

      resolutions.forEach(item => {
        const is_local = messageId===item.message ? true : false;
        const gkey = is_local ? `l${item.group}` : `e${item.group}`;
        if(Object.hasOwn(res, gkey)){
          res[gkey].resolutions.push(item)
        }
      });

      notifications.forEach(item => {
        const is_local = messageId===item.message ? true : false;
        const gkey = is_local ? `l${item.group}` : `e${item.group}`;
        if(Object.hasOwn(res, gkey)){
          res[gkey].notifications.push(item)
        }
      });

      if(canEdit){
          res[`l${max+1}`] = {
            group: max+1,
            local: true,
            title:'+ Добавить группу',
            responsible: [],
            resolutions: [],
            notifications: [],
            can_delete: false}
      }

    return res
  }
  const respGroups = getResponsibleGroupsData();

  const getMessageData = () => {
    const fieldsCopy = {...fields};
    delete fieldsCopy['files'];
    delete fieldsCopy['creator'];
    delete fieldsCopy['registrator'];

    Object.keys(modelDefinition).forEach((key) => {
      if (modelDefinition[key]?.read_only || modelDefinition[key]?.field_type === 'related_model' || key === 'files') {
        delete fieldsCopy[key]
      }
    });
    return fieldsCopy;

  };

  const makeFetch = (send_data, success) => {
    setIsLoading(true)
    const url = messageApiUrl + '?' + getStamp()
    uniFetch(url, 'PATCH', send_data, false, true, true).then(data => {
      setResponseStatus(200);
      if (data?.id) {
        updateData(data);
      }

      if(success && data?.id){
        success(data);
      }
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(data => {
      setIsLoading(false);
    });
  };

  const handleSave = () => {
    const fieldsCopy = getMessageData();
    if(fields.state==='draft') {
      fieldsCopy.transition = `${fields.state}_to_new`
    }
    makeFetch(fieldsCopy);
  };

  const makeRegistration = (data) =>{
    makeFetch({'transition': `${data.state}_to_registration`});
  }

  const handleRegistration = () => {
    let messagChanged = true; //todo: global
    const fieldsCopy = getMessageData();
    if (messagChanged){
      makeFetch(fieldsCopy, makeRegistration);
    }else{
      makeRegistration(fieldsCopy);
    }
  }

  const genRegNumber = (data) =>{
    makeFetch({'command_gen_regnumber': true, 'reg_number': data.reg_number});
  }

  const handleGenRegNumber = () => {
    let messagChanged = true; //todo: global
    const fieldsCopy = getMessageData();
    if (messagChanged){
      makeFetch(fieldsCopy, genRegNumber);
    }else{
      genRegNumber(fieldsCopy);
    }
  }

  const makeModeration = (data) =>{
    makeFetch({'transition': `${data.state}_to_moderation`});
  }

  const makeWork = (data) =>{
    makeFetch({'transition': `${data.state}_to_work`});
  }
  const makeAnswered = (data) =>{
    makeFetch({'transition': `${data.state}_to_answered`});
  }
  const makeClosed = (data) =>{
    makeFetch({'transition': `${data.state}_to_closed`});
  }

  const handleChange = (e) => {
    const {name, value} = e.target;

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleCreateFiles = (value) => {
    setFields(prevFields => ({
      ...prevFields,
      files: value,
    }));
  };

  const handleChangeFile = (value) => {

    setFields(prevFields => {
      const updatedFiles = prevFields.files.map(file => {
        const changedFile = value.find(fil=>fil.id == file.id)
        return !!changedFile ? formattedFile(JSON.parse(file?.serverId)) : file;
      })

      return {
        ...prevFields,
        files: updatedFiles,
      };
    });
  };

  const handleChangeList = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });

    setFields(prevFields => {
      const updatedList = prevFields[name].map(obj => (obj.id === value.id ? value : obj));
      if (Array.isArray(value)) {
        return {
          ...prevFields,
          [name]: value,
        };
      } else if (typeof value === 'number') {
        const filteredList = updatedList.filter(obj => obj.id !== value);
        return {
          ...prevFields,
          [name]: filteredList,
        };
      } else if (typeof value === 'object' && value !== null) {
        const exists = updatedList.some(obj => obj.id === value.id);

        if (!exists) {
          const maxId = updatedList.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
          const maxPosition = updatedList.reduce((max, obj) => (obj.position > max ? obj.position : max), 0);
          updatedList.push({...value, id: maxId + 1, position: maxPosition + 1});
        }

        return {
          ...prevFields,
          [name]: updatedList,
        };
      }

      return prevFields;
    });
  };

  const handleChangeDescription = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeSelect = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeDate = (e) => {
    const {name, value} = e.target;
    const formattedDateTime = value ? `${value}T08:00` : null;
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => ({
      ...prevFields,
      [name]: formattedDateTime,
    }));
  };

  const handleProblemCreate = (name, value) => {
    setFields(prevFields => ({
      ...prevFields,
      [name]: [...prevFields[name], value],
    }));
  }

  const handleProblemDelete = (name, id) => {
    setFields(prevFields => ({
      ...prevFields,
      [name]: prevFields[name].filter(item => item.id !== id),
    }));
  }

  const handleRadioChange = (name, e) => {
    let {value} = e.target;
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    setFields(prevFields => {
      const currentValue = prevFields[name];

      if (Array.isArray(currentValue)) {
        value = currentValue.includes(value)
          ? currentValue.filter(item => item !== value)
          : [...currentValue, value];
      }

      return {
        ...prevFields,
        [name]: value,
      };
    });
  };

  const fetchDatamart = (value, slug, limit) => {
    const params = new URLSearchParams();
    if (value) params.append('title', value);
    if (limit) params.append('limit', limit);
    const paramsStr = `${params.toString() ? `&${params.toString()}` : ''}`;
    return uniFetch(`/edw/api/data-marts/${slug}/entities/?format=json${paramsStr}`).then(data => {
      const options = data.results.objects.map(item => ({
        value: item.id,
        label: item.entity_name
      }));
      return options;
    });
  };

  const loadOptions = (inputValue, callback, slug) => {
    fetchDatamart(inputValue, slug, QUERY_LIMIT).then(options => {callback(options); setOrgOptions(options)});
  };

  const handleSelectMainTab = (key) => {
    setActiveTab(key);
  }

  const handleUpdateCommand = (command, data) => {
      //command_update_resolution
      if((command==="command_update_resolution")||(command==="command_send_resolution")){
         let new_resolutions = resolutions.filter(item => item.id !== data.id);
         new_resolutions.push(data);
         setResolutions(new_resolutions);
       }else if(command==="command_delete_resolution") {
         let new_resolutions = resolutions.filter(item => item.id !== data);
         setResolutions(new_resolutions);
       }

      //command_update_notification
      else if ((command === "command_update_notification") || (command === "command_send_notification")) {
        let new_notifications = notifications.filter(item => item.id !== data.id);
        new_notifications.push(data);
        setNotifications(new_notifications);
      } else if (command === "command_delete_notification") {
        let new_notifications = notifications.filter(item => item.id !== data);
        setNotifications(new_notifications);
      } else if ((command === "command_update_answer") && (data)) {

        const responsible_id = data?.responsible

        if (responsible_id) {
           const index_resp = responsible.findIndex((item) => item.id === responsible_id);
           const resp = index_resp !== -1 ? responsible[index_resp] : null;
           if(resp){
              let new_answers;
              if (resp.answers){
                  const index = resp.answers.findIndex(item => item.id === data.id);
                  if (index !== -1) {
                      new_answers = [...resp.answers];
                      new_answers[index] = Object.assign({}, data);
                  } else {
                      new_answers = [Object.assign({}, data), ...resp.answers];
                  }
              }else {
                new_answers = [Object.assign({}, data)];
              }
              let new_responsible = [...responsible];
              new_responsible[index_resp] = Object.assign({}, resp, {answers: new_answers});
              setResponsible(new_responsible);
           }
        }
      } else if ((command === "command_delete_answer") && (data)) {

        const responsible_id = data?.responsible

        if (responsible_id) {
          const index_resp = responsible.findIndex((item) => item.id === responsible_id);
          const resp = index_resp !== -1 ? responsible[index_resp] : null;
          let new_answers = []
          if (resp) {
            new_answers = resp.answers.filter(item => item.id !== data.id);
          }
          const new_responsible = [...responsible];
          new_responsible[index_resp] = Object.assign({}, resp, {answers: new_answers});
          setResponsible(new_responsible);
        }
      }
  }
  //setResponsible(data['responsible'] || []);

  const handleApiCommand = (command, data_for_send, callback) => {
    let send = {};
    send[command] = data_for_send; //{'id': 'efrevfr'} //
    if (isChanged) {
      const fieldsCopy = getMessageData();
      Object.keys(fieldsCopy).forEach(key => {
        send[key] = fieldsCopy[key]
      })
    }
    setIsLoading(true);
    const url = messageApiUrl + '?' + getStamp();
    uniFetch(url, 'PATCH', send,false, true, true).then(data => {
      setResponseStatus(200);
      updateData(data);
      if (typeof callback === 'function') {
        callback(data);
      }
      if(command === "command_clear_responsible"){
          setActiveTab('detail_tab');
      }
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
      setIsLoading(false)
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleDeleteDuplicate = () => {
    const url = messageApiUrl + '?' + getStamp()
    let data = {is_del_duplicate_for: true}
    if (isChanged) {
      const fieldsCopy = getMessageData();
      data = {...data, ...fieldsCopy}
    }
    uniFetch(url, 'PATCH', data).then((data) => {
      setIsDuplicateDelModal(false);
      updateData(data)
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    })
  }
  const handleCloseResponseModal = () =>{
    setResponseStatus(0)
  }

  const GenRegisterButton = () => {
    const [modal, setModal] = useState(false)
    return (
      <>
        {modal && <ConfirmModal
          label={'Генерация регистрационного номера'}
          text={'Вы действительно собираетесь повторно сгенерировать регистрационный номер?'}
          handleConfirm={handleGenRegNumber}
          handleCancel={() => setModal(false)}/>}
        <button className="btn btn-outline-success"
                type="button"
                onClick={() => !fields['reg_number']? handleGenRegNumber():setModal(true)}
                disabled={!canRegistrate}
                title={'Сгенерировать регистрационный номер'}>
          <SvgIcon type={svgType.register} size={{width: 16, height: 16}}/>
        </button>
      </>
    )
  }

  const getModerationWarning = () => {
    let warnings = [];
    if (!fields['reg_number']) warnings.push("Не задан регистрационный номер");
    if (!fields['reg_at']) warnings.push("Не указана дата регистрации");
    if (!fields['post_at']) warnings.push("Не указана дата поступления");
    if (fields['post_at']&&fields['reg_at']&&(fields['post_at']>fields['reg_at'])) warnings.push("Дата поступления позже даты регистрации");
    if (fields['applicants']?.length < 1) warnings.push("Не указаны данные о заявителях");
    if (responsible?.length < 1) warnings.push("Не добавлены ответственные лица");
    return warnings
  }

  const getWorkWarning = () => {
    let warnings = getModerationWarning();
    const group_data = respGroups;
    const group_length = Object.keys(group_data).length;
    if ( group_length < 2) warnings.push("Не назначены исполнители");
    for (const [key, group] of Object.entries(group_data)) {
      if ((group.local)&&(group.responsible.length > 0)){
        const nots = group.notifications;
        const ress = group.resolutions;
        if ((nots.length < 1) || (!nots.some((el) => (el.not_need||el.send||el.addition_fileinfo||el.signed_fileinfo)))) {
          warnings.push(`Не добавлено уведомление заявителям группы ${group.group}`);
        }
        if ((ress.length < 1) || (!ress.some((el) => (
            (!el.responsible)&&(el.not_need||el.send||el.addition_fileinfo||el.signed_fileinfo))))
        ) {
          warnings.push(`Не добавлена резолюция исполнителям группы ${group.group}`);
        }
      }
    }
    return warnings
  }

  const getReceptionWarning = () => {
    let warnings = [];
    if (!fields['post_at']) warnings.push("Не указана дата записи");
    if (fields['applicants']?.length < 1) warnings.push("Не указаны данные о заявителях");
    if (responsible?.length < 1) warnings.push("Не добавлены ответственные лица");
    return warnings
  }

  const renderModerationWarning = () => {
    const warnings = getModerationWarning()
    return (
        warnings.length > 0 ?
            <>
              <p className={'text-danger fs-5'}>
                <span className={'fw-bold'}>Внимание: </span><br/>
                {warnings.map((item, key)=>{
                  return(<span key={key}><span className={'small'}>{item}</span><br/></span>)
                })}
              </p>
            </>
            : null
    )
  }

  const renderWorkWarning = () => {
    const warnings = getWorkWarning()
    return (
        warnings.length > 0 ?
            <>
              <p className={'text-danger fs-5'}>
                <span className={'fw-bold'}>Внимание: </span><br/>
                {warnings.map((item, key)=>{
                  return(<span key={key}><span className={'small'}>{item}</span><br/></span>)
                })}
              </p>
            </>
            : null
    )
  }

  //label, html, handleConfirm, handleCancel
  const renderConfirmStatusMessage = () =>{
    if(showStatusConfirm==='registration'){
      return (
          <h4 className={'text-center'}>Вы действительно хотите отправить обращение на регистрацию?</h4>
      )
    }
    if(showStatusConfirm==='registration_with_reason'){
      return (
          <>
          <h4 className={'text-center'}>Вы действительно хотите вернуть обращение на регистрацию?</h4>
            <InputField
              name={'status_reason'}
              required={true}
              column={1}
              onChange={e => {setReasonText(e.target.value)}}
              label={() => 'Причина'}
              value={() => reasonText}
            />
          </>
      )
    }
    if(showStatusConfirm==='work_with_reason'){
      return (
          <>
          <h4 className={'text-center'}>Вы действительно хотите вернуть обращение в работу?</h4>
            <InputField
              name={'status_reason'}
              required={true}
              column={1}
              onChange={e => {setReasonText(e.target.value)}}
              label={() => 'Причина'}
              value={() => reasonText}
            />
          </>
      )
    }
    if(showStatusConfirm==='moderation'){
        return (
            <><h4 className={'text-center'}>Вы действительно хотите отправить обращение на модерацию?</h4>
              {renderModerationWarning()}
            </>
      )
    }
    if(showStatusConfirm==='work'){
        return (
            <>
              <h4 className={'text-center'}>Вы действительно хотите отправить обращение в работу?</h4>
              {renderWorkWarning()}
              </>
      )
    }
    if (showStatusConfirm === 'to_work') {
      return (
        <>
          <h4 className={'text-center'}>Вы действительно хотите вернуть обращение в работу?</h4>
        </>
      )
    }
    if(showStatusConfirm==='reception_to_work'){
        return (
            <>
              <h4 className={'text-center'}>Вы действительно хотите запись на прием в работу?</h4>
              {getReceptionWarning()}
              </>
      )
    }
    if(showStatusConfirm==='work_without_moderation'){
        return (
            <>
              <h4 className={'text-center'}>Вы действительно хотите отправить обращение в работу без модерации?</h4>
              {renderWorkWarning()}
            </>
        )
    }
    if(showStatusConfirm==='answered'){
        return (
          <h4 className={'text-center'}>Вы действительно хотите отметить обращение как отвеченное?</h4>
      )
    }

    if(showStatusConfirm==='closed'){
        return (
          <h4 className={'text-center'}>Вы действительно хотите закрыть обращение?</h4>
      )
    }

    return (
        <h2>Подтвердить</h2>
    )
  }
  const confirmStatusMessage = () =>{
    let messagChanged = true; //todo: global
    let fieldsCopy = getMessageData();

    if(showStatusConfirm==='registration'){
        if (messagChanged){
          makeFetch(fieldsCopy, makeRegistration);
        }else{
          makeRegistration(fieldsCopy);
        }
    }
    if(showStatusConfirm==='registration_with_reason'){
        fieldsCopy.status_reason = reasonText;
        setReasonText('');
        if (messagChanged){
          makeFetch(fieldsCopy, makeRegistration);
        }else{
          makeRegistration(fieldsCopy);
        }
    }
    if(showStatusConfirm==='work_with_reason'){
        fieldsCopy.status_reason = reasonText;
        setReasonText('');
        if (messagChanged){
          makeFetch(fieldsCopy, makeWork);
        }else{
          makeWork(fieldsCopy);
        }
    }


    if(showStatusConfirm==='moderation'){
        if (messagChanged){
          makeFetch(fieldsCopy, makeModeration);
        }else{
          makeModeration(fieldsCopy);
        }
    }

    if(showStatusConfirm==='work'||showStatusConfirm==='work_without_moderation'||showStatusConfirm==='reception_to_work'||showStatusConfirm==='to_work'){
        if (messagChanged){
          makeFetch(fieldsCopy, makeWork);
        }else{
          makeWork(fieldsCopy);
        }
    }

    if(showStatusConfirm==='answered'){
      if (messagChanged){
          makeFetch(fieldsCopy, makeAnswered);
        }else{
          makeAnswered(fieldsCopy);
        }
    }
    if(showStatusConfirm==='closed'){
      if (messagChanged){
          makeFetch(fieldsCopy, makeClosed);
        }else{
          makeClosed(fieldsCopy);
        }
    }
    //closed

    setShowStatusConfirm(null);
  }

  const validateConfirmStatus = () => {
    if (showStatusConfirm === 'registration_with_reason') {
      if (reasonText) {
        return true
      }
      return false
    }
    if (showStatusConfirm === 'work_with_reason') {
      if (reasonText) {
        return true
      }
      return false
    }

    return true
  }

  const renderConfirmStatusChangeModal = () => {
    if (showStatusConfirm===null) return null;
    return (
        <HTMLConfirmModal label={'Изменить статус обращения?'}
                          handleConfirm={()=> confirmStatusMessage()}
                          handleCancel={()=> setShowStatusConfirm(null)}
                          validateConfirm={validateConfirmStatus()}
                          >
          {renderConfirmStatusMessage()}
        </HTMLConfirmModal>
    )
  }

  const renderDeleteMessageModal = () => {
    if (!showDeleteConfirm) return null;
    return (
        <HTMLConfirmModal label={'Вы действительно хотите удалить обращение?'}
                          handleConfirm={()=> confirmDeleteMessage()}
                          handleCancel={()=> setShowDeleteConfirm(false)}
                          validateConfirm={true}
                          >
          <h4 className={'text-center'}>Дальнейшее редактирование обращение станет недоступным</h4>
        </HTMLConfirmModal>
    )
  }

  const renderDeleteChildMessageModal = (id) => {
    if (!showDeleteChildConfirm && id || !showDeleteChildDetailConfirm && !id) return null;
    const confirmHandle =
      checkDeleteMessage?.can_delete ? () => confirmDeleteChildMessage(id ?? messageId) :
        checkDeleteMessage?.can_ask && id ? () => confirmAskDeleteChildMessage(id) :
          null;
    const confirmLabel =
      checkDeleteMessage?.can_delete ? 'Удалить' :
        checkDeleteMessage?.can_ask && id ? 'Запросить удаление' :
          'Ок'
    const handleCancel = id ? () => setShowDeleteChildConfirm(false) : () => setShowDeleteChildDetailConfirm(false)
    return (
      <HTMLConfirmModal label={'Удаление обращения'}
                        handleConfirm={confirmHandle}
                        handleCancel={handleCancel}
                        confirmLabel={confirmLabel}
                        cancelLabel={'Отмена'}
                        validateConfirm={true}
      >
        <h5 className={'text-center text-danger'}>Внимание!</h5>
        {checkDeleteMessage?.alerts?.map((text, index) => <h5 key={index}
                                                              className={'text-center text-danger'}>{text}</h5>)}
      </HTMLConfirmModal>
    )
  }

  const renderDeleteAskCancelMessageModal = (id) => {
    if (!showDeleteAskCancelConfirm && id || !showDeleteAskCancelDetailConfirm && !id) return null;
    const handleCancel = id ? () => setShowDeleteAskCancelConfirm(false) : () => setShowDeleteAskCancelDetailConfirm(false)
    return (
      <HTMLConfirmModal label={'Отмена запроса'}
                        handleConfirm={() => confirmAskDeleteChildMessage(id ?? messageId, false)}
                        handleCancel={handleCancel}
                        confirmLabel={'Отменить запрос'}
                        cancelLabel={'Отмена'}
                        validateConfirm={true}
      >
        <h5 className={'text-center'}>Вы действительно хотите отменить запрос на удаление?</h5>
      </HTMLConfirmModal>
    )
  }

  const confirmDeleteChildMessage = (id) => {
    setIsLoading(true);
    const messageUrl = `/edw/api/entities/${id}/?${getStamp()}`;
    uniFetch(messageUrl, 'PATCH', {command_delete: messageId}, false, true, true).then((data) => {
      setIsLoading(false)
      if (id === messageId) {
        setResponseStatus(204)
        setErrorFields({error: 'Обращение удалено'});
      }
      setFields(prevState => {
        if (id !== messageId) {
          return {
            ...prevState,
            children: prevState?.children?.filter(child => child?.id !== id)
          }
        }
        return {}
      })
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => {
      setIsLoading(false);
      setShowDeleteChildConfirm(false);
    })
  }

  const confirmAskDeleteChildMessage = (id, data = true) => {
    setIsLoading(true);
    const messageUrl = `/edw/api/entities/${id}/?${getStamp()}`;
    uniFetch(messageUrl, 'PATCH', {command_ask_delete: data}, false, true, true).then((data) => {
      setIsLoading(false)
      setFields(prevState => {
        if (id === messageId) {
          return {
            ...prevState,
            asked_delete: data?.asked_delete
          }
        } else {
          return {
            ...prevState,
            children: prevState?.children?.map(child => child?.id === id ? {
              ...child,
              asked_delete: data?.asked_delete
            } : child)
          }
        }
      })
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => {
      setIsLoading(false);
      setShowDeleteChildConfirm(false);
      setShowDeleteAskCancelConfirm(false);
      setShowDeleteAskCancelDetailConfirm(false);
    })
  }
  const confirmDeleteMessage = () => {
    setShowDeleteConfirm(false);
    makeFetch({'active': false});
  }

  const handleDeleteMessage = (e) =>{
    // setShowDeleteConfirm(true);
    handleCheckDelete(messageId);
  }

  const checkAnswer = (data) => {
    let status = 'empty';
    data?.responsible?.some(responsible => {
      if (responsible?.answers?.length) {
        status = 'not_send';
        if (responsible.answers.some(answer => answer.send)) {
          status = 'send';
        }
        return status === 'send';
      }
      return false;
    });

    return status;
  };

  const checkAskSign = (data) => {

    let res = data?.resolutions?.some(item => { return item?.wait_sign==="ASKED" });

    if (res) return res;
    res = data?.notifications?.some(item => { return item?.wait_sign==="ASKED" });
    if (res) return res;

    res = data?.responsible?.some(responsible => {
      if (responsible?.answers?.length) {
        return responsible.answers.some(answer => answer?.wait_sign==="ASKED");
      }
      return false;
    });
    if (res) return res;

    return false;
  }

  const handleCheckDelete = (id) => {
    setIsLoading(true);
    const messageUrl = `/edw/api/entities/${id}/?${getStamp()}`;
    uniFetch(messageUrl, 'PATCH', {command_check_delete: messageId}, false, true, true).then((data) => {
      setCheckDeleteMessage(data?.command_response)
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
    }).finally(() => {
      setIsLoading(false)
      if (messageId !== id) {
        setShowDeleteChildConfirm(true)
      } else {
        setShowDeleteChildDetailConfirm(true)
      }

    })
  }

  if (isLoading && !Object.keys(fields).length) return (<LoaderModal/>)
  if (!Object.keys(fields).length && [403, 404, 500, 204].includes(responseStatus)) return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <div className="text-center">
        <h2 className={'text-danger'}>{errorFields?.detail ?? errorFields?.error}</h2>
      </div>
    </div>
  )
  if (!Object.keys(fields).length) return (<></>)
  return (
    <>
        {renderConfirmStatusChangeModal()}
        {renderDeleteMessageModal()}
        {renderDeleteAskCancelMessageModal()}
        {renderDeleteChildMessageModal()}
        {isLoading ? <LoaderModal/>: null}
        <ResponseModal onClose={handleCloseResponseModal} status={(Object.keys(fields).length && responseStatus!==403) ? responseStatus : null}>
          <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
        </ResponseModal>
        <ActionButtons
            canAdd={canAdd}
            canEdit={canEdit}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            id={messageId}
            modalMedo={setIsModal}
            modalDuplicate={setIsAddDuplicateModal}
            modalDuplicateFor={setIsDuplicateModal}
            state={fields?.state}
            reg_number={fields?.reg_number}
            reg_at={fields?.reg_at}
            post_at={fields?.post_at}
            expired_at={fields?.expired_at}
            is_reception={is_reception}
            can_delete={canDelete}
            deleteFn ={handleDeleteMessage}
        />
        <DownloadMedoModal
          id={messageId}
          isModal={isModal}
          handleModelClose={handleModelClose}
          handleSendFileMedo={handleSendFileMedo}
          isButtonMedoLoading={isButtonMedoLoading}
          file={file}
          setFile={setFile}
          modelDefinition={modelDefinition}
          errorFieldsMedo={errorFieldsMedo}
        />
        <DuplicateForm
          name={'duplicate'}
          modelDefinition={name => modelDefinition[name]}
          isModal={isAddDuplicateModal}
          setIsModal={setIsAddDuplicateModal}
          messageId={messageId}
          permsUnit={permsUnit}
          firstApplicant={fields?.applicants?.[0]}
          orgunit={orgunit}
          title={'Создание на основе'}
          header={'Создать на основе'}
          onSave={handlePatchAddDuplicate}
        />
        {fields['source_type'] === 'pos' && <div className="alert alert-info">
          Данное обращение связано с личным кабинетом заявителя на портале обращений граждан. Ответ требуется направить<br/>
          в личный кабинет заявителя в ПОС через соответствующую закладку ниже. Ответы отправленные через электронную почту<br/>
          из интерфейса создания ответа не заменяют необходимости отправить сам ответ заявителю через закладку ПОС (только один<br/>
          ответ может быть направлен заявителю).
        </div>}
        {fields['source_type'] !== 'pos' && some_has_auth_user && <div className="alert alert-info">
          Данное обращение создано заявителем авторизованным через портал государственных услуг.<br/>
          Все уведомления и ответы будут направлены в личный кабинет заявителя.
        </div>}
        {!!fields?.['asked_delete'] && canEdit &&
          <div className="alert alert-danger row">
            <span
              className={'col-8'}>{labels['asked_delete']} {fields?.['asked_delete']?.sender_org_title} ({fields?.['asked_delete']?.sender_fio}) {fields?.['asked_delete']?.date}</span>
            <div className={'d-flex col-4'}>
              <button type="button" className="btn btn-outline-success w-100 me-2"
                      onClick={(e) => setShowDeleteAskCancelDetailConfirm(true)}>
                Отменить
              </button>
              <button type="button" className="btn btn-outline-danger w-100"
                      onClick={(e) => handleCheckDelete(messageId)}>
                Удалить
              </button>
            </div>
          </div>
        }
        {!!fields['status_reason'] && fields['state'] === 'registration' && <div className="alert alert-danger">
          {'Причина отправки на регистрацию: '+ fields['status_reason'] }
        </div>}
        {!!fields['status_reason'] && fields['state'] === 'work' && <div className="alert alert-danger">
          {'Причина возврата в работу: '+ fields['status_reason'] }
        </div>}
        {!fields?.active ?
            <div className="alert alert-danger text-center">
              <h3>Данное обращение удалено</h3>
            </div>
            : null
        }
        {isEditing ? <>
              <Tabs id="fz-message-detail-tabs" onSelect={handleSelectMainTab} activeKey={active_tab}>
                <Tab title={'Обращение'}
                     eventKey="detail_tab"
                     className={active_tab === 'detail_tab' ? 'show active' : ''}
              >
                {active_tab === 'detail_tab' ?
                    <div className="form-container">
                      <div className="blocks-container">
                        <BlockContainer label='Основные данные'>
                          <div className="block-container-content">
                            <div className="mb-3">
                              <AsyncSelectField
                                  name={'reg_organisation'}
                                  disabled={!(canEditRegOrg && !isClosed) && !canRoot}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  required={name => modelDefinition[name]?.required}
                                  column={1}
                                  debounceTimeout={DEBOUNCE_TIMEOUT}
                                  onChange={handleChangeSelect}
                                  loadOptions={(value, callback) => loadOptions(value, callback, 'fzorganisation-dm')}
                                  currentValue={name => modelDefinition[name]?.current_value}
                                  errorMessages={name => errorFields?.[name]}
                                  placeholder="Выберите..."
                                  initialOtions={orgOptions}
                              />
                            </div>
                            {/*<div className={'row'}>*/}
                            {/*  <div className={'col-6'}>*/}

                            {/*  </div>*/}
                            {/*</div>*/}
                            <Row className="mb-3">
                              <Col sm={halfColumnSize}>
                                <StaticField
                                    name={'state'}
                                    value={name => modelDefinition[name]?.children?.find(child => child.slug === fields[name]).name}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                />
                              </Col>
                              <Col sm={halfColumnSize}>
                                <DateField
                                    name={'post_at'}
                                    value={name => fields[name]}
                                    label={name => !is_reception ? labels[name]: labels.recept_at}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                    onChange={handleChangeDate}
                                    disabled={!(canEdit && !isClosed) && !canRoot}
                                    errorMessages={name => errorFields?.[name]}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col sm={halfColumnSize}>
                                <InputField
                                    name={'reg_number'}
                                    disabled={!(canEditRegNumber && !isClosed) && !canRoot}
                                    value={name => fields[name]}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                    onChange={handleChange}
                                    rightButton={<GenRegisterButton/>}
                                    errorMessages={name => errorFields?.[name]}
                                />
                              </Col>
                              <Col sm={halfColumnSize}>
                                <DateField
                                    name={'reg_at'}
                                    disabled={!(canEditRegDate && !isClosed) && !canRoot}
                                    value={name => fields[name]}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                    onChange={handleChangeDate}
                                    errorMessages={name => errorFields?.[name]}
                                />
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <InputField
                                  name={'sed_number'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  required={name => modelDefinition[name]?.required}
                                  column={1}
                                  onChange={handleChange}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </div>
                            <div className="mb-3">
                              <ButtonGroupField
                                  name={'appeal_loan_type'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  required={name => modelDefinition[name]?.required}
                                  defaultHide={true}
                                  column={1}
                                  onChange={handleRadioChange}
                                  children={name => modelDefinition[name]?.children}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </div>
                            <div className="mb-3">
                              <ButtonGroupField
                                  name={'applicant_type'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  required={name => modelDefinition[name]?.required}
                                  defaultHide={true}
                                  column={1}
                                  onChange={handleRadioChange}
                                  children={name => modelDefinition[name]?.children}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </div>
                            <div className="mb-3">
                              <ButtonGroupField
                                  name={'income_source_type'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  required={name => modelDefinition[name]?.required}
                                  defaultHide={true}
                                  column={1}
                                  onChange={handleRadioChange}
                                  children={name => modelDefinition[name]?.children}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </div>
                            <div className="mb-3">
                              <ButtonGroupField
                                  name={'destination_type'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  required={name => modelDefinition[name]?.required}
                                  defaultHide={true}
                                  column={1}
                                  onChange={handleRadioChange}
                                  children={name => modelDefinition[name]?.children}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </div>
                          </div>
                        </BlockContainer>
                        <BlockContainer
                            label='Источник'
                            canHide={false}
                        >
                          <SelectField
                              name={'income_type'}
                              value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
                              label={name => labels[name]}
                              required={name => modelDefinition[name]?.required}
                              column={1}
                              disabled={!(canEdit && !isClosed) && !canRoot}
                              options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
                              onChange={handleChangeSelect}
                              errorMessages={name => errorFields?.[name]}
                              placeholder="Выберите..."
                          />
                          <Row className={'mb-3'}>
                            <Col sm={halfColumnSize}>
                              <InputField
                                  name={'income_number'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  required={name => modelDefinition[name]?.required}
                                  column={2}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  onChange={handleChange}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </Col>
                            <Col sm={halfColumnSize}>
                              <DateField
                                  name={'income_at'}
                                  value={name => fields[name]}
                                  label={name => labels[name]}
                                  required={name => modelDefinition[name]?.required}
                                  column={2}
                                  disabled={!(canEdit && !isClosed) && !canRoot}
                                  onChange={handleChangeDate}
                                  errorMessages={name => errorFields?.[name]}
                              />
                            </Col>
                          </Row>
                          {fields['parent'] ?
                              <div className={'mb-3'}>
                                <Row className={'mb-3'}>
                                  <Col sm={6} className="fw-bold">
                                    Исходное обращение
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={2} className="fw-bold">Номер</Col>
                                  <Col sm={3} className="fw-bold">Рег. организация</Col>
                                  <Col sm={3} className="fw-bold">Фио</Col>
                                  <Col sm={2} className="fw-bold">Статус</Col>
                                  <Col sm={2} className="fw-bold">Дата</Col>
                                </Row>
                                <Row>
                                  <Col sm={2}>
                                    <a href={fields.parent?.absolute_url} target={'_blank'}>
                                      {fields.parent?.reg_number}
                                    </a>
                                  </Col>
                                  <Col sm={3}>{fields.parent?.reg_organisation?.title}</Col>
                                  <Col sm={3}>{fields.parent?.fio}</Col>
                                  <Col sm={2}>
                                    {modelDefinition['state']?.children?.find(child => child.slug === fields.parent?.state).name}
                                  </Col>
                                  <Col sm={2}>{convertDateFormat(fields.parent?.reg_at)}</Col>
                                </Row>
                                {fields.parent?.annotation ?
                                    <Row className={'pt-0'}>
                                      <Col sm={2} className="fw-bold">Аннотация</Col>
                                      <Col sm={10}>{fields.parent?.annotation}</Col>
                                    </Row> : null
                                }
                              </div>
                              : null
                          }
                        </BlockContainer>
                        {fields?.children?.length > 0 ?
                            <BlockContainer
                                label={labels.children}
                                canHide={false}
                            >
                              <Col sm={11}>
                              <Row>
                                <Col sm={2} className="fw-bold">Номер</Col>
                                <Col sm={3} className="fw-bold">Рег. организация</Col>
                                <Col sm={3} className="fw-bold">Фио</Col>
                                <Col sm={2} className="fw-bold">Статус</Col>
                                <Col sm={1} className="fw-bold">Дата</Col>
                                <Col sm={1} className="fw-bold"></Col>
                              </Row>
                              </Col>
                              {Object.entries(fields.children).map(([key, chield]) => {
                                    return (
                                        <Row className={'block-table-row'} key={key}>
                                          {renderDeleteChildMessageModal(chield?.id)}
                                          <Col sm={11}>
                                          <Row key={'r1_' + key}>
                                            <Col sm={2}>
                                              <a href={chield?.absolute_url} target={'_blank'}>
                                                {chield?.reg_number || chield?.id || "НЕ ЗАДАН"}
                                              </a>
                                            </Col>
                                            <Col sm={3}>{chield?.reg_organisation?.title}</Col>
                                            <Col sm={3}>{chield?.fio}</Col>
                                            <Col sm={2}>
                                              {modelDefinition['state']?.children?.find(child => child.slug === chield?.state).name}
                                            </Col>
                                            <Col sm={2}>{convertDateFormat(chield?.reg_at)}</Col>

                                          </Row>
                                          </Col>
                                           {!chield?.asked_delete && (canEdit || canRoot) ? <Col sm={1} className={'actions d-flex align-items-start'}>
                                            <button type="button" className="btn btn-outline-danger w-100"
                                                    onClick={(e) => handleCheckDelete(chield?.id)}>
                                              <SvgIcon type={svgType.delete} size={{width: 16, height: 16}}/>
                                            </button>
                                          </Col>: null}
                                          {chield?.annotation ?
                                              <Row key={'r2_' + key} className={'pt-0'}>
                                                <Col sm={2} className="fw-bold">Аннотация</Col>
                                                <Col sm={10}>{chield?.annotation}</Col>
                                              </Row> : null
                                          }
                                          {chield?.asked_delete && (canEdit || canRoot) ? <div className={'mt-3 d-flex'}>
                                            {renderDeleteAskCancelMessageModal(chield?.id)}
                                            <Col sm={9} className={'text-danger'}>{labels['asked_delete']} {chield?.asked_delete?.sender_org_title} ({chield?.asked_delete?.sender_fio}) {chield?.asked_delete?.date}</Col>
                                            <Col sm={3}>
                                              <button type="button" className="btn btn-outline-danger w-100"
                                                      onClick={(e) => setShowDeleteAskCancelConfirm(true)}>
                                                Отменить запрос
                                              </button>
                                            </Col>
                                          </div>: null}
                                        </Row>
                                    )
                                  }
                              )}
                            </BlockContainer> : null}
                        <BlockContainer label='Заявители*'>
                          <ApplicantTable
                              name="applicants"
                              value={name => fields[name]}
                              label={applicantTableLabels}
                              labels={applicantLabels}
                              required={name => modelDefinition[name]?.required}
                              onChange={handleChangeList}
                              onDelete={handleProblemDelete}
                              messageId={messageId}
                              canEdit={(canEdit && !isClosed) || canRoot}
                              modelDefinition={name => modelDefinition[name]}
                          />
                        </BlockContainer>
                        <BlockContainer label='Аннотация'>
                          <div className="block-container-content">
                            <InputField
                                name={'annotation'}
                                value={name => fields[name]}
                                label={name => labels[name]}
                                required={name => modelDefinition[name]?.required}
                                hideLabel={true}
                                column={1}
                                disabled={!(canEdit && !isClosed) && !canRoot}
                                onChange={handleChange}
                                errorMessages={name => errorFields?.[name]}
                            />
                          </div>
                        </BlockContainer>
                        <BlockContainer label='Текст обращения' className="ck-field">
                          <CKEditorField
                              name={'description'}
                              value={fields?.description}
                              label={name => labels[name]}
                              required={name => modelDefinition[name]?.required}
                              hideLabel={true}
                              disabled={!(canEdit && !isClosed) && !canRoot}
                              column={1}
                              wrappable={true}
                              onChange={handleChangeDescription}
                              errorMessages={name => errorFields?.[name]}
                          />
                        </BlockContainer>
                        <BlockContainer label='Характер обращения*'>
                          <AppealTable
                              name={'problems'}
                              fields={fields}
                              modelDefinition={name => modelDefinition[name]}
                              messageId={messageId}
                              canEdit={(canEdit && !isClosed) || canRoot}
                              onChange={handleProblemCreate}
                              onDelete={handleProblemDelete}
                          />
                        </BlockContainer>
                        <BlockContainer label='Дополнительно'>
                          <div className="block-container-content">
                            <Row className="mb-3">
                              <Col sm={8}>
                                <ButtonGroupField
                                    name={'frequency_type'}
                                    value={name => fields[name]}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    defaultHide={true}
                                    column={1}
                                    disabled={!(canEdit && !isClosed) && !canRoot}
                                    onChange={handleRadioChange}
                                    children={name => modelDefinition[name]?.children}
                                    errorMessages={name => errorFields?.[name]}
                                />
                              </Col>
                              <Col sm={4}>
                                <Button onClick={() => setIsDuplicateModal(true)} bsStyle="success" className={'w-100'} disabled={!(canEdit && !isClosed) && !canRoot}>Проверить
                                  повторное</Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={halfColumnSize}>
                                <SelectField
                                    name={'appeal_cont_type'}
                                    value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                    isMulti={true}
                                    disabled={!(canEdit && !isClosed) && !canRoot}
                                    options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
                                    onChange={handleChangeSelect}
                                    errorMessages={name => errorFields?.[name]}
                                    placeholder="Выберите..."
                                />
                              </Col>
                              <Col sm={halfColumnSize}>
                                <SelectField
                                    name={'appeal_chars_type'}
                                    value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition[name]?.children), fields[name])}
                                    label={name => labels[name]}
                                    required={name => modelDefinition[name]?.required}
                                    column={2}
                                    isMulti={true}
                                    disabled={!(canEdit && !isClosed) && !canRoot}
                                    options={name => formatDateToSelectOptions(modelDefinition[name]?.children)}
                                    onChange={handleChangeSelect}
                                    errorMessages={name => errorFields?.[name]}
                                    placeholder="Выберите..."
                                />
                              </Col>
                            </Row>
                          </div>
                          {fields['duplicates'].length ? <Row className={'pb-0'}>
                            <Col sm={6}>
                              <h4 className={'my-2'}>Повторные сообщения</h4>
                              {fields['duplicates'].length > 1 && <h6>Количество повторных сообщений: {fields['duplicates'].length}
                                <Button onClick={() => setHideDuplicates(prevState => !prevState)}>
                                  {hideDuplicates ? "Развернуть" : "Свернуть"}
                                </Button>
                              </h6>}
                            </Col>
                            {fields['duplicate_for'] ?
                                <Col sm={6} className={'d-flex align-items-center justify-content-end'}>
                                  <Button onClick={() => setIsDuplicateDelModal(true)} bsStyle="danger" disabled={!(canEdit && !isClosed) && !canRoot}>Убрать cтатус
                                    повторного
                                    сообщение</Button>
                                </Col> : null}
                            {isDuplicateDelModal && <ConfirmModal
                                label={'Удаление статуса повторого сообщения'}
                                text={'Внимание будет снят статус повторного сообщения. Вы действительно собираетесь удалить связь?'}
                                handleConfirm={handleDeleteDuplicate}
                                handleCancel={() => setIsDuplicateDelModal(false)}/>}
                          </Row> : null}
                          <DuplicateForm
                              name={'duplicate'}
                              modelDefinition={name => modelDefinition[name]}
                              isModal={isDuplicateModal}
                              setIsModal={setIsDuplicateModal}
                              messageId={messageId}
                              permsUnit={permsUnit}
                              orgunit={orgunit}
                              firstApplicant={fields?.applicants?.[0]}
                              onSave={handlePatchDuplicate}
                          />
                          {!hideDuplicates && fields['duplicates'].length > 1 || fields['duplicates'].length === 1 ? <DuplicateTable
                            duplicates={fields['duplicates']}
                            modelDefinition={modelDefinition}
                          />: null}
                        </BlockContainer>
                        {!(isClosed && !fields['files'].length) ? <BlockContainer label='Прикрепленные файлы'>
                          <div className="block-container-content">
                            <FilesField
                              name={'files'}
                              paramName={'file'}
                              value={name => formatFiles(fields[name])}
                              label={name => labels[name]}
                              required={name => modelDefinition[name]?.required}
                              hideLabel={true}
                              column={1}
                              onChange={handleCreateFiles}
                              onChangeFile={handleChangeFile}
                              instantUpload={true}
                              allowDownload={true}
                              isConfirmDelete={true}
                              disabled={!(canEdit && !isClosed) && !canRoot}
                              beforeRemoveFile={handleRemoveFiles}
                              server={FilePodServer(messageId, "/fz59/api/files/")}
                              errorMessages={name => errorFields?.[name]}
                            />
                          </div>
                        </BlockContainer>:null}
                      </div>
                    </div>
                    : null
                }
              </Tab>
              {!is_reception ?
               <Tab title={fields?.status_sstu!=="not_synced" ? 'ССТУ.РФ':
                 <>ССТУ.РФ<span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                 <span className="visually-hidden">Не отправлено</span>
                 </span></>}
                    eventKey="sstu_tab"
                    className={active_tab === 'sstu_tab' ? 'show active' : ''}
               >
                 {active_tab === 'sstu_tab' ?
                    <SSTUTab
                      messageId={messageId}
                      fields={fields}
                      labels={labels}
                      orgunit={orgunit}
                      responsible={responsible}
                      respGroups={respGroups}
                      apiCommand={handleApiCommand}
                      canEdit={canEdit && !isClosed}
                    />
                    : null
                }
              </Tab>: null}
              {fields['source_type'] === 'medo' ?
                  <Tab title={'Ответ в Упр. Президента'}
                       eventKey="response_president_tab"
                       className={active_tab === 'response_president_tab' ? 'show active' : ''}
                       mountOnEnter
                       unmountOnExit
                  >{active_tab === 'response_president_tab' ?
                    <ResponsePresidentTab
                        fields={fields}
                        modelDefinition={modelDefinition}
                        genRegisterButton={<GenRegisterButton/>}
                        handleCreateFiles={handleCreateFiles}
                        handleChangeFile={handleChangeFile}
                        messageId={messageId}
                        orgunit={orgunit}
                        responsible={responsible}
                    /> : null}
                  </Tab> : null
              }
              {fields['source_type'] === 'pos' ?
                  <Tab title={fields?.status_pos!=="not_synchronized" ? 'ПОС':
                         <>ПОС<span className="position-absolute top-0 start-100 translate-middle">
                         <SvgIcon
                          type={svgType.askSign}
                          size={{width: 16, height: 16}}
                          color='#e73751'/>
                         </span>
                      </>}
                       eventKey="response_pos_tab"
                       className={active_tab === 'response_pos_tab' ? 'show active' : ''}
                       mountOnEnter
                       unmountOnExit
                  >{active_tab === 'response_pos_tab' ?
                    <POSTab
                      messageId={messageId}
                      fields={fields}
                      labels={labels}
                      orgunit={orgunit}
                      responsible={responsible}
                      respGroups={respGroups}
                      apiCommand={handleApiCommand}
                      canEdit={canEdit && !isClosed}
                    /> : null}
                  </Tab> : null}
              {Object.entries(respGroups).map(([key, value]) => {
                const hasAnswer = checkAnswer(value);
                const hasAskSign = checkAskSign(value);
                return (
                    <Tab title={
                      <>
                        {value.title}
                        <span className={'position-absolute top-0 start-100 translate-middle'}>
                         {hasAskSign ?
                          <SvgIcon
                          type={svgType.askSign}
                          size={{width: 16, height: 16}}
                          color={'#e73751'}/> : null}
                         { hasAskSign || hasAnswer === 'empty' ? null :
                          <SvgIcon
                          type={svgType.envelope}
                          size={{width: 20, height: 20}}
                          color={hasAnswer === 'send' ? '#44D686':'#e73751'}/>
                         }
                    </span>
                      </>}
                         className={active_tab === 'resp_tab' + key ? 'show active' : ''}
                         eventKey={'resp_tab' + key}
                         key={'resp' + key}
                    >
                      {active_tab === 'resp_tab' + key ?
                          <ResponsibleGroup
                              attrs={value}
                              gid={key}
                              orgunit={orgunit}
                              reg_organisation={fields['reg_organisation']}
                              message_problems={fields['problems']}
                              apiCommand={handleApiCommand}
                              updateCommand={handleUpdateCommand}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              messageId={messageId}
                              modelDefinition={modelDefinition}
                              canEdit={canEdit || canRoot}
                          />
                          : null
                      }
                    </Tab>
                )
              })
              }
              {showHistory ?
                <Tab title={'История'}
                     eventKey="history"
                     className={active_tab === 'history' ? 'show active' : ''}
                >
                  {active_tab === 'history' ?
                      <FzActionLogTab objectId={messageId}
                      />
                      : null
                  }
                </Tab>
                  :null
              }
            </Tabs>
            <div className="d-flex justify-content-end">
              {canReturnToWork && fields.state === 'closed' ?
                <>
                <Button onClick={() => setShowStatusConfirm('to_work')} bsStyle="success" className={canRoot && active_tab !== 'sstu_tab' ? 'me-3': ''}
                         disabled={isLoading}>
                  Вернуть в работу
                </Button>
                </> : null
              }
              {(canEdit || canRoot) && !(active_tab === 'sstu_tab' || active_tab === 'medo_tab' || active_tab === 'response_pos_tab') ?
                  <>
                    {is_reception && ((fields.state === 'draft') || (fields.state === 'new')) ?
                        <Button onClick={() => setShowStatusConfirm('reception_to_work')} bsStyle="outline-success"
                                  className={'me-3'} disabled={isLoading}>
                            Отправить запись в работу
                          </Button> : null
                    }
                    {(canClose) ?
                        <button onClick={() => setShowStatusConfirm('closed')} className="btn btn-outline-danger me-3"
                                disabled={isLoading}>
                          Закрыть обращение
                        </button> : null
                    }
                    {((fields.state === 'answered') && (canClose)) ?
                        <button onClick={() => {
                          setReasonText('');
                          setShowStatusConfirm('work_with_reason')
                        }}
                                className="btn btn-outline-success me-3" disabled={isLoading}>
                          Вернуть в работу
                        </button> : null
                    }
                    {(canAnswer && !is_reception) ?
                        <button onClick={() => setShowStatusConfirm('answered')} className="btn btn-outline-success me-3"
                                disabled={isLoading}>
                          Дан результирующий ответ
                        </button> : null
                    }
                    {(canModerate) ?
                        <>
                          <Button onClick={() => {
                            setReasonText('');
                            setShowStatusConfirm('registration_with_reason')
                          }} bsStyle="outline-danger"
                                  className={'me-3'} disabled={isLoading}>
                            Вернуть на регистрацию
                          </Button>
                          <Button onClick={() => setShowStatusConfirm('work')} bsStyle="outline-success"
                                  className={'me-3'} disabled={isLoading}>
                            Отправить в работу
                          </Button>
                        </> : null
                    }
                    {((fields.state === 'registration') && (canRegistrate)) ?
                        <>
                          <Button onClick={() => setShowStatusConfirm('work_without_moderation')}
                                  bsStyle="outline-danger"
                                  className={'me-3'}
                                  disabled={isLoading}>В работу без модерации
                          </Button>
                          <Button onClick={() => setShowStatusConfirm('moderation')}
                                  bsStyle="outline-success"
                                  className={'me-3'}
                                  disabled={isLoading}>Отправить на Модерацию
                          </Button>
                        </> : null
                    }
                    {!is_reception && ((fields.state === 'draft') || (fields.state === 'new')) ?
                        <Button onClick={() => setShowStatusConfirm('registration')} bsStyle="outline-success"
                                  className={'me-3'} disabled={isLoading}>
                            Отправить на регистрацию
                          </Button>
                          : null
                    }
                    <Button onClick={() => handleSave()} bsStyle="primary" disabled={isLoading}>
                      {fields.state === 'draft' ? 'Сохранить как новое' : 'Сохранить'}
                    </Button>
                  </>
                  :
                  null
              }
            </div>
          </>
          :
          <div className="form-container">
            <div className="blocks-container">
              <ReadDetail
                  fields={fields}
                  modelDefinition={modelDefinition}
                  labels={labels}
                  messageId={messageId}
                  responsible={responsible}
              />
            </div>
          </div>
      }
      </>
  );
}

export default FZMessageDetail;

/*
 * {getResponsibleGroups()?.forEach((value, rkey, map) =>{
 *       return (<Tab title={'Группа исполненителей '+ rkey}  className={'show active'} eventKey="resp1">
 *         <div>
 *           <h3>ddd rkey</h3>
 *           <ResponsibleGroup />
 *         </div>
 *       </Tab>)
 *       }
 *       )}
 */


