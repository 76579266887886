import React, { useState, useMemo, useCallback } from 'react';
import {Button} from 'react-bootstrap';
import RadioGroup from "../RadioGroup";
import {FieldContainer} from "./FieldContainer";

function findNameBySlug(options, slug) {
  if (!options || !slug) return {parentName: null, childName: null};
  for (let option of options) {
    if (option.slug === slug) {
      return {parentName: null, childName: option.name};
    }
    if (option.children) {
      for (let child of option.children) {
        if (child.slug === slug) {
          return {parentName: option.name, childName: child.name};
        }
      }
    }
  }
  return {parentName: null, childName: null};
};

export function ButtonGroupField({
  name,
  value,
  label,
  onChange,
  column,
  row,
  required = false,
  hideLabel = false,
  children,
  defaultHide = false,
  multiple = false,
  disabled = false,
  errorMessages
}) {
  const { parentName, childName } = useMemo(() => findNameBySlug(children(name), value(name)), [children, value]);
  const groupField = childName ? `${childName}${parentName ? ` (${parentName})` : ''}` : '';

  const [hide, setHide] = useState(value(name) ? defaultHide: false);

  const toggleHide = useCallback(() => {
    setHide(prevState => !prevState);
  }, []);

  const buttonLabel = hide ? "Редактировать" : "Закрыть";

  const hasChildren = children(name)?.[0]?.children?.length > 0 ||  children(name)?.length > 6;

  return (
    <FieldContainer {...{name, label: label?label(name):'', column, required, hideLabel, errorMessages}}>
      {hasChildren && (
        <span className="fw-bold">
          {groupField && <label className="me-5 selected">{groupField}</label>}
          <Button onClick={toggleHide} className={'btn-transparent'}>
            {buttonLabel}
          </Button>
        </span>
      )}
      {!(hide && hasChildren) && (
        <div className="mt-2">
          <RadioGroup
            label={label?label(name):''}
            options={children(name)}
            value={value(name)}
            onChange={(e) => {
              onChange(name, e);
              hasChildren && toggleHide();
            }}
            name={name}
            multiple={multiple}
            disabled={disabled}
          />
        </div>
      )}
    </FieldContainer>
  );
}
