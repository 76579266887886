import cookie from 'react-cookies';


const resolveJson = async (response) => {
  let json;

  try {
    json = await response.json();
  } catch (error) {
    if (response.status === 204) return {};
    throw {code: response.status, text: "Ошибка сервера" }
  }
  return json;
}

const addAuthToken = (val) => {
  const csrftoken = cookie.load('csrftoken'),
    token = cookie.load('sessionid2');

  if (token)
    val['Authorization'] = `Token ${token}`;
  if (csrftoken)
    val['X-CSRFToken'] = csrftoken;

  return val
};

export default async (url, method = "GET", data = "", isAddAuthToken = false, headersJSON= true, getAllResponseWithErrors= false) => {
  const params = {
    method,
    credentials: 'include',
    headers: {
    }
  };
  if (headersJSON) {
    params.headers['Accept'] = 'application/json'
    params.headers['Content-Type'] = 'application/json'
  }

  if (["PATCH", "POST", "DELETE"].includes(method)) {
    params.headers['X-CSRFToken'] = cookie.load('csrftoken');
    if (headersJSON) {
      params.body = JSON.stringify(data);
    } else {
      params.body = data
    }

  }

  if (isAddAuthToken) params.headers = addAuthToken(params.headers);

  let response;

  try {
    response = await fetch(url, params);
  } catch (error) {
    throw error;
  }

  if("DELETE" === method) return response

  const json = await resolveJson(response);

  if (response.ok)
    return json;

  let msg = typeof data === 'object' ? Object.values(json)[0] : json[0];

  if (getAllResponseWithErrors) {
    msg = typeof json === 'object' ? Object.values(json)[0] : json[0];
    throw { code: response.status, errors: json, text: msg };
  }

  throw msg;
}
