import React, {useState} from 'react';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import uniFetch from "../../../utils/uniFetch";
import {InputField} from "../../../components/Fields/InputField";
import {SelectField} from "../../../components/Fields/SelectField";
import {
  formatDateToSelectOptions,
  getSelectOptionFromSlug,
  getSelectOptionFromSlugOrCreated
} from "../../../utils/common";
import {CreateSelectField} from "../../../components/Fields/СreateSelectField";
import {LoaderModal, ResponseModal} from "../../../components/Modal";
import {ResponseAlert} from "../../../components/ResponseAlert";

const fullColumnSize = 12;
const halfColumnSize = fullColumnSize / 2;
const thirdColumnSize = fullColumnSize / 3;
const fourthColumnSize = fullColumnSize / 4;

export function ApplicantForm({name, item, onChange, modelDefinition, apiUrl, messageId, applicantId, onClose, labels}) {
  const [newItem, setNewItem] = useState(item)
  const [isLoading, setIsLoading] = useState(false)
  const [responseStatus, setResponseStatus] = useState(0);
  const [errorFields, setErrorFields] = useState({})

  const handleChange = (e) => {
    const {name, value} = e.target;

    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });

    setNewItem(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeSelect = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });

    setNewItem(prevFields => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleChangeRegion = (name, value) => {
    setErrorFields(prevErrorFields => {
      const {[name]: _, ...newErrorFields} = prevErrorFields;
      return newErrorFields;
    });
    const exist_region = modelDefinition?.model_definition[name]?.children?.find(child=>child.slug === value);

    setNewItem(prevFields => ({
      ...prevFields,
      [name]: exist_region ? value : '',
      ['postal_district']:  exist_region ? exist_region.name : value
    }));
  };
  const handleSave = () => {
    setIsLoading(true);
    const params = {url: apiUrl, method: 'POST'};
    if (applicantId) {
      params.url = apiUrl + applicantId + '/';
      params.method = 'PATCH';
    };
    let new_data =  {...newItem};
    if (!new_data.message) new_data.message = messageId;

    uniFetch(params.url, params.method, new_data, false, true, true).then((data) => {
      setIsLoading(false);
      onChange(name, data);
    }).catch(data => {
      if (data?.code && data?.errors) {
        setResponseStatus(data?.code)
        setErrorFields(data?.errors);
      } else {
        setResponseStatus(500)
        setErrorFields({error: 'Ошибка сервера'});
      }
      setIsLoading(false)
    })
  }

  const handleCloseResponseModal = () =>{
    setResponseStatus(0)
  }
  return (
    <div>
       {isLoading ? <LoaderModal/>: null}
      <ResponseModal onClose={handleCloseResponseModal}
                     status={responseStatus}>
        <ResponseAlert status={responseStatus} result={errorFields} labels={labels}/>
      </ResponseModal>
      <Row className="mb-3">
        <Col sm={thirdColumnSize}>
          <InputField
            name={'last_name'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <InputField
            name={'first_name'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}

          />
        </Col>
        <Col sm={thirdColumnSize}>
          <InputField
            name={'middle_name'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={thirdColumnSize}>
          <InputField
            name={'last_name_dp'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <InputField
            name={'email'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <Row>
            <Col sm={halfColumnSize}>
              <InputField
                name={'phone_number'}
                value={name => newItem[name]}
                label={name => labels[name]}
                required={name => modelDefinition?.model_definition[name]?.required}
                errorMessages={name => errorFields[name]}
                onChange={handleChange}
                column={2}
                row={2}
              />
            </Col>
            <Col sm={halfColumnSize}>
              <SelectField
                name={'gender'}
                value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children), newItem[name])}
                label={name => labels[name]}
                options={name => formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children)}
                onChange={handleChangeSelect}
                required={name => modelDefinition?.model_definition[name]?.required}
                errorMessages={name => errorFields[name]}
                placeholder="Выберите..."
                column={2}
                row={2}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={thirdColumnSize}>
          <SelectField
            name={'citizenship'}
            value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children), newItem[name])}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            column={3}
            options={name => formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children)}
            onChange={handleChangeSelect}
            errorMessages={name => errorFields[name]}
            placeholder="Выберите..."
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <SelectField
            name={'social_status'}
            value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children), newItem[name])}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            isMulti={true}
            column={3}
            options={name => formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children)}
            onChange={handleChangeSelect}
            errorMessages={name => errorFields[name]}
            placeholder="Выберите..."
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <SelectField
            name={'preference_status'}
            value={name => getSelectOptionFromSlug(formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children), newItem[name])}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            isMulti={true}
            column={3}
            options={name => formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children)}
            onChange={handleChangeSelect}
            errorMessages={name => errorFields[name]}
            placeholder="Выберите..."
            row={2}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={8}>
          <InputField
            name={'orgname'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <InputField
            name={'inn'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
      </Row>
      <h5 className="my-3">Почтовый адрес</h5>
    <Row className="mb-3">
      <Col sm={fourthColumnSize}>
        <InputField
          name={'postal_code'}
          value={name => newItem[name]}
          label={name => labels[name]}
          required={name => modelDefinition?.model_definition[name]?.required}
          errorMessages={name => errorFields[name]}
          onChange={handleChange}
          column={4}
          row={2}
        />
      </Col>
      <Col sm={fourthColumnSize}>
        <InputField
          name={'postal_country'}
          value={name => newItem[name]}
          label={name => labels[name]}
          required={name => modelDefinition?.model_definition[name]?.required}
          errorMessages={name => errorFields[name]}
          onChange={handleChange}
          column={4}
          row={2}
        />
      </Col>
      <Col sm={fourthColumnSize}>
        <InputField
          name={'postal_region'}
          value={name => newItem[name]}
          label={name => labels[name]}
          required={name => modelDefinition?.model_definition[name]?.required}
          errorMessages={name => errorFields[name]}
          onChange={handleChange}
          column={4}
          row={2}
        />
      </Col>
      <Col sm={fourthColumnSize}>
        <CreateSelectField
          name={'region_type'}
          value={name => getSelectOptionFromSlugOrCreated(formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children), newItem[name] ? newItem[name] : newItem['postal_district'])}
          label={name => labels[name]}
          options={name => formatDateToSelectOptions(modelDefinition?.model_definition[name]?.children)}
          onChange={handleChangeRegion}
          required={name => modelDefinition?.model_definition[name]?.required}
          errorMessages={name => errorFields[name]}
          placeholder="Выберите..."
          column={3}
          row={2}
        />
      </Col>
    </Row>
      <Row className="mb-3">
        <Col sm={thirdColumnSize}>
          <InputField
            name={'postal_city'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <InputField
            name={'postal_street'}
            value={name => newItem[name]}
            label={name => labels[name]}
            required={name => modelDefinition?.model_definition[name]?.required}
            errorMessages={name => errorFields[name]}
            onChange={handleChange}
            column={3}
            row={2}
          />
        </Col>
        <Col sm={thirdColumnSize}>
          <Row>
            <Col sm={thirdColumnSize}>
              <InputField
                name={'postal_home'}
                value={name => newItem[name]}
                label={name => labels[name]}
                required={name => modelDefinition?.model_definition[name]?.required}
                errorMessages={name => errorFields[name]}
                onChange={handleChange}
                column={3}
                row={2}
              />
            </Col>
            <Col sm={thirdColumnSize}>
              <InputField
                name={'postal_corpus'}
                value={name => newItem[name]}
                label={name => labels[name]}
                required={name => modelDefinition?.model_definition[name]?.required}
                errorMessages={name => errorFields[name]}
                onChange={handleChange}
                column={3}
                row={2}
              />
            </Col>
            <Col sm={thirdColumnSize}>
              <InputField
                name={'postal_house'}
                value={name => newItem[name]}
                label={name => labels[name]}
                required={name => modelDefinition?.model_definition[name]?.required}
                errorMessages={name => errorFields[name]}
                onChange={handleChange}
                column={3}
                row={2}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="col-12 text-end">
        <Button onClick={onClose} bsStyle="danger" className={'mx-2 my-3'}>Отменить</Button>
        <Button onClick={handleSave} bsStyle="success" className={'my-3'}>Сохранить</Button>
      </div>

  </div>)

}
