import React from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';

export function FieldContainer({name, label, column = 1, row = 1, required, hideLabel, children, errorMessages, className, inputClassName, lableClassName}) {
  const labelClass = row === 1 ? `col-md-${column * 2} col-form-label col-12` + " " + lableClassName ?? "": 'col-form-label '+ lableClassName ?? "";
  const inputClass = row === 1 ? `col-md-${hideLabel ? 12 : 12 - column * 2} col-12`: '';
  const errors = errorMessages ? errorMessages(name) : [];
  const requiredField =  typeof required === 'function' ? required(name) : required;

  return (
    <FormGroup
      className={`form-group row${requiredField ? ' required' : ''}` + " " + className ?? ""}
      controlId={name}
      validationState={errors?.length ? 'error': null}
    >
      {!hideLabel ? <ControlLabel className={labelClass}>{label ?? ''}</ControlLabel> : null}
      <div className={inputClass + " "+ inputClassName ?? ""}>
        {children}
      </div>
      <FormControl.Feedback />
      {errors?.map((error, index)=> <HelpBlock key={index}>{error}</HelpBlock>)}
    </FormGroup>
  );
}
