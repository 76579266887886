import React, {useState} from 'react';
import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap';

import {AuthorForm} from './Form'

const fullColumnSize = 12;
const thirdColumnSize = 4;
const twoColumnSize = 6;
const sixColumnSize = 2;
const fieldLabels = {
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  email: 'Email',
};

export default function CoAuthorsBlock(props) {
  const [coAuthors, setCoAuthors] = useState(0);

  const addCoAuthor = () => {
    setCoAuthors(coAuthors + 1);
    props.setFields(prevState => {
      const newIndex = Object.keys(prevState.co_authors || {}).length;
      return {
        ...prevState,
        co_authors: {
          ...prevState.co_authors,
          [newIndex]: {}
        }
      };
    });
  };

  const removeCoAuthor = (index) => {
    setCoAuthors(coAuthors - 1);
    props.setFields(prevState => {
      const { [index]: _, ...updatedCoAuthors } = prevState.co_authors;
      const reindexedCoAuthors = Object.keys(updatedCoAuthors).reduce((acc, key, idx) => {
        acc[idx] = updatedCoAuthors[key];
        return acc;
      }, {});
      return {
        ...prevState,
        co_authors: reindexedCoAuthors
      };
    });
  };

  return (
    <Row>
      {Array.from(Array(coAuthors), (_, index) => (
        <React.Fragment key={index}>
          <Col sm={fullColumnSize}>
            <hr/>
            <AuthorForm
              coAuthors={true}
              index={index}
              fields={props.fields}
              setFields={props.setFields}
              agree={props.agree}
              setAgree={props.setAgree}
              socialStatuses={props.socialStatuses}
              categories={props.categories}
              errorsFields={props.errorsFields}
              setErrorsFields={props.errorsFields}
              validatePhoneNumber={props.validatePhoneNumber}
              setPhoneError={props.setPhoneError}
              canEdit={props.canEdit}
            />
          </Col>
          <Col sm={sixColumnSize}>
            <button
              className="btn btn-primary add-coauthor"
              disabled={!props.canEdit}
              onClick={(e) => {
                e.preventDefault();
                removeCoAuthor(index);
              }}
            >
              -
            </button>
          </Col>
        </React.Fragment>
      ))}
      <Col sm={sixColumnSize}>
        <button
          className="btn btn-primary add-coauthor"
          disabled={!props.canEdit}
          onClick={(e) => {
            e.preventDefault();
            addCoAuthor();
          }}
        >
          +
        </button>
      </Col>
    </Row>
  );
}
