import React, {useState} from 'react';
import { Row, Col, Button, HelpBlock } from 'react-bootstrap';

import uniFetch from "../../utils/uniFetch";
import {InputField} from "../../components/Fields/InputField";
import {ResponseAlert} from "../../components/ResponseAlert";


function FZOrganizationUser(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorsPasswordFields, setErrorsPasswordFields] = useState({});
  const [fields, setFields] = useState({});
  const [status, setStatus] = useState(0)


  const handleChangeFields = (e) => {
    const {name, value} = e.target;
    setFields(prevState => {
      return {...prevState, [name]: value};
    });
  }

  const handleSave = () => {
    setStatus(0)
    setIsLoading(true)
    if (fields.password === "") {
      delete fields['password']
    } else if (fields.password2 === "") {
      delete fields['password2']
    }
    uniFetch('/fz59/change-user-data/', 'POST', fields, false, true, true)
    .then(data => {
      setFields({})
      setErrorsPasswordFields({})
      setStatus(200)
    })
    .catch((data) => {
      setErrorsPasswordFields({errors: data.errors})
      setStatus(0)
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <div className="form-container">
      <div className="blocks-container">
        <div className="block-container full-width mb-5 w-100">
          <label className="block-label d-flex justify-content-between align-items-center">Пользователь</label>
          <div className="card">
            <div className="card-body">
              <Row className='justify-content-center'>
                {isLoading ?
                <div className="text-center">
                  <div className="spinner-border text-primary m-5" role="status"
                       style={{width: '3rem', height: '3rem'}}>
                    <span className="visually-hidden">Загрузка...</span>
                  </div>
                </div> :
                  <Row>
                    {/*hack против автозаполнения бразуером полей при рендеринге*/}
                    <input type="text" style={{display: 'none'}}/>
                    <input type="password" style={{display: 'none'}}/>
                    <Row className='justify-content-center mb-2'>
                      <Col sm={6}>
                        <InputField
                          name="username"
                          value={name => fields[name]}
                          label={name => "Новый логин"}
                          type="text"
                          required={false}
                          onChange={handleChangeFields}
                          placeholder={'Можно оставить пустым'}
                          column={2}
                          errorMessages={name => errorsPasswordFields?.errors?.username ? [errorsPasswordFields?.errors?.username] : null}
                        />
                      </Col>
                    </Row>
                    <Row className='justify-content-center mb-2'>
                      <Col sm={6}>
                        <InputField
                          name="password"
                          value={name => fields[name]}
                          label={name => "Пароль"}
                          type="password"
                          required={true}
                          onChange={handleChangeFields}
                          column={2}
                          errorMessages={name => errorsPasswordFields?.errors?.password ? [errorsPasswordFields?.errors?.password] : null}
                        />
                      </Col>
                    </Row>
                    <Row className='justify-content-center mb-2'>
                      <Col sm={6}>
                        <InputField
                          name="password2"
                          value={name => fields[name]}
                          label={name => "Повторите пароль"}
                          type="password"
                          required={true}
                          onChange={handleChangeFields}
                          column={2}
                          errorMessages={name => errorsPasswordFields?.errors?.password2 ? [errorsPasswordFields?.errors?.password2] : null}
                        />
                      </Col>
                    </Row>
                  </Row>}
              </Row>
              <Row className='justify-content-center mb-2'>
                <Col sm={6} className={'pt-2'}>
                  <ul>
                    <li>Пароль должен содержать как минимум 6 символов.</li>
                    <li>Пароль должен содержать как минимум одну цифру.</li>
                    <li>Пароль должен содержать как минимум одну букву в верхнем регистре и нижнем регистре.</li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className={'pt-3'}>
                {errorsPasswordFields.errors?.error && (
                    <HelpBlock className={"alert alert-danger d-flex justify-content-center"}>{errorsPasswordFields.errors.error}</HelpBlock>
                )}
                <ResponseAlert status={status} result={{}} labels={{}}/>
                </Col>
                <div className="d-flex justify-content-center">
                  <Button bsStyle="success" disabled={isLoading} className={'my-3'} onClick={handleSave}>Сохранить</Button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default FZOrganizationUser
